import React from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import ReactDragListView from 'react-drag-listview';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';
const { v1: uuidv1 } = require('uuid');

class ModifierComponent extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        let modifierId = this.props.modifierId;
        let errorOptions = [];
        let modifierData = this.props && this.props.modifierData ? this.props.modifierData : null;
        let itemOptions = this.props && this.props.itemOptions ? this.props.itemOptions : [];
        let optionArray = [];
        if (modifierData && modifierData.options && modifierData.options.length) {
            for (let i = 0; i < modifierData.options.length; i += 1) {
                optionArray.push(modifierData.options[i]);
                var temp = optionArray.sort((a, b) => a.isSorting - b.isSorting);
                optionArray=temp;
                errorOptions.push({
                    price: "",
                    name: "",
                    fullPrice: "",
                    halfPrice: ""
                });
            }
        }
        if (!modifierData) {
            this.getSingleModifiers(modifierId);
        }
        this.state = {
            vendorAllDetails,
            modifierId: modifierId,
            allChangePrice: "0",
            model: this.props && this.props.model ? this.props.model : "add",
            editModifierData: {
                _id: modifierData && modifierData._id ? modifierData._id: "",
                name: modifierData && modifierData.name ? modifierData.name : "",
                displayName: modifierData && modifierData.displayName ? modifierData.displayName : "",
                choiceLimit: modifierData && modifierData.choiceLimit ? modifierData.choiceLimit : "",
                isRequired: modifierData && modifierData.isRequired === true ? true : false,
                isFixed: modifierData && modifierData.isFixed === true ? true : false,
                isComesWith: modifierData && modifierData.isComesWith === true ? true : false,
                isItemOption: modifierData && modifierData.isItemOption === true ? true : false,
                isPizzaTopping: modifierData && modifierData.isPizzaTopping === true ? true : false,
                ofFull: modifierData && modifierData.ofFull ? modifierData.ofFull : 0,
                options: optionArray && optionArray.length ? optionArray : [],
                isItemOptionId: modifierData.isItemOptionId ? modifierData.isItemOptionId : "",
                id: modifierData && modifierData.id ? modifierData.id : uuidv1().toUpperCase(),
            },
            editModifierErrorData: {
                name: '',
                displayName: '',
                options: errorOptions
            },
            submitProgress: false,
            itemOptions: itemOptions
        }
        let self = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let editModifierData = self.state.editModifierData;
                const options = [...editModifierData.options];
                const item = options.splice(fromIndex, 1)[0];
                options.splice(toIndex, 0, item);
                for (let i = 0; i < options.length; i += 1) {
                    options[i].isSorting = i;
                }
                editModifierData.options = options;
                self.setState({
                    editModifierData: editModifierData
                });
                self.setState({orderChange: true});
            },
            nodeSelector: "section",
            handleSelector: 'p'
        };
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
    }

    componentDidMount() {
    }
    async getSingleModifiers(modifierId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifiers/getsingle/${vendorId}/${modifierId}`;
        if (vendorId && modifierId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifiers(modifierId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.options && data.options.length) {
                                for (let i = 0; i < data.options.length; i += 1) {
                                    errorOptions.push({
                                        price: "",
                                        name: "",
                                        fullPrice: "",
                                        halfPrice: "",
                                    });
                                }
                            }
                            let tempFormData = self.state.editModifierData;
                            let tempFormErrorData = self.state.editModifierErrorData;
                            tempFormData.name = data && data.name ? data.name : "";
                            tempFormData.displayName = data && data.displayName ? data.displayName : "";
                            tempFormData.choiceLimit = data && data.choiceLimit ? data.choiceLimit : "";
                            tempFormData.isRequired =  data && data.isRequired === true ? true : false;
                            tempFormData.isFixed =  data && data.isFixed === true ? true : false;
                            tempFormData.isComesWith =  data && data.isComesWith === true ? true : false;
                            tempFormData.isItemOption =  data && data.isItemOption === true ? true : false;
                            tempFormData.isPizzaTopping =  data && data.isPizzaTopping === true ? true : false;
                            tempFormData.ofFull =  data && data.ofFull ? data.ofFull : 0;
                            let optionArray = [];
                            if (data && data.options && data.options.length) {
                                for (let i = 0; i < data.options.length; i += 1) {
                                    optionArray.push(data.options[i]);
                                    var temp = optionArray.sort((a, b) => a.isSorting - b.isSorting);
                                    optionArray=temp;
                                }
                            }
                            tempFormData.options =  optionArray;
                            self.setState({ editModifierData: tempFormData });
                            tempFormErrorData.name = '';
                            tempFormErrorData.displayName = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editModifierErrorData: tempFormErrorData });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    onAlertPriceChange(e) {
        e.preventDefault();
        console.log('allChangePrice', this.state.allChangePrice)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this modifier price.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let tempData = self.state.editModifierData;
                if (tempData.options && tempData.options.length) {
                    for (let i = 0; i < tempData.options.length; i++) {
                        tempData.options[i].fullPrice = self.state.allChangePrice;
                        if (Number(tempData.options[i].fullPrice) > 0 && Number(tempData.ofFull) > 0) {
                            tempData.options[i].halfPrice = (Number(tempData.options[i].fullPrice)*Number(tempData.ofFull))/100;
                        }
                    }
                }
                this.setState({ editModifierData: tempData})
                // let requestData = {
                //     id: id,
                //     vendorID: row.vendorID
                // }
                // let url = `delivery/removedeliverypartner`;
                // try {
                //     let token = getAuthTokenFromLocalStorage();
                //     if (!token) {
                //         let auth = await getAuth();
                //         if (auth && auth.success && auth.token) {
                //             localStorage.setItem('token', auth.token);
                //         }
                //     }
                //     axiosInstance.delete(url, { data: requestData })
                //         .then(result => {
                //             // If auth token expired
                //             if ("success" in result.data && result.data.success === false) {
                //                 localStorage.removeItem('token');
                //                 toast.error("Oops! We encountered a problem in deleting the delivery partner. Please try again.", {
                //                     autoClose: 5000,
                //                     closeOnClick: true,
                //                     pauseOnHover: true,
                //                 });
                //                 return;
                //             }
                //             if (result.data.status) {
                //                 self.getDeliveryPartnersList();
                //                 toast.success("Success: delivery partner deleted!", {
                //                     autoClose: 3000,
                //                     closeOnClick: true,
                //                     pauseOnHover: true,
                //                 })
                //             } else {
                //                 toast.error(result.data.statusText, {
                //                     autoClose: 5000,
                //                     closeOnClick: true,
                //                     pauseOnHover: true,
                //                 });
                //             }
                //         })
                //         .catch(error => {
                //             toast.error("Oops! We encountered a problem in deleting the delivery partner. Please try again.", {
                //                 autoClose: 5000,
                //                 closeOnClick: true,
                //                 pauseOnHover: true,
                //             });
                //             console.error("Problem in deleting delivery partner: ", error);
                //         })
                // }
                // catch (error) {
                //     toast.error("Oops! We encountered a problem in deleting the driver. Please try again.", {
                //         autoClose: 5000,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //     });
                //     console.error("Problem in deleting driver: ", error);
                // }
            }
        });
    }
    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editModifierData;
        let formErrors = this.state.editModifierErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"].trim() === "")) {
            fieldName = 'name';
            formDetails["name"] = "";
            formErrors[fieldName] = `The 'Name' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "displayName" && value === "") || (formSubmit === true && formDetails["displayName"].trim() === "")) {
            fieldName = 'displayName';
            formDetails["displayName"] = "";
            formErrors[fieldName] = `The 'Display Name' is a required field.`;
            isError = true;
        }

        if (!formDetails.isPizzaTopping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                        // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        fieldName = 'options';
                        formDetails.options[i].name = "";
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].price === "") {
                        fieldName = 'options';
                        formErrors.options[i].price = `The 'Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].price) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].price ="";
                        isError = true;
                    }
                }
            }
        } else {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                let dName = newField;
                if (newField === 'fullPrice') {
                    dName = "Full Price";
                } else if(newField === 'halfPrice') {
                    dName = "Half Price";
                }
                if (index !== undefined) {
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${dName}' is a required field.`;
                        isError = true;
                    } else if (newField === 'fullPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    } else if (newField === 'halfPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        formDetails.options[i].name = "";
                        fieldName = 'options';
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].fullPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].fullPrice = `The 'Full Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].fullPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].fullPrice ="";
                        isError = true;
                    }
                    if (formDetails.options[i].halfPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].halfPrice = `The 'Half Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].halfPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].halfPrice ="";
                        isError = true;
                    }
                }
            }
        }

        
        this.setState({ editModifierErrorData: formErrors });
        this.setState({ editModifierData: formDetails });
        this.props.errorMessage(isError);
        return isError;
    }
    onBasicDetailsChange(e, name = "") {
        e.preventDefault();
        let formDetails = this.state.editModifierData;
        let formErrors = this.state.editModifierErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editModifierData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        this.props.onChangeInput();
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editModifierErrorData: formErrors });
        }
    }
    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editModifierData;
        let formDetailsError = this.state.editModifierErrorData;
        formDetails[formField].push({
            name: "",
            price: 0,
            isSorting: formDetails[formField].length,
            fullPrice: 0,
            halfPrice: 0,
            id: uuidv1().toUpperCase()
        });
        formDetailsError[formField].push({
            name: "",
            price: "",
            fullPrice: "",
            halfPrice: ""
        });
        this.setState({ editModifierData: formDetails, editModifierErrorData: formDetailsError})
    }
    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editModifierData;
        let formDetailsError = this.state.editModifierErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        for (let i = 0; i < formDetails.options.length; i += 1) {
            formDetails.options[i].isSorting = i;
        }
        this.setState({ editModifierData: formDetails, editModifierErrorData: formDetailsError})
    }
    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        let tmpeditModifierData = self.state.editModifierData;
        if ((tmpeditModifierData.isRequired || tmpeditModifierData.isFixed) && !tmpeditModifierData.choiceLimit) {
            tmpeditModifierData.choiceLimit = "1";
        }
        if (Number(tmpeditModifierData.choiceLimit) > tmpeditModifierData.options.length) {
            toast.error(`Please Add ${Number(tmpeditModifierData.choiceLimit) - tmpeditModifierData.options.length} More Option`, {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        tmpeditModifierData.name = tmpeditModifierData.name.trim();
        tmpeditModifierData.displayName = tmpeditModifierData.displayName.trim();
        for (let i = 0; i < tmpeditModifierData.options.length; i += 1) {
            tmpeditModifierData.options[i].name = tmpeditModifierData.options[i].name.trim();
            if (tmpeditModifierData.isPizzaTopping) {
                tmpeditModifierData.options[i].fullPrice = Number(Number(tmpeditModifierData.options[i].fullPrice).toFixed(2));
                tmpeditModifierData.options[i].halfPrice = Number(Number(tmpeditModifierData.options[i].halfPrice).toFixed(2));
                tmpeditModifierData.options[i].price = 0;
            } else if (tmpeditModifierData.isComesWith) {
                tmpeditModifierData.options[i].isChecked = true;
                tmpeditModifierData.options[i].fullPrice = 0;
                tmpeditModifierData.options[i].halfPrice = 0;
                tmpeditModifierData.options[i].price = Number(Number(tmpeditModifierData.options[i].price).toFixed(2));
            } else if (tmpeditModifierData.isItemOption) {
                if (i === 0) {
                    tmpeditModifierData.options[i].isChecked = true;
                } else {
                    tmpeditModifierData.options[i].isChecked = false;
                }
                tmpeditModifierData.options[i].fullPrice = 0;
                tmpeditModifierData.options[i].halfPrice = 0;
                tmpeditModifierData.options[i].price = Number(Number(tmpeditModifierData.options[i].price).toFixed(2));
            } else {
                tmpeditModifierData.options[i].isChecked = false;
                tmpeditModifierData.options[i].fullPrice = 0;
                tmpeditModifierData.options[i].halfPrice = 0;
                tmpeditModifierData.options[i].price = Number(Number(tmpeditModifierData.options[i].price).toFixed(2));
            }
        }
        tmpeditModifierData.ofFull = Number(Number(tmpeditModifierData.ofFull).toFixed(2));
        this.props.save(tmpeditModifierData, isError);
    }
    render() {
        let { vendorAllDetails,
            editModifierData,
            editModifierErrorData,
            submitProgress,
            itemOptions,
            allChangePrice,
            model
        } = { ...this.state }
        return (
            <div className="add-product-page">
                {vendorAllDetails &&
                    <div>
                        <form className="needs-validation clearfix" noValidate>
                            <ReactTooltip />
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <button type="submit" className="btn btn-primary float-right mt-0" onClick={this.onEditSubmit}>
                                        Modifier Save{submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (editModifierErrorData.name !== "" ? 'invalid' : '')}
                                            id="name"
                                            name="name"
                                            maxLength="40"
                                            placeholder="Name"
                                            value={editModifierData.name}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {editModifierErrorData.name !== "" &&
                                            <div className="invalid-feedback">
                                                {editModifierErrorData.name}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Display Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (editModifierErrorData.displayName !== "" ? 'invalid' : '')}
                                            id="displayName"
                                            name="displayName"
                                            maxLength="40"
                                            placeholder="Display Name"
                                            value={editModifierData.displayName}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {editModifierErrorData.displayName !== "" &&
                                            <div className="invalid-feedback">
                                                {editModifierErrorData.displayName}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isItemOption ? true : false}
                                        value={editModifierData.isItemOption}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.choiceLimit = "1";
                                                tempeditModifierData.isRequired = true;
                                                tempeditModifierData.isFixed = false;
                                                tempeditModifierData.isComesWith = false;
                                                tempeditModifierData.isPizzaTopping = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isItemOption = event.target.value === 'true' ? true : false;
                                            this.props.onChangeInput();
                                            this.setState({ editModifierData: tempeditModifierData})
                                        }}
                                        disabled={editModifierData.isComesWith || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Item Option
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="choiceLimit">Choice Limit</label>
                                    <div className="input-group">
                                        <select
                                            className="form-control"
                                            name="choiceLimit"
                                            id="choiceLimit"
                                            onChange={(event) => {
                                                let tempeditModifierData = this.state.editModifierData;
                                                tempeditModifierData.choiceLimit = event.target.value;
                                                this.props.onChangeInput();
                                                this.setState({ editModifierData: tempeditModifierData})
                                            }}
                                            value={editModifierData.choiceLimit}
                                            disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping}>
                                            <option value="">No Limit</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isRequired ? true : false}
                                        value={editModifierData.isRequired}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempeditModifierData = this.state.editModifierData;
                                            tempeditModifierData.isRequired = event.target.value === 'true' ? true : false;
                                            tempeditModifierData.isFixed = false;
                                            if (tempeditModifierData.isRequired && !tempeditModifierData.choiceLimit) {
                                                tempeditModifierData.choiceLimit = "1";
                                            }
                                            this.props.onChangeInput();
                                            this.setState({ editModifierData: tempeditModifierData})
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Required
                                    </label>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isFixed ? true : false}
                                        value={editModifierData.isFixed}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempeditModifierData = this.state.editModifierData;
                                            tempeditModifierData.isFixed = event.target.value === 'true' ? true : false;
                                            tempeditModifierData.isRequired = false;
                                            if (tempeditModifierData.isFixed && !tempeditModifierData.choiceLimit) {
                                                tempeditModifierData.choiceLimit = "1";
                                            }
                                            this.props.onChangeInput();
                                            this.setState({ editModifierData: tempeditModifierData})
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Fixed
                                    </label>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isComesWith ? true : false}
                                        value={editModifierData.isComesWith}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.isItemOption = false;
                                                tempeditModifierData.isPizzaTopping = false;
                                                tempeditModifierData.choiceLimit = "";
                                                tempeditModifierData.isRequired = false;
                                                tempeditModifierData.isFixed = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isComesWith = event.target.value === 'true' ? true : false;
                                            this.props.onChangeInput();
                                            this.setState({ editModifierData: tempeditModifierData})
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Comes With
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 col-lg-4">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isPizzaTopping ? true : false}
                                        value={editModifierData.isPizzaTopping}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.isComesWith = false;
                                                tempeditModifierData.isItemOption = false;
                                                tempeditModifierData.choiceLimit = "";
                                                tempeditModifierData.isRequired = false;
                                                tempeditModifierData.isFixed = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isPizzaTopping = event.target.value === 'true' ? true : false;
                                            this.props.onChangeInput();
                                            this.setState({ editModifierData: tempeditModifierData})
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Pizza Topping
                                    </label>
                                </div>
                                {editModifierData.isPizzaTopping && 
                                    <div className="col-lg-1 col-md-1">
                                        <label htmlFor="name">% of full</label>
                                        <div className="input-group">
                                        <input type="number"
                                            className="form-control"
                                            id="ofFull"
                                            name="ofFull"
                                            placeholder="% of full"
                                            value={editModifierData.ofFull}
                                            onWheel={ event => event.currentTarget.blur() }
                                            onChange={(e) => {
                                                let tempData = editModifierData;
                                                tempData.ofFull = e.target.value;
                                                if (Number(tempData.ofFull) < 0) {
                                                    tempData.ofFull = "";
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = 0;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = (Number(tempData.options[i].fullPrice)*Number(editModifierData.ofFull))/100;
                                                            }
                                                        }
                                                    }
                                                }
                                                this.props.onChangeInput();
                                                this.setState({ editModifierData: tempData})
                                            }}
                                        />
                                        </div>
                                    </div>
                                }
                                {editModifierData.isPizzaTopping && model === "edit" &&
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="name">Change All Price</label>
                                        <div className="input-group">
                                        <input type="number"
                                            className="form-control"
                                            id="allChangePrice"
                                            name="allChangePrice"
                                            placeholder="Price"
                                            value={allChangePrice}
                                            onWheel={ event => event.currentTarget.blur() }
                                            onChange={(e) => {
                                                allChangePrice = e.target.value;
                                                this.setState({ allChangePrice: allChangePrice})
                                            }}
                                        />
                                            {!allChangePrice && 
                                                <button type="button" className={"btn btn-primary ml-3 mt-0 pt-1 pb-1 " + (!allChangePrice ? 'disabled' : '')}>Change</button>
                                            }
                                            {allChangePrice && 
                                                <button type="button" className="btn btn-primary ml-3 mt-0 pt-1 pb-1" onClick={(e) => this.onAlertPriceChange(e)}>Change</button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {!editModifierData.isItemOption && !editModifierData.isComesWith &&
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="ite,">Item Option List</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control"
                                                name="isItemOptionId"
                                                id="isItemOptionId"
                                                onChange={(event) => {
                                                    let tempeditModifierData = this.state.editModifierData;
                                                    tempeditModifierData.isItemOptionId = event.target.value;
                                                    this.props.onChangeInput();
                                                    this.setState({ editModifierData: tempeditModifierData})
                                                }}
                                                value={editModifierData.isItemOptionId}>
                                                <option value="">No Select</option>
                                                {itemOptions.length > 0 && itemOptions.map((iRow, index) => (
                                                    <option value={iRow.id} key={index}>{iRow.name}</option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <label>Options</label>
                                    <div className="row">
                                        <div className="col-md-1 option-drag-drop"></div>
                                        <div className="col-md-3">
                                            <label htmlFor="optionaName">Name</label>
                                        </div>
                                        {!editModifierData.isPizzaTopping &&
                                            <div className="col-md-1">
                                                <label htmlFor="optionaPrice">Price</label>
                                            </div>
                                        }
                                        {editModifierData.isPizzaTopping &&
                                            <div className="col-md-1">
                                                <label htmlFor="fullPrice">Full</label>
                                            </div>
                                        }
                                        {editModifierData.isPizzaTopping &&
                                            <div className="col-md-1">
                                                <label htmlFor="halfPrice">Half</label>
                                            </div>
                                        }
                                    </div>
                                    <ReactDragListView {...this.dragProps}>
                                        {editModifierData.options && editModifierData.options.length > 0 &&
                                            editModifierData.options.map((optionsRow, index) => (
                                                <section key={index}>
                                                    <div className="row">
                                                        <div className="col-md-1 option-drag-drop">
                                                            {/* eslint-disable-next-line */}
                                                            <p className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <input type="text"
                                                                    className={"form-control " + (editModifierErrorData.options[index].name !== "" ? 'invalid' : '')}
                                                                    id="optionaName"
                                                                    name="optionaName"
                                                                    maxLength="40"
                                                                    placeholder="Name"
                                                                    value={optionsRow.name}
                                                                    onChange={(e) => {
                                                                        let tempData = editModifierData;
                                                                        let tempErrorData = editModifierErrorData;
                                                                        tempData.options[index].name = e.target.value;
                                                                        this.props.onChangeInput();
                                                                        this.setState({ editModifierData: tempData})
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'name');
                                                                        if (!isError) {
                                                                            tempErrorData.options[index].name = "";
                                                                            this.setState({ editModifierErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                    />
                                                                    {editModifierErrorData.options[index].name !== "" &&
                                                                        <div className="invalid-feedback">
                                                                            {editModifierErrorData.options[index].name}
                                                                        </div>
                                                                    }
                                                            </div>
                                                        </div>
                                                        {!editModifierData.isPizzaTopping &&
                                                            <div className="col-md-1">
                                                                <div className="input-group">
                                                                    <input type="number"
                                                                        className={"form-control " + (editModifierErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                                        id="optionaPrice"
                                                                        name="optionaPrice"
                                                                        placeholder="Price"
                                                                        value={optionsRow.price}
                                                                        onWheel={ event => event.currentTarget.blur() }
                                                                        onChange={(e) => {
                                                                            let tempData = editModifierData;
                                                                            let tempErrorData = editModifierErrorData;
                                                                            tempData.options[index].price = e.target.value;
                                                                            this.props.onChangeInput();
                                                                            this.setState({ editModifierData: tempData})
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].price = "";
                                                                            this.setState({ editModifierErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        {/* {editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                        } */}
                                                                        {editModifierErrorData.options[index].price !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {editModifierErrorData.options[index].price}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                        }
                                                        {!editModifierData.isPizzaTopping && editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                            <div className="col-md-1">
                                                                <div className="input-group">
                                                                    <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                </div>
                                                            </div>
                                                        }
                                                        {editModifierData.isPizzaTopping &&
                                                            <div className="col-md-1">
                                                                <div className="input-group">
                                                                    <input type="number"
                                                                        className={"form-control " + (editModifierErrorData.options[index].fullPrice !== "" ? 'invalid' : '')}
                                                                        id="fullPrice"
                                                                        name="fullPrice"
                                                                        placeholder="Full Price"
                                                                        value={optionsRow.fullPrice}
                                                                        onWheel={ event => event.currentTarget.blur() }
                                                                        onChange={(e) => {
                                                                            let tempData = editModifierData;
                                                                            let tempErrorData = editModifierErrorData;
                                                                            tempData.options[index].fullPrice = e.target.value;
                                                                            if (Number(tempData.options[index].fullPrice) > 0 && Number(editModifierData.ofFull) > 0) {
                                                                                tempData.options[index].halfPrice = (Number(tempData.options[index].fullPrice)*Number(editModifierData.ofFull))/100;
                                                                            }
                                                                            this.props.onChangeInput();
                                                                            this.setState({ editModifierData: tempData})
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'fullPrice');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].fullPrice = "";
                                                                            this.setState({ editModifierErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        {editModifierErrorData.options[index].fullPrice !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {editModifierErrorData.options[index].fullPrice}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                        }
                                                        {editModifierData.isPizzaTopping &&
                                                            <div className="col-md-1">
                                                                <div className="input-group">
                                                                    <input type="number"
                                                                        className={"form-control " + (editModifierErrorData.options[index].halfPrice !== "" ? 'invalid' : '')}
                                                                        id="halfPrice"
                                                                        name="halfPrice"
                                                                        placeholder="Half Price"
                                                                        value={optionsRow.halfPrice}
                                                                        onWheel={ event => event.currentTarget.blur() }
                                                                        onChange={(e) => {
                                                                            let tempData = editModifierData;
                                                                            let tempErrorData = editModifierErrorData;
                                                                            tempData.options[index].halfPrice = e.target.value;
                                                                            this.props.onChangeInput();
                                                                            this.setState({ editModifierData: tempData})
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'halfPrice');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].halfPrice = "";
                                                                            this.setState({ editModifierErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        {/* {editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                        } */}
                                                                        {editModifierErrorData.options[index].halfPrice !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {editModifierErrorData.options[index].halfPrice}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                        }
                                                        {editModifierData.isPizzaTopping && editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                            <div className="col-md-1">
                                                                <div className="input-group">
                                                                    <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </section>
                                            ))
                                        }
                                    </ReactDragListView>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    {/* eslint-disable-next-line */}
                                    <a className="common-link" onClick={(e) => this.onAddField(e, "options")}>Add New</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                        Modifier Save{submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                }
            </div>
        );
    }
}

export default ModifierComponent;