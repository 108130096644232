import React from 'react'
import ReactDOM from "react-dom";
import queryString from 'query-string';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import DataTablesComp from '../../DataTables';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';

class PrintManagement extends React.Component {
    constructor(props) {
        super(props);
        let activeTab = queryString.parse(this.props.location.search).tab;
        if (!activeTab || activeTab === "") {
            activeTab = "Print Stations";
        }
        if (activeTab && activeTab === 'print_stations') {
            activeTab = "Print Stations";
        }
        if (activeTab && activeTab === 'print_product_management') {
            activeTab = "Print Product Management";
        }
        const columns = [
            { title: 'Print Station Name', data: 'printStationName' },
            { title: 'Device Address', data: 'tdeviceAddress' },
            { title: 'Host Name', data: 'thostName' },
            { title: 'Port', data: 'tport' },
            { title: 'Dot width', data: 'tdotwidth' },
            { title: 'Printer Vendor', data: 'tprinterVendor' },
            { title: 'Auto Print', data: 'autoPrintStation' },
            { title: 'Print Type', data: 'printType' },
            { title: 'Action', data: 'printType', orderable: false },
        ];
        this.state = {
            printStation: {
                data: null
            },
            columns: columns,
            reloadTable: false,
            activeTab: activeTab
        }
        this.openAddPrintStationPage = this.openAddPrintStationPage.bind(this);
        this.openEditPrintStationPage = this.openEditPrintStationPage.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.openPreviewPage = this.openPreviewPage.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidMount() {
        this.getPrintStation();
    }

    onTabChange(tabName) {
        this.setState({ activeTab: tabName });
    }

    onActionChange(event, printStationId, row) {
        let action = event.target.value;
        if (action === "Edit") {
            this.openEditPrintStationPage(event, printStationId, row);
        }
        if (action === "Delete") {
            this.confirmAndDelete(event, printStationId, row);
        }
        // if(action === "Preview"){
        //     this.openPreviewPage(event, printStationId, row);
        // }

        event.target.value = "";
    }

    confirmAndDelete(e, printStationId, row) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this print station.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let requestData = {
                    id: printStationId,
                    vendorID: row.vendorID,
                    printStationName: row.printStationName
                }
                let url = `print/remove-printstation`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the print station. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                self.getPrintStation();
                                toast.success("Success: print station deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the print station. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting print station: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the print station. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting print station: ", error);
                }
            }
        });
    }

    openPreviewPage(event) {
        event.preventDefault();
        this.props.history.push(`/print_management/preview`);
    }

    openAddPrintStationPage(event) {
        event.preventDefault();
        this.props.history.push(`/print_management/print_station/add`);
    }

    openEditPrintStationPage(event, printStationId, row) {
        event.preventDefault();
        this.props.history.push(`/print_management/print_station/${printStationId}`, { printStationData: row });
    }

    preparePrintStationData(data) {
        let printData = [];
        let printType = ''
        printData = data.map(pData => {
            if (pData.printTicket) {
                printType = 'Ticket';
            }
            if (pData.printReceipt) {
                printType = 'Receipt';
            }
            if (pData.printTicket && pData.printReceipt) {
                printType = 'Ticket, Receipt';
            }
            return {
                ...pData,
                tdeviceAddress: pData?.deviceAddress ? pData.deviceAddress : 'Cloud print not enabled',
                thostName: pData?.host?.hostName ? pData.host.hostName : 'Local print not enabled',
                tport: pData?.host?.port ? pData.host.port : ' - ',
                tdotwidth: pData?.dotwidth ? pData.dotwidth : ' - ',
                tprinterVendor: pData?.printerVendor ? pData.printerVendor : ' - ',
                printType: printType,
                autoPrintStation: pData.autoPrint ? 'Yes' : 'No'
            }
        });
        return printData;
    }

    async getPrintStation() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `print/get-print-station/${vendorId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getPrintStation();
                    return;
                }
                let printStationData = result.data.response;
                this.setState({
                    printStation: {
                        data: self.preparePrintStationData(printStationData),
                        columnDefs: [{
                            targets: [8],
                            createdCell: (td, cellData, rowData) =>
                                ReactDOM.render(
                                    <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData._id, rowData)}>
                                            <option value="">Select</option>
                                            <option value="Edit">Edit</option>
                                            {/* <option value="Preview">Preview</option> */}
                                            <option value="Delete">Delete</option>
                                        </select>
                                    </div>
                                    , td)
                        }
                        ]
                    }
                });
                this.setState({ reloadTable: true });
            }).catch(error => {
                this.setState({ printStation: { data: [] } })
                console.error("Problem in fetching print station data: ", error);
            })
        } catch (error) {
            this.setState({ printStation: { data: [] } })
            console.error("Problem in fetching print station data: ", error);
        }
    }

    render() {
        const { printStation, reloadTable, columns, activeTab } = { ...this.state }
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Print</span>
                        <ol className="breadcrumb pl-0 pt-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Print</li>
                        </ol>
                    </nav>
                    <div className="tab-content div-print-station-list">
                        <div role="tabpanel"
                            className={activeTab && (activeTab === "" || activeTab.includes('Print Stations')) ? "tab-pane active fade in show" : "tab-pane fade"}
                            id="tab1">
                            <button onClick={(event) => { this.openPreviewPage(event) }} className="btn btn-primary has-icon btn_add_preview_station btn-sm">
                                Preview
                                    </button>
                            <button onClick={(event) => { this.openAddPrintStationPage(event) }} className="btn btn-primary has-icon btn_add_print_station btn-sm">
                                {/* eslint-disable-next-line */}
                                <i className="fa fa-plus fa-xs"></i> Add a Print Station
                                    </button>
                            {(!printStation || printStation.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {printStation && printStation.data !== null &&
                                    <DataTablesComp data={printStation} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="asc" id="printerTable" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrintManagement;