import React from 'react'
import ReactDOM from "react-dom";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2';
import DataTablesComp from '../../DataTables';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';
import '../../../footer';

function uniqueBy(arr, prop){
  return arr.reduce((a, d) => {
    if (!a.includes(d[prop])) { a.push(d[prop]); }
    return a;
  }, []);
}

function filterUsers (array, filter, start, end) {
  // var result = [];
  var result= array.filter(function(item) {
    // item.price = Number(item.price);
    for (var key in filter) {
      // if (item[key] === undefined || item[key] != filter[key] || item['price'] < start || item['price'] >= end)
      // eslint-disable-next-line
      if (item[key] === undefined || item[key] != filter[key])
        return false;
    }
    return true;
  });
  return result;
}

class MenuDetails extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { title: 'Category', data: 'product_category' },
      { title: 'Product Name', data: 'name' },
      // { title: 'Customizable', data: 'customizable' },
      { title: 'Status', data: 'disableStatus' },
      { title: 'Available', data: 'availableStatus' },
      // { title: 'Price', data: 'price' },
      // { title: 'Action', data: 'name', orderable: false },
    ];
    this.state = {
      prodData: this.props.productsData,
      productsData: {
        data: null
      },
      columns: columns,
      reloadTable: false,
      productCategoryList: [],
      productCategoryValue: '',
      fullProductData: [],
      modifiersData: [],
      printStation: []
    }
    this.openAddProductPage = this.openAddProductPage.bind(this);
    this.openAddProductsPage = this.openAddProductsPage.bind(this);
    this.openEditProductPage = this.openEditProductPage.bind(this);
    this.confirmAndDelete = this.confirmAndDelete.bind(this);
  }
  
  componentDidMount() {
    this.getProductsList();
    // if (!(this.state.prodData && this.state.prodData.length)) {
    //     this.getProductsList();
    // } else {
    //   let products = this.state.prodData;
    //   this.setState({ productsData: { data: this.prepareOrderDatatable(products),
    //     columnDefs: [{
    //       targets: [1],
    //       createdCell: (td, cellData, rowData) =>
    //         ReactDOM.render(
    //           <a href="/#" onClick={(event) => this.openEditProductPage(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>{rowData.name}</a>, td)
    //       }, {
    //         targets: [3],
    //         createdCell: (td, cellData, rowData) =>
    //           ReactDOM.render(
    //               <div>
    //                 <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>
    //                   <option value="">Select</option>
    //                   <option value="Edit">Edit</option>
    //                   {rowData.disable &&
    //                     <option value="Enable">Enable</option>
    //                   }
    //                   {!rowData.disable &&
    //                     <option value="Disable">Disable</option>
    //                   }
    //                   <option value="Delete">Delete</option>
    //                 </select>
    //               </div>
    //              , td
    //           )}
    //     ]}
    //   })
    // }
    this.getmodifiers();
    this.getPrintStation();
  }
  async getPrintStation() {
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `print/get-print-station/${vendorId}?db=mongodb`;
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        axiosInstance.get(url).then(result => {
            // If auth token expired
            if("success" in result.data && result.data.success === false){
                localStorage.removeItem('token');
                this.getPrintStation();
                return;
            }
            let printStationData = result.data.response;
            this.setState({ printStation: printStationData})
        }).catch(error => {
            this.setState({ printStation: [] })
            console.error("Problem in fetching print station data: ",error);
        })
    } catch (error) {
        this.setState({ printStation: { data: []} })
        console.error("Problem in fetching print station data: ",error);
    }
}
  async getmodifiers() {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `modifiers/get/${vendorId}`;
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        axiosInstance.get(url).then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                self.getmodifiers('Upsell');
                return;
            }
            if (!result.data.status) {
              self.setState({ modifiersData: [] })
              console.error("Problem in fetching modifiers data");
              return;
            }
            let modifiersData = result.data.response;
            if (!(modifiersData && modifiersData.length)) {
              self.setState({ modifiersData: [] });
              return;
            }
            self.setState({ modifiersData: modifiersData });
        }).catch(error => {
            self.setState({ modifiersData: [] })
            console.error("Problem in fetching modifiers data: ", error);
        })
    } catch (error) {
        self.setState({ modifiersData: [] })
        console.error("Problem in fetching modifiers data: ", error);
    }
  }

  openAddProductPage(event){
    event.preventDefault();
    let self = this;
    // self.props.history.push(
    //     {
    //         pathname: "/menu_management/product/add",
    //         state: {productsData: self.state.productsData.data}
    //     });
    this.props.history.push(`/menu_management/product/add`, {productsData: self.state.productsData.data});
  }

  openAddProductsPage(event){
    event.preventDefault();
    let self = this;
    this.props.history.push(`/menu_management/products/bulkadd`, {productsData: self.state.productsData.data});
  }

  openEditProductPage(event, productId, rowId, modifierData){
    event.preventDefault();
    let self = this;
    let tempProductData = self.state.productsData.data;
    let productData = tempProductData[rowId];
    this.props.history.push(`/menu_management/product/${productId}`, {printStation: this.state.printStation, productData, productsData: self.state.productsData.data, modifiersData: self.state.modifiersData});
  }

  confirmAndDelete(event, productId, rowId){
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(async (result) => {
      if (result.value) {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}/${productId}`;
        try {
          let token = getAuthTokenFromLocalStorage();
          if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
              localStorage.setItem('token', auth.token);
            }
          }
          axiosInstance.delete(url)
            .then(result => {
              // If auth token expired
              if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                toast.error("Problem in deleting product. Please try after sometime!", {
                  autoClose: 5000,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                return;
              }
              if (result.data.status) {
                let tempProductData = self.state.productsData.data;
                tempProductData.splice(rowId, 1);
                self.setState({productsData: {data: tempProductData}});
                this.setState({ reloadTable: true });
                toast.success("Success: Product deleted!", {
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                })
              } else {
                toast.error(result.data.statusText, {
                  autoClose: 5000,
                  closeOnClick: true,
                  pauseOnHover: true,
              });
              }            
            })
            .catch(error => {
              toast.error("Problem in deleting product", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
              });
              console.error("Problem in deleting product : ", error);
            })
        }
        catch (error) {
          toast.error("Problem in deleting product", {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          console.error("Problem in deleting product: ", error);
        }
      }
    });
  }

  async getProductsList() {
    // let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `menu/productv2/${vendorId}`;
    try {
      let token = getAuthTokenFromLocalStorage();
      if (!token) {
        let auth = await getAuth();
        if (auth && auth.success && auth.token) {
          localStorage.setItem('token', auth.token);
        }
      }
      axiosInstance.get(url)
        .then(result => {
          // If auth token expired
          if("success" in result.data && result.data.success === false){
            localStorage.removeItem('token');
            this.getProductsList();
            return;
          }
          let products = result.data.response;
          // eslint-disable-next-line
          this.setState({fullProductData: products, productCategoryList: uniqueBy(products, "product_category").sort((a, b) => a > b && 1 || -1)})
          this.setState({ productsData: { data: products,
            columnDefs: [{
              targets: [1],
              createdCell: (td, cellData, rowData) =>
                ReactDOM.render(
                  <a href="/#" onClick={(event) => this.openEditProductPage(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>{rowData.name}</a>, td)
              },
              // {
              //   targets: [3],
              //   createdCell: (td, cellData, rowData) =>
              //     ReactDOM.render(
              //         <div>
              //           <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>
              //             <option value="">Select</option>
              //             <option value="Edit">Edit</option>
              //             {rowData.disable &&
              //               <option value="Enable">Enable</option>
              //             }
              //             {!rowData.disable &&
              //               <option value="Disable">Disable</option>
              //             }
              //             <option value="Delete">Delete</option>
              //           </select>
              //         </div>
              //        , td
              //     )
              // }
            ]}
          })
        })
        
        .catch(error => {
          this.setState({fullProductData: [], productsData: { data: []} })
          console.error("Problem in fetching vendor product: ",error);
        })
    }
    catch (error) {
      this.setState({fullProductData: [], productsData: { data: []} })
      console.error("Problem in fetching vendor product: ",error);
    }
  }
  async enableDisableProduct(event, rowId, rowIndex, status) {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `menu/product-disable`;
    try {
      let token = getAuthTokenFromLocalStorage();
      if (!token) {
        let auth = await getAuth();
        if (auth && auth.success && auth.token) {
          localStorage.setItem('token', auth.token);
        }
      }
      axiosInstance.post(url, {
        disable: status,
        vendorId: vendorId,
        productId: rowId
      })
        .then(result => {
          // If auth token expired
          if ("success" in result.data && result.data.success === false) {
            localStorage.removeItem('token');
            toast.error("Problem in updating product. Please try after sometime!", {
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
            return;
          }
          if (result.data.status) {
            self.setState({ reloadTable: true });
            for (let i = 0; i < self.state.fullProductData.length; i += 1) {
              if (self.state.fullProductData[i].id === rowId) {
                self.state.fullProductData[i].disable = status;
                break;
              }
            }
            this.setState({
              productCategoryValue: ""
            });
            self.reloadData(self.state.fullProductData)
            toast.success("Success: Product updating!", {
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
            })
          } else {
            toast.error(result.data.statusText, {
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
          });
          }            
        })
        .catch(error => {
          toast.error("Problem in updating product", {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          console.error("Problem in updating product : ", error);
        })
    }
    catch (error) {
      toast.error("Problem in deleting product", {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.error("Problem in deleting product: ", error);
    }
  }
  onActionChange(event, rowId, rowIndex){
    let action = event.target.value;
    if(action === "Edit"){
        this.openEditProductPage(event, rowId, rowIndex)
    }
    if(action === "Enable"){
      this.enableDisableProduct(event, rowId, rowIndex, false);
    }
    if(action === "Disable"){
      this.enableDisableProduct(event, rowId, rowIndex, true);
    }
    if(action === "Delete"){
        this.confirmAndDelete(event, rowId, rowIndex)
    }
    
    event.target.value = "";
}
reloadData(products) {
  // var self = this;
  this.setState({
    productsData: {
      data: products,
      columnDefs: [{
        targets: [1],
        createdCell: (td, cellData, rowData) =>
          ReactDOM.render(
            <a href="/#" onClick={(event) => this.openEditProductPage(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>{rowData.name}</a>, td)
        },
        // {
        //   targets: [3],
        //   createdCell: (td, cellData, rowData) =>
        //     ReactDOM.render(
        //       <div>
        //         <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.id, rowData.rowIndex, this.state.modifierData)}>
        //           <option value="">Select</option>
        //           <option value="Edit">Edit</option>
        //           {rowData.disable &&
        //             <option value="Enable">Enable</option>
        //           }
        //           {!rowData.disable &&
        //             <option value="Disable">Disable</option>
        //           }
        //           <option value="Delete">Delete</option>
        //         </select>
        //       </div>
        //       , td
        //     )
        // }
      ]
    }
  });
  this.setState({ reloadTable: true });
}
filterData(value) {
  let object = {
    product_category: ''
  };
  
  this.setState({
    productCategoryValue: value
  });
  if (!value) {
    delete object.product_category;
  } else {
    object.product_category = value;
  }
  this.reloadData(filterUsers(this.state.fullProductData, object));
}


  prepareOrderDatatable(products) {
    let productsData = [];
    if (products && products.length) {
      productsData = products.map((product, index) => {
        return {
          ...product,
          rowIndex: index,
          disable: product.disable ? product.disable : false,
          // price: product.price ? '$' + product.price : '',
          // customizable: product.customizable === true ? "Yes" : "No"
        }
      });
    }
    return productsData
  }

  render() {
    const { productsData, columns, productCategoryList } = { ...this.state }
    return (
      <div className="row">
        <div className="col-md-12">
          <div>
            <button onClick={(event) => { this.openAddProductPage(event) }} className="btn btn-primary has-icon float-right btn-sm mt-0 mb-4 ml-4">
              {/* eslint-disable-next-line */}
              <i className="fa fa-plus fa-xs"></i> Add Product
            </button>
            {/* <button onClick={(event) => { this.openAddProductsPage(event) }} className="btn btn-primary has-icon float-right btn-sm mt-0 mb-4">
              <i className="fa fa-plus fa-xs"></i> Add Products
            </button> */}
            <div className="row">
              <div className="col-md-4">
                <div className="input-group">
                  <label className="filter-label">Category: </label>
                  <select className="form-control" value={this.state.productCategoryValue} onChange={(e) => this.filterData(e.target.value)}>
                      <option value="">Select Category</option>
                      {productCategoryList.map((statusRow, statusIndex) => (
                          <option key={statusIndex} value={statusRow}>{statusRow}</option>
                      ))}    
                  </select>
                </div>
              </div>
            </div>
            {(!productsData || productsData.data === null) && <Spinner />}
            <div className="table-responsive div-product-list">
              {productsData && productsData.data !== null &&
                <DataTablesComp data={productsData} columns={columns} reloadTable={true} sortColumn="0" sortOrder="asc" id="menuTable" />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MenuDetails);