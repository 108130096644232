import React from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import EmailEditor from 'react-email-editor';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import sample from '../../../assets/Email/sample.json';

import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class CampaignUpdate extends React.Component {
    constructor(props) {
        super(props);
        let couponsData = this?.props?.location?.state?.couponsData ? this.props.location.state.couponsData : null;
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        let vendorTimezone = vendorAllDetails?.tz && vendorAllDetails.tz !== "" ? vendorAllDetails.tz : "America/New_York";
        let campaignId = this.props.match.params.campaignId;
        const initialCampaignData = {
            campaignName: "",
            deliveryMethod: "Email",
            linkedCoupon: "",
            emailSubject: "",
            fromName: "",
            replyToEmail: vendorAllDetails?.vendor_email && vendorAllDetails.vendor_email !== "" ? vendorAllDetails.vendor_email : "",
            recipients: "all",
            schedule: "now",
            scheduleTime: moment().tz(vendorTimezone).add(1, 'hours'),
            emailContentHtml: "",
            emailContentJson: null,
            notificationTitle: "",
            notificationContent: "",
            isTriggerCampaign: false,
            triggerType: "",
            isTriggerEnabled: false,
            premiumCustomerOrderFrequency: 0,
            premiumCustomerAmountFilter: 'average',
            premiumCustomerAmountValue: 0,
            premiumCustomerDurationInMonth: '1'
        }
        const initialCampaignErrorData = {
            campaignName: "",
            deliveryMethod: "",
            linkedCoupon: "",
            emailSubject: "",
            fromName: "",
            fromEmail: "",
            replyToEmail: "",
            scheduleTime: "",
            emailContentHtml: "",
            notificationTitle: "",
            notificationContent: "",
            premiumCustomerOrderFrequency: "",
            premiumCustomerAmountFilter: "",
            premiumCustomerAmountValue: "",
            premiumCustomerDurationInMonth: ""
        }
        this.emailEditorRef = React.createRef();
        this.state = {
            vendorId: vendorAllDetails && vendorAllDetails.id ? vendorAllDetails.id : "",
            campaignId: campaignId,
            promotionDeliveryMethod: ['Email', 'sVang App', 'Email & sVang App'],
            recipientsOptions: [{ name: 'All Customers', value: "all", tip: "Customers who has subscribed and ordered food items.", allowedFor: ['Email'] },
            { name: "Active Customers", value: "active", tip: "Customers who has ordered food items from your restaurant.", allowedFor: ['Email', 'sVang App', 'Email & sVang App'] },
            { name: "Premium Customers", value: "premium", tip: "Premium customers", allowedFor: ['Email', 'sVang App', 'Email & sVang App'] }],
            scheduleOptions: [{ name: 'Now', value: "now" }, { name: "Later", value: "later" }],
            triggerTypes: [{ name: 'Thank you email/notification after delivery of an order', value: "thankYouOnOrder" }],
            couponsData: couponsData ? couponsData.data : [],
            campaignData: JSON.parse(JSON.stringify(initialCampaignData)),
            campaignErrorData: JSON.parse(JSON.stringify(initialCampaignErrorData)),
            submitProgressDraft: false,
            submitProgress: false,
            currentPage: 1,
            file: null,
            editor: null,
            vendorTimezone,
            selectedCoupon: null,
            readyToSubmit: false,
            amountFilterOptions: [{ name: 'Average Order Amount', value: 'average' }, { name: 'Total Order Amount', value: 'total' }],
            durationOptions: [{ name: 'Last One Month', value: '1' }, { name: 'Last Three Months', value: '3' }, { name: 'Last Six Months', value: '6' }, { name: 'Last One Year', value: '12' }]
        };
        this.checkValidation = this.checkValidation.bind(this);
        this.checkValidationForDraft = this.checkValidationForDraft.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.exportHtml = this.exportHtml.bind(this);
        this.onSaveAsDraft = this.onSaveAsDraft.bind(this);
        this.onSend = this.onSend.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.updateCampaignEmailFormat = this.updateCampaignEmailFormat.bind(this);
        this.onLoadEmailEditor = this.onLoadEmailEditor.bind(this);
    }

    componentDidMount() {
        let campaignId = this.props.match.params.campaignId;
        this.getCampaignData(campaignId);
    }

    async getCampaignData(campaignId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `campaign/${vendorId}/${campaignId}`;
        if (vendorId && campaignId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getCampaignData(campaignId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result?.data?.response ? result.data.response : {};
                            let tempFormData = self.state.campaignData;
                            tempFormData = { ...data };
                            tempFormData.scheduleTime = tempFormData.schedule !== "now" && tempFormData.scheduleTime && tempFormData.scheduleTime !== "" ? moment(tempFormData.scheduleTime).tz(self.state.vendorTimezone) : "";
                            if (tempFormData.linkedCoupon !== "" && tempFormData.couponData) {
                                self.setState({ selectedCoupon: tempFormData.couponData });
                                delete tempFormData.couponData;
                            }
                            if (data.recipients === "premium") {
                                let premiumCustomerCriteria = 'premiumCustomerCriteria' in data ? data.premiumCustomerCriteria : {};
                                tempFormData.premiumCustomerOrderFrequency = 'orderFrequency' in premiumCustomerCriteria ? Number(premiumCustomerCriteria.orderFrequency) : 0;
                                tempFormData.premiumCustomerAmountFilter = 'amountFilter' in premiumCustomerCriteria ? premiumCustomerCriteria.amountFilter : 'average';
                                tempFormData.premiumCustomerAmountValue = 'amountValue' in premiumCustomerCriteria ? Number(premiumCustomerCriteria.amountValue) : 0;
                                tempFormData.premiumCustomerDurationInMonth = 'durationInMonth' in premiumCustomerCriteria ? premiumCustomerCriteria.durationInMonth : '1';
                            } else {
                                tempFormData.premiumCustomerOrderFrequency = 0;
                                tempFormData.premiumCustomerAmountFilter = 'average';
                                tempFormData.premiumCustomerAmountValue = 0;
                                tempFormData.premiumCustomerDurationInMonth = '1';
                            }
                            delete tempFormData.premiumCustomerCriteria;
                            self.setState({ campaignData: tempFormData }, () => {
                                if (tempFormData.deliveryMethod === "Email" || tempFormData.deliveryMethod === "Email & sVang App") {
                                    let replacedJson = tempFormData.emailContentJson;
                                    if (this.emailEditorRef.current) {
                                        this.emailEditorRef.current.editor.loadDesign(JSON.parse(replacedJson));
                                    } else {
                                        setTimeout(() => this.emailEditorRef.current.editor.loadDesign(JSON.parse(replacedJson)), 5000);
                                    }
                                }
                            });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in adding product details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating restaurant details. ", error);
            }
        } else {
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onLoadEmailEditor() {
        try {
            let replacedJson = sample;
            let self = this;
            if (this.state.vendorId !== "") {
                replacedJson = JSON.stringify(replacedJson).replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/vendorproduct/${this.state.vendorId}`);
            } else {
                replacedJson = JSON.stringify(replacedJson).replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/home`);
            }
            if (this.emailEditorRef.current) {
                this.emailEditorRef.current.editor.loadDesign(JSON.parse(replacedJson));
                this.emailEditorRef.current.editor.addEventListener('design:updated', function (data) {
                    self.emailEditorRef.current.editor.exportHtml((data) => {
                        let formDetails = self.state.campaignData;
                        const { design, html } = data;
                        formDetails.emailContentHtml = html;
                        formDetails.emailContentJson = design;
                        self.setState({ campaignData: formDetails });
                    });
                })
            }
        } catch (error) {
            console.error(error);
        }
    }

    exportHtml(status) {
        let formDetails = this.state.campaignData;
        if (formDetails.isTriggerCampaign && status === "sent") {
            formDetails.isTriggerEnabled = true;
        } else {
            formDetails.isTriggerEnabled = false;
        }
        if (formDetails.deliveryMethod === "Email" || formDetails.deliveryMethod === "Email & sVang App") {
            this.emailEditorRef.current.editor.exportHtml((data) => {
                const { design, html } = data;
                formDetails.emailContentHtml = html;
                formDetails.emailContentJson = design;
                if (formDetails.deliveryMethod === "Email") {
                    formDetails.notificationTitle = "";
                    formDetails.notificationContent = "";
                }
                if (formDetails.isTriggerCampaign) {
                    formDetails.recipients = "active";
                }
                this.setState({ campaignData: formDetails });
                this.onFormSubmit(status);
            });
        } else {
            formDetails.emailContentHtml = "";
            formDetails.emailContentJson = {};
            formDetails.emailSubject = "";
            formDetails.fromName = "";
            // formDetails.recipients="active";
            this.setState({ campaignData: formDetails });
            this.onFormSubmit(status);
        }
    };

    onScheduleDateTimeChange(value) {
        let formDetails = this.state.campaignData;
        let formErrors = this.state.campaignErrorData;
        let fieldName = 'scheduleTime';
        formDetails[fieldName] = value;
        this.setState({ campaignData: formDetails });
        let isError = this.checkValidation(fieldName, value);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ campaignErrorData: formErrors });
        }
    }

    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.campaignData;
        let formErrors = this.state.campaignErrorData;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "campaignName" && value === "") || (formSubmit === true && formDetails["campaignName"] === "")) {
            fieldName = 'campaignName';
            formErrors[fieldName] = `Campaign name is required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "campaignName" && value !== "") || (formSubmit === true && formDetails["campaignName"] !== "")) {
            if (!(/[a-zA-Z]/.test(formDetails["campaignName"]))) {
                fieldName = 'campaignName';
                formErrors[fieldName] = `Campaign name is not in valid format.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "deliveryMethod" && value === "") || (formSubmit === true && formDetails["deliveryMethod"] === "")) {
            fieldName = 'deliveryMethod';
            formErrors[fieldName] = `Delivery method is required field.`;
            isError = true;
        }
        if (formDetails["deliveryMethod"] === "Email" || formDetails["deliveryMethod"] === "Email & sVang App") {
            if ((formSubmit === false && fieldName === "emailSubject" && value === "") || (formSubmit === true && formDetails["emailSubject"] === "")) {
                fieldName = 'emailSubject';
                formErrors[fieldName] = `Email subject is required field.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "emailSubject" && value.length < 2) || (formSubmit === true && formDetails["emailSubject"].length < 2)) {
                fieldName = 'emailSubject';
                formErrors[fieldName] = `Email subject is not in valid format.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "emailSubject" && value !== "") || (formSubmit === true && formDetails["emailSubject"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["emailSubject"]))) {
                    fieldName = 'emailSubject';
                    formErrors[fieldName] = `Email subject is not in valid format.`;
                    isError = true;
                }
            }
            if ((formSubmit === false && fieldName === "fromName" && value === "") || (formSubmit === true && formDetails["fromName"] === "")) {
                fieldName = 'fromName';
                formErrors[fieldName] = `From name is required field.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "fromName" && value.length < 2) || (formSubmit === true && formDetails["fromName"].length < 2)) {
                fieldName = 'fromName';
                formErrors[fieldName] = `From name is not in valid format.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "fromName" && value !== "") || (formSubmit === true && formDetails["fromName"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["fromName"]))) {
                    fieldName = 'fromName';
                    formErrors[fieldName] = `From name is not in valid format.`;
                    isError = true;
                }
            }
        }

        if (formDetails["deliveryMethod"] === "sVang App" || formDetails["deliveryMethod"] === "Email & sVang App") {
            if ((formSubmit === false && fieldName === "notificationTitle" && value === "") || (formSubmit === true && formDetails["notificationTitle"] === "")) {
                fieldName = 'notificationTitle';
                formErrors[fieldName] = `Notification title is required field.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "notificationTitle" && value.length < 2) || (formSubmit === true && formDetails["notificationTitle"].length < 2)) {
                fieldName = 'notificationTitle';
                formErrors[fieldName] = `Notification title is not in valid format.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "notificationTitle" && value !== "") || (formSubmit === true && formDetails["notificationTitle"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["notificationTitle"]))) {
                    fieldName = 'notificationTitle';
                    formErrors[fieldName] = `Notification title is not in valid format.`;
                    isError = true;
                }
            }

            if ((formSubmit === false && fieldName === "notificationContent" && value === "") || (formSubmit === true && formDetails["notificationContent"] === "")) {
                fieldName = 'notificationContent';
                formErrors[fieldName] = `Notification content is required field.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "notificationContent" && value.length < 2) || (formSubmit === true && formDetails["notificationContent"].length < 2)) {
                fieldName = 'notificationContent';
                formErrors[fieldName] = `Notification content is not in valid format.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "notificationContent" && value !== "") || (formSubmit === true && formDetails["notificationContent"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["notificationContent"]))) {
                    fieldName = 'notificationContent';
                    formErrors[fieldName] = `Notification content is not in valid format.`;
                    isError = true;
                }
            }
        }

        if (formDetails['schedule'] === "later") {
            if ((formSubmit === false && fieldName === "scheduleTime" && value === "") || (formSubmit === true && formDetails["scheduleTime"] === "")) {
                fieldName = 'scheduleTime';
                formErrors[fieldName] = `Schedule Time is required field.`;
                isError = true;
            } else if ((formSubmit === false && fieldName === "scheduleTime" && value !== "") || (formSubmit === true && formDetails["scheduleTime"] !== "")) {
                if (formDetails["scheduleTime"] < moment().tz(this.state.vendorTimezone).add(1, 'hours')) {
                    fieldName = 'scheduleTime';
                    formErrors[fieldName] = `Schedule time cannot be less than 1 hour from the current time`;
                    isError = true;
                }
            }
        }
        if (formDetails['recipients'] === "premium") {
            if ((formSubmit === false && fieldName === "premiumCustomerAmountValue" && value === "") || (formSubmit === true && formDetails["premiumCustomerAmountValue"] === "")) {
                fieldName = 'premiumCustomerAmountValue';
                formErrors[fieldName] = `Order amount is required field.`;
                isError = true;
            } else if ((formSubmit === false && fieldName === "premiumCustomerAmountValue" && !this.isValidNumber(value)) || (formSubmit === true && !this.isValidNumber(formDetails["premiumCustomerAmountValue"]))) {
                fieldName = 'premiumCustomerAmountValue';
                formDetails[fieldName] = "";
            } else if ((formSubmit === false && fieldName === "premiumCustomerAmountValue" && value < 0) || (formSubmit === true && formDetails["premiumCustomerAmountValue"] < 0)) {
                fieldName = 'premiumCustomerAmountValue';
                formDetails[fieldName] = "";
            }

            if (formDetails['premiumCustomerAmountFilter'] === "average") {
                if ((formSubmit === false && fieldName === "premiumCustomerOrderFrequency" && value === "") || (formSubmit === true && formDetails["premiumCustomerOrderFrequency"] === "")) {
                    fieldName = 'premiumCustomerOrderFrequency';
                    formErrors[fieldName] = `Order frequency is required field.`;
                    isError = true;
                } else if ((formSubmit === false && fieldName === "premiumCustomerOrderFrequency" && !this.isValidNumber(value)) || (formSubmit === true && !this.isValidNumber(formDetails["premiumCustomerOrderFrequency"]))) {
                    fieldName = 'premiumCustomerOrderFrequency';
                    formDetails[fieldName] = "";
                } else if ((formSubmit === false && fieldName === "premiumCustomerOrderFrequency" && value < 0) || (formSubmit === true && formDetails["premiumCustomerOrderFrequency"] < 0)) {
                    fieldName = 'premiumCustomerOrderFrequency';
                    formDetails[fieldName] = "";
                }
            }
        }
        // if ((formSubmit === false && fieldName === "emailContentHtml" && value === "") || (formSubmit === true && formDetails["emailContentHtml"] === ""))  {
        //     fieldName = 'emailContentHtml';
        //     formErrors[fieldName] = `Email content is required field.`;
        //     isError = true;
        // }

        this.setState({ campaignErrorData: formErrors });
        this.setState({ campaignData: formDetails });
        return isError;
    }

    checkValidationForDraft(fieldName = "", value = "") {
        let formDetails = this.state.campaignData;
        let formErrors = this.state.campaignErrorData;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "campaignName" && value === "") || (formSubmit === true && formDetails["campaignName"] === "")) {
            fieldName = 'campaignName';
            formErrors[fieldName] = `The campaign name is the required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "campaignName" && value !== "") || (formSubmit === true && formDetails["campaignName"] !== "")) {
            if (!(/[a-zA-Z]/.test(formDetails["campaignName"]))) {
                fieldName = 'campaignName';
                formErrors[fieldName] = `The campaign name is not in a valid format.`;
                isError = true;
            }
        }
        if (formDetails["deliveryMethod"] === "Email" || formDetails["deliveryMethod"] === "Email & sVang App") {
            if ((formSubmit === false && fieldName === "emailSubject" && value === "") || (formSubmit === true && formDetails["emailSubject"] === "")) {
                fieldName = 'emailSubject';
                formErrors[fieldName] = `The subject is a required field.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "emailSubject" && value !== "") || (formSubmit === true && formDetails["emailSubject"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["emailSubject"]))) {
                    fieldName = 'emailSubject';
                    formErrors[fieldName] = `The subject is not in a valid format.`;
                    isError = true;
                }
            }
            if ((formSubmit === false && fieldName === "fromName" && value !== "") || (formSubmit === true && formDetails["fromName"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["fromName"]))) {
                    fieldName = 'fromName';
                    formErrors[fieldName] = `The from name is not in a valid format.`;
                    isError = true;
                }
            }
        }
        if (formDetails["deliveryMethod"] === "sVang App" || formDetails["deliveryMethod"] === "Email & sVang App") {
            if ((formSubmit === false && fieldName === "notificationTitle" && value === "") || (formSubmit === true && formDetails["notificationTitle"] === "")) {
                fieldName = 'notificationTitle';
                formErrors[fieldName] = `The notification title is a required field.`;
                isError = true;
            }

            else if ((formSubmit === false && fieldName === "notificationTitle" && value.length < 2) || (formSubmit === true && formDetails["notificationTitle"].length < 2)) {
                fieldName = 'notificationTitle';
                formErrors[fieldName] = `The notification title is not in a valid format.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "notificationTitle" && value !== "") || (formSubmit === true && formDetails["notificationTitle"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["notificationTitle"]))) {
                    fieldName = 'notificationTitle';
                    formErrors[fieldName] = `The notification title is not in a valid format.`;
                    isError = true;
                }
            }

            if ((formSubmit === false && fieldName === "notificationContent" && value !== "") || (formSubmit === true && formDetails["notificationContent"] !== "")) {
                if (!(/[a-zA-Z]/.test(formDetails["notificationContent"]))) {
                    fieldName = 'notificationContent';
                    formErrors[fieldName] = `The notification content is not in a valid format.`;
                    isError = true;
                }
            }
        }
        if (formDetails['schedule'] === "later") {
            if ((formSubmit === false && fieldName === "scheduleTime" && value !== "") || (formSubmit === true && formDetails["scheduleTime"] !== "")) {
                if (formDetails["scheduleTime"] < moment().tz(this.state.vendorTimezone).add(1, 'hours')) {
                    fieldName = 'scheduleTime';
                    formErrors[fieldName] = `The schedule time cannot be less than 1 hour from the current time.`;
                    isError = true;
                }
            }
        }
        this.setState({ campaignErrorData: formErrors });
        this.setState({ campaignData: formDetails });
        return isError;
    }

    isValidNumber(value) {
        return !isNaN(value);
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    updateCampaignEmailFormat(coupon) {
        if (this.state.campaignData.deliveryMethod === "Email" || this.state.campaignData.deliveryMethod === "Email & sVang App") {
            let replacedJson = JSON.stringify(sample);
            if (coupon !== null) {
                let offer = coupon.discountType === "amountOff" ? `GET $${coupon.takeOff} OFF`
                    : coupon.discountType === "percentageOff" ? `GET ${coupon.takeOff}% OFF`
                        : coupon.discountType === "freeDelivery" ? `GET FREE DELIVERY` : "";
                let orderAmount = coupon.orderAbove ? `Redeem this deal code on any order above $${coupon.orderAbove}.` : `Redeem this deal code on any order.`
                replacedJson = JSON.stringify(sample).replace("{{Code}}", coupon.couponCode);
                let ValidUpto = coupon.fromDate ? `Deal valid from ${moment(coupon.fromDate).tz(this.state.vendorTimezone).format('MMMM Do')} to ${moment(coupon.validuptoDate).tz(this.state.vendorTimezone).format('MMMM Do')}` : `Deal valid till ${moment(coupon.validuptoDate).format('MMMM Do')}`
                if (this.state.vendorId !== "") {
                    replacedJson = replacedJson.replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/vendorproduct/${this.state.vendorId}?couponcode=${coupon.couponCode}`);
                } else {
                    replacedJson = replacedJson.replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/home`);
                }
                replacedJson = replacedJson.replace("{{Offer}}", offer);
                replacedJson = replacedJson.replace("{{OrderAmount}}", orderAmount);
                replacedJson = replacedJson.replace("{{ValidUpto}}", ValidUpto);
            } else {
                if (this.state.vendorId !== "") {
                    replacedJson = replacedJson.replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/vendorproduct/${this.state.vendorId}`);
                } else {
                    replacedJson = replacedJson.replace("{{VisitSite}}", `${process.env.REACT_APP_WEBAPP_URL}/home`);
                }
            }
            if (this.state.campaignData.fromName !== "") {
                replacedJson = replacedJson.replace("{{FromName}}", this.state.campaignData.fromName);
            }
            if (this.emailEditorRef.current) {
                this.emailEditorRef.current.editor.loadDesign(JSON.parse(replacedJson));
            } else {
                setTimeout(() => this.emailEditorRef.current.editor.loadDesign(JSON.parse(replacedJson)), 5000);
            }
        }
    }

    onDetailsChange(e) {
        let formDetails = this.state.campaignData;
        let formErrors = this.state.campaignErrorData;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        if (fieldName === "deliveryMethod" && fieldValue !== "Email") {
            formDetails['recipients'] = "active";
        } else if (fieldName === "deliveryMethod" && fieldValue === "Email") {
            formDetails['recipients'] = "all";
        }
        this.setState({ campaignData: formDetails });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ campaignErrorData: formErrors });
        }
        if ((fieldName === "linkedCoupon" && fieldValue !== "")) {
            let coupon = JSON.parse(e.target.options[e.target.selectedIndex].dataset.coupon);
            this.updateCampaignEmailFormat(coupon);
            this.setState({ selectedCoupon: coupon });
        }
        if ((fieldName === "fromName" && fieldValue !== "") || (fieldName === "deliveryMethod" && (fieldValue === "Email" || fieldValue === "Email & sVang App"))) {
            this.updateCampaignEmailFormat(this.state.selectedCoupon);
        }
    }

    onSaveAsDraft(e) {
        e.preventDefault();
        let self = this;
        let isError = self.checkValidationForDraft();
        self.setState({ readyToSubmit: true });
        if (!isError) {
            this.setState({ submitProgressDraft: true });
            this.exportHtml("draft");
        } else {
            window.scrollTo(0, 0);
            toast.error("Oops! You did not fill in all required fields in the correct format to save as a draft.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onSend(e) {
        e.preventDefault();
        let self = this;
        let isError = self.checkValidation();
        self.setState({ readyToSubmit: true });
        if (!isError) {
            let message = "";
            if (self.state.campaignData.schedule && self.state.campaignData.schedule === "now") {
                message = "Alert! Note that your action will start the campaign, and it will notify to your customers immediately."
            } else {
                message = "Note your action will start the campaign and send notifications to your customers at the scheduled time."
            }
            Swal.fire({
                title: 'Are you sure?',
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, send it!',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.value) {
                    self.setState({ submitProgress: true });
                    if (self.state.campaignData.schedule === "later") {
                        this.exportHtml("startLater");
                    } else if (self.state.campaignData.schedule === "now") {
                        this.exportHtml("sent");
                    }
                }
            });
        } else {
            window.scrollTo(0, 0);
            toast.error("Oops! You did not fill in all required fields in the correct format.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    async onFormSubmit(status) {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let vendorTimezone = vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
        let url = `campaign?db=mongodb`;
        let requestData = new FormData();
        requestData.append("vendorId", vendorId);
        requestData.append("campaignId", self.state.campaignId);

        Object.keys(self.state.campaignData).forEach(function (key) {
            if (key === "scheduleTime" && self.state.campaignData[key] !== "") {
                self.state.campaignData[key] = moment(self.state.campaignData[key]).tz(vendorTimezone).format("MM-DD-YYYY HH:mm");
                requestData.append(key, self.state.campaignData[key]);
            } else if (key === "emailContentJson") {
                requestData.append(key, JSON.stringify(self.state.campaignData[key]));
            } else if (key === "status") {
                requestData.append(key, status);
            } else {
                requestData.append(key, self.state.campaignData[key]);
            }
        });

        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.put(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.onFormSubmit(status);
                        return;
                    }
                    if (result.data.status) {
                        toast.success("The campaign is updated!", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        self.props.history.push('/marketing_management')
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 10000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    self.setState({ submitProgress: false });
                    self.setState({ submitProgressDraft: false });
                })
                .catch(error => {
                    self.setState({ submitProgress: false });
                    self.setState({ submitProgressDraft: false });
                    toast.error("Oops. We encountered an error. Please try again.", {
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in creating new campaign.: ", error);
                })
        }
        catch (error) {
            self.setState({ submitProgress: false });
            self.setState({ submitProgressDraft: false });
            toast.error("Oops. We encountered an error. Please try again.", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in creating new campaign. ", error);
        }
    }

    render() {
        const { campaignData, campaignErrorData, promotionDeliveryMethod, triggerTypes,
            couponsData, submitProgress, submitProgressDraft, recipientsOptions, scheduleOptions, vendorTimezone,
            readyToSubmit, amountFilterOptions, durationOptions } = { ...this.state }
        return (
            <div className="update-campaign-page">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Campaign Update</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/marketing_management">Marketing</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Campaign Update</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="col-md-12">
                        <div>
                            <form className="form-coupon">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="ms-panel custom-panel">
                                            <div className="ms-panel-header">
                                                <div className="d-flex">
                                                    <h5 className="mb-0">Setup</h5>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="ms-panel-body">
                                                <div className="form-row">
                                                    <div className="col-lg-6 col-md-6 mb-2">
                                                        <label htmlFor="name" className="required">Campaign Name</label>
                                                        <div className="input-group">
                                                            <input type="text"
                                                                className={"form-control " + (campaignErrorData.campaignName !== "" ? 'invalid' : '')}
                                                                id="campaignName"
                                                                placeholder="Campaign Name. This name is not visible to anyone."
                                                                value={campaignData.campaignName}
                                                                onChange={this.onDetailsChange}
                                                                onBlur={this.onFocusOut}
                                                            />
                                                            {campaignErrorData.campaignName && campaignErrorData.campaignName !== "" &&
                                                                <div className="invalid-feedback">{campaignErrorData.campaignName}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mb-2">
                                                        <label htmlFor="tax" className="required">Linked Coupon</label>
                                                        <div className="input-group">
                                                            <select
                                                                className={"form-control " + (campaignErrorData.linkedCoupon !== "" ? 'invalid' : '')}
                                                                id="linkedCoupon"
                                                                onChange={this.onDetailsChange}
                                                                value={campaignData.linkedCoupon}>
                                                                <option value="">Select</option>
                                                                {couponsData && couponsData.map((coupon, index) => (
                                                                    <option key={index}
                                                                        data-coupon={JSON.stringify(coupon)}
                                                                        data-code={coupon.couponCode}
                                                                        value={coupon.id}>{`${coupon.couponName} (${coupon.couponCode})`}</option>
                                                                ))}
                                                            </select>
                                                            {campaignErrorData.linkedCoupon && campaignErrorData.linkedCoupon !== "" &&
                                                                <div className="invalid-feedback">{campaignErrorData.linkedCoupon}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mb-2">
                                                        <label htmlFor="tax" className="required">Delivery Method</label>
                                                        <div className="input-group">
                                                            <select
                                                                className={"form-control " + (campaignErrorData.deliveryMethod !== "" ? 'invalid' : '')}
                                                                id="deliveryMethod"
                                                                onChange={this.onDetailsChange}
                                                                value={campaignData.deliveryMethod}>
                                                                <option value="">Select</option>
                                                                {promotionDeliveryMethod.map((method, index) => (
                                                                    <option key={method} value={method}>{method}</option>
                                                                ))}
                                                            </select>
                                                            {campaignErrorData.deliveryMethod && campaignErrorData.deliveryMethod !== "" &&
                                                                <div className="invalid-feedback">{campaignErrorData.deliveryMethod}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 mb-2 div-schedule-campaign">
                                                        <label htmlFor="name" className="required">Schedule Campaign</label>
                                                        <ul className="ms-list d-flex">
                                                            {scheduleOptions && scheduleOptions.length > 0 &&
                                                                scheduleOptions.map((option, index) => (
                                                                    <li className="ms-list-item pl-0" key={`schedule-option-${index}`}>
                                                                        <label className="ms-checkbox-wrap">
                                                                            <input type="radio"
                                                                                name="radioExample1"
                                                                                value={option.value}
                                                                                checked={campaignData.schedule === option.value}
                                                                                title={option.tip}
                                                                                onChange={(event) => {
                                                                                    let tempCampaignData = this.state.campaignData;
                                                                                    tempCampaignData.schedule = event.currentTarget.value;
                                                                                    this.setState({ campaignData: tempCampaignData })
                                                                                }}
                                                                            />
                                                                            <i className="ms-checkbox-check"></i>
                                                                        </label>
                                                                        <span> {option.name} </span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                    {campaignData.schedule !== "now" && <div className="col-lg-4 col-md-6 mb-2">
                                                        <label htmlFor="name" className="required">Select Date & Time</label>
                                                        <div className="input-group">
                                                            <Datetime
                                                                initialValue={campaignData.scheduleTime && campaignData.scheduleTime !== ""
                                                                    ? campaignData.scheduleTime
                                                                    : moment().tz(vendorTimezone).add(1, 'hours')}
                                                                dateFormat="MM-DD-YYYY"
                                                                input={true}
                                                                onChange={(value) => { this.onScheduleDateTimeChange(value) }} />
                                                            {campaignErrorData.scheduleTime && campaignErrorData.scheduleTime !== "" &&
                                                                <div className="invalid-feedback">{campaignErrorData.scheduleTime}</div>}
                                                        </div>
                                                    </div>}
                                                    <div className="col-lg-6 col-md-6 mb-2 div-schedule-campaign">
                                                        <label htmlFor="name" className="required">Is Trigger Campaign?</label>
                                                        <div>
                                                            <label className="ms-switch">
                                                                <input type="checkbox" checked={campaignData.isTriggerCampaign}
                                                                    onChange={(e) => {
                                                                        let tempData = campaignData;
                                                                        tempData.isTriggerCampaign = e.target.checked;
                                                                        if (e.target.checked) tempData.triggerType = "thankYouOnOrder";
                                                                        else tempData.triggerType = "";
                                                                        this.setState({ campaignData: tempData })
                                                                    }} />
                                                                <span className="ms-switch-slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {campaignData.isTriggerCampaign && <div className="col-lg-6 col-md-6 mb-2 div-schedule-campaign">
                                                        <label htmlFor="name" className="required">Trigger Type</label>
                                                        <ul className="ms-list d-flex">
                                                            {triggerTypes && triggerTypes.length > 0 &&
                                                                triggerTypes.map((option, index) => (
                                                                    <li className="ms-list-item pl-0" key={`schedule-option-${index}`}>
                                                                        <label className="ms-checkbox-wrap">
                                                                            <input type="radio"
                                                                                name="radioExample2"
                                                                                value={option.value}
                                                                                checked={campaignData.triggerType === option.value}
                                                                                title={option.tip}
                                                                                onChange={(event) => {
                                                                                    let tempCampaignData = this.state.campaignData;
                                                                                    tempCampaignData.triggerType = event.currentTarget.value;
                                                                                    this.setState({ campaignData: tempCampaignData })
                                                                                }}
                                                                            />
                                                                            <i className="ms-checkbox-check"></i>
                                                                        </label>
                                                                        <span> {option.name} </span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>}
                                                    {!campaignData.isTriggerCampaign && <div className="col-lg-12 col-md-12 mb-2 div-recipients">
                                                        <label htmlFor="name" className="required">Recipients</label>
                                                        <ul className="ms-list d-flex">
                                                            {recipientsOptions && recipientsOptions.length > 0 &&
                                                                recipientsOptions.map((option, index) => (
                                                                    option.allowedFor.includes(campaignData.deliveryMethod) && <li className="ms-list-item pl-0" key={`option-${index}`}>
                                                                        <label className="ms-checkbox-wrap">
                                                                            <input type="radio"
                                                                                name="radioExample"
                                                                                value={option.value}
                                                                                checked={campaignData.recipients === option.value}
                                                                                title={option.tip}
                                                                                onChange={(event) => {
                                                                                    let tempCampaignData = this.state.campaignData;
                                                                                    tempCampaignData.recipients = event.currentTarget.value;
                                                                                    this.setState({ campaignData: tempCampaignData })
                                                                                }}
                                                                            />
                                                                            <i className="ms-checkbox-check"></i>
                                                                        </label>
                                                                        <span> {option.name} </span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>}
                                                    {!campaignData.isTriggerCampaign && campaignData.recipients === "premium" && <div className="col-lg-12 col-md-12 mb-2">
                                                        <label htmlFor="name" className="required">Premium Customer Selection Criteria</label>
                                                        <div className="div-premium-customer row">
                                                            <div className="col-lg-4 col-md-12 mb-2 ipad-pro-w-50">
                                                                <label htmlFor="name" className="required">Filter By</label>
                                                                <ul className="ms-list d-flex">
                                                                    {amountFilterOptions && amountFilterOptions.length > 0 &&
                                                                        amountFilterOptions.map((option, index) => (
                                                                            <li className="ms-list-item pl-0 pt-1" key={`amount-option-${index}`}>
                                                                                <label className="ms-checkbox-wrap">
                                                                                    <input type="radio"
                                                                                        name="radioExample3"
                                                                                        value={option.value}
                                                                                        checked={campaignData.premiumCustomerAmountFilter === option.value}
                                                                                        onChange={(event) => {
                                                                                            let tempCampaignData = this.state.campaignData;
                                                                                            tempCampaignData.premiumCustomerAmountFilter = event.currentTarget.value;
                                                                                            this.setState({ campaignData: tempCampaignData })
                                                                                        }}
                                                                                    />
                                                                                    <i className="ms-checkbox-check"></i>
                                                                                </label>
                                                                                <span> {option.name} </span>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 mb-2 ipad-pro-w-50">
                                                                <label htmlFor="name" className="required">Order Amount</label>
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                        className={"form-control " + (campaignErrorData.premiumCustomerAmountValue !== "" ? 'invalid' : '')}
                                                                        id="premiumCustomerAmountValue"
                                                                        placeholder="Order Amount"
                                                                        value={campaignData.premiumCustomerAmountValue}
                                                                        onChange={this.onDetailsChange}
                                                                        onBlur={this.onFocusOut}
                                                                    />
                                                                    {campaignErrorData.premiumCustomerAmountValue && campaignErrorData.premiumCustomerAmountValue !== "" &&
                                                                        <div className="invalid-feedback">{campaignErrorData.premiumCustomerAmountValue}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 mb-2 ipad-pro-w-50">
                                                                <label htmlFor="tax" className="required">Duration</label>
                                                                <div className="input-group">
                                                                    <select
                                                                        className={"form-control " + (campaignErrorData.premiumCustomerDurationInMonth !== "" ? 'invalid' : '')}
                                                                        id="premiumCustomerDurationInMonth"
                                                                        onChange={this.onDetailsChange}
                                                                        value={campaignData.premiumCustomerDurationInMonth}>
                                                                        <option value="">Select</option>
                                                                        {durationOptions.map((option, index) => (
                                                                            <option key={option.name} value={option.value}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {campaignErrorData.premiumCustomerDurationInMonth && campaignErrorData.premiumCustomerDurationInMonth !== "" &&
                                                                        <div className="invalid-feedback">{campaignErrorData.premiumCustomerDurationInMonth}</div>}
                                                                </div>
                                                            </div>
                                                            {campaignData.premiumCustomerAmountFilter === "average" && <div className="col-lg-3 col-md-4 mb-2 ipad-pro-w-50">
                                                                <label htmlFor="name" className="required">Order Frequency</label>
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                        className={"form-control " + (campaignErrorData.premiumCustomerOrderFrequency !== "" ? 'invalid' : '')}
                                                                        id="premiumCustomerOrderFrequency"
                                                                        placeholder="Order Frequency"
                                                                        value={campaignData.premiumCustomerOrderFrequency}
                                                                        onChange={this.onDetailsChange}
                                                                        onBlur={this.onFocusOut}
                                                                    />
                                                                    {campaignErrorData.premiumCustomerOrderFrequency && campaignErrorData.premiumCustomerOrderFrequency !== "" &&
                                                                        <div className="invalid-feedback">{campaignErrorData.premiumCustomerOrderFrequency}</div>}
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {campaignData.deliveryMethod && (campaignData.deliveryMethod === "Email" || campaignData.deliveryMethod === "Email & sVang App") && <div className="col-md-12 accordion has-gap ms-accordion-chevron" id="accordionEmailSetup">
                                        <div className="ms-panel custom-panel">
                                            <div
                                                className={campaignData.deliveryMethod === "Email & sVang App" && !readyToSubmit ? "ms-panel-header collapsed" : "ms-panel-header"}
                                                data-toggle="collapse" role="button" data-target="#EmailSetupBody" aria-expanded={campaignData.deliveryMethod === "Email & sVang App" && !readyToSubmit ? "false" : "true"} aria-controls="collapseFour">
                                                <div className="d-flex">
                                                    <h5 className="mb-0">Email Setup</h5>
                                                </div>
                                                <hr />
                                            </div>
                                            <div id="EmailSetupBody"
                                                className={campaignData.deliveryMethod === "Email & sVang App" && !readyToSubmit ? "collapse" : "collapse show"}
                                                data-parent="#accordionEmailSetup">
                                                <div className="ms-panel-body">
                                                    <div className="form-row">
                                                        <div className="col-lg-6 col-md-6 mb-2">
                                                            <label htmlFor="name" className="required">Email Subject</label>
                                                            <div className="input-group">
                                                                <input type="text"
                                                                    className={"form-control " + (campaignErrorData.emailSubject !== "" ? 'invalid' : '')}
                                                                    id="emailSubject"
                                                                    placeholder="You can add contact information inside your subject"
                                                                    value={campaignData.emailSubject}
                                                                    onChange={this.onDetailsChange}
                                                                    onBlur={this.onFocusOut}
                                                                />
                                                                {campaignErrorData.emailSubject && campaignErrorData.emailSubject !== "" &&
                                                                    <div className="invalid-feedback">{campaignErrorData.emailSubject}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 mb-2 div-from-name">
                                                            <label htmlFor="name" className="required">From Name</label>
                                                            <div className="input-group">
                                                                <input type="text"
                                                                    className={"form-control " + (campaignErrorData.fromName !== "" ? 'invalid' : '')}
                                                                    id="fromName"
                                                                    placeholder="Enter from name"
                                                                    value={campaignData.fromName}
                                                                    onChange={this.onDetailsChange}
                                                                    onBlur={this.onFocusOut}
                                                                />
                                                                {campaignErrorData.fromName && campaignErrorData.fromName !== "" &&
                                                                    <div className="invalid-feedback">{campaignErrorData.fromName}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <label htmlFor="name" className="required">Format Email</label>
                                                            {/* {campaignData.emailContentJson && <EmailEditor ref={this.emailEditorRef} onLoad={this.onLoadEmailEditor} minHeight="850px" />}
                                                                    {!campaignData.emailContentJson && <Spinner />} */}
                                                            <EmailEditor ref={this.emailEditorRef} minHeight="850px" onLoad={this.onLoadEmailEditor} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {campaignData.deliveryMethod && (campaignData.deliveryMethod === "sVang App" || campaignData.deliveryMethod === "Email & sVang App") && <div className="col-md-12 accordion has-gap ms-accordion-chevron" id="accordionNotificationSetup">
                                        <div className="ms-panel custom-panel">
                                            <div
                                                className={(campaignData.deliveryMethod === "Email & sVang App") && !readyToSubmit ? "ms-panel-header collapsed" : "ms-panel-header"}
                                                data-toggle="collapse" role="button" data-target="#NotificationSetupBody" aria-expanded={campaignData.deliveryMethod === "Email & sVang App" && !readyToSubmit ? "false" : "true"} aria-controls="collapseFour">
                                                <div className="d-flex">
                                                    <h5 className="mb-0">Notification Setup</h5>
                                                </div>
                                                <hr />
                                            </div>
                                            <div id="NotificationSetupBody"
                                                className={campaignData.deliveryMethod === "Email & sVang App" && !readyToSubmit ? "collapse" : "collapse show"}
                                                data-parent="#accordionNotificationSetup">
                                                <div className="ms-panel-body">
                                                    <div className="form-row">
                                                        <div className="col-md-6 mb-2">
                                                            <label htmlFor="name" className="required">Title</label>
                                                            <div className="input-group">
                                                                <input type="text"
                                                                    className={"form-control " + (campaignErrorData.notificationTitle !== "" ? 'invalid' : '')}
                                                                    id="notificationTitle"
                                                                    placeholder="Add notification title"
                                                                    value={campaignData.notificationTitle}
                                                                    onChange={this.onDetailsChange}
                                                                    onBlur={this.onFocusOut}
                                                                    maxLength={50}
                                                                />
                                                                {campaignErrorData.notificationTitle && campaignErrorData.notificationTitle !== "" &&
                                                                    <div className="invalid-feedback">{campaignErrorData.notificationTitle}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <label htmlFor="name" className="required">Content</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    rows="3"
                                                                    id="notificationContent"
                                                                    className={"form-control " + (campaignErrorData.notificationContent !== "" ? 'invalid' : '')}
                                                                    placeholder="Write content fot notification"
                                                                    value={campaignData.notificationContent}
                                                                    onChange={this.onDetailsChange}
                                                                    onBlur={this.onFocusOut}
                                                                    required
                                                                    maxLength={250}
                                                                ></textarea>
                                                                {campaignErrorData.notificationContent && campaignErrorData.notificationContent !== "" &&
                                                                    <div className="invalid-feedback">{campaignErrorData.notificationContent}</div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="col-md-12 mb-4">
                                        <button type="button" className="btn btn-primary float-right"
                                            onClick={(e) => this.onSend(e)}>
                                            Send {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                                        </button>
                                        <button type="button" className="btn btn-light float-right mr-2"
                                            onClick={(e) => this.onSaveAsDraft(e)}>
                                            Save as Draft {submitProgressDraft && <i className="fas fa-spinner fa-pulse"></i>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignUpdate;