import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import ExtraHotSpice from '../../../assets/img/spice/extraHot.png';
import HotSpice from '../../../assets/img/spice/hot.png';
import MediumSpice from '../../../assets/img/spice/medium.png';
import MildSpice from '../../../assets/img/spice/mild.png';
class AddAdditionOptions extends React.Component {
    constructor(props) {
        super(props);
        const spiceOptions = [{
            item: "Mild",
            isSelected: true
        }, {
            item: "Medium",
            isSelected: false
        }, {
            item: "Hot",
            isSelected: false
        }, {
            item: "Extra Hot",
            isSelected: false
        }];
        this.state = {
            spiceOptions: spiceOptions,
            addAdditionOptionsData: {
                title: '',
                description: '',
                isSpiceOption: false,
                options: [{
                    isSelected: false,
                    item: ""
                }],
            },
            addAdditionOptionsErrorData: {
                title: '',
                options: [{
                    item: ""
                }]
            },
            helpText: {
                title: 'Give a descriptive title of the additional options. There is no cost for these items but they may change the behavior of the menu item.',
                description: 'Give an optional description. Example: Spice Level for dishes.',
                isSpiceOption: 'If yes, you will see these spice icons on the menu option to choose from. If no, you can choose descriptive modifiers.',
                options: 'Specify a list of items and select the one which should default to. Example: Fries, Chips and make Chips default.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {

    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addAdditionOptionsData;
        let formErrors = this.state.addAdditionOptionsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }
        if (!formDetails.isSpiceOption) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                }
            }
        }
        
        this.setState({ addAdditionOptionsErrorData: formErrors });
        this.setState({ addAdditionOptionsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addAdditionOptionsData;
        let formErrors = this.state.addAdditionOptionsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addAdditionOptionsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addAdditionOptionsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addAdditionOptionsData;
        let formDetailsError = this.state.addAdditionOptionsErrorData;
        formDetails[formField].push({
            isSelected: false,
            item: ""
        });
        formDetailsError[formField].push({
            item: ""
        });
        this.setState({ addAdditionOptionsData: formDetails, addAdditionOptionsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addAdditionOptionsData;
        let formDetailsError = this.state.addAdditionOptionsErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ addAdditionOptionsData: formDetails, addAdditionOptionsErrorData: formDetailsError })
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const addAdditionOptionsData = this.state.addAdditionOptionsData;
            const addObj = {
                modifiresObject: {
                    title: addAdditionOptionsData.title,
                    description: addAdditionOptionsData?.description ? addAdditionOptionsData.description : "",
                },
                isSpiceOption: addAdditionOptionsData.isSpiceOption,
                title: addAdditionOptionsData.title,
                description: addAdditionOptionsData?.description ? addAdditionOptionsData.description : "",
                categoryName: "additionOptions",
                vendorId: vendorId
            };
            
            addObj.modifiresObject.options = addAdditionOptionsData.options;
            if (addAdditionOptionsData.isSpiceOption) {
                addObj.modifiresObject.isSpiceOption = true;
                addObj.modifiresObject.options = self.state.spiceOptions;
            }
            if (!addAdditionOptionsData.isSpiceOption) {
                // eslint-disable-next-line
                addObj.modifiresObject.options.sort((a, b) => a.isSelected < b.isSelected && 1 || -1);
            }
            if (vendorId) {
                let url = `modifier/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=oneitemselectionwithnoprice`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            addAdditionOptionsData,
            addAdditionOptionsErrorData,
            submitProgress,
            spiceOptions,
            helpText
        } = { ...this.state };
        return (
            <div className="addition-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add One Item Without Price</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=oneitemselectionwithnoprice">One Item Without Price</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add One Item Without Price</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="title">Title</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                            <div className="input-group">
                                <input type="text"
                                    className={"form-control " + (addAdditionOptionsErrorData.title !== "" ? 'invalid' : '')}
                                    id="title"
                                    name="title"
                                    maxLength="40"
                                    placeholder="Title"
                                    value={addAdditionOptionsData.title}
                                    onChange={this.onBasicDetailsChange}
                                    />
                                {addAdditionOptionsErrorData.title !== "" &&
                                    <div className="invalid-feedback">
                                        {addAdditionOptionsErrorData.title}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="description">Description</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    maxLength="120"
                                    placeholder="Description"
                                    value={addAdditionOptionsData.description}
                                    onChange={this.onBasicDetailsChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6">
                            <label htmlFor="optionaPrice">Spice Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.isSpiceOption}></i>
                            <div className="input-group mb-0">
                                <ul className="ms-list d-flex">
                                    <li className="ms-list-item pl-0">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio"
                                                name="radioExample2"
                                                value={true}
                                                checked={addAdditionOptionsData.isSpiceOption === true}
                                                onChange={(event) => {
                                                    let addAdditionOptionsData = this.state.addAdditionOptionsData;
                                                    addAdditionOptionsData.isSpiceOption = event.currentTarget.value === "true" ? true : false;
                                                    this.setState({ addAdditionOptionsData: addAdditionOptionsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Yes </span>
                                    </li>
                                    <li className="ms-list-item">
                                        <label className="ms-checkbox-wrap">
                                            <input
                                                type="radio"
                                                name="radioExample2"
                                                value={false}
                                                checked={addAdditionOptionsData.isSpiceOption === false}
                                                onChange={(event) => {
                                                    let addAdditionOptionsData = this.state.addAdditionOptionsData;
                                                    addAdditionOptionsData.isSpiceOption = event.currentTarget.value === "true" ? true : false;
                                                    this.setState({ addAdditionOptionsData: addAdditionOptionsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> No </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {addAdditionOptionsData.isSpiceOption &&
                          <div className="col-md-12 col-lg-12">
                              {spiceOptions && spiceOptions.length > 0 &&
                                    spiceOptions.map((spiceOptionsRow, index) => (
                                        <div className="spice-list-wrapper" key={index}>
                                            {spiceOptionsRow.item === 'Mild' &&
                                                <div className="spice_list">
                                                    <img src={MildSpice} alt={spiceOptionsRow.item}></img>
                                                    <p>{spiceOptionsRow.item}</p>
                                                </div>
                                            }
                                            {spiceOptionsRow.item === 'Medium' &&
                                                <div className="spice_list">
                                                    <img src={MediumSpice} alt={spiceOptionsRow.item}></img>
                                                    <p>{spiceOptionsRow.item}</p>
                                                </div>
                                            }
                                            {spiceOptionsRow.item === 'Hot' &&
                                                <div className="spice_list">
                                                    <img src={HotSpice} alt={spiceOptionsRow.item}></img>
                                                    <p>{spiceOptionsRow.item}</p>
                                                </div>
                                            }
                                            {spiceOptionsRow.item === 'Extra Hot' &&
                                                <div className="spice_list">
                                                    <img src={ExtraHotSpice} alt={spiceOptionsRow.item}></img>
                                                    <p>{spiceOptionsRow.item}</p>
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                          </div>
                        }
                        {!addAdditionOptionsData.isSpiceOption &&
                            <div className="col-md-6 col-lg-6">
                                <ReactTooltip />
                                <label>Options</label>
                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="addition-option-radio">
                                            <label className="ms-checkbox-wrap"></label>
                                            <label htmlFor="optionaItem">Item</label>
                                        </div>
                                    </div>
                                </div>
                                {addAdditionOptionsData.options && addAdditionOptionsData.options.length > 0 &&
                                    addAdditionOptionsData.options.map((optionsRow, index) => (
                                        <div className="row" key={index}>
                                            <div className="col-md-6">
                                                <div className="addition-option-radio mb-15">
                                                    <label className="ms-checkbox-wrap mb-8">
                                                        <input
                                                            type="radio"
                                                            name="optionsRadioExample"
                                                            value={optionsRow.isSelected}
                                                            checked={optionsRow.isSelected === true}
                                                            onClick={(e) => {
                                                                if (e.currentTarget.value === 'true') {
                                                                    let addAdditionOptionsData = this.state.addAdditionOptionsData;
                                                                    addAdditionOptionsData.options[index].isSelected = false;
                                                                    this.setState({ addAdditionOptionsData: addAdditionOptionsData })
                                                                }
                                                            }}
                                                            onChange={(event) => {
                                                                let addAdditionOptionsData = this.state.addAdditionOptionsData;
                                                                for (let i = 0; i < addAdditionOptionsData.options.length; i += 1) {
                                                                    if (index === i) {
                                                                        addAdditionOptionsData.options[i].isSelected = true;
                                                                    } else {
                                                                        addAdditionOptionsData.options[i].isSelected = false;
                                                                    }
                                                                }
                                                                this.setState({ addAdditionOptionsData: addAdditionOptionsData })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <div className="input-group mb-0">
                                                        {/* <label htmlFor="optionaItem">Item</label> */}
                                                        <input type="text"
                                                            className={"form-control " + (addAdditionOptionsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                            id="optionaItem"
                                                            name="optionaItem"
                                                            maxLength="40"
                                                            placeholder="Item"
                                                            value={optionsRow.item}
                                                            // onChange={this.onBasicDetailsChange}
                                                            onChange={(e) => {
                                                                let tempData = addAdditionOptionsData;
                                                                let tempErrorData = addAdditionOptionsErrorData;
                                                                tempData.options[index].item = e.target.value;
                                                                this.setState({ addAdditionOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                                if (!isError) {
                                                                tempErrorData.options[index].item = "";
                                                                this.setState({ addAdditionOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                            />
                                                            { addAdditionOptionsData.options.length === 1 && index === 0 &&
                                                                <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                            }
                                                            { addAdditionOptionsData.options.length === index+1 && index > 0 &&
                                                                <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                            }
                                                            { index < addAdditionOptionsData.options.length-1 &&
                                                                <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                            }
                                                            {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                            } */}
                                                            {addAdditionOptionsErrorData.options[index].item !== "" &&
                                                                <div className="invalid-feedback">
                                                                    {addAdditionOptionsErrorData.options[index].item}
                                                                </div>
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                            Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddAdditionOptions;