import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import DataTable from '../../DataTables';
import Spinner from '../../spinner';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth, getLoginUserDetails } from '../../../utils';

class CampaignsIndex extends React.Component {
    constructor(props) {
        super(props);
        const allCampaignColumns = [
            { title: 'Name', data: 'campaignName' },
            { title: 'Trigger', data: 'triggerCampaign' },
            { title: 'Delivery Method', data: 'deliveryMethod' },
            { title: 'Status', data: 'status' },
            { title: 'Created On', data: 'createdAt'},
            { title: 'Action', data: 'createdAt', orderable: false }
        ];
        const sentCampaignColumns = [
            { title: 'Name', data: 'campaignName' },
            { title: 'Trigger', data: 'triggerCampaign' },
            { title: 'Delivery Method', data: 'deliveryMethod' },
            { title: 'Total Sent', data: 'totalSent' },
            { title: 'Created On', data: 'createdAt'},
            { title: 'Action', data: 'createdAt', orderable: false }
        ];
        const draftCampaignColumns = [
            { title: 'Name', data: 'campaignName' },
            { title: 'Trigger', data: 'triggerCampaign' },
            { title: 'Delivery Method', data: 'deliveryMethod' },
            { title: 'Created On', data: 'createdAt'},
            { title: 'Action', data: 'createdAt', orderable: false }
        ];
        const scheduledCampaignColumns = [
            { title: 'Name', data: 'campaignName' },
            { title: 'Trigger', data: 'triggerCampaign' },
            { title: 'Delivery Method', data: 'deliveryMethod' },
            { title: 'Schedule Date & Time', data: 'scheduleTime' },
            { title: 'Created On', data: 'createdAt'},
            { title: 'Action', data: 'createdAt', orderable: false }
        ];
        this.triggerStatusColor = {
            true: '#7fba22',
            false: '#000'
        }
        this.state = {
            couponsData: this.props.couponsData,
            allCampaignColumns,
            sentCampaignColumns,
            draftCampaignColumns,
            scheduledCampaignColumns,
            allCampaignsData: { data: null },
            sentCampaignsData: { data: null },
            draftCampaignsData: { data: null },
            scheduledCampaignsData: { data: null },
            reloadCampaignTable: false,
        }
        this.setupCampaignClick = this.setupCampaignClick.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.openEditCampaign = this.openEditCampaign.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
    }
    componentDidMount() {
        this.getCampaigns();
    }

    openEditCampaign(event, campaignId) {
        event.preventDefault();
        this.props.history.push(
            {
                pathname: `/marketing_management/${campaignId}`,
                state: { couponsData: this.state.couponsData }
            });
    }

    confirmAndDelete(e, campaignId) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this campaign!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let vendor = JSON.parse(localStorage.getItem('vendor'));
                let requestData = {
                    vendorId: vendor.id,
                    campaignId: campaignId
                }
                let url = `campaign?db=mongodb`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the campaign. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                self.getCampaigns();
                                toast.success("Success: campaign deleted.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the campaign. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting campaign: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the campaign. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting campaign: ", error);
                }
            }
        });
    }

    onActionChange(event, campaign, rowIndex) {
        let campaignID = campaign.id;
        let action = event.target.value;
        if (action === "Edit") {
            this.openEditCampaign(event, campaignID);
        }
        if (action === "Delete") {
            this.confirmAndDelete(event, campaignID);
        }
        if (action === "Duplicate") {
            this.props.history.push(
                {
                    pathname: "/marketing_management/setup",
                    state: { couponsData: this.state.couponsData, sourceCampaignID: campaignID }
                });
        }
        if (action === "Preview") {
            let campaign = this?.state?.allCampaignsData?.data
                && this.state.allCampaignsData.data.length > 0
                && this.state.allCampaignsData.data[rowIndex] ? this.state.allCampaignsData.data[rowIndex] : "";
            this.props.history.push(
                {
                    pathname: "/marketing_management/preview",
                    state: { emailHtml: campaign && campaign.emailContentHtml ? campaign.emailContentHtml : "" }
                });
        }
        if (action === "Report") {
            this.props.history.push(
                {
                    pathname: `/marketing_management/report/${campaignID}`,
                });
        }
        if (action === "enableTrigger" || action === "disableTrigger") {
            this.updateTriggerEnableStatus(event, action === "enableTrigger" ? true : false, campaignID);
        }
        event.target.value = "";
    }

    updateTriggerEnableStatus(e, status, campaignId) {
        e.preventDefault();
        let self = this;
        let text = status === true ? "Your action will enable this trigger campaign." : "Your action will disable this trigger campaign."
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                let vendor = getLoginUserDetails();
                let vendorId = vendor.id;
                if (vendorId) {
                    let url = `campaign/updatetriggerenableflag?db=mongodb`;
                    let requestData = {
                        vendorId,
                        campaignId,
                        triggerStatus: status
                    };
                    try {
                        let token = getAuthTokenFromLocalStorage();
                        if (!token) {
                            let auth = await getAuth();
                            if (auth && auth.success && auth.token) {
                                localStorage.setItem('token', auth.token);
                            }
                        }
                        axiosInstance.post(url, requestData)
                            .then(result => {
                                // If auth token expired
                                if ("success" in result.data && result.data.success === false) {
                                    localStorage.removeItem('token');
                                    toast.error("Oops! We encountered a problem in updating the status. Please try again.", {
                                        autoClose: 5000,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    });
                                    return;
                                }
                                if (result.data.status) {
                                    self.getCampaigns();
                                    toast.success("👍👍👍: Campaign updated!", {
                                        autoClose: 3000,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    })
                                } else {
                                    toast.error(result.data.statusText, {
                                        autoClose: 5000,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    });
                                }
                            })
                            .catch(error => {
                                toast.error("Oops! We encountered an error. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                console.error("Problem in updating enable flag: ", error);
                            })
                    }
                    catch (error) {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in updating enable flag: ", error);
                    }

                } else {
                    toast.error("Oops! It seems that there is some problem with the data. Please check and try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            }
        });
    }

    async getCampaigns() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `campaign/${vendorId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getCampaigns();
                        return;
                    }
                    let campaignsListData = result.data.response;
                    let sentCampaignsData = [];
                    let draftCampaignsData = [];
                    let scheduledCampaignsData = [];
                    for (let i = 0; i < campaignsListData.length; i++) {
                        if (campaignsListData[i].status === "draft") {
                            draftCampaignsData.push(campaignsListData[i]);
                        } else if (campaignsListData[i].status === "sent") {
                            sentCampaignsData.push(campaignsListData[i]);
                        } else {
                            scheduledCampaignsData.push(campaignsListData[i]);
                        }
                    }
                    this.setState({
                        allCampaignsData: {
                            data: this.prepareCampaignDatatable(campaignsListData),
                            columnDefs: [{
                                targets: [5],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.status === "Draft") {
                                        ReactDOM.render(
                                            <div>
                                                <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                    <option value="">Select</option>
                                                    <option value="Edit">Edit</option>
                                                    <option value="Duplicate">Duplicate</option>
                                                    <option value="Delete">Delete</option>
                                                </select>
                                            </div>
                                            , td)
                                    } else if (rowData.status === "Sent") {
                                        if (rowData.deliveryMethod === "Email" || rowData.deliveryMethod === "Email & sVang App") {
                                            if (!('isTriggerCampaign' in rowData) || !rowData.isTriggerCampaign) {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Report">View Report</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="Preview">Preview</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            } else {
                                                if ('isTriggerCampaign' in rowData && rowData.isTriggerCampaign && rowData.isTriggerEnabled) {
                                                    ReactDOM.render(
                                                        <div>
                                                            <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                                <option value="">Select</option>
                                                                <option value="Report">View Report</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                                <option value="Preview">Preview</option>
                                                                <option value="disableTrigger">Disable Trigger</option>
                                                                <option value="Delete">Delete</option>
                                                            </select>
                                                        </div>
                                                        , td)
                                                } else {
                                                    ReactDOM.render(
                                                        <div>
                                                            <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                                <option value="">Select</option>
                                                                <option value="Report">View Report</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                                <option value="Preview">Preview</option>
                                                                <option value="enableTrigger">Enable Trigger</option>
                                                                <option value="Delete">Delete</option>
                                                            </select>
                                                        </div>
                                                        , td)
                                                }
                                            }
                                        } else {
                                            if (!('isTriggerCampaign' in rowData) || !rowData.isTriggerCampaign) {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            } else {
                                                if ('isTriggerCampaign' in rowData && rowData.isTriggerCampaign && rowData.isTriggerEnabled) {
                                                    ReactDOM.render(
                                                        <div>
                                                            <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                                <option value="">Select</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                                <option value="disableTrigger">Disable Trigger</option>
                                                                <option value="Delete">Delete</option>
                                                            </select>
                                                        </div>
                                                        , td)
                                                } else {
                                                    ReactDOM.render(
                                                        <div>
                                                            <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                                <option value="">Select</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                                <option value="enableTrigger">Enable Trigger</option>
                                                                <option value="Delete">Delete</option>
                                                            </select>
                                                        </div>
                                                        , td)
                                                }
                                            }
                                        }
                                    } else {
                                        if (rowData.deliveryMethod === "Email" || rowData.deliveryMethod === "Email & sVang App") {
                                            ReactDOM.render(
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                        <option value="">Select</option>
                                                        <option value="Preview">Preview</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                </div>
                                                , td)
                                        } else {
                                            ReactDOM.render(
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                        <option value="">Select</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                </div>
                                                , td)
                                        }
                                    }
                                }
                            },
                            {
                                targets: [3],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.status === "Draft") {
                                        ReactDOM.render(
                                            <div><span className="status-dot bg-secondary"></span><span>Draft</span></div>
                                            , td)
                                    } else if (rowData.status === "Sent") {
                                        ReactDOM.render(
                                            <div><span className="status-dot bg-primary"></span><span>Sent</span></div>
                                            , td)
                                    } else if (rowData.status === "Scheduled") {
                                        ReactDOM.render(
                                            <div><span className="status-dot bg-success"></span><span>Scheduled</span></div>
                                            , td)
                                    }
                                }
                            },
                            {
                                targets: [1],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.triggerCampaign === "Yes") {
                                        ReactDOM.render(
                                            <div title={rowData.isTriggerEnabled ? "Enabled" : "Disabled"}><span className="status-dot" style={{ backgroundColor: self.triggerStatusColor[rowData.isTriggerEnabled] }}></span><span>{rowData.triggerCampaign}</span></div>
                                            , td)
                                    }
                                }
                            }
                            ]
                        }
                    });
                    this.setState({
                        sentCampaignsData: {
                            data: this.prepareCampaignDatatable(sentCampaignsData),
                            columnDefs: [{
                                targets: [5],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.deliveryMethod === "Email" || rowData.deliveryMethod === "Email & sVang App") {
                                        if (!('isTriggerCampaign' in rowData) || !rowData.isTriggerCampaign) {
                                            ReactDOM.render(
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                        <option value="">Select</option>
                                                        <option value="Report">View Report</option>
                                                        <option value="Duplicate">Duplicate</option>
                                                        <option value="Preview">Preview</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                </div>
                                                , td)
                                        } else {
                                            if ('isTriggerCampaign' in rowData && rowData.isTriggerCampaign && rowData.isTriggerEnabled) {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Report">View Report</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="Preview">Preview</option>
                                                            <option value="disableTrigger">Disable Trigger</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            } else {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Report">View Report</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="Preview">Preview</option>
                                                            <option value="enableTrigger">Enable Trigger</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            }
                                        }
                                    } else {

                                        if (!('isTriggerCampaign' in rowData) || !rowData.isTriggerCampaign) {
                                            ReactDOM.render(
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                        <option value="">Select</option>
                                                        <option value="Duplicate">Duplicate</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                </div>
                                                , td)
                                        } else {
                                            if ('isTriggerCampaign' in rowData && rowData.isTriggerCampaign && rowData.isTriggerEnabled) {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="disableTrigger">Disable Trigger</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            } else {
                                                ReactDOM.render(
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                            <option value="">Select</option>
                                                            <option value="Duplicate">Duplicate</option>
                                                            <option value="enableTrigger">Enable Trigger</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                    , td)
                                            }
                                        }
                                    }
                                }
                            }, {
                                targets: [3],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.deliveryMethod === "Email") {
                                        if (!('totalSent' in rowData)) {
                                            rowData['totalSent'] = 0;
                                        }
                                        ReactDOM.render(
                                            <div>
                                                <span className="badge badge-outline-primary">Email: {rowData['totalSent']}</span>
                                            </div>
                                            , td)
                                    } else if (rowData.deliveryMethod === "sVang App") {
                                        if (!('totalNotificationSent' in rowData)) {
                                            rowData['totalNotificationSent'] = 0;
                                        }
                                        ReactDOM.render(
                                            <div>
                                                <span className="badge badge-outline-primary">Notification: {rowData['totalNotificationSent']}</span>
                                            </div>
                                            , td)
                                    } else {
                                        if (!('totalSent' in rowData)) {
                                            rowData['totalSent'] = 0;
                                        }
                                        if (!('totalNotificationSent' in rowData)) {
                                            rowData['totalNotificationSent'] = 0;
                                        }
                                        ReactDOM.render(
                                            <div>
                                                <span className="badge badge-outline-primary">Email: {rowData['totalSent']}</span>
                                                <span className="badge badge-outline-primary ml-2">Notification: {rowData['totalNotificationSent']}</span>
                                            </div>
                                            , td)
                                    }
                                }
                            }, {
                                targets: [1],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.triggerCampaign === "Yes") {
                                        ReactDOM.render(
                                            <div title={rowData.isTriggerEnabled ? "Enabled" : "Disabled"}><span className="status-dot" style={{ backgroundColor: self.triggerStatusColor[rowData.isTriggerEnabled] }}></span><span>{rowData.triggerCampaign}</span></div>
                                            , td)
                                    }
                                }
                            }]
                        }
                    });
                    this.setState({
                        draftCampaignsData: {
                            data: this.prepareCampaignDatatable(draftCampaignsData),
                            columnDefs: [{
                                targets: [4],
                                createdCell: (td, cellData, rowData) => {
                                    ReactDOM.render(
                                        <div>
                                            <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                <option value="">Select</option>
                                                <option value="Edit">Edit</option>
                                                <option value="Duplicate">Duplicate</option>
                                                <option value="Delete">Delete</option>
                                            </select>
                                        </div>
                                        , td)
                                }
                            }, {
                                targets: [1],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.triggerCampaign === "Yes") {
                                        ReactDOM.render(
                                            <div title={rowData.isTriggerEnabled ? "Enabled" : "Disabled"}><span className="status-dot" style={{ backgroundColor: self.triggerStatusColor[rowData.isTriggerEnabled] }}></span><span>{rowData.triggerCampaign}</span></div>
                                            , td)
                                    }
                                }
                            }]
                        }
                    });
                    this.setState({
                        scheduledCampaignsData: {
                            data: this.prepareCampaignDatatable(scheduledCampaignsData),
                            columnDefs: [{
                                targets: [5],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.deliveryMethod === "Email" || rowData.deliveryMethod === "Email & sVang App") {
                                        ReactDOM.render(
                                            <div>
                                                <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                    <option value="">Select</option>
                                                    <option value="Preview">Preview</option>
                                                    <option value="Delete">Delete</option>
                                                </select>
                                            </div>
                                            , td)
                                    } else {
                                        ReactDOM.render(
                                            <div>
                                                <select className="form-control" onChange={(event) => this.onActionChange(event, rowData, rowData.rowIndex)}>
                                                    <option value="">Select</option>
                                                    <option value="Delete">Delete</option>
                                                </select>
                                            </div>
                                            , td)
                                    }
                                }
                            }, {
                                targets: [1],
                                createdCell: (td, cellData, rowData) => {
                                    if (rowData.triggerCampaign === "Yes") {
                                        ReactDOM.render(
                                            <div title={rowData.isTriggerEnabled ? "Enabled" : "Disabled"}><span className="status-dot" style={{ backgroundColor: self.triggerStatusColor[rowData.isTriggerEnabled] }}></span><span>{rowData.triggerCampaign}</span></div>
                                            , td)
                                    }
                                }
                            }]
                        }
                    });
                    this.setState({ reloadCampaignTable: true });
                })
                .catch(error => {
                    this.setState({ allCampaignsData: { data: [] } })
                    console.error("Problem in fetching campaigns data: ", error);
                })
        }
        catch (error) {
            this.setState({ allCampaignsData: { data: [] } })
            console.error("Problem in fetching campaigns data: ", error);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.couponsData.data !== nextProps.couponsData.data) {
            let couponData = nextProps.couponsData && nextProps.couponsData.data && nextProps.couponsData.data.length > 0 ? nextProps.couponsData.data : []
            this.setState({ couponsData: { data: couponData } })
        }
    }

    setupCampaignClick(e) {
        e.preventDefault();
        this.props.history.push(
            {
                pathname: "/marketing_management/setup",
                state: { couponsData: this.state.couponsData }
            });
    }

    prepareCampaignDatatable(campaigns) {
        let allCampaignsData = [];
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorTimezone = vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
        // let dateFormat = vendor.vendorAllDetails && vendor.vendorAllDetails.dateFormat && vendor.vendorAllDetails.dateFormat !== "" ? vendor.vendorAllDetails.dateFormat : "MM-DD-YYYY";
        allCampaignsData = campaigns.map((campaign, index) => {
            let status = campaign.status === "sent" ? "Sent" : campaign.status === "draft" ? "Draft" : "Scheduled";
            let recipients = campaign.recipients === "all" ? "All Customers" : "Active Customers";
            return {
                ...campaign,
                status,
                recipients,
                rowIndex: index,
                linkedCoupon: campaign.linkedCoupon !== "" && campaign?.couponData?.couponName ? campaign.couponData.couponName : "",
                scheduleTime: campaign.scheduleTime !== "" ? moment(campaign.scheduleTime).tz(vendorTimezone).format('YYYY-MM-DD HH:mm') : "",
                createdAt: moment(campaign.createdAt).tz(vendorTimezone).format('YYYY-MM-DD'),
                triggerCampaign: 'isTriggerCampaign' in campaign && campaign.isTriggerCampaign ? 'Yes' : 'No'
            }

        });
        return allCampaignsData;
    }

    render() {
        const { allCampaignColumns, allCampaignsData,
            sentCampaignColumns, sentCampaignsData,
            draftCampaignColumns, draftCampaignsData,
            scheduledCampaignColumns, scheduledCampaignsData,
            reloadCampaignTable } = { ...this.state }
        return (
            <div>
                <button type="button" href="/#" onClick={this.setupCampaignClick}
                    className="btn btn-primary has-icon btn-sm btn-setup-campaign">
                    <i className="fa fa-plus fa-xs"></i>
                    Setup Campaign
                </button>
                <div>
                    <ul
                        className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4 mt-0"
                        role="tablist"
                    >
                        <li role="presentation">
                            <a
                                href="#tab3"
                                aria-controls="tab3"
                                className="active show"
                                role="tab"
                                data-toggle="tab"
                            >
                                All
                      </a>
                        </li>
                        <span className="tab-separator"></span>
                        <li role="presentation">
                            <a
                                href="#tab4"
                                aria-controls="tab4"
                                role="tab"
                                data-toggle="tab"
                            >
                                Sent
                      </a>
                        </li>
                        <span className="tab-separator"></span>
                        <li role="presentation">
                            <a
                                href="#tab5"
                                aria-controls="tab5"
                                role="tab"
                                data-toggle="tab"
                            >
                                Scheduled
                      </a>
                        </li>
                        <span className="tab-separator"></span>
                        <li role="presentation">
                            <a
                                href="#tab6"
                                aria-controls="tab6"
                                role="tab"
                                data-toggle="tab"
                            >
                                Draft
                      </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active show fade in" id="tab3">
                            {(!allCampaignsData || allCampaignsData.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {allCampaignsData && allCampaignsData.data !== null &&
                                    <DataTable data={allCampaignsData} columns={allCampaignColumns} reloadTable={reloadCampaignTable} sortColumn="4" sortOrder="desc" id="campaignsTable" />}
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="tab4">
                            {(!sentCampaignsData || sentCampaignsData.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {sentCampaignsData && sentCampaignsData.data !== null &&
                                    <DataTable data={sentCampaignsData} columns={sentCampaignColumns} reloadTable={reloadCampaignTable} sortColumn="4" sortOrder="desc" id="sentCampaignsTable" />}
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="tab5">
                            {(!scheduledCampaignsData || scheduledCampaignsData.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {scheduledCampaignsData && scheduledCampaignsData.data !== null &&
                                    <DataTable data={scheduledCampaignsData} columns={scheduledCampaignColumns} reloadTable={reloadCampaignTable} sortColumn="4" sortOrder="desc" id="scheduledCampaignsTable" />}
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="tab6">
                            {(!draftCampaignsData || draftCampaignsData.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {draftCampaignsData && draftCampaignsData.data !== null &&
                                    <DataTable data={draftCampaignsData} columns={draftCampaignColumns} reloadTable={reloadCampaignTable} sortColumn="3" sortOrder="desc" id="draftCampaignsTable" />}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(CampaignsIndex);