import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = "";
if(process.env.REACT_APP_STAGE === "dev"){
    firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASECONNECTIONDEV)  
} 

if(process.env.REACT_APP_STAGE === "production"){
    firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASECONNECTIONPRODUCTION)
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const collectionName = {
    USERS: 'users',
    VENDOR: 'vendors',
    VENDORORDER: 'vendor_orders',
    VENDORFILTERS: 'vendor_filters'
}
