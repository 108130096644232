import React from 'react';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';

import ReactDragListView from 'react-drag-listview';

class RestaurantCategoryDetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cateName: "",
            cateNameError: "",
            submitProgress: false,
            editCateName: "",
            oldEditCateName: "",
            isOpenEdit: false,
            editCateNameError: "",
            editSubmitProgress: false,
            data: [],
            orderChange: false,
            sortingProgress: false
        }
        const self = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...self.state.data];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                self.setState({
                    data
                });
                self.setState({orderChange: true});
            },
            handleSelector: "tr",
        };
        this.onActionChange = this.onActionChange.bind(this);
        this.addNewCategory = this.addNewCategory.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onEditInputChange = this.onEditInputChange.bind(this);
        this.editSubmitCategory = this.editSubmitCategory.bind(this);
        this.closeEditCategory = this.closeEditCategory.bind(this);
        this.isCategoryInUse = this.isCategoryInUse.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.saveNewSortOrder = this.saveNewSortOrder.bind(this);
    }

    componentDidMount() {
        let vendorDetails = getLoginUserDetails();
        if (vendorDetails?.vendorAllDetails?.product_categories?.length) {
            this.setState({data: vendorDetails.vendorAllDetails.product_categories});
        } else {
            this.setState({data: []});
        }
    }

    onActionChange(event, cateName) {
        let action = event.target.value;
        if(action === "Edit"){
            this.openEditCategoryPage(event, cateName);
        }
        if(action === "Delete"){
            this.openDelete(event, cateName);
        }
        
        event.target.value = "";
    }

    isCategoryInUse(name){
        let allCategoryName = this.state.data;
        let index = allCategoryName.findIndex(categoryName => {
            return categoryName.toLowerCase() === name.toLowerCase()
        });
        return index;
    }

    onInputChange(field, value){
        if(field === "cateName"){
            this.setState({cateName : value});            
            if (value === "") {
                this.setState({cateNameError: "Category name field is required" });
            } else {
                this.setState({ cateNameError: "" });
            }
        }
    }

    async addNewCategory(){
        let self = this;
        self.setState({ submitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/addcategory`;

        if(self.state.cateName === ""){
            self.setState({ cateNameError: "The 'Category name'is a required field" });
            self.setState({ submitProgress: false });
            toast.error("Please provide all required data.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        if (this.isCategoryInUse(self.state.cateName) !== -1) {
            this.setState({cateNameError: "The 'category name' already exists." });
            self.setState({ submitProgress: false });
            toast.error("The 'category name' already exists." , {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        // Prepare post data 
        let requestData = {
            "vendorId": vendorId,
            "cateName": self.state.cateName
        };
        
        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.addNewCategory();
                    return;
                }
                if(result.data.status){
                    let vendorCurrentData = getLoginUserDetails();
                    vendorCurrentData['vendorAllDetails'] = result.data.response;
                    localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                    // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                    this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                    toast.success("Success: Category added!", {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    })
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                self.setState({ submitProgress: false });
                self.setState({ cateName: "" });
            })
            .catch(error => {
                self.setState({ submitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating category.: ", error);
            })
        }
        catch (error) {
            self.setState({ submitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating category. ", error);
        } 
    }

    openEditCategoryPage(event, cateName) {
        this.setState({
            oldEditCateName: cateName,
            editCateName: cateName,
            isOpenEdit: true
        });
        setTimeout(() => {
            this.nameInput.focus();
        }, 500);
    }

    onEditInputChange(field, value) {
        if(field === "editCateName"){
            this.setState({editCateName : value});            
            if (value === "") {
                this.setState({editCateNameError:  "The 'Category name'is a required field"});
            } else {
                this.setState({ editCateNameError: "" });
            }
        }
    }

    async editSubmitCategory() {
        let self = this;
        self.setState({ editSubmitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/editcategory`;

        if(self.state.editCateName === ""){
            self.setState({ editCateNameError:  "The 'Category name'is a required field" });
            self.setState({ editSubmitProgress: false });
            toast.error("Please provide all required data.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        if (this.isCategoryInUse(self.state.editCateName) !== -1) {
            this.setState({editCateNameError: "The 'category name' already exists." });
            self.setState({ editSubmitProgress: false });
            toast.error("The 'category name' already exists.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        // Prepare post data 
        let requestData = {
            "vendorId": vendorId,
            "editCateName": self.state.editCateName,
            "oldEditCateName": self.state.oldEditCateName
        };

        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.editSubmitCategory();
                    return;
                }
                if(result.data.status){
                    let vendorCurrentData = getLoginUserDetails();
                    vendorCurrentData['vendorAllDetails'] = result.data.response;
                    localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                    // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                    this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                    toast.success("Success: Category added!", {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    })
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                self.setState({ editSubmitProgress: false });
                self.setState({ editCateName: "" });
                self.setState({ oldEditCateName: "" });
                this.closeEditCategory();
            })
            .catch(error => {
                self.setState({ editSubmitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating category.: ", error);
            })
        }
        catch (error) {
            self.setState({ editSubmitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating category. ", error);
        } 
    }

    closeEditCategory() {
        this.setState({
            editCateName: "",
            isOpenEdit: false,
            cateName: "",
            oldEditCateName: ""
        });
    }

    async openDelete(event, cateName) {
        let self = this;
        self.setState({ editSubmitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/checkcategoryassignproduct`;
        let requestData = {
            "vendorId": vendorId,
            "cateName": cateName,
        };

        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.openDelete();
                    return;
                }
                if(result.data.status){
                    self.setState({ editSubmitProgress: false });
                    if (result.data.empty) {
                        this.confirmAndDelete(event, cateName, vendorId);
                    } else {
                        toast.error("You can't delete this category name. One of the food items is using it.", {
                            autoClose: 4000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }) 
                    }
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    self.setState({ editSubmitProgress: false });
                }
            })
            .catch(error => {
                self.setState({ editSubmitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in checking category assign in product.: ", error);
            })
        }
        catch (error) {
            self.setState({ editSubmitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in checking category assign in product. ", error);
        } 
    }

    async confirmAndDelete(e, categoryName, vendorId) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this category.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let requestData = {
                    "vendorId": vendorId,
                    "cateName": categoryName,
                };
                let url = `vendor/updatevendor/removecategory`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the category. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                let vendorCurrentData = getLoginUserDetails();
                                vendorCurrentData['vendorAllDetails'] = result.data.response;
                                localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                                // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                                this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                                toast.success("Success: category deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the print station. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting print station: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the print station. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting print station: ", error);
                }
            }
        });
    }

    async saveNewSortOrder() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let self = this;
        self.setState({sortingProgress: true});
        let requestData = {
            "vendorId": vendorId,
            "categoryArray": self.state.data,
        };
        let url = `vendor/updatevendor/editcategoryOrder`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        self.setState({sortingProgress: false});
                        toast.error("Problem in updating sort order:", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        return;
                    }
                    if (result.data.status) {
                        let vendorCurrentData = getLoginUserDetails();
                        let vendorAllDetails = vendorCurrentData['vendorAllDetails'];
                        vendorAllDetails.product_categories = self.state.data;
                        vendorCurrentData['vendorAllDetails'] = vendorAllDetails;
                        localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                        self.setState({orderChange: false});
                        self.setState({sortingProgress: false});
                        toast.success("Success: Sorting order saved succesfully!", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        self.setState({sortingProgress: false});
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    self.setState({sortingProgress: false});
                    toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating sort order: ", error);
                })
        }
        catch (error) {
            self.setState({sortingProgress: false});
            toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating sort order: ", error);
        }
    }

    render() {
        const { cateName, isOpenEdit, editCateName, cateNameError, editCateNameError, orderChange, sortingProgress, submitProgress, editSubmitProgress } = { ...this.state }
        return (
            <div className="section-offdays">
                <form className="needs-validation form-add-offdays clearfix p-3 mt-2" noValidate>
                    {!isOpenEdit &&
                        <div className="form-row">
                            <div className="col-lg-3 col-md-6">
                                <label htmlFor="cateName" className="required">Category Name</label>
                                <div className="input-group">
                                    <input type="text" id="cateName" value={cateName}
                                        onChange={(event) => {
                                            this.onInputChange('cateName', event.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Category Name" />
                                    {cateNameError && cateNameError !== "" && <div className="invalid-feedback">
                                        {cateNameError}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.addNewCategory}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                            </div>
                        </div>
                    }
                    {isOpenEdit &&
                        <div className="form-row">
                            <div className="col-lg-3 col-md-6">
                                <label htmlFor="editCateName" className="required">Category Name</label>
                                <div className="input-group">
                                    <input type="text" ref={(input) => { this.nameInput = input; }} id="editCateName" value={editCateName}
                                        onChange={(event) => {
                                            this.onEditInputChange('editCateName', event.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Category Name" />
                                    {editCateNameError && editCateNameError !== "" && <div className="invalid-feedback">
                                        {editCateNameError}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.editSubmitCategory}>Edit {editSubmitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                <button type="button" className="btn btn-primary mt-4 ml-3" onClick={this.closeEditCategory}>Cancel</button>
                            </div>
                        </div>
                    }
                </form>


                <div className="form-row">
                    <div className="col-md-12">
                        <ReactDragListView {...this.dragProps}>
                            <div className="table-responsive category-list-table mt-5">
                                {orderChange && <div className="text-right">
                                    <button type="button" className="btn btn-primary mt-0" onClick={this.saveNewSortOrder}>Save New Order {sortingProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                </div>}
                                <h6 className="text-info">Note: Drag and drop category name to change its display order on Webapp and sVang App.</h6>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Category Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data && this.state.data.length > 0 ? this.state.data.map((categoryName, index) => (
                                            <tr key={index}>
                                                <td>{categoryName}</td>
                                                <td>
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, categoryName)}>
                                                            <option value="">Select</option>
                                                            <option value="Edit">Edit</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan="2" className="text-center"><label>No data available in table</label></td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </ReactDragListView>
                    </div>
                </div>

            </div>
        );
    }
}

export default RestaurantCategoryDetails;