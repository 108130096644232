import React from 'react';
import queryString from 'query-string';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class WebManagement extends React.Component {
    constructor(props) {
        super(props);
        let activeTab = queryString.parse(this.props.location.search).tab;
        if(!activeTab || activeTab === ""){
          activeTab = "web";
        }
        this.state = {
            isBtnSpinner: false,
            isSpinner: true,
            vendorData: {},
            htmlStr: '',
            editorState: EditorState.createEmpty(),
            activeTab,
            photo: "",
            file: null,
            submitProgress: false
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.openSave = this.openSave.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
    }

    componentDidMount() {
        this.getVendorData();
    }
    async getVendorData() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `getsinglevendors/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.getVendorData();
                    return;
                }
                let response = result.data;
                if (!response.status) {
                  self.setState({ vendorData: {}, isSpinner: false });
                  console.error("Problem in fetching vendor data: ", response.statusText);
                  return;
                }
                if (response.data && response.data.webSection) {
                    const html = response.data.webSection;
                    const contentBlock = htmlToDraft(html);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    self.setState({
                        editorState: editorState,
                        htmlStr: html,
                    });
                }
                self.setState({ vendorData: response.data ? response.data: {}, isSpinner: false });
            }).catch(error => {
                self.setState({ vendorData: {}, isSpinner: false })
                console.error("Problem in fetching vendor data: ", error);
            })
        } catch (error) {
          self.setState({ vendorData: {}, isSpinner: false })
            console.error("Problem in fetching vendor data: ", error);
        }
    }
    onTabChange(tabName){
      this.setState({activeTab: tabName});
    }
    onEditorStateChange(editorState) {
        let self= this;
        self.setState({
          editorState,
          htmlStr: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        });
    };
    async openSave(e, status) {
      let self = this;
      if (status) {
        self.setState({isBtnSpinner: true});
      }
      let vendor = JSON.parse(localStorage.getItem('vendor'));
      let vendorId = vendor.id;
      let url = `vendor/updatevendor/sectiondetails`;
      let requestData = new FormData();
      requestData.append("vendorId", vendorId);
      let htmlStr = self.state.htmlStr;
      if (!status) {
        htmlStr = vendor.vendorAllDetails.defaultSection;
      }
      const contentBlock = htmlToDraft(htmlStr);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      self.setState({
          editorState: editorState,
          htmlStr: htmlStr,
      });
      if (!(vendor.vendorAllDetails && vendor.vendorAllDetails.defaultSection)) {
        requestData.append("defaultSection", htmlStr);
      }
      requestData.append("webSection", htmlStr);

      try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
          let auth = await getAuth();
          if (auth && auth.success && auth.token) {
            localStorage.setItem('token', auth.token);
          }
        }
        axiosInstance.post(url, requestData)
          .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
              localStorage.removeItem('token');
              this.openSave();
              return;
            }
            if (result.data.status) {
              let vendorCurrentData = getLoginUserDetails();
              vendorCurrentData['vendorAllDetails'] = result.data.response;
              localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
            //   self.props.vendorChange();
              let tempData = self.state.editorState;
              if (status) {
                self.setState({ editorState: tempData, isBtnSpinner: false });
              }
              toast.success("Success: details updated!", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              if (status) {
                self.setState({isBtnSpinner: false});
              }
              toast.error(result.data.statusText, {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          })
          .catch(error => {
            if (status) {
              self.setState({isBtnSpinner: false});
            }
            toast.error("Oops! We encountered an error. Please try again.", {
              autoClose: 2000,
              closeOnClick: true,
              pauseOnHover: true,
            });
            console.error("Problem in updating details.: ", error);
          })
        }
        catch (error) {
          if (status) {
            self.setState({isBtnSpinner: false});
          }
          toast.error("Oops! We encountered an error. Please try again.", {
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          console.error("Problem in updating details. ", error);
        }
    }
    onDetailsChange(e) {
      let fieldValue = e.target.files[0];
      this.setState({ file: URL.createObjectURL(fieldValue) })
      this.setState({ photo: fieldValue });
    }
    render() {
        const { editorState, isBtnSpinner, isSpinner, vendorData, activeTab } = { ...this.state }
        return (
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                    <span className="page-title">Web</span>
                    <ol className="breadcrumb pl-0 pt-0">
                        <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Web</li>
                    </ol>
                </nav>
                <div>
                  <ul
                    className="nav nav-tabs tabs-bordered mb-4"
                    role="tablist"
                  >
                    <li role="presentation">
                      <Link
                        to="/web_management?tab=web"
                        aria-controls="tab1"
                        className={activeTab && (activeTab === "" || activeTab.includes('web')) ?  "active show" : ""}
                        role="tab"
                        data-toggle="tab"
                        onClick={() => {this.onTabChange("web")}} 
                      >
                        Web
                          </Link>
                    </li>
                  </ul>
                  <div className="tab-content dashboard_table">
                      <div role="tabpanel" 
                        className={activeTab && (activeTab === "" || activeTab.includes('web')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                        id="tab1">
                          {!isSpinner && !vendorData.manageWebsite &&
                            <div className="col-md-12">
                              <h6 className='text-center'>Selected feature is not available. Please change option in settings manages website is yes.</h6>
                            </div>
                          }
                          {!isSpinner && vendorData.manageWebsite &&
                            <div>
                              <div>
                                  <Editor
                                      editorState={editorState}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      toolbar={{
                                          fontSize: {
                                              options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 28, 30, 36, 46, 48, 60, 72, 96],
                                          },
                                          colorPicker: {
                                              colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgb(255 0 0)'],
                                            }
                                      }}
                                      onEditorStateChange={this.onEditorStateChange}
                                  />
                              </div>
                              <div className="mt-50">
                                {!isBtnSpinner &&
                                  <button className="btn btn-primary" onClick={(event) => { this.openSave(event, true) }}>Save</button>
                                }
                                {isBtnSpinner &&
                                  <button type="button" className="btn btn-primary">Save <i className="fas fa-spinner fa-pulse"></i></button>
                                }
                                  <button className="btn btn-primary ml-4" onClick={(event) => { this.openSave(event, false) }}>Reward Original Site</button>
                              </div>
                            </div>
                          }
                          { isSpinner &&
                            <Spinner />
                          }
                      </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}

export default WebManagement;