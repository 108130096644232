import React from 'react';
import { Link } from "react-router-dom";
import { withRouter} from "react-router-dom";

class EmailPreview extends React.Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
    let emailHtml = this?.props?.location?.state?.emailHtml ? this.props.location.state.emailHtml : "";
    this.state = {
        emailHtml
    }
  }

  componentDidMount(){
    let document = this.iframe.current.contentDocument;
    document.body.innerHTML = this.state.emailHtml;
  }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Campaign Preview</span>
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/marketing_management">Marketing</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Campaign Preview</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Campaign Preview</h6>
                        </div>
                        <div className="ms-panel-body ms-panel-fh">
                            <iframe title="Campaign Preview" ref={this.iframe} style={{ width: "100%", height: "750px" }} />
                        </div>
                    </div></div></div>

        );
    }
}

export default withRouter(EmailPreview);