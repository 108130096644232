import React from 'react';
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import DataTablesComp from '../../DataTables';
// const { v1: uuidv1 } = require('uuid');
class ChildOptionsDetails extends React.Component {
    constructor(props) {
        super(props);
        let parentOptionsId = this.props.match.params.parentOptionsId;
        let parentOptionsData = this.props.location.state && this.props.location.state.parentOptionsData ? this.props.location.state.parentOptionsData : null;
        const columns = [
            // { title: 'Category Name', data: 'categoryName' },
            { title: 'Name', data: 'title' },
            { title: 'Description', data: 'description' },
            // { title: 'Child Section', data: 'description' },
            { title: 'Action', data: 'description'},
        ];
        this.state = {
            columns,
            parentOptionsId: parentOptionsId,
            parentOptionsData: parentOptionsData,
            modifiersAllData: {
                data: null
            },
            reloadTable: false
        }
        this.openAddChildOptions = this.openAddChildOptions.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.openChildOptionsDelete = this.openChildOptionsDelete.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
    }
    componentDidMount() {
        this.getmodifiers('linkTemplateChildOptions');
    }
    openEditChildOptions(e, modifireId, row) {
        e.preventDefault();
        let self = this;
        self.props.history.push(`/menu_management/modifiers/child-options/${modifireId}`, {
            childOptionsData: row,
            parentOptionsData: self.state.parentOptionsData
            // productsData: self.state.productsData,
            // selectedFoodItems: JSON.parse(this.state.firstTimeFoodItems)
        });
    }
    openChildOptions(e, modifireId, row) {
        e.preventDefault();
        let self = this;
        self.props.history.push(`/menu_management/modifiers/child-options-details/${modifireId}`, {
            parentOptionsData: row,
            // productsData: self.state.productsData,
            // selectedFoodItems: JSON.parse(this.state.firstTimeFoodItems)
        });
    }
    async openChildOptionsDelete(event, modifireId, row) {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `modifier/checkassignproduct`;
        let requestData = {
            "vendorId": vendorId,
            "modifireId": modifireId,
            "fieldName": "child_options"
        };

        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.openChildOptionsDelete(event, modifireId, row);
                    return;
                }
                if(result.data.status){
                    if (result.data.empty) {
                        this.confirmAndDelete(event, modifireId, row, result.data.empty);
                    } else {
                        this.confirmAndDelete(event, modifireId, row, result.data.empty);
                    }
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            })
            .catch(error => {
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in checking child options assign in product.: ", error);
            })
        }
        catch (error) {
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in checking child options assign in product. ", error);
        } 
    }
    confirmAndDelete(e, modifireId, row, empty) {
        e.preventDefault();
        let message = "Remove this child options because one of the food items is using it."
        if (empty) {
            message = 'You will not be able to recover this modifier.';
        }
        Swal.fire({
            title: 'Are you sure?',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let requestData = {
                    id: modifireId,
                    vendorId: row.vendorId,
                    empty: empty,
                    fieldName: 'child_options'
                }
                let url = `modifier/delete`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                this.getmodifiers('linkTemplateChildOptions');
                                toast.success("Modifier deleted Successfully", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting modifier: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting modifier: ", error);
                }
            }
        });
    }
    async addCloneData(row) {
        let self = this;
        let url = `modifier/add`;
        // let requestData = new FormData();
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, row)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.addCloneData(row);
                        return;
                    }
                    if (result.data.status) {
                        toast.success("We Clone the modifier successfully.", {
                            autoClose: 3000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        self.setState({
                            isOpenModifierModal: false,
                            submitProgress: false,
                            reloadTable: true
                        });
                        this.getmodifiers('linkTemplateChildOptions');
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in Cloning modifier details: ", error);
                })
        }
        catch (error) {
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in Cloning modifier details. ", error);
        }
    }
    cloneChildOptions(e, modifireId, row) {
        e.preventDefault();
        let tmpRow = JSON.parse(row);
        Swal.fire({
            title: 'Are you sure?',
            text: 'Clone this all child options record.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, clone it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                delete tmpRow._id;
                delete tmpRow.__v;
                delete tmpRow.updatedAt;
                delete tmpRow.createdAt;
                tmpRow.title = `${tmpRow.title} Copy`;
                tmpRow.modifiresObject.title = `${tmpRow.modifiresObject.title} Copy`;
                tmpRow.categoryName = 'linkTemplateChildOptions';
                tmpRow.isClone = true;
                // if (tmpRow.modifiresObject && tmpRow.modifiresObject.options && tmpRow.modifiresObject.options.length) {
                //     for (let i = 0; i < tmpRow.modifiresObject.options.length; i += 1) {
                //         tmpRow.modifiresObject.options[i].id = uuidv1().toUpperCase();
                //     }
                // }
                let self = this;
                let vendor = getLoginUserDetails();
                let vendorId = vendor.id;
                if (vendorId) {
                    this.addCloneData(tmpRow);
                } else {
                    toast.error("Oops! We found problems in the data. Please check and try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    self.setState({ submitProgress: false });
                }
            }
        })
    }
    onActionChange(event, modifireId, row) {
        let action = event.target.value;
        if (action === "Edit") {
            this.openEditChildOptions(event, modifireId, row);
        }
        if (action === "Clone") {
            this.cloneChildOptions(event, modifireId, JSON.stringify(row));
        }
        if (action === "Delete") {
            this.openChildOptionsDelete(event, modifireId, row);
        }

        event.target.value = "";
    }
    prepareModifiersData(data) {
        let modiData = [];
        modiData = data.map(pData => {
            return {
                ...pData,
                // categoryName: 'Optional Items'
            }
        });
        return modiData;
    }
    async getmodifiers(categoryName) {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `modifier/get-child-option/${vendorId}/${categoryName}/${self.state.parentOptionsId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getmodifiers('linkTemplateChildOptions');
                    return;
                }
                let modifiersData = result.data.response;
                this.setState({
                    modifiersAllData: {
                        data: self.prepareModifiersData(modifiersData),
                        columnDefs: [{
                            targets: [0],
                            createdCell: (td, cellData, rowData) =>
                              ReactDOM.render(
                                <a href="/#" onClick={(event) => this.openEditChildOptions(event, rowData._id, rowData)}>{rowData.title}</a>, td)
                            },
                            {
                            targets: [2],
                            className: 'width-10per',
                            createdCell: (td, cellData, rowData) =>
                                ReactDOM.render(
                                    // <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData._id, rowData)}>
                                            <option value="">Select</option>
                                            <option value="Edit">Edit</option>
                                            <option value="Clone">Clone</option>
                                            <option value="Delete">Delete</option>
                                        </select>
                                    // </div>
                                    , td)
                        }
                        ]
                    }
                });
                this.setState({ reloadTable: true });
            }).catch(error => {
                this.setState({ modifiersAllData: { data: [] } })
                console.error("Problem in fetching modifiers data: ", error);
            })
        } catch (error) {
            this.setState({ modifiersAllData: { data: [] } })
            console.error("Problem in fetching modifiers data: ", error);
        }
    }
    openAddChildOptions(e) {
        e.preventDefault();
        let self = this;
        self.props.history.push(`/menu_management/modifiers/child-options/add`, {
            parentOptionsId: this.state.parentOptionsId,
            parentOptionsData: this.state.parentOptionsData
        });
    }

    render() {
        const {
            modifiersAllData,
            columns,
            reloadTable
        } = { ...this.state }
        return (
            <div className="child-options-modifier">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Child Options Details</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=linktemplates">Parent Options</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Child Options Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="button-add-modifier">
                            {/* <h4 className="float-left">Optional Items Section</h4> */}
                            <button onClick={this.openAddChildOptions} className="btn btn-primary has-icon btn-sm float-right mt-0 mb-4">
                                {/* eslint-disable-next-line */}
                                <i className="fa fa-plus fa-xs"></i> Add Child Options
                            </button>
                        </div>
                        <div className="table-responsive">
                            {modifiersAllData && modifiersAllData.data !== null &&
                                <DataTablesComp data={modifiersAllData} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="asc" id="childOptionTable" />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ChildOptionsDetails;