import React from 'react';
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import DataTablesComp from '../../DataTables';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';

class DriverIndex extends React.Component {
    constructor(props) {
        super(props);
        const columns = [
            { title: 'Driver Name', data: 'driverName' },
            { title: 'Phone Number', data: 'phone' },
            { title: 'Email', data: 'email' },
            { title: 'License Number', data: 'licenseNumber' },
            { title: 'Home Address', data: 'address' },
            { title: 'Enable', data: 'displayDriver' },
            { title: 'Action', data: 'licenseNumber', orderable: false },
        ];
        this.state = {
            driver: {
                data: null
            },
            columns: columns,
            reloadTable: false
        }
        this.openAddDriverPage = this.openAddDriverPage.bind(this);
        this.openEditDriverPage = this.openEditDriverPage.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
    }

    componentDidMount() {
        this.getDriverList();
    }

    onActionChange(event, driverId, row) {
        let action = event.target.value;
        if (action === "Edit") {
            this.openEditDriverPage(event, driverId, row);
        }
        if (action === "enable") {
            this.changeDriverEnableDisable(action, driverId, row);
        }
        if (action === "disable") {
            this.changeDriverEnableDisable(action, driverId, row);
        }
        if (action === "Delete") {
            this.confirmAndDelete(event, driverId, row);
        }

        event.target.value = "";
    }

    async changeDriverEnableDisable(action, driverId, row) {
        let editObj = {
            driverId: driverId
        };
        if (action === 'enable') {
            editObj.isDisable = false;
        } else {
            editObj.isDisable = true;
        }

        let url = `delivery/driverdisableorenable`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, editObj)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.changeDriverEnableDisable(action, driverId, row);
                        return;
                    }
                    if (result.data.status) {
                        this.getDriverList();
                        toast.success(`Driver ${action} successfully.`, {
                            autoClose: 3000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error(`Problem in driver ${action} details: `, error);
                })
        }
        catch (error) {
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    confirmAndDelete(e, driverId, row) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this driver.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let requestData = {
                    id: driverId,
                    vendorID: row.vendorID
                }
                let url = `delivery/removedriver`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the driver. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                self.getDriverList();
                                toast.success("Success: driver deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the driver. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting driver: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the driver. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting driver: ", error);
                }
            }
        });
    }

    openAddDriverPage(event) {
        event.preventDefault();
        this.props.history.push(`/delivery_management/driver/add`);
    }

    openEditDriverPage(event, driverId, row) {
        event.preventDefault();
        this.props.history.push(`/delivery_management/driver/${driverId}`, { driverData: row });
    }

    prepareDriverData(data) {
        let driverData = [];
        driverData = data.map(dData => {
            return {
                ...dData,
                displayDriver: (dData.isDisable === undefined || dData.isDisable === false) ? 'Yes': 'No'
            }
        });
        return driverData;
    }

    async getDriverList() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `delivery/getdriverlist/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getDriverList();
                    return;
                }

                let driverData = result.data.response;
                this.setState({
                    driver: {
                        data: self.prepareDriverData(driverData),
                        columnDefs: [{
                            targets: [6],
                            createdCell: (td, cellData, rowData) =>
                                ReactDOM.render(
                                    <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.id, rowData)}>
                                            <option value="">Select</option>
                                            {(rowData.isDisable === undefined || rowData.isDisable === false) &&
                                                <option value="Edit">Edit</option>
                                            }
                                            {rowData.isDisable === true &&
                                                <option value="enable">Enable</option>
                                            }
                                            {(rowData.isDisable === undefined || rowData.isDisable === false) &&
                                                <option value="disable">Disable</option>
                                            }
                                            {/* <option value="Delete">Delete</option> */}
                                        </select>
                                    </div>
                                    , td)
                        }
                        ]
                    }
                });
                this.setState({ reloadTable: true });
            }).catch(error => {
                this.setState({ driver: { data: [] } })
                console.error("Problem in fetching driver list data: ", error);
            })
        } catch (error) {
            this.setState({ driver: { data: [] } })
            console.error("Problem in fetching driver list data: ", error);
        }
    }

    render() {
        const { driver, reloadTable, columns } = { ...this.state }
        return (
            <div className="row">
                <div className="col-md-12">
                    {/* <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Delivery</span>
                        <ol className="breadcrumb pl-0 pt-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Delivery</li>
                        </ol>
                    </nav> */}
                    <div className="tab-content">
                        <div role="tabpanel"
                            className="tab-pane active fade in show" id="tab1">
                            <button onClick={(event) => { this.openAddDriverPage(event) }} className="btn btn-primary has-icon btn_add_driver btn-sm">
                                {/* eslint-disable-next-line */}
                                <i className="fa fa-plus fa-xs"></i> Add a Driver
                            </button>
                            {(!driver || driver.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {driver && driver.data !== null &&
                                    <DataTablesComp data={driver} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="asc" id="driverTable" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DriverIndex);