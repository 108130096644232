import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
// const { v1: uuidv1 } = require('uuid');
class AddChildOptions extends React.Component {
    constructor(props) {
        super(props);
        let parentOptionsId = this.props.location.state && this.props.location.state.parentOptionsId ? this.props.location.state.parentOptionsId : '';
        let parentOptionsData = this.props.location.state && this.props.location.state.parentOptionsData ? this.props.location.state.parentOptionsData : null;
        this.state = {
            parentOptionsId: parentOptionsId,
            parentOptionsData: parentOptionsData,
            addChildOptionsData: {
                title: '',
                templateId: parentOptionsId,
                topping: true,
                linkId: '',
                description: '',
                options: [{
                    item: "",
                    include: false,
                    price: 0,
                    fullPrice: 0,
                    halfPrice: 0,
                    leftPrice: 0,
                    rightPrice: 0,
                    description: ""
                }],
            },
            addChildOptionsErrorData: {
                title: '',
                linkId: '',
                options: [{
                    item: "",
                    price: "",
                    fullPrice: "",
                    halfPrice: "",
                }]
            },
            helpText: {
                title: 'Give a descriptive title.',
                description: 'Optionally link to where it should belong.',
                options: 'You can add as many items as with the price. Make one item default which is selected automatically and the customer will be able to select any other option. Example: Default pizza size may be small but a customer can select either medium, large or x-large.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {

    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addChildOptionsData;
        let formErrors = this.state.addChildOptionsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "linkId" && value === "") || (formSubmit === true && formDetails["linkId"] === "")) {
            fieldName = 'linkId';
            formErrors[fieldName] = `The 'Parent Option' is a required field.`;
            isError = true;
        }

        if (!formDetails.topping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }

            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].price === "") {
                        fieldName = 'options';
                        formErrors.options[i].price = `The 'Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].price) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].price ="";
                        isError = true;
                    }
                }
            }
        }
        if (formDetails.topping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'fullPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    } else if (newField === 'halfPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }

            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].fullPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].fullPrice = `The 'Full Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].fullPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].fullPrice ="";
                        isError = true;
                    }
                    
                    if (formDetails.options[i].halfPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].halfPrice = `The 'Half Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].halfPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].halfPrice ="";
                        isError = true;
                    }
                }
            }
        }

        
        this.setState({ addChildOptionsErrorData: formErrors });
        this.setState({ addChildOptionsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addChildOptionsData;
        let formErrors = this.state.addChildOptionsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addChildOptionsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addChildOptionsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addChildOptionsData;
        let formDetailsError = this.state.addChildOptionsErrorData;
        formDetails[formField].push({
            include: false,
            item: "",
            price: 0,
            fullPrice: 0,
            halfPrice: 0,
            leftPrice: 0,
            rightPrice: 0,
            description: ""
        });
        formDetailsError[formField].push({
            item: "",
            price: "",
            fullPrice: "",
            halfPrice: "",
        });
        this.setState({ addChildOptionsData: formDetails, addChildOptionsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addChildOptionsData;
        let formDetailsError = this.state.addChildOptionsErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ addChildOptionsData: formDetails, addChildOptionsErrorData: formDetailsError })
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const addChildOptionsData = this.state.addChildOptionsData;
            const addObj = {
                modifiresObject: {
                    topping: addChildOptionsData.topping,
                    templateId: addChildOptionsData.templateId,
                    title: addChildOptionsData.title,
                    linkId: addChildOptionsData.linkId,
                    description: addChildOptionsData?.description ? addChildOptionsData.description : "",
                },
                topping: addChildOptionsData.topping,
                templateId: addChildOptionsData.templateId,
                linkId: addChildOptionsData.linkId,
                title: addChildOptionsData.title,
                description: addChildOptionsData?.description ? addChildOptionsData.description : "",
                categoryName: "linkTemplateChildOptions",
                vendorId: vendorId
            };
            for (let i = 0; i < addChildOptionsData.options.length; i += 1) {
                if (!addChildOptionsData.topping) {
                    addChildOptionsData.options[i].price = Number(Number(addChildOptionsData.options[i].price).toFixed(2));
                    delete addChildOptionsData.options[i].fullPrice;
                    delete addChildOptionsData.options[i].halfPrice;
                    delete addChildOptionsData.options[i].leftPrice;
                    delete addChildOptionsData.options[i].rightPrice;
                }
                if (addChildOptionsData.topping) {
                    addChildOptionsData.options[i].fullPrice = Number(Number(addChildOptionsData.options[i].fullPrice).toFixed(2));
                    addChildOptionsData.options[i].leftPrice = Number(Number(addChildOptionsData.options[i].halfPrice).toFixed(2));
                    addChildOptionsData.options[i].rightPrice = Number(Number(addChildOptionsData.options[i].halfPrice).toFixed(2));
                    // delete addChildOptionsData.options[i].halfPrice;
                    delete addChildOptionsData.options[i].price;
                }
            }
            addObj.modifiresObject.options = addChildOptionsData.options;
            if (vendorId) {
                let url = `modifier/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                self.props.history.push(`/menu_management/modifiers/child-options-details/${self.state.parentOptionsId}`, {
                                    parentOptionsData: self.state.parentOptionsData
                                });
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }
    moveChildOptions(e, modifireId, row) {
        e.preventDefault();
        let self = this;
        self.props.history.push(`/menu_management/modifiers/child-options-details/${modifireId}`, {
            parentOptionsData: row,
        });
    }
    render() {
        const {
            addChildOptionsData,
            addChildOptionsErrorData,
            parentOptionsId,
            parentOptionsData,
            submitProgress,
            helpText
        } = { ...this.state };
        return (
            <div className="parent-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add Child Options</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                {/* eslint-disable-next-line */}
                                <li className="breadcrumb-item"><a className="primary-link" onClick={(e) => this.moveChildOptions(e, parentOptionsId, parentOptionsData)}>Child Options</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Child Options</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <label htmlFor="name">Pizza Topping</label>
                            <div className="input-group mb-0">
                                <ul className="ms-list d-flex">
                                    <li className="ms-list-item pl-0">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio"
                                                name="toppingRadio"
                                                value={true}
                                                checked={addChildOptionsData.topping === true}
                                                onChange={(event) => {
                                                    let tempAddChildOptionsData = this.state.addChildOptionsData;
                                                    tempAddChildOptionsData.topping = event.currentTarget.value === "true" ? true : false;
                                                    this.setState({ addChildOptionsData: tempAddChildOptionsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Yes </span>
                                    </li>
                                    <li className="ms-list-item">
                                        <label className="ms-checkbox-wrap">
                                            <input
                                                type="radio"
                                                name="toppingRadio"
                                                value={false}
                                                checked={addChildOptionsData.topping === false}
                                                onChange={(event) => {
                                                    let tempAddChildOptionsData = this.state.addChildOptionsData;
                                                    tempAddChildOptionsData.topping = event.currentTarget.value === "false" ? false : true;
                                                    this.setState({ addChildOptionsData: tempAddChildOptionsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> No </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <label htmlFor="linkId">Parent Option</label>
                            <div className="input-group">
                                <select
                                    className={"form-control " + (addChildOptionsErrorData.linkId !== "" ? 'invalid' : '')}
                                    name="linkId"
                                    id="linkId"
                                    onChange={this.onBasicDetailsChange}
                                    // onChange={(event) => {
                                    //     let tempAddChildOptionsData = this.state.addChildOptionsData;
                                    //     tempAddChildOptionsData.linkId = event.target.value;
                                    //     this.setState({ addChildOptionsData: tempAddChildOptionsData })
                                    // }}
                                    value={addChildOptionsData.linkId}>
                                    <option value="">Select Parent Option</option>
                                    {parentOptionsData?.modifiresObject && parentOptionsData?.modifiresObject?.options && parentOptionsData?.modifiresObject?.options.length && parentOptionsData.modifiresObject.options.map((oRow, index) => (
                                        <option key={index} value={oRow.id}>{oRow.item}</option>
                                    ))}
                                </select>
                                {addChildOptionsErrorData.linkId && addChildOptionsErrorData.linkId !== "" &&
                                    <div className="invalid-feedback">{addChildOptionsErrorData.linkId}</div>}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <label htmlFor="title">Title</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                            <div className="input-group">
                                <input type="text"
                                    className={"form-control " + (addChildOptionsErrorData.title !== "" ? 'invalid' : '')}
                                    id="title"
                                    name="title"
                                    maxLength="40"
                                    placeholder="Title"
                                    value={addChildOptionsData.title}
                                    onChange={this.onBasicDetailsChange}
                                    />
                                {addChildOptionsErrorData.title !== "" &&
                                    <div className="invalid-feedback">
                                        {addChildOptionsErrorData.title}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <label htmlFor="description">Description</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    maxLength="120"
                                    placeholder="Description"
                                    value={addChildOptionsData.description}
                                    onChange={this.onBasicDetailsChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-12 col-lg-12">
                            <label>Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="addition-option-radio">
                                        <label className="ms-checkbox-wrap"></label>
                                        <div className="input-group ml-3 mb-0">
                                            <label htmlFor="parentOptionsItem">Item</label>
                                        </div>
                                        <div className="input-group ml-3 mb-0">
                                            <label htmlFor="parentOptionsDesc">Description</label>
                                        </div>
                                        {!addChildOptionsData.topping &&
                                            <div className="input-group ml-3 mb-0">
                                                <label htmlFor="parentOptionsPrice">Price</label>
                                            </div>
                                        }
                                        {addChildOptionsData.topping &&
                                            <div className="input-group ml-3 mb-0">
                                                <label htmlFor="parentOptionsFullPrice">Full Price</label>
                                            </div>
                                        }
                                        
                                        {addChildOptionsData.topping &&
                                            <div className="input-group ml-3 mb-0">
                                                <label htmlFor="parentOptionsHalfPrice">Half Price</label>
                                            </div>
                                        }
                                        {/* {addChildOptionsData.topping &&
                                            <div className="input-group ml-3 mb-0">
                                                <label htmlFor="parentOptionsLeftPrice">Left Price</label>
                                            </div>
                                        }
                                        {addChildOptionsData.topping &&
                                            <div className="input-group ml-3 mb-0">
                                                <label htmlFor="parentOptionsRightPrice">Right Price</label>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {addChildOptionsData.options && addChildOptionsData.options.length > 0 &&
                                addChildOptionsData.options.map((optionsRow, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-md-12">
                                            <div className="addition-option-radio mb-15">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="checkbox" 
                                                    checked = {optionsRow.include ? true : false}
                                                    onChange={(e) => {
                                                        let addChildOptionsData = this.state.addChildOptionsData;
                                                        addChildOptionsData.options[index].include = e.target.checked;
                                                        this.setState({ addChildOptionsData: addChildOptionsData })
                                                    }}/>
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <div className="input-group ml-3 mb-0">
                                                    <input type="text"
                                                        className={"form-control " + (addChildOptionsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                        id="parentOptionsItem"
                                                        name="parentOptionsItem"
                                                        maxLength="40"
                                                        placeholder="Item"
                                                        value={optionsRow.item}
                                                        onChange={(e) => {
                                                            let tempData = addChildOptionsData;
                                                            let tempErrorData = addChildOptionsErrorData;
                                                            tempData.options[index].item = e.target.value;
                                                            this.setState({ addChildOptionsData: tempData })
                                                            let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                            if (!isError) {
                                                            tempErrorData.options[index].item = "";
                                                            this.setState({ addChildOptionsErrorData: tempErrorData });
                                                            }
                                                        }}
                                                    />
                                                    {addChildOptionsErrorData.options[index].item !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addChildOptionsErrorData.options[index].item}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="input-group ml-3 mb-0">
                                                    <input type="text"
                                                        className="form-control"
                                                        id="parentOptionsDesc"
                                                        name="parentOptionsDesc"
                                                        maxLength="120"
                                                        placeholder="Description"
                                                        value={optionsRow.description}
                                                        onChange={(e) => {
                                                            let tempData = addChildOptionsData;
                                                            tempData.options[index].description = e.target.value;
                                                            this.setState({ addChildOptionsData: tempData })
                                                        }}
                                                    />
                                                </div>
                                                {!addChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <input type="number"
                                                            className={"form-control " + (addChildOptionsErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                            id="parentOptionsPrice"
                                                            name="parentOptionsPrice"
                                                            placeholder="Price"
                                                            value={optionsRow.price}
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            onChange={(e) => {
                                                                let tempData = addChildOptionsData;
                                                                let tempErrorData = addChildOptionsErrorData;
                                                                tempData.options[index].price = e.target.value;
                                                                this.setState({ addChildOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                if (!isError) {
                                                                tempErrorData.options[index].price = "";
                                                                this.setState({ addChildOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                        />
                                                        { addChildOptionsData.options.length === 1 && index === 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { addChildOptionsData.options.length === index+1 && index > 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { index < addChildOptionsData.options.length-1 &&
                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                        }
                                                        {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                            : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                        } */}
                                                        {addChildOptionsErrorData.options[index].price !== "" &&
                                                            <div className="invalid-feedback">
                                                                {addChildOptionsErrorData.options[index].price}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {addChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <input type="number"
                                                            className={"form-control " + (addChildOptionsErrorData.options[index].fullPrice !== "" ? 'invalid' : '')}
                                                            id="parentOptionsFullPrice"
                                                            name="parentOptionsFullPrice"
                                                            placeholder="Full Price"
                                                            value={optionsRow.fullPrice}
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            onChange={(e) => {
                                                                let tempData = addChildOptionsData;
                                                                let tempErrorData = addChildOptionsErrorData;
                                                                tempData.options[index].fullPrice = e.target.value;
                                                                this.setState({ addChildOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'fullPrice');
                                                                if (!isError) {
                                                                tempErrorData.options[index].fullPrice = "";
                                                                this.setState({ addChildOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                        />
                                                        {addChildOptionsErrorData.options[index].fullPrice !== "" &&
                                                            <div className="invalid-feedback">
                                                                {addChildOptionsErrorData.options[index].fullPrice}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {addChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <input type="number"
                                                            className={"form-control " + (addChildOptionsErrorData.options[index].halfPrice !== "" ? 'invalid' : '')}
                                                            id="parentOptionsHalfPrice"
                                                            name="parentOptionsHalfPrice"
                                                            placeholder="Half Price"
                                                            value={optionsRow.halfPrice}
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            onChange={(e) => {
                                                                let tempData = addChildOptionsData;
                                                                let tempErrorData = addChildOptionsErrorData;
                                                                tempData.options[index].halfPrice = e.target.value;
                                                                this.setState({ addChildOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'halfPrice');
                                                                if (!isError) {
                                                                tempErrorData.options[index].halfPrice = "";
                                                                this.setState({ addChildOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                        />
                                                        { addChildOptionsData.options.length === 1 && index === 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { addChildOptionsData.options.length === index+1 && index > 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { index < addChildOptionsData.options.length-1 &&
                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                        }
                                                        {addChildOptionsErrorData.options[index].halfPrice !== "" &&
                                                            <div className="invalid-feedback">
                                                                {addChildOptionsErrorData.options[index].halfPrice}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {/* {addChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <input type="number"
                                                            className={"form-control " + (addChildOptionsErrorData.options[index].leftPrice !== "" ? 'invalid' : '')}
                                                            id="parentOptionsLeftPrice"
                                                            name="parentOptionsLeftPrice"
                                                            placeholder="Left Price"
                                                            value={optionsRow.leftPrice}
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            onChange={(e) => {
                                                                let tempData = addChildOptionsData;
                                                                let tempErrorData = addChildOptionsErrorData;
                                                                tempData.options[index].leftPrice = e.target.value;
                                                                this.setState({ addChildOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'leftPrice');
                                                                if (!isError) {
                                                                tempErrorData.options[index].leftPrice = "";
                                                                this.setState({ addChildOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                        />
                                                        {addChildOptionsErrorData.options[index].leftPrice !== "" &&
                                                            <div className="invalid-feedback">
                                                                {addChildOptionsErrorData.options[index].leftPrice}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {addChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <input type="number"
                                                            className={"form-control " + (addChildOptionsErrorData.options[index].rightPrice !== "" ? 'invalid' : '')}
                                                            id="parentOptionsRightPrice"
                                                            name="parentOptionsRightPrice"
                                                            placeholder="Right Price"
                                                            value={optionsRow.rightPrice}
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            onChange={(e) => {
                                                                let tempData = addChildOptionsData;
                                                                let tempErrorData = addChildOptionsErrorData;
                                                                tempData.options[index].rightPrice = e.target.value;
                                                                this.setState({ addChildOptionsData: tempData })
                                                                let isError = this.handleValidation("options", e.target.value, index, 'rightPrice');
                                                                if (!isError) {
                                                                tempErrorData.options[index].rightPrice = "";
                                                                this.setState({ addChildOptionsErrorData: tempErrorData });
                                                                }
                                                            }}
                                                        />
                                                        { addChildOptionsData.options.length === 1 && index === 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { addChildOptionsData.options.length === index+1 && index > 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { index < addChildOptionsData.options.length-1 &&
                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                        }
                                                        {addChildOptionsErrorData.options[index].rightPrice !== "" &&
                                                            <div className="invalid-feedback">
                                                                {addChildOptionsErrorData.options[index].rightPrice}
                                                            </div>
                                                        }
                                                    </div>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                            Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddChildOptions;