import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner from '../../spinner';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class EditAdditionOptions extends React.Component {
    constructor(props) {
        super(props);
        let additionOptionsWithPriceId = this.props.match.params.additionOptionsWithPriceId;
        let errorOptions = [];
        let additionOptionWithPriceData = this.props.location.state && this.props.location.state.additionOptionWithPriceData ? this.props.location.state.additionOptionWithPriceData : null;
        if (additionOptionWithPriceData && additionOptionWithPriceData.modifiresObject && additionOptionWithPriceData.modifiresObject.options && additionOptionWithPriceData.modifiresObject.options.length) {
            for (let i = 0; i < additionOptionWithPriceData.modifiresObject.options.length; i += 1) {
                errorOptions.push({
                    item: "",
                    price: ""
                });
            }
        }

        if (!additionOptionWithPriceData) {
            this.getSingleModifier(additionOptionsWithPriceId);
        }
        this.state = {
            isLoadPage: true,
            additionOptionsWithPriceId: additionOptionsWithPriceId,
            productsData: [],
            editAdditionOptionsWithPriceData: {
                title: additionOptionWithPriceData && additionOptionWithPriceData.title ? additionOptionWithPriceData.title : "",
                description: additionOptionWithPriceData && additionOptionWithPriceData.description ? additionOptionWithPriceData.description : "",
                options: additionOptionWithPriceData && additionOptionWithPriceData.modifiresObject && additionOptionWithPriceData.modifiresObject.options ? additionOptionWithPriceData.modifiresObject.options : [],
            },
            editAdditionOptionsWithPriceErrorData: {
                title: '',
                options: errorOptions
            },
            helpText: {
                title: 'Give a descriptive title.',
                description: 'Optionally link to where it should belong.',
                options: 'You can add as many items as with the price. Make one item default which is selected automatically and the customer will be able to select any other option. Example: Default pizza size may be small but a customer can select either medium, large or x-large.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    
    componentDidMount() {
        this.getProductsList();
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    if (!(result.data && result.data.status)) {
                        this.setState({ productsData: [] });
                        this.setState({ selectedFoodItems: [], firstTimeFoodItems: JSON.stringify([]) });
                        this.setState({isLoadPage: false});
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    if (products[i].addition_options_with_price && products[i].addition_options_with_price.length) {
                                        for (let iii = 0; iii < products[i].addition_options_with_price.length; iii += 1) {
                                            if (products[i].addition_options_with_price[iii].id === this.state.additionOptionsWithPriceId) {
                                                tempSelectedFoodItems[ii].product.push({
                                                    id: products[i].id,
                                                    name: products[i].name
                                                });
                                            }
                                        }
                                    }
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name
                                    });
                                }
                            }
                        }
                        for (let i = 0; i < manageProductData.length; i += 1) {
                            if (manageProductData[i].product && manageProductData[i].product.length) {
                                if (tempSelectedFoodItems[i].product && tempSelectedFoodItems[i].product.length === manageProductData[i].product.length) {
                                    tempSelectedFoodItems[i].allProductSelected = true;
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems, firstTimeFoodItems: JSON.stringify(tempSelectedFoodItems) });
                        this.setState({isLoadPage: false});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching vendor product: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching vendor product: ", error);
        }
    }

    async getSingleModifier(additionOptionsWithPriceId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifier/getsingle/${vendorId}/${additionOptionsWithPriceId}`;
        if (vendorId && additionOptionsWithPriceId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifier(additionOptionsWithPriceId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.modifiresObject && data.modifiresObject.options && data.modifiresObject.options.length) {
                                for (let i = 0; i < data.modifiresObject.options.length; i += 1) {
                                    errorOptions.push({
                                        item: "",
                                        price: ""
                                    });
                                }
                            }
                            let tempFormData = self.state.editAdditionOptionsWithPriceData;
                            let tempFormErrorData = self.state.editAdditionOptionsWithPriceErrorData;

                            tempFormData.title = data && data.title ? data.title : "";
                            tempFormData.description =  data && data.description ? data.description : "";
                            tempFormData.options =  data && data.modifiresObject && data.modifiresObject.options ? data.modifiresObject.options : [];
                            self.setState({ editAdditionOptionsWithPriceData: tempFormData });
                            
                            tempFormErrorData.title = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editAdditionOptionsWithPriceErrorData: tempFormErrorData });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editAdditionOptionsWithPriceData;
        let formErrors = this.state.editAdditionOptionsWithPriceErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }
        
        // if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
        //     fieldName = 'options';
        //     if (index !== undefined) {       
        //         if (formDetails['options'][index][newField] === "") {
        //             formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
        //             isError = true;
        //         }
        //     }
        // }

        if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
            fieldName = 'options';
            if (index !== undefined) {       
                if (formDetails['options'][index][newField] === "") {
                    formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                    // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                    formDetails['options'][index][newField] = "";
                    isError = true;
                }
            }
        }
        if (fieldName === 'options' && index === undefined) {
            for (let i = 0; i < formDetails.options.length; i += 1) {
                if (formDetails.options[i].item === "") {
                    fieldName = 'options';
                    formErrors.options[i].item = `The 'Item' is a required field.`;
                    isError = true;
                }
                if (formDetails.options[i].price === "") {
                    fieldName = 'options';
                    formErrors.options[i].price = `The 'price' is a required field.`;
                    isError = true;
                } else if (Number(formDetails.options[i].price) < 0) {
                    fieldName = 'options';
                    formDetails.options[i].price ="";
                    isError = true;
                }
            }
        }
        
        this.setState({ editAdditionOptionsWithPriceErrorData: formErrors });
        this.setState({ editAdditionOptionsWithPriceData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.editAdditionOptionsWithPriceData;
        let formErrors = this.state.editAdditionOptionsWithPriceErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editAdditionOptionsWithPriceData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editAdditionOptionsWithPriceErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editAdditionOptionsWithPriceData;
        let formDetailsError = this.state.editAdditionOptionsWithPriceErrorData;
        formDetails[formField].push({
            isSelected: false,
            item: "",
            price: 0,
            description: ""
        });
        formDetailsError[formField].push({
            item: "",
            price: ""
        });
        this.setState({ editAdditionOptionsWithPriceData: formDetails, editAdditionOptionsWithPriceErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editAdditionOptionsWithPriceData;
        let formDetailsError = this.state.editAdditionOptionsWithPriceErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ editAdditionOptionsWithPriceData: formDetails, editAdditionOptionsWithPriceErrorData: formDetailsError })
    }

    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const editAdditionOptionsWithPriceData = this.state.editAdditionOptionsWithPriceData;

            const editObj = {
                id: self.state.additionOptionsWithPriceId,
                selectedFoodItems: self.state.selectedFoodItems,
                modifiresObject: {
                    title: editAdditionOptionsWithPriceData.title,
                    description: editAdditionOptionsWithPriceData?.description ? editAdditionOptionsWithPriceData.description : "",
                },
                title: editAdditionOptionsWithPriceData.title,
                description: editAdditionOptionsWithPriceData?.description ? editAdditionOptionsWithPriceData.description : "",
                categoryName: "additionOptionsWithPrice",
                vendorId: vendorId
            };
            for (let i = 0; i < editAdditionOptionsWithPriceData.options.length; i += 1) {
                editAdditionOptionsWithPriceData.options[i].price = Number(Number(editAdditionOptionsWithPriceData.options[i].price).toFixed(2));
            }
            editObj.modifiresObject.options = editAdditionOptionsWithPriceData.options;
            let selectedArray = [];
            let sortingArray = [];
            for (let i = 0; i < editObj.modifiresObject.options.length; i += 1) {
                if (editObj.modifiresObject.options[i].isSelected) {
                    selectedArray.unshift(editObj.modifiresObject.options[i])
                } else {
                    sortingArray.push(editObj.modifiresObject.options[i]);
                }
            }
            // eslint-disable-next-line
            sortingArray.sort((a, b) => a.item > b.item && 1 || -1);
            editObj.modifiresObject.options = selectedArray.concat(sortingArray);
            if (vendorId) {
                let url = `modifier/additionoptionswithprice/edit`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // this.getmodifiers();
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=oneitemselectionwithprice`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            editAdditionOptionsWithPriceData,
            editAdditionOptionsWithPriceErrorData,
            submitProgress,
            productsData,
            selectedFoodItems,
            isLoadPage,
            helpText
        } = { ...this.state };
        return (
            <div className="addition-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Edit One Item With Price</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=oneitemselectionwithprice">One Item With Price</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit One Item With Price</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {isLoadPage && 
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            {isLoadPage && <Spinner />}
                        </div>
                    </div>
                }
                {!isLoadPage && 
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6 col-lg-6">
                            <form className="needs-validation clearfix" noValidate>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="title">Title</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                                        <div className="input-group">
                                            <input type="text"
                                                className={"form-control " + (editAdditionOptionsWithPriceErrorData.title !== "" ? 'invalid' : '')}
                                                id="title"
                                                name="title"
                                                maxLength="40"
                                                placeholder="Title"
                                                value={editAdditionOptionsWithPriceData.title}
                                                onChange={this.onBasicDetailsChange}
                                                />
                                            {editAdditionOptionsWithPriceErrorData.title !== "" &&
                                                <div className="invalid-feedback">
                                                    {editAdditionOptionsWithPriceErrorData.title}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="description">Description</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                                        <div className="input-group">
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                maxLength="120"
                                                placeholder="Description"
                                                value={editAdditionOptionsWithPriceData.description}
                                                onChange={this.onBasicDetailsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <ReactTooltip />
                                        <label>Options</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="addition-option-radio">
                                                    <label className="ms-checkbox-wrap"></label>
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="additionWithItem">Item</label>
                                                    </div>
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="additionWithDesc">Description</label>
                                                    </div>
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="additionWithPrice">Price</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {editAdditionOptionsWithPriceData.options && editAdditionOptionsWithPriceData.options.length > 0 &&
                                            editAdditionOptionsWithPriceData.options.map((optionsRow, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-md-12">
                                                        <div className="addition-option-radio mb-15">
                                                            <label className="ms-checkbox-wrap mb-8">
                                                                <input
                                                                    type="radio"
                                                                    name="optionsRadioExample"
                                                                    value={optionsRow.isSelected}
                                                                    checked={optionsRow.isSelected === true}
                                                                    onClick={(e) => {
                                                                        if (e.currentTarget.value === 'true') {
                                                                            let editAdditionOptionsWithPriceData = this.state.editAdditionOptionsWithPriceData;
                                                                            editAdditionOptionsWithPriceData.options[index].isSelected = false;
                                                                            this.setState({ editAdditionOptionsWithPriceData: editAdditionOptionsWithPriceData })
                                                                        }
                                                                    }}
                                                                    onChange={(event) => {
                                                                        let editAdditionOptionsWithPriceData = this.state.editAdditionOptionsWithPriceData;
                                                                        for (let i = 0; i < editAdditionOptionsWithPriceData.options.length; i += 1) {
                                                                            if (index === i) {
                                                                                editAdditionOptionsWithPriceData.options[i].isSelected = true;
                                                                            } else {
                                                                                editAdditionOptionsWithPriceData.options[i].isSelected = false;
                                                                            }
                                                                        }
                                                                        this.setState({ editAdditionOptionsWithPriceData: editAdditionOptionsWithPriceData })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <div className="input-group ml-3 mb-0">
                                                                {/* <label htmlFor="additionWithItem">Item</label> */}
                                                                <input type="text"
                                                                    className={"form-control " + (editAdditionOptionsWithPriceErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                                    id="additionWithItem"
                                                                    name="additionWithItem"
                                                                    maxLength="40"
                                                                    placeholder="Item"
                                                                    value={optionsRow.item}
                                                                    // onChange={this.onBasicDetailsChange}
                                                                    onChange={(e) => {
                                                                        let tempData = editAdditionOptionsWithPriceData;
                                                                        let tempErrorData = editAdditionOptionsWithPriceErrorData;
                                                                        tempData.options[index].item = e.target.value;
                                                                        this.setState({ editAdditionOptionsWithPriceData: tempData })
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                                        if (!isError) {
                                                                        tempErrorData.options[index].item = "";
                                                                        this.setState({ editAdditionOptionsWithPriceErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                />
                                                                {editAdditionOptionsWithPriceErrorData.options[index].item !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editAdditionOptionsWithPriceErrorData.options[index].item}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="input-group ml-3 mb-0">
                                                                {/* <label htmlFor="additionWithDesc">Description</label> */}
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id="additionWithDesc"
                                                                    name="additionWithDesc"
                                                                    maxLength="120"
                                                                    placeholder="Description"
                                                                    value={optionsRow.description}
                                                                    // onChange={this.onBasicDetailsChange}
                                                                    onChange={(e) => {
                                                                        let tempData = editAdditionOptionsWithPriceData;
                                                                        tempData.options[index].description = e.target.value;
                                                                        this.setState({ editAdditionOptionsWithPriceData: tempData })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="input-group ml-3 mb-0">
                                                                {/* <label htmlFor="additionWithPrice">Price</label> */}
                                                                <input type="number"
                                                                    className={"form-control " + (editAdditionOptionsWithPriceErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                                    id="additionWithPrice"
                                                                    name="additionWithPrice"
                                                                    placeholder="Price"
                                                                    value={optionsRow.price}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                    // onChange={this.onBasicDetailsChange}
                                                                    onChange={(e) => {
                                                                        let tempData = editAdditionOptionsWithPriceData;
                                                                        let tempErrorData = editAdditionOptionsWithPriceErrorData;
                                                                        tempData.options[index].price = e.target.value;
                                                                        this.setState({ editAdditionOptionsWithPriceData: tempData })
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                        if (!isError) {
                                                                        tempErrorData.options[index].price = "";
                                                                        this.setState({ editAdditionOptionsWithPriceErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                />
                                                                { editAdditionOptionsWithPriceData.options.length === 1 && index === 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { editAdditionOptionsWithPriceData.options.length === index+1 && index > 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { index < editAdditionOptionsWithPriceData.options.length-1 &&
                                                                    <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                }
                                                                {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                    : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                } */}
                                                                {editAdditionOptionsWithPriceErrorData.options[index].price !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editAdditionOptionsWithPriceErrorData.options[index].price}
                                                                    </div>
                                                                }
                                                            </div>                        
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>    
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Select food items assign to this modifier</label>
                                    <div id="accordion">
                                        {productsData && productsData.length === 0 &&
                                            <h5 className="text-center">No Items Found.</h5>
                                        }
                                        {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                            productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                <div className="card-header">
                                                    <span className="ml-1">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                    </span>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                        <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                    </a>
                                                </div>

                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {productData && productData.product && productData.product.map((item) => (
                                                            <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                Update {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default EditAdditionOptions;