import React from 'react';
import { auth } from "../../../firebase";
import logo from '../../../assets/img/logo2.png';

class SetNewPassword extends React.Component {

    constructor(props) {
        super(props);
        const INITIAL_STATE = {
            passwordOne: '',
            passwordTwo: '',
            errorOne: null,
            errorTwo: null
        };
        this.state = { ...INITIAL_STATE };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === "passwordTwo") {
            this.setState({ errorTwo: null });
        } else {
            this.setState({ errorOne: null });
        }

    }

    onFocusOut(event) {
        let passwordOne = this.state.passwordOne;
        if (event.target.name === "passwordTwo") {
            if (passwordOne !== event.target.value) {
                this.setState({ errorTwo: "Passwords do not match. Please re-enter again." });
            } else {
                this.setState({ errorTwo: null });
            }
        } else if (event.target.name === "passwordOne") {
            let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
            if (!passwordRegex.test(passwordOne)) {
                this.setState({ errorOne: "Password should contain atleast one number and one special character such as !@#$%^&* to protect your login." });
            } else {
                this.setState({ errorOne: null });
            }
        }
    }

    validateData() {
        let passwordOne = this.state.passwordOne;
        let passwordTwo = this.state.passwordTwo;
        let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        let error = false;
        if (passwordOne !== passwordTwo) {
            this.setState({ errorTwo: "Passwords do not match. Please re-enter again." });
            error = true;
        }
        if (!passwordRegex.test(passwordOne)) {
            this.setState({ errorOne: "Password should contain atleast one number and one special character such as !@#$%^&* to protect your login." });
            error = true;
        }
        return error;
    }

    onSubmit(event) {
        event.preventDefault();
        let isError = this.validateData();
        if (!isError) {
            const { passwordOne } = this.state;
            auth.currentUser.updatePassword(passwordOne);
            this.props.history.push("/set_vendor");
        }
    }

    render() {
        const { passwordOne, passwordTwo, errorOne, errorTwo } = { ...this.state };
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';
        return (
            <div className="ms-auth-container custom-auth-container">
                <div className="ms-auth-col custom-auth-col-4">
                    <div className="ms-auth-bg"></div>
                </div>
                <div className="ms-auth-col custom-auth-col-8">
                    <div className="ms-auth-form page-form">

                        <form className="frm-set-password" onSubmit={this.onSubmit}>
                            <img src={logo} alt="sVang" className="mb-4" />
                            <h4>Set New Password</h4>
                            <p>Please enter your new password to continue</p>
                            <div className="mb-3">
                                <label htmlFor="validationCustom09">New Password</label>
                                <div className="input-group">
                                    <input
                                        name="passwordOne"
                                        value={passwordOne}
                                        onChange={this.onChange}
                                        onBlur={this.onFocusOut}
                                        type="password"
                                        className={"form-control " + (errorOne === null ? '' : 'invalid')}
                                        placeholder="New Password"
                                    />
                                    <div className="invalid-feedback">
                                        {errorOne}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="validationCustom09">Confirm Password</label>
                                <div className="input-group">
                                    <input
                                        name="passwordTwo"
                                        value={passwordTwo}
                                        onChange={this.onChange}
                                        onBlur={this.onFocusOut}
                                        type="password"
                                        className={"form-control " + (errorTwo === null ? '' : 'invalid')}
                                        placeholder="Confirm Password"
                                    />
                                    <div className="invalid-feedback">
                                        {errorTwo}
                                    </div>
                                </div>
                            </div>
                            <button disabled={isInvalid}
                                className="btn btn-primary mt-5 d-block w-100"
                                type="submit"
                            > Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetNewPassword;