import React from 'react';
// import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';

class AddPrintStation extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails,
            printVendorData: [],
            printStation: [],
            productsData: [],
            collapseOpen: false,
            selectedFoodItems: [],
            helpText: {
                printStationName: "Provide a meaningful print station name that corresponds to a section in the kitchen, for example, 'Vegetarian.' It must not be greater than 15 characters.",
                numCopies: "Specify the number of copies of the receipt you want to print. Specify two if you want to retain one for your record and send one to the customer.",
                cloudPrint: "If you have a cloud-enabled printer such as Start MC-30, the print can go directly to the printer from our cloud services. Contact sVang if you need this capability. It eliminates the need for you to confirm the order manually, and it prints automatically in the kitchen as soon as a customer orders through the mobile or web app.",
                printerVendor: "Select the supported print vendors fron the dropdown. If you do not see a printer of your choice, contact at support@svang.app.",
                deviceAddress: "The printer MAC address is required when you need cloud printing. If you have a Star printer, switch off the printer. Hold the feed button and power on. The self-test page will print, and you can find the MAC address of the printer.",
                hostName: "It is the IP address of the printer. If you have a Star printer, switch off the printer. Hold the feed button and power on. The self-test page will print, and you can find the IP address of the printer.",
                port: "It is the port number of the printer and normally it is 9100.",
                // dotwidth: "It is the number of characters per line that your printer can print. To see the proper format, count the number of characters that a printer is printing and then adjust this value. If in doubt, contact support@svang.app and specify the printer name and model.",
                printType: "Select one of the value from the dropdown.",
                otherTickets: "Usually, we print the food items assigned to a station (defined in the menu management) per ticket; however, if you need the other ticket details to print in each station's ticket, enable this field. Typically, it is helpful so that folks in each print station area order's entire content.",
                autoPrint: "If you enable auto print, the order will print immediately in the Kitchen as soon as a customer creates an order. Otherwise, you have to confirm the order in the Admin dashboard on your tablet, and then it will print in the Kitchen.",
                footer: "Enter here anything that you want to print at the end of the receipt. Example: Thank you for your order - we look forward to serving you again.",
                selectAllCategoryItems: "Pick up a category of the food item to assign to the print station.",
                unSelectAllCategoryItems: "To clear all, click here to unselect all items.",
                assignRemainingItems: "If you assigned, say, 50 items to the first print station name previously, and now you want to assign the remaining items to this print station, click here.",
                allCollapse: "Click here to collapse all.",
                orderType: "If you select all then all order print and select delivery or pickup then print only delivery or pickup order.",
                autoConfirm: "If ypu enable auto confirm, the order status confirm will print immediately."
            },
            frm_printStationDetails: {
                vendorID: vendorAllDetails.id.trim(),
                vendorName: vendorAllDetails.name,
                vendorCity: vendorAllDetails.city,
                vendorState: vendorAllDetails.state,
                printStationName: "",
                cloudPrint: vendorAllDetails.cloudPrint,
                deviceAddress: "",
                hostName: "",
                printerVendor: "",
                port: "9100",
                printType: 'ticket',
                numCopies: "",
                dotwidth: "",
                otherTickets: true,
                autoPrint: false,
                autoConfirm: true,
                footer: "",
                orderType: "all"
            },
            frm_errors: {
                printStationName: "",
                deviceAddress: "",
                printerVendor: "",
                hostName: "",
                port: "",
                numCopies: "",
                // dotwidth: "",
            },
            submitProgress: false,
        }
        this.checkValidation = this.checkValidation.bind(this);
        this.isPrintStationInUse = this.isPrintStationInUse.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
        this.onAllCategorySelect = this.onAllCategorySelect.bind(this);
        this.onAllCategoryUnSelect = this.onAllCategoryUnSelect.bind(this);
        this.onAssignRemainingItems = this.onAssignRemainingItems.bind(this);
        this.clickPrint = this.clickPrint.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
    }

    componentDidMount() {
        this.getPrintVendor();
        this.getProductsList();
        this.getPrintStation();
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickPrint() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/print_management`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/print_management`);
    }
    async getPrintStation() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `print/get-print-station/${vendorId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getPrintStation();
                    return;
                }
                let printStationData = result.data.response;
                this.setState({ printStation: printStationData });
            }).catch(error => {
                this.setState({ printStation: [] })
                console.error("Problem in fetching print station data: ", error);
            })
        } catch (error) {
            this.setState({ printStation: [] })
            console.error("Problem in fetching print station data: ", error);
        }
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    let tmpName = '';
                                    if (products[i].printStationName && products[i].printStationName.length) {
                                        tmpName = ` (${(products[i].printStationName).toString().replace(/,/g, ', ')})`;
                                    }
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name + tmpName,
                                        printStationName: products[i].printStationName || []
                                    });
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems });
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching previous days orders: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching previous days orders: ", error);
        }
    }
    async getPrintVendor() {
        const vendorKey = 'printVendor';
        let url = `print/get-print-vendor/${vendorKey}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getPrintVendor();
                    return;
                }
                if (result.data.response && result.data.response.length) {
                    let printVendorData = result.data.response[0].print;
                    this.setState({ printVendorData: printVendorData })
                } else {
                    this.setState({ printVendorData: [] })
                }
            }).catch(error => {
                this.setState({ printVendorData: [] })
                console.error("Problem in fetching print station data: ", error);
            })
        } catch (error) {
            this.setState({ printVendorData: [] })
            console.error("Problem in fetching print station data: ", error);
        }
    }
    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }
    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }
    onAllCategorySelect() {
        let tmpProd = this.state.productsData;
        let tmpProd1 = JSON.stringify(this.state.productsData);
        for (let i = 0; i < tmpProd.length; i += 1) {
            if (tmpProd[i].product && tmpProd[i].product.length) {
                tmpProd[i].allProductSelected = true;
            }
        }

        this.setState({
            selectedFoodItems: tmpProd,
            productsData: JSON.parse(tmpProd1)
        });
        setTimeout(() => {
            this.openAllExpand('true');
        }, 200)
    }
    onAllCategoryUnSelect() {
        let tmpProd = this.state.productsData;
        let tmpProd1 = JSON.stringify(this.state.productsData);
        for (let i = 0; i < tmpProd.length; i += 1) {
            if (tmpProd[i].product && tmpProd[i].product.length) {
                tmpProd[i].product = [];
                tmpProd[i].allProductSelected = false;
            }
        }

        this.setState({
            selectedFoodItems: tmpProd,
            productsData: JSON.parse(tmpProd1)
        });
        setTimeout(() => {
            this.openAllExpand('false');
        }, 200)
    }
    onAssignRemainingItems() {
        const productsData = this.state.productsData;
        let tmpProd1 = JSON.stringify(this.state.productsData);
        const remainingArray = [];
        for (let i = 0; i < productsData.length; i += 1) {
            let isSelectedAll = true;
            remainingArray.push({
                categoryName: productsData[i].categoryName,
                product: []
            });
            if (productsData[i].product && productsData[i].product.length) {
                for (let ii = 0; ii < productsData[i].product.length; ii += 1) {
                    if (!(productsData[i].product[ii].printStationName && productsData[i].product[ii].printStationName.length)) {
                        remainingArray[i].product.push({
                            id: productsData[i].product[ii].id,
                            name: productsData[i].product[ii].name
                        });
                    } else {
                        isSelectedAll = false;
                    }
                }
                if (isSelectedAll) {
                    remainingArray[i].allProductSelected = true;
                } else {
                    remainingArray[i].allProductSelected = false;
                }
            }
        }
        this.setState({
            selectedFoodItems: remainingArray,
            productsData: JSON.parse(tmpProd1)
        });
        setTimeout(() => {
            this.openAllExpand('true');
        }, 200)
    }

    isPrintStationInUse(name) {
        let allPrintStation = this.state.printStation;
        let index = allPrintStation.findIndex(printObj => {
            return printObj.printStationName.toLowerCase() === name.toLowerCase()
        });
        return index;
    }
    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_printStationDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "printStationName" && value === "") || (formSubmit === true && formDetails["printStationName"] === "")) {
            fieldName = 'printStationName';
            formErrors[fieldName] = `The 'Print station name' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "printStationName" && value !== "") || (formSubmit === true && formDetails["printStationName"] !== "")) {
            // eslint-disable-next-line
            if (/[^a-zA-Z0-9()\ [\]\/&.+-]/.test(formDetails["printStationName"])) {
                fieldName = 'printStationName';
                formErrors[fieldName] = `The 'Print station name' can only include letters, digits and special characters like Blank space ( ) [ ] + - & . /`;
                isError = true;
            } else if (!(/[a-zA-Z]/.test(formDetails["printStationName"]))) {
                fieldName = 'printStationName';
                formErrors[fieldName] = `The 'Print station name' is not in a valid format.`;
                isError = true;
            } else if (this.isPrintStationInUse(formDetails["printStationName"]) !== -1) {
                fieldName = 'printStationName';
                formErrors[fieldName] = `The 'print station name' already exists.`;
                isError = true;

            }
        }

        if (formDetails.cloudPrint) {
            if ((formSubmit === false && fieldName === "deviceAddress" && value === "") || (formSubmit === true && formDetails["deviceAddress"] === "")) {
                fieldName = 'deviceAddress';
                formErrors[fieldName] = `The 'Device Address' is a required field. It is the MAC address of the printer.`;
                isError = true;
            } else if ((formSubmit === false && fieldName === "deviceAddress" && value !== "") || (formSubmit === true && formDetails["deviceAddress"] !== "")) {
                if (!(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(formDetails["deviceAddress"]))) {
                    fieldName = 'deviceAddress';
                    formErrors[fieldName] = `The 'Device Address' is not in a valid format.`;
                    isError = true;
                }
            }

        }

        if ((formSubmit === false && fieldName === "printerVendor" && value === "") || (formSubmit === true && formDetails["printerVendor"] === "")) {
            fieldName = 'printerVendor';
            formErrors[fieldName] = `Please select the Printer Vendor.`;
            isError = true;
        }

        if (!formDetails.cloudPrint) {
            if ((formSubmit === false && fieldName === "hostName" && value === "") || (formSubmit === true && formDetails["hostName"] === "")) {
                fieldName = 'hostName';
                formErrors[fieldName] = `The 'Host Name' is a required field. It must be in the form of the IP address. Use the sVang mobile app admin screen to discover the printers.`;
                isError = true;
            } else if ((formSubmit === false && fieldName === "hostName" && value !== "") || (formSubmit === true && formDetails["hostName"] !== "")) {
                if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(formDetails["hostName"]))) {
                    fieldName = 'hostName';
                    formErrors[fieldName] = `The 'Host Name' is not in the valid IP address format.`;
                    isError = true;
                }
            }

            if ((formSubmit === false && fieldName === "port" && value === "") || (formSubmit === true && formDetails["port"] === "")) {
                fieldName = 'port';
                formErrors[fieldName] = `The 'Port' is a required field and normally it is 9100.`;
                isError = true;
            } else if ((formSubmit === false && fieldName === "port" && value !== "") || (formSubmit === true && formDetails["port"] !== "")) {
                if (!(/^[0-9]+$/.test(formDetails["port"]))) {
                    fieldName = 'port';
                    formErrors[fieldName] = `The 'Port' field is in a number only format.`;
                    isError = true;
                }
            }
        }

        if ((formSubmit === false && fieldName === "numCopies" && value === "") || (formSubmit === true && formDetails["numCopies"] === "")) {
            fieldName = 'numCopies';
            formErrors[fieldName] = `The 'Number of Copy' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "numCopies" && value !== "") || (formSubmit === true && formDetails["numCopies"] !== "")) {
            if (!(/^[0-9]+$/.test(formDetails["numCopies"]))) {
                fieldName = 'numCopies';
                formErrors[fieldName] = `The 'Number of Copy' is a number only field.`;
                isError = true;
            }
        }

        this.setState({ frm_errors: formErrors });
        this.setState({ frm_printStationDetails: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        let formDetails = this.state.frm_printStationDetails;
        let formErrors = this.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ frm_printStationDetails: formDetails, isOpenFooter: true });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ frm_errors: formErrors });
        }
    }

    async onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            let addObj = {
                vendorID: self.state.frm_printStationDetails.vendorID,
                vendorName: self.state.frm_printStationDetails.vendorName,
                vendorCity: self.state.frm_printStationDetails.vendorCity,
                vendorState: self.state.frm_printStationDetails.vendorState,
                cloudPrint: self.state.frm_printStationDetails.cloudPrint,
                printerVendor: self.state.frm_printStationDetails.printerVendor,
                printStationName: self.state.frm_printStationDetails.printStationName,
                numCopies: Number(self.state.frm_printStationDetails.numCopies),
                otherTickets: self.state.frm_printStationDetails.otherTickets,
                autoPrint: self.state.frm_printStationDetails.autoPrint,
                autoConfirm: self.state.frm_printStationDetails.autoConfirm,
                orderType: self.state.frm_printStationDetails.orderType,
                footer: self.state.frm_printStationDetails.footer,
                selectedFoodItems: self.state.selectedFoodItems
            };
            if (self.state.frm_printStationDetails.cloudPrint) {
                delete addObj.host;
                delete addObj.dotwidth;
                addObj.deviceAddress = (self.state.frm_printStationDetails.deviceAddress).toLowerCase();
            } else {
                delete addObj.deviceAddress;
                addObj.host = {
                    hostName: self.state.frm_printStationDetails.hostName,
                    port: Number(self.state.frm_printStationDetails.port),
                }
                if (self.state.frm_printStationDetails.printerVendor === 'impact') {
                    addObj.dotwidth = 396;
                } else {
                    addObj.dotwidth = 576;
                }
                // addObj.dotwidth = Number(self.state.frm_printStationDetails.dotwidth)
            }

            if (self.state.frm_printStationDetails.printType === 'receipt') {
                addObj.printTicket = false;
                addObj.printReceipt = true;
                delete addObj.otherTickets;
            } else {
                addObj.printTicket = true;
                addObj.printReceipt = false;
            }
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            addObj.vendorId = addObj.vendorID;
            if (vendorId) {
                let url = `print/add-print-station`;
                // let requestData = new FormData();
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onFormSubmit();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the print station successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                                this.props.history.push(`/print_management`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ submitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding product details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding product details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }

    openAllExpand(key) {
        let tmpValue = this.state.collapseOpen;
        if (key === 'true') {
            tmpValue = false;
        }
        if (key === 'false') {
            tmpValue = true;
        }
        tmpValue = !tmpValue;
        const tmpProd = this.state.productsData;
        for (let i = 0; i < tmpProd.length; i += 1) {
            if (tmpValue) {
                tmpProd[i].isOpen = true;
            } else {
                tmpProd[i].isOpen = false;
            }
        }
        this.setState({
            collapseOpen: tmpValue,
            productsData: tmpProd
        });
    }
    render() {
        let { vendorAllDetails, frm_printStationDetails, frm_errors, submitProgress, printVendorData, productsData, collapseOpen, selectedFoodItems, helpText, isOpenFooter, isShowFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="add-product-page">
                <ReactTooltip />
                {vendorAllDetails &&
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                <span className="page-title">Add Print Station</span>
                                <ol className="breadcrumb pl-0 pt-0">
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.clickPrint}>Print</a></li>
                                    {/* <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li> */}
                                    {/* <li className="breadcrumb-item"><Link to="/print_management">Print</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Add Print Station</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-xl-12 col-md-12">
                            <form className="needs-validation clearfix" noValidate>
                                <div className="ms-panel ms-panel-fh custom-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex">
                                            <h5 className="mb-0">Basic Details</h5>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="ms-panel-body">
                                        <div className="form-row">
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="printStationName" className="required">Print Station Name</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.printStationName}></i>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.printStationName !== "" ? 'invalid' : '')}
                                                        placeholder="Print Station Name"
                                                        id="printStationName"
                                                        name="printStationName"
                                                        maxLength="15"
                                                        value={frm_printStationDetails.printStationName}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.printStationName && frm_errors.printStationName !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.printStationName}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="numCopies" className="required">Number of Copy</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.numCopies}></i>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.numCopies !== "" ? 'invalid' : '')}
                                                        placeholder="Number of Copy"
                                                        id="numCopies"
                                                        name="numCopies"
                                                        value={frm_printStationDetails.numCopies}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.numCopies && frm_errors.numCopies !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.numCopies}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="printerVendor" className="required">Print Vendor</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.printerVendor}></i>
                                                <div>
                                                    {/* printerVendor */}
                                                    <div className="input-group">
                                                        <select
                                                            className={"form-control " + (frm_errors.printerVendor !== "" ? 'invalid' : '')}
                                                            id="printerVendor"
                                                            name="printerVendor"
                                                            value={frm_printStationDetails.printerVendor}
                                                            onChange={this.onDetailsChange}
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            {printVendorData.map((pRow, pIndex) => (
                                                                <option key={pIndex} value={pRow.type}>{pRow.name}</option>
                                                            ))}
                                                        </select>
                                                        {frm_errors.printerVendor && frm_errors.printerVendor !== "" &&
                                                            <div className="invalid-feedback">{frm_errors.printerVendor}</div>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="ms-panel ms-panel-fh custom-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex">
                                            <h5 className="mb-0">Hosting Details</h5>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="ms-panel-body">

                                        {frm_printStationDetails && frm_printStationDetails.cloudPrint &&
                                            <div className="form-row"><div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="deviceAddress" className="required">Device Address</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.deviceAddress}></i>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.deviceAddress !== "" ? 'invalid' : '')}
                                                        placeholder="Device Address"
                                                        id="deviceAddress"
                                                        name="deviceAddress"
                                                        value={frm_printStationDetails.deviceAddress}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.deviceAddress && frm_errors.deviceAddress !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.deviceAddress}</div>}
                                                </div>
                                            </div>
                                            </div>
                                        }
                                        {!(frm_printStationDetails && frm_printStationDetails.cloudPrint) &&
                                            <div className="form-row">
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <label htmlFor="hostName" className="required">Host Name</label>
                                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.hostName}></i>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={"form-control " + (frm_errors.hostName !== "" ? 'invalid' : '')}
                                                            placeholder="Host Name"
                                                            id="hostName"
                                                            name="hostName"
                                                            value={frm_printStationDetails.hostName}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            required
                                                        />
                                                        {frm_errors.hostName && frm_errors.hostName !== "" &&
                                                            <div className="invalid-feedback">{frm_errors.hostName}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <label htmlFor="port" className="required">Port</label>
                                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.port}></i>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={"form-control " + (frm_errors.port !== "" ? 'invalid' : '')}
                                                            placeholder="Port"
                                                            id="port"
                                                            name="port"
                                                            value={frm_printStationDetails.port}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            required
                                                        />
                                                        {frm_errors.port && frm_errors.port !== "" &&
                                                            <div className="invalid-feedback">{frm_errors.port}</div>}
                                                    </div>
                                                </div>

                                                {/* <div className="col-lg-3 col-md-4 mb-3">
                                                    <label htmlFor="dotwidth" className="required">Dot Width</label>
                                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.dotwidth}></i>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={"form-control " + (frm_errors.dotwidth !== "" ? 'invalid' : '')}
                                                            placeholder="Dot Width"
                                                            id="dotwidth"
                                                            name="dotwidth"
                                                            value={frm_printStationDetails.dotwidth}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            required
                                                        />
                                                        {frm_errors.dotwidth && frm_errors.dotwidth !== "" &&
                                                            <div className="invalid-feedback">{frm_errors.dotwidth}</div>}
                                                    </div>
                                                </div> */}


                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="ms-panel ms-panel-fh custom-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex">
                                            <h5 className="mb-0">Printing Details</h5>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="ms-panel-body">
                                        <div className="form-row">
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="autoPrint">Auto Print</label>
                                                <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.autoPrint}></i>
                                                <div className="input-group">
                                                    <ul className="ms-list d-flex">
                                                        <li className="ms-list-item pl-0 mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="radio" disabled={true}
                                                                    name="radioExample2"
                                                                    value="true"
                                                                    checked={frm_printStationDetails.autoPrint ? true : false}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.autoPrint = event.currentTarget.value === "true" ? true : false;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Yes </span>
                                                        </li>
                                                        <li className="ms-list-item mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input
                                                                    type="radio" disabled={true}
                                                                    name="radioExample2"
                                                                    value="false"
                                                                    checked={!frm_printStationDetails.autoPrint ? true : false}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.autoPrint = event.currentTarget.value === "true" ? true : false;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> No </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="autoConfirm">Auto Confirm</label>
                                                <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.autoConfirm}></i>
                                                <div className="input-group">
                                                    <ul className="ms-list d-flex">
                                                        <li className="ms-list-item pl-0 mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="radio"
                                                                    name="autoConfirmRadio"
                                                                    value="true"
                                                                    checked={frm_printStationDetails.autoConfirm ? true : false}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.autoConfirm = event.currentTarget.value === "true" ? true : false;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Yes </span>
                                                        </li>
                                                        <li className="ms-list-item mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input
                                                                    type="radio"
                                                                    name="autoConfirmRadio"
                                                                    value="false"
                                                                    checked={!frm_printStationDetails.autoConfirm ? true : false}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.autoConfirm = event.currentTarget.value === "true" ? true : false;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> No </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="deviceAddress" className="required">Print Type</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.printType}></i>
                                                <div className="input-group">
                                                    <ul className="ms-list d-flex">
                                                        <li className="ms-list-item pl-0 mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="radio"
                                                                    name="radioExample1"
                                                                    value="ticket"
                                                                    checked={frm_printStationDetails.printType === "ticket"}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.printType = event.currentTarget.value;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Ticket </span>
                                                        </li>
                                                        <li className="ms-list-item mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input
                                                                    type="radio"
                                                                    name="radioExample1"
                                                                    value="receipt"
                                                                    checked={frm_printStationDetails.printType === "receipt"}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.printType = event.currentTarget.value;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Receipt </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {this.state.frm_printStationDetails && this.state.frm_printStationDetails.printType === 'ticket' &&
                                                <div className="col-lg-3 col-md-4 mb-3">
                                                    <label htmlFor="otherTickets">Other Tickets Print </label>
                                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.otherTickets}></i>
                                                    <div className="input-group">
                                                        <ul className="ms-list d-flex">
                                                            <li className="ms-list-item pl-0 mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="radio"
                                                                        name="radioExample3"
                                                                        value="true"
                                                                        checked={frm_printStationDetails.otherTickets ? true : false}
                                                                        onChange={(event) => {
                                                                            let tempfrmData = frm_printStationDetails;
                                                                            tempfrmData.otherTickets = event.currentTarget.value === "true" ? true : false;
                                                                            this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> Yes </span>
                                                            </li>
                                                            <li className="ms-list-item mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="radio"
                                                                        name="radioExample3"
                                                                        value="false"
                                                                        checked={!frm_printStationDetails.otherTickets ? true : false}
                                                                        onChange={(event) => {
                                                                            let tempfrmData = frm_printStationDetails;
                                                                            tempfrmData.otherTickets = event.currentTarget.value === "true" ? true : false;
                                                                            this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> No </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="orderType" className="required">Order Type</label>
                                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.orderType}></i>
                                                <div className="input-group">
                                                    <ul className="ms-list d-flex">
                                                        <li className="ms-list-item pl-0 mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="radio"
                                                                    name="orderTypeRadio"
                                                                    value="all"
                                                                    checked={frm_printStationDetails.orderType === "all"}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.orderType = event.currentTarget.value;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> All </span>
                                                        </li>
                                                        <li className="ms-list-item mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input
                                                                    type="radio"
                                                                    name="orderTypeRadio"
                                                                    value="delivery"
                                                                    checked={frm_printStationDetails.orderType === "delivery"}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.orderType = event.currentTarget.value;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Delivery </span>
                                                        </li>
                                                        <li className="ms-list-item mb-0">
                                                            <label className="ms-checkbox-wrap">
                                                                <input
                                                                    type="radio"
                                                                    name="orderTypeRadio"
                                                                    value="pickup"
                                                                    checked={frm_printStationDetails.orderType === "pickup"}
                                                                    onChange={(event) => {
                                                                        let tempfrmData = frm_printStationDetails;
                                                                        tempfrmData.orderType = event.currentTarget.value;
                                                                        this.setState({ frm_printStationDetails: tempfrmData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Pickup </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-panel ms-panel-fh custom-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex">
                                            <h5 className="mb-0">Description</h5>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="ms-panel-body">
                                        <div className="form-row">
                                            <div className="col-lg-6 col-md-8 mb-3">
                                                <label htmlFor="footer">Footer</label>
                                                <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.footer}></i>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Add Footer Description to print in the receipt."
                                                        id="footer"
                                                        name="footer"
                                                        value={frm_printStationDetails.footer}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="fooditem-group print-fooditem-group">
                                <div className="row">
                                    <div className="col-md-12 pb-3 text-left">
                                        <button type="button" className="btn btn-primary mr-4" onClick={this.onAllCategorySelect}>Select All Category Items <i className="fa fa-question-circle ml-2" data-tip={helpText.selectAllCategoryItems}></i></button>
                                        <button type="button" className="btn btn-primary mr-4" onClick={this.onAllCategoryUnSelect}>Unselect All Category Items <i className="fa fa-question-circle ml-2" data-tip={helpText.unSelectAllCategoryItems}></i></button>
                                        <button type="button" className="btn btn-primary mr-4" onClick={this.onAssignRemainingItems}>Assign Remaining Items <i className="fa fa-question-circle ml-2" data-tip={helpText.assignRemainingItems}></i></button>
                                        <button type="button" className="btn btn-primary" data-toggle="collapse" onClick={() => this.openAllExpand()}>{collapseOpen ? "Collapse All" : "Expand All"} <i className="fa fa-question-circle ml-2" data-tip={helpText.allCollapse}></i> </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div id="accordion">
                                            {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                                productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                    <div className="card-header">
                                                        <span className="ml-1">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="checkbox"
                                                                    checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                    onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                        </span>
                                                        {/* eslint-disable-next-line */}
                                                        <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                            <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                        </a>
                                                    </div>

                                                    <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                        <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                            {productData && productData.product && productData.product.map((item) => (
                                                                <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                    <label className="ms-checkbox-wrap">
                                                                        <input type="checkbox"
                                                                            checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                                return item.id === selectedItem.id
                                                                            }) !== -1 ? true : false}
                                                                            value={item.id}
                                                                            onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                        />
                                                                        <i className="ms-checkbox-check"></i>
                                                                    </label>
                                                                    <span>{item.name}</span>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pb-3 text-right">
                                        <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default AddPrintStation;