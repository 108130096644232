import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import Swal from 'sweetalert2';


import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import DataTable from '../../DataTables';
import Spinner from '../../spinner';

class PromotionIndex extends React.Component {
    constructor(props) {
        super(props);
        const couponsColumns = [
            { title: 'Code', data: 'couponCode' },
            { title: 'Name', data: 'couponName' },
            { title: 'Discount Type', data: 'discountType' },
            { title: 'Redemptions', data: 'numRedemptions' },
            { title: 'Percentage Off (%)', data: 'percentage' },
            { title: 'Amount Off ($)', data: 'fixedAmount' },
            { title: 'From Date', data: 'fromDate' },
            { title: 'To Date', data: 'validuptoDate' },
            { title: 'Status', data: 'state'},
            { title: 'Action', data: 'validuptoDate', orderable: false },
        ];
        const initialCouponData = {
            couponName: "",
            couponCode: "",
            fromDate: "",
            validuptoDate: "",
            numRedemptions: "",
            percentage: "",
            fixedAmount: "",
            orderAmount: "",
            autoGenerateCode: false,
            state: "inactive"
        }
        const initialCouponErrorData = {
            couponName: "",
            couponCode: "",
            fromDate: "",
            validuptoDate: "",
            numRedemptions: "",
            percentage: "",
            fixedAmount: "",
            orderAmount: ""
        }
        this.state = {
            reloadCouponsTable: false,
            couponsColumns: couponsColumns,
            couponsData: this.props.couponsData,
            couponData: JSON.parse(JSON.stringify(initialCouponData)),
            couponErrorData: JSON.parse(JSON.stringify(initialCouponErrorData)),
            discountType: "percentage",
            openModal: false,
            initialCouponData,
            initialCouponErrorData,
            couponIndexToUpdate: "",
            couponRowIndexToUpdate: ""
        }
        this.couponStatusClass = {
            'Active': '#7fba22',
            'In-active': '#000',
            'Expired': '#ff0000'
        }
        this.baseState = this.state;
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.updateCouponStatus = this.updateCouponStatus.bind(this);
        this.onAddCouponClick = this.onAddCouponClick.bind(this);
        this.onEditCouponClick = this.onEditCouponClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.couponsData.data !== nextProps.couponsData.data) {
            var self = this;
            let couponData = nextProps.couponsData && nextProps.couponsData.data && nextProps.couponsData.data.length > 0 ? nextProps.couponsData.data : []
            this.setState({
                couponsData: {
                    data: self.prepareCouponDatatable(couponData),
                    columnDefs: [{
                        targets: [9],
                        createdCell: (td, cellData, rowData) => {
                            let resultantStatus = rowData?.state === "Active" ? "inactive" : "active"
                            if (rowData.state !== "Active") {
                                ReactDOM.render(
                                    <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, resultantStatus, rowData.id, rowData.rowIndex)}>
                                            <option value="">Select</option>
                                            <option value="Activate">Activate</option>
                                            <option value="Edit">Edit</option>
                                            <option value="Delete">Delete</option>
                                        </select>
                                    </div>
                                    , td)
                            } else {
                                ReactDOM.render(
                                    <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, resultantStatus, rowData.id, rowData.rowIndex)}>
                                            <option value="">Select</option>
                                            <option value="Deactivate">Deactivate</option>
                                        </select>
                                    </div>
                                    , td)
                            }
                        }
                    },
                    {
                        targets: [8],
                        createdCell: (td, cellData, rowData) => {
                            if (rowData.state === "Active") {
                                ReactDOM.render(
                                    <div style={{ minWidth: '75px' }}><span className="status-dot" style={{ backgroundColor: self.couponStatusClass[rowData.state] }}></span><span>Active</span></div>
                                    , td)
                            } else if (rowData.state === "In-active") {
                                ReactDOM.render(
                                    <div style={{ minWidth: '75px' }}><span className="status-dot" style={{ backgroundColor: self.couponStatusClass[rowData.state] }}></span><span>In-active</span></div>
                                    , td)
                            } else if (rowData.state === "Expired") {
                                ReactDOM.render(
                                    <div style={{ minWidth: '75px' }}><span className="status-dot" style={{ backgroundColor: self.couponStatusClass[rowData.state] }}></span><span>Expired</span></div>
                                    , td)
                            }
                        }
                    },
                    {
                        targets: [0],
                        createdCell: (td, cellData, rowData) => {
                            ReactDOM.render(
                                <p className="m-0" style={{ minWidth: '75px' }}>{rowData.couponCode}</p>
                                , td)
                        }
                    },
                    {
                        targets: [1],
                        createdCell: (td, cellData, rowData) => {
                            ReactDOM.render(
                                <p className="m-0" style={{ minWidth: '75px' }}>{rowData.couponName}</p>
                                , td)
                        }
                    },
                    {
                        targets: [7],
                        createdCell: (td, cellData, rowData) => {
                            ReactDOM.render(
                                <p className="m-0" style={{ minWidth: '75px' }}>{rowData.validuptoDate}</p>
                                , td)
                        }
                    },
                    {
                        targets: [6],
                        createdCell: (td, cellData, rowData) => {
                            ReactDOM.render(
                                <p className="m-0" style={{ minWidth: '75px' }}>{rowData.fromDate}</p>
                                , td)
                        }
                    }
                    ]
                }
            })
            self.setState({ reloadCouponsTable: true });
        }
    }

    onActionChange(event, resultantStatus, rowId, rowIndex) {
        let action = event.target.value;
        if (action === "Edit") {
            this.onEditCouponClick(event, rowId)
        }
        if (action === "Delete") {
            this.confirmAndDelete(event, rowId, rowIndex)
        }
        if (action === "Activate" || action === "Deactivate") {
            this.updateCouponStatus(event, resultantStatus, rowId, rowIndex)
        }
        event.target.value = "";
    }

    confirmAndDelete(e, couponId, rowId) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this coupon!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let vendor = JSON.parse(localStorage.getItem('vendor'));
                let requestData = {
                    vendorId: vendor.id,
                    couponId: couponId
                }
                let url = `coupon?db=mongodb`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the coupon. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                self.props.DeleteCoupon(rowId);
                                toast.success("Success: Coupon deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the coupon. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting coupon: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the coupon. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting coupon: ", error);
                }
            }
        });
    }

    updateCouponStatus(e, status, couponId, rowId) {
        e.preventDefault();
        let self = this;
        let text = status === "active" ? "Once you set the 'coupon status' to 'active', user will be able to redeem this coupon." : "Once you set 'coupon status' to 'in-active', users will not be able to redeem this coupon."
        self.setState({ couponRowIndexToUpdate: rowId });
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                let vendor = getLoginUserDetails();
                let vendorId = vendor.id;
                if (vendorId) {
                    let url = `coupon/updatestatus?db=mongodb`;
                    let requestData = {
                        vendorId,
                        couponId,
                        state: status
                    };
                    try {
                        let token = getAuthTokenFromLocalStorage();
                        if (!token) {
                            let auth = await getAuth();
                            if (auth && auth.success && auth.token) {
                                localStorage.setItem('token', auth.token);
                            }
                        }
                        axiosInstance.put(url, requestData)
                            .then(result => {
                                // If auth token expired
                                if ("success" in result.data && result.data.success === false) {
                                    localStorage.removeItem('token');
                                    this.onFormSubmitToAdd();
                                    return;
                                }
                                if (result.data.status) {
                                    toast.success("Success: The 'Coupon status' is updated!", {
                                        autoClose: 3000,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    })
                                    self.props.EditCoupon(result.data.response, self.state.couponRowIndexToUpdate);
                                } else {
                                    toast.error(result.data.statusText, {
                                        autoClose: 5000,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    });
                                }
                            })
                            .catch(error => {
                                toast.error("Oops! We encountered an error. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                console.error("Problem in adding coupon details: ", error);
                            })
                    }
                    catch (error) {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in adding coupon details: ", error);
                    }

                } else {
                    toast.error("Oops! We found problems in the data. Please check and try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            }
        });
    }

    prepareCouponDatatable(coupons) {
        let couponsData = [];
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorTimezone = vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
        // let dateFormat = vendor.vendorAllDetails && vendor.vendorAllDetails.dateFormat && vendor.vendorAllDetails.dateFormat !== "" ? vendor.vendorAllDetails.dateFormat : "MM-DD-YYYY";
        couponsData = coupons.map((coupon, index) => {
            let state = coupon.state === "inactive" ? "In-active" : "Active";
            if (coupon.state === 'expired') {
                state = 'Expired';
            }
            return {
                ...coupon,
                rowIndex: index,
                validuptoDate: moment(coupon.validuptoDate).tz(vendorTimezone).format('YYYY-MM-DD'),
                fromDate: coupon?.fromDate && coupon.fromDate !== "" && coupon.fromDate !== null ? moment(coupon.fromDate).tz(vendorTimezone).format('YYYY-MM-DD') : "N/A",
                percentage: coupon.discountType === "percentageOff" ? coupon.takeOff : 'N/A',
                fixedAmount: coupon.discountType === "amountOff" ? coupon.takeOff : 'N/A',
                state: state,
                discountType: coupon.discountType === "percentageOff" ? "Percentage Off" : coupon.discountType === "amountOff" ? "Amount Off" : coupon.discountType === "freeDelivery" ? "Free Delivery" : ""
            }
        });
        return couponsData
    }

    onAddCouponClick(e) {
        e.preventDefault();
        this.props.history.push(
            {
                pathname: "/marketing_management/coupon/add"
            });
    }

    onEditCouponClick(e, couponId) {
        e.preventDefault();
        this.props.history.push(
            {
                pathname: `/marketing_management/coupon/${couponId}`
            });
    }

    render() {
        const { couponsColumns, couponsData, reloadCouponsTable } = { ...this.state }
        return (
            <div>
                <button type="button" href="/#"
                    className="btn btn-primary has-icon btn_add_coupon btn-sm"
                    onClick={this.onAddCouponClick}>
                    <i className="fa fa-plus fa-xs"></i>
                     Add Coupon
                </button>
                {(!couponsData || couponsData.data === null) && <Spinner />}
                <div className="table-responsive">
                    {couponsData && couponsData.data !== null &&
                        <DataTable data={couponsData} columns={couponsColumns} reloadTable={reloadCouponsTable} sortColumn="8" sortOrder="asc" id="promotionTable" />}
                </div>
            </div>
        );
    }
}

export default withRouter(PromotionIndex);