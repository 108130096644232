import React from 'react';
import moment from 'moment';
import TimeRange from 'react-time-range';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ReactDragListView from 'react-drag-listview';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';

class RestaurantScheduleDetails extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = this.props.vendorAllDetails;
        let vendorScheduleDetails = vendorAllDetails.schedule;
        let weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let weekdayFullname = { "SUN": "Sunday", "MON": "Monday", "TUE": "Tuesday", "WED": "Wednesday", "THU": "Thursday", "FRI": "Friday", "SAT": "Saturday" }
        let weekdaySortOrder = { "sun": 0, "mon": 1, "tue": 2, "wed": 3, "thu": 4, "fri": 5, "sat": 6 }

        let uiScheduleDetails = [];
        let newDummySlot = {
            day: "", name: "", available: false, delivery: "", door: "", order: 1, className: "div-new-schedule", isAllDataValid: true
        };
        let selectedDaysForCopy = [];
        weekdays.forEach((day) => {
            selectedDaysForCopy.push({ name: day, value: false });
        });
        if (vendorScheduleDetails) {
            for (let [dayName, daySchedule] of Object.entries(vendorScheduleDetails)) {
                for (const [slotName, slotDetails] of Object.entries(daySchedule)) {
                    uiScheduleDetails.push({ day: dayName, name: slotName, ...slotDetails, className: "", isAllDataValid: true });
                }
            }
        }

        if (uiScheduleDetails.length <= 0) {
            uiScheduleDetails.push(newDummySlot);
        } else {
            // Sort all the data weekday and order wise
            // eslint-disable-next-line
            uiScheduleDetails.sort(function sortByDay(a, b) {
                let day1 = a.day.toLowerCase();
                let day2 = b.day.toLowerCase();
                if(weekdaySortOrder[day1] > weekdaySortOrder[day2]) return 1;
                if(weekdaySortOrder[day1] < weekdaySortOrder[day2]) return -1;
                if(a.order > b.order) return 1;
                if(a.order < b.order) return -1;
            });
        }

        const self = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...self.state.uiScheduleDetails];
                if (data[fromIndex].day === data[toIndex].day) {
                    const item = data.splice(fromIndex, 1)[0];
                    data.splice(toIndex, 0, item);
                    self.setState({
                        uiScheduleDetails: data
                    });
                } else {
                    toast.error("Oops! You can only interchange an order of the same day scheduleds.", {
                        autoClose: 7000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            },
            handleSelector: "tr",
        };

        this.state = {
            isOpenFooter: false,
            isShowFooter: this.props.isShowFooter,
            uiScheduleDetails,
            tmpuiScheduleDetails: "",
            weekdays,
            tmpweekdays: "",
            newDummySlot,
            tmpnewDummySlot: "",
            selectedDaysForCopy,
            tmpselectedDaysForCopy: "",
            cloneIndex: "",
            tmpcloneIndex: "",
            weekdayFullname,
            tmpweekdayFullname: "",
            submitProgress: false
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.prepareScheduleData = this.prepareScheduleData.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onCloneClick = this.onCloneClick.bind(this);
        this.onDayClick = this.onDayClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        
    }

    componentDidMount() {
        this.setState({
            tmpuiScheduleDetails: JSON.stringify(this.state.uiScheduleDetails),
            tmpweekdays: JSON.stringify(this.state.weekdays),
            tmpnewDummySlot: JSON.stringify(this.state.newDummySlot),
            tmpselectedDaysForCopy: JSON.stringify(this.state.selectedDaysForCopy),
            tmpweekdayFullname: JSON.stringify(this.state.weekdayFullname),
            tmpcloneIndex: JSON.stringify(this.state.cloneIndex),
        });
    }
    prepareScheduleData() {
        let schedules = this.state.uiScheduleDetails;
        let requestData = {};
        schedules.forEach(schedule => {
            let order = 1;

            if (requestData[schedule.day]) {
                for (var key in requestData[schedule.day]) {
                    if (requestData[schedule.day].hasOwnProperty(key)) {
                        order += 1;
                    }
                }
            }
            requestData[schedule.day] = {
                ...requestData[schedule.day],
                [(schedule.name).trim()]: {
                    available: schedule.available,
                    delivery: schedule.door,
                    door: schedule.door,
                    order: order
                }
            };
        });
        return requestData;
    }

    confirmAndDelete(event, index) {
        event.preventDefault();
        var self = this;
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this schedule details!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                let tempData = self.state.uiScheduleDetails;
                let newDummySlot = self.state.newDummySlot;
                tempData.splice(index, 1);
                tempData.length === 0 ? this.setState({ uiScheduleDetails: [JSON.parse(JSON.stringify(newDummySlot))] }) :
                    this.setState({ uiScheduleDetails: tempData })
            }
        });
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false,
            uiScheduleDetails: JSON.parse(this.state.tmpuiScheduleDetails),
            weekdays: JSON.parse(this.state.tmpweekdays),
            newDummySlot: JSON.parse(this.state.tmpnewDummySlot),
            selectedDaysForCopy: JSON.parse(this.state.tmpselectedDaysForCopy),
            weekdayFullname: JSON.parse(this.state.tmpweekdayFullname),
            cloneIndex: JSON.parse(this.state.tmpcloneIndex),
        });
        self.props.saveData(false);
    }
    onTimeChange(value, index, day) {
        this.props.saveData(true);
        this.setState({
            isOpenFooter: true
        });
        let startTime = moment(Date.parse(value.startTime));
        let endTime = moment(Date.parse(value.endTime));
        let schedule = this.state.uiScheduleDetails;
        let isOvarlappingTime = false;
        schedule[index].isAllDataValid = true;

        if (startTime > endTime || startTime.isSame(endTime)) {
            schedule[index].isAllDataValid = false;
        } else {
            schedule.forEach((data, dataIndex) => {
                if (data.day === day && index !== dataIndex && data.door !== "") {
                    let ST = moment(data.door.split('-')[0], "HH:mm");
                    let ET = moment(data.door.split('-')[1], "HH:mm");
                    if (startTime.isBetween(ST, ET) || endTime.isBetween(ST, ET) || ST.isBetween(startTime, endTime) || ET.isBetween(startTime, endTime)) {
                        isOvarlappingTime = true;
                        return false;
                    }
                }
            });
        }
        if (isOvarlappingTime) {
            // schedule[index].door = "";   
            // schedule[index].delivery = ""; 
            // schedule[index].isAllDataValid = true;
            // this.setState({uiScheduleDetails: schedule});  
            toast.error(`Error: Selected time is overlapping with the other schedules of the ${this.state.weekdayFullname[day]}.`, {
                autoClose: 10000,
                closeOnClick: true
            })
        } else {
            startTime = moment(Date.parse(value.startTime)).format("HH:mm");
            endTime = moment(Date.parse(value.endTime)).format("HH:mm");
            let time = startTime + "-" + endTime;
            schedule[index].door = time;
            schedule[index].delivery = time;
            this.setState({ uiScheduleDetails: schedule });
        }
    }

    onCloneClick() {
        let selectedDays = this.state.selectedDaysForCopy;
        let schedules = this.state.uiScheduleDetails
        let dayToClone = schedules[this.state.cloneIndex];
        let startTime = moment(dayToClone.door.split('-')[0], "HH:mm");
        let endTime = moment(dayToClone.door.split('-')[1], "HH:mm");
        let entriesToClone = [];
        selectedDays.forEach((day, index) => {
            if (day.value) {
                let isOvarlappingTime = false;
                let isSameEntry = false;
                schedules.forEach((data, dataIndex) => {
                    if (data.day === day.name && dataIndex !== this.state.cloneIndex) {
                        if (data.door !== "") {
                            let ST = moment(data.door.split('-')[0], "HH:mm");
                            let ET = moment(data.door.split('-')[1], "HH:mm");
                            if (startTime.isBetween(ST, ET) || endTime.isBetween(ST, ET) || ST.isBetween(startTime, endTime) || ET.isBetween(startTime, endTime)) {
                                isOvarlappingTime = true;
                                return false;
                            }
                        }
                        if (data.door === dayToClone.door && data.available === dayToClone.available && data.name === dayToClone.name) {
                            isSameEntry = true;
                            return false;
                        }
                    }
                });
                if (isOvarlappingTime) {
                    toast.error(`Error: New schedule is overlapping with the other schedules of the ${this.state.weekdayFullname[day.name]}.`, {
                        autoClose: 7000,
                        closeOnClick: true
                    })
                } else if (isSameEntry) {
                    toast.error(`Error: Same schedule avaialble on ${this.state.weekdayFullname[day.name]}.`, {
                        autoClose: 7000,
                        closeOnClick: true
                    })
                } else {
                    entriesToClone.push({ ...dayToClone, day: day.name })
                }
                selectedDays[index].value = false;
            }
        })
        this.setState({ selectedDaysForCopy: selectedDays });
        let successCloneDays = " ";
        for (let i = 0; i < entriesToClone.length; i++) {
            let destinationIndex = this.state.cloneIndex + (i + 1);
            for (let j = schedules.length - 1; j >= 0; j--) {
                if (schedules[j].day === entriesToClone[i].day) {
                    destinationIndex = j;
                    break;
                }
            }
            schedules.splice(destinationIndex + 1, 0, entriesToClone[i]);
            if (i + 1 === entriesToClone.length) {
                successCloneDays += this.state.weekdayFullname[entriesToClone[i].day] + ",";
            } else {
                successCloneDays += this.state.weekdayFullname[entriesToClone[i].day] + ", ";
            }

        }
        if (entriesToClone.length > 0) {
            toast.success(`Success: Schedule copied for ${successCloneDays}`, {
                autoClose: 7000,
                closeOnClick: true
            })
        }
    }

    onDayClick(event, index) {
        let schedules = this.state.uiScheduleDetails;
        let scheduleToUpdate = schedules[index];
        let isSameAvailable = false;
        let isOvarlappingTime = false;
        this.props.saveData(true);
        this.setState({
            isOpenFooter: true
        });
        schedules.forEach((schedule, sIndex) => {
            if (schedule.day === event.target.value && sIndex !== index &&
                (schedule.name === scheduleToUpdate.name
                    && schedule.door === scheduleToUpdate.door
                    && schedule.available === scheduleToUpdate.available)
            ) {
                isSameAvailable = true;
                return false;
            } else if (schedule.day === event.target.value && sIndex !== index && schedule.door !== "") {
                let ST = moment(schedule.door.split('-')[0], "HH:mm");
                let ET = moment(schedule.door.split('-')[1], "HH:mm");
                let startTime = moment(scheduleToUpdate.door.split('-')[0], "HH:mm");
                let endTime = moment(scheduleToUpdate.door.split('-')[1], "HH:mm");
                if (startTime.isBetween(ST, ET) || endTime.isBetween(ST, ET)) {
                    isOvarlappingTime = true;
                    return false;
                }
            }
        });
        if (isSameAvailable) {
            toast.error(`Error: Same schedule is avaialble on ${this.state.weekdayFullname[event.target.value]}.`, {
                autoClose: 7000,
                closeOnClick: true
            })
        } else if (isOvarlappingTime) {
            toast.error(`Error: This change will create an overlapping entry for ${this.state.weekdayFullname[event.target.value]}.`, {
                autoClose: 10000,
                closeOnClick: true
            })
        }
        else {
            schedules[index].day = event.target.value;
            this.setState({ uiScheduleDetails: schedules })
        }

    }

    onNameChange(event, index) {
        let schedules = this.state.uiScheduleDetails;
        let targetSchedule = schedules[index];
        let newName = (event.target.value).trim();
        schedules.forEach((schedule, sIndex) => {
            if (index !== sIndex && (schedule.name).trim() === newName && targetSchedule.day === schedule.day) {
                schedules[index].name = "";
                this.setState({ uiScheduleDetails: schedules });
                toast.error(`Error: Same name is already taken.`, {
                    autoClose: 7000,
                    closeOnClick: true
                });
            }
        })
    }

    handleValidation() {
        let isDataValid = true;
        let schedules = this.state.uiScheduleDetails;
        schedules.forEach(schedule => {
            if (schedule.name === "" || (schedule.available === true && schedule.door === "") || !schedule.isAllDataValid) {
                isDataValid = false;
                return false;
            }
        });
        if (!isDataValid) {
            return { status: true, message: "Oops! We found problems in the data. Please check and try again." };
        } else {
            let scheduleData = this.prepareScheduleData();
            let scheduleDataLength = 0;
            if (scheduleData) {
                for (var key in scheduleData) {
                    if (scheduleData.hasOwnProperty(key)) {
                        scheduleDataLength += 1;
                    }
                }
            }
            if (scheduleDataLength !== this.state.weekdays.length) {
                return { status: true, message: "Error: Enter all weekday's schedule data." };
            } else {
                return { status: false };
            }
        }
    }

    async onFormSubmit() {
        let self = this;
        self.setState({ submitProgress: true });

        // Check all data is valid to submit
        let isError = self.handleValidation();

        if (!isError.status) {
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            let url = `vendor/updatevendor/scheduledetails`;

            // Prepare post data 
            let requestData = { "vendorId": vendorId, "schedule": self.prepareScheduleData() };

            // Submit request to update data
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.post(url, requestData)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.onFormSubmit();
                            return;
                        }
                        if (result.data.status) {
                            let vendorCurrentData = getLoginUserDetails();
                            vendorCurrentData['vendorAllDetails'] = result.data.response;
                            global.config.sidebarFooter = false;
                            global.config.context = false;
                            this.props.saveData(false);
                            this.setState({
                                isOpenFooter: false,
                                isShowFooter: false,
                                tmpuiScheduleDetails: JSON.stringify(this.state.uiScheduleDetails),
                                tmpweekdays: JSON.stringify(this.state.weekdays),
                                tmpnewDummySlot: JSON.stringify(this.state.newDummySlot),
                                tmpselectedDaysForCopy: JSON.stringify(this.state.selectedDaysForCopy),
                                tmpweekdayFullname: JSON.stringify(this.state.weekdayFullname),
                                tmpcloneIndex: JSON.stringify(this.state.cloneIndex)
                            })
                            localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                            toast.success("Success: Schedule details updated!", {
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            })
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 7000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        self.setState({ submitProgress: false });
                    })
                    .catch(error => {
                        self.setState({ submitProgress: false });
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 7000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in updating schedule details.: ", error);
                    })
            }
            catch (error) {
                self.setState({ submitProgress: false });
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 7000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating schedule details. ", error);
            }
        } else {
            self.setState({ submitProgress: false });
            toast.error(isError.message, {
                autoClose: 7000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        let { uiScheduleDetails, weekdays, newDummySlot,
            selectedDaysForCopy, weekdayFullname, cloneIndex, isOpenFooter, isShowFooter } = { ...this.state }
            if (isOpenFooter) {
                global.config.context = true;
            } else {
                global.config.context = false;
            }
            if (global.config.sidebarFooter) {
                isShowFooter = true;
            }
        return (
            <div>
                <div className="container section-schedule">
                    <div className="table-responsive">
                    <ReactDragListView {...this.dragProps}>
                    <h6 className="text-info">Note:</h6>
                    <ul className="mb-3">
                        <li><h6 className="text-info">1. Webapp and sVang App will show schedules in the same order as shown below.</h6></li>
                        <li><h6 className="text-info">2. Drag and drop schedules to change its display order on Webapp and sVang App. </h6></li>
                        
                    </ul>     
                        <table className="table thead-primary tbl-schedule">
                            <tbody>
                                {uiScheduleDetails && uiScheduleDetails.length > 0 && uiScheduleDetails.map((rowData, index) => (
                                    <tr key={index} className={rowData.className}>
                                        <td>
                                            <div className="input-group">
                                                <label className="ms-checkbox-wrap ms-checkbox-primary">
                                                    <input type="checkbox"
                                                        checked={rowData.available}
                                                        onChange={(event) => {
                                                            let tempData = uiScheduleDetails;
                                                            tempData[index].available = event.target.checked;
                                                            this.props.saveData(true);
                                                            this.setState({ uiScheduleDetails: tempData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <select className="form-control"
                                                    value={rowData.day}
                                                    onChange={(event) => this.onDayClick(event, index)} >
                                                    <option value="">Select</option>
                                                    {weekdays.map((dayName, dayIndex) => (
                                                        <option key={`name-${index}-${dayIndex}`} value={dayName}>{dayName}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text"
                                                    name="slotName"
                                                    className="form-control"
                                                    value={rowData.name}
                                                    placeholder="You can type lunch or dinner or anything"
                                                    onChange={(event) => {
                                                        let tempData = uiScheduleDetails;
                                                        tempData[index].name = event.target.value;
                                                        this.props.saveData(true);
                                                        this.setState({ uiScheduleDetails: tempData, isOpenFooter: true })
                                                    }}
                                                    onBlur={(event) => { this.onNameChange(event, index) }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <TimeRange
                                                    onChange={(value) => this.onTimeChange(value, index, rowData.day)}
                                                    sameIsValid={rowData.available ? false : true}
                                                    startMoment={rowData.door !== "" ? moment(rowData.door.split('-')[0], "HH:mm").toISOString() : moment("00:00", "HH:mm").toISOString()}
                                                    endMoment={rowData.door !== "" ? moment(rowData.door.split('-')[1], "HH:mm").toISOString() : moment("00:00", "HH:mm").toISOString()}
                                                    minuteIncrement="15"
                                                    use24Hours="true" />
                                            </div>
                                        </td>
                                        <td className="mt-1">
                                            <a href="\#" className="ml-2" title="Add"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.props.saveData(true);
                                                    let tempData = uiScheduleDetails;
                                                    tempData.splice(index + 1, 0, JSON.parse(JSON.stringify({ ...newDummySlot, day: rowData.day })));
                                                    this.setState({ uiScheduleDetails: tempData, isOpenFooter: true })
                                                }}>
                                                <i className="fa fa-plus text-success"></i>
                                            </a>
                                            <a href="\#" className="ml-2" title="Copy"
                                                data-toggle="modal" data-target="#modal-7" onClick={() => { 
                                                    this.props.saveData(true);
                                                    this.setState({ cloneIndex: index, isOpenFooter: true }) 
                                                }}>
                                                <i className="fa fa-copy text-muted"></i>
                                            </a>
                                            <a href="\#"
                                                className="ml-2"
                                                title="Delete"
                                                onClick={
                                                    (event) => {
                                                        this.props.saveData(true);
                                                        this.setState({
                                                            isOpenFooter: true
                                                        });
                                                        this.confirmAndDelete(event, index);
                                                    }}>
                                                <i className="fas fa-trash text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                   </ReactDragListView>
                    </div>
                    <div className="col-md-12 mb-4 text-right">
                        <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Save {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                    </div>
    
                    {/* Copy Modal */}
                    <div className="modal fade" id="modal-7" tabIndex="-1" role="dialog" aria-labelledby="modal-7">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content">
    
                                <div className="modal-header bg-secondary">
                                    <h5 className="modal-title has-icon text-white"><i className="fa fa-copy"></i> Select Day</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
    
                                <div className="modal-body">
                                    <form method="post">
                                        <div>
                                            {selectedDaysForCopy.map((dayData, index) => (
                                                <div className="form-group" key={index}>
                                                    <label className="ms-checkbox-wrap ms-checkbox-primary">
                                                        <input type="checkbox"
                                                            disabled={cloneIndex !== "" && dayData.name === uiScheduleDetails[cloneIndex].day ? true : false}
                                                            key={`day-copy-${index}`}
                                                            checked={dayData.value}
                                                            onChange={(event) => {
                                                                let tempData = selectedDaysForCopy;
                                                                tempData[index].value = event.target.checked;
                                                                this.setState({ selectedDaysForCopy: tempData })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span> {`${weekdayFullname[dayData.name]}`} </span>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </form>
                                    <div className="text-right">
                                        <button type="button"
                                            className="btn btn-primary shadow-none"
                                            data-dismiss="modal"
                                            onClick={this.onCloneClick}>Copy</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { isShowFooter &&
                    <div className="unsave-footer">
                      <p>There are unsaved changes, please save your changes.</p>
                      <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                      <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default RestaurantScheduleDetails;