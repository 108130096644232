import React from 'react';
import queryString from 'query-string';
import ReactDOM from "react-dom";
import moment from 'moment-timezone'

import { Link } from "react-router-dom";
import DataTablesComp from '../../DataTables';
import { dateToISOString } from '../../../utils';
import Spinner from '../../spinner';
import {firestore, collectionName } from "../../../firebase";

function filterUsers (array, filter, start, end) {
  // var result = [];
  var result= array.filter(function(item) {
    // item.price = Number(item.price);
    for (var key in filter) {
      // if (item[key] === undefined || item[key] != filter[key] || item['price'] < start || item['price'] >= end)
      // eslint-disable-next-line
      if (item[key] === undefined || item[key] != filter[key])
        return false;
    }
    return true;
  });
  return result;
}

class OrderManagement extends React.Component {
  constructor(props) {
    super(props);
    let activeTab = queryString.parse(this.props.location.search).tab;
    if(!activeTab || activeTab === ""){
      activeTab = "today";
    }
    const columns = [
      { title: 'Order ID', data: 'orderID' },
      { title: 'Order Source', data: 'orderSource' },
      { title: 'Ordered Time', data: 'orderDateTime' },
      { title: 'Customer Name', data: 'customerName' },
      { title: 'Delivery Type', data: 'deliveryType' },
      { title: 'Delivery Location', data: 'location' },
      { title: 'Order Status', data: 'status' },
      { title: 'Ticket Print', data: 'printingTicketStatus' },
      { title: 'Order Price', data: 'price', className: "text-right" },
    ];
    this.statusColor = {
      'Pending': '#ff8306',
      'Confirm': '#008080',
      'Out for Delivery': '#ff914d',
      'Delivered': '#7fba22',
      'Delivered (A)': '#ff8306',
      'At the door': '#0fc4d4',
      'Ready for Pickup': '#FFBF00',
      'Out from kitchen': '#CCCCFF',
      'Picked up': '#FF00FF',
      'Picked up (A)': '#ff8306',
      'Cancelled': '#000' ,
      'Completed': '#ff8306',
      'Completed (A)': '#ff8306',
    }
    this.state = {
      orderStatus: [],
      orders: [],
      orderStatusValue: '',
      orderType: [],
      orderTypeValue: '',
      orderPriceValue: '',
      todaysOrder: {
        data: null
      },
      previousOrders: {
        data: null
      },
      todayDataFetchComplete: false,
      previousDataFetchComplete: false,
      columns: columns,
      reloadTable: false,
      activeTab: activeTab
    }
    this.openDetailsPage = this.openDetailsPage.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.getTodaysOrder();
  }

  openDetailsPage(event, orderId){
    event.preventDefault();
    this.props.history.push(
      {
          pathname: `/order_management/${orderId}`, 
          state: { activeTab:this.state.activeTab}
      });
  }

  onTabChange(tabName){
    this.setState({activeTab: tabName});
  }

  /**
   * This function called from fetch today orders and previous orders 
   * @param {Array} orders The order data
   * @returns Add $ in price return array of order data
   */
  prepareOrderDatatable(orders) {
      let ordersData = []
      ordersData = orders.map(order => {
        if (!order.orderSource) {
            order.orderSource = 'svang'
        }
        return {
          ...order,
          price: order.price ? '$' + order.price : ''
        }
      });
      return ordersData
  }

  /**
   * This function called from page first time initialisation
   ** Fetch order data from firebase snapshot vendor order collection when vendor id and today create date match
   ** If fetch data after called function [prepareOrderDatatable](#prepareorderdatatable)
   */
  async getTodaysOrder() {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let vendorTimezone = vendor.vendorAllDetails &&  vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
    // const now = moment().tz(vendorTimezone).utc();
    
    // const currentTime =  new Date(moment().tz(tz).format()).getTime();
    // GET TODAY'S START AND END TIME
    const dayStartMinute =  new Date(moment().tz(vendorTimezone).set("hour", 0).set("minute", 0).set('second', 0).format());
    const dayLastMinute =  new Date(moment().tz(vendorTimezone).set("hour", 23).set("minute", 59).set('second', 59).format());
    // const todayStartDate = now.startOf('day').toDate();
    // const todayEndDate = now.endOf('day').toDate();
    try {
    firestore.collection(collectionName.VENDORORDER)
                .where('vendorID', '==', vendorId)
                .where('createdAt', '>', dayStartMinute)
                .where('createdAt', '<', dayLastMinute)
                .orderBy('createdAt', 'desc')
                .onSnapshot(data => {
                  let orders = [];
                  data.forEach(order => {
                    let orderData = order.data();
                    let orderAddress = orderData.address && orderData.address.line1 ? orderData.address.line1 : "";
                    if (orderAddress) {
                      orderAddress += `, ${orderData.address && orderData.address.line2 ? orderData.address.line2 : ""}`;
                    }
                    let printStationName = '';
                    if (orderData.printingTicketObj) {
                      for (const [key, value] of Object.entries(orderData.printingTicketObj)) {
                        if (value === 'No') {
                          if (!printStationName) {
                            printStationName = key;
                          } else {
                            printStationName += ', ' +key;
                          }
                        }
                      }
                    }
                    let data = {
                      id: orderData.id,
                      orderID: orderData.orderNumber ? orderData.orderNumber : "View",
                      orderSource: orderData.orderSource ? orderData.orderSource : "svang",
                      deliveryType: orderData.deliveryType ? orderData.deliveryType : "",
                      orderType: orderData.orderType ? orderData.orderType : "",
                      orderDateTime: moment(dateToISOString(orderData.createdAt)).tz(vendorTimezone).format('YYYY-MM-DD HH:mm:ss'),
                      printingTicketStatus: orderData?.printingTicketStatus ? (orderData.printingTicketStatus === 'No' || orderData.printingTicketStatus === 'Partially') ? `${orderData.printingTicketStatus} (${printStationName})` : orderData.printingTicketStatus : "Done",
                      customerName: (orderData.author && orderData.author.firstName ? orderData.author.firstName : "")
                        + (orderData.author && orderData.author.lastName ? (" " + orderData.author.lastName) : ""),
                      location: orderAddress,
                      status: orderData.status,
                      price: orderData.resturantDetails && orderData.resturantDetails.total ? orderData.resturantDetails.total.toString() : ""
                    }
                    orders.push(data);
                  });
                  this.setState({
                    todaysOrder: {
                      data: JSON.parse(JSON.stringify(self.prepareOrderDatatable(orders))),
                      columnDefs: [{
                        targets: [0],
                        createdCell: (td, cellData, rowData) =>
                          ReactDOM.render(
                            <a href="/#" onClick={(event) => this.openDetailsPage(event, rowData.id)}>{rowData.orderID}</a>, td)
                      }, {
                        targets: [6],
                        createdCell: (td, cellData, rowData) =>
                          ReactDOM.render(
                            <div><span className="status-dot" style={{ backgroundColor: self.statusColor[rowData.status] }}></span><span>{rowData.status}</span></div>, td)
                      }]
                    }
                  })
                  this.setState({ reloadTable: true });
                });
    
  }
    catch (error) {
      this.setState({ todaysOrder: { data: [] } })
      console.error("Problem in fetching today's orders: ",);
    }
  }

  reloadData(orders) {
    var self = this;
    this.setState({
      previousOrders: {
        data: self.prepareOrderDatatable(orders),
        columnDefs: [{
          targets: [0],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <a href="/#" onClick={(event) => this.openDetailsPage(event, rowData.FB_ID)}>{rowData.orderID}</a>, td)
        }, {
          targets: [6],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <span className="status-dot" style={{backgroundColor: self.statusColor[rowData.isCronUpdate ? rowData.status + ' (A)' : rowData.status]}}></span>
                <span>{rowData.status}</span>
              </div>, td)
        }]
      }
    });
    this.setState({ reloadTable: true });
  }
  filterData(value, key) {
    let object = {
      status: '',
      deliveryType: ''
    };
    if (key === 'orderStatus') {
      this.setState({
        orderStatusValue: value
      });
      if (!value) {
        delete object.status;
        if (this.state.orderTypeValue) {
          object.deliveryType = this.state.orderTypeValue;
        } else {
          delete object.deliveryType;
        }
      } else {
        if (this.state.orderTypeValue) {
          object.deliveryType = this.state.orderTypeValue;
          object.status = value;
        } else {
          delete object.deliveryType;
          object.status = value;
        }
      }
      
      this.reloadData(filterUsers(this.state.orders, object));
      // orders
    }

    if (key === 'orderType') {
      this.setState({
        orderTypeValue: value
      });
      if (!value) {
        delete object.deliveryType;
        if (this.state.orderStatusValue) {
          object.status = this.state.orderStatusValue;
        } else {
          delete object.status;
        }
      } else {
        if (this.state.orderStatusValue) {
          object.status = this.state.orderStatusValue;
          object.deliveryType = value;
        } else {
          delete object.status;
          object.deliveryType = value;
        }
      }
      this.reloadData(filterUsers(this.state.orders, object));
    }

    if (key === 'orderPrice') {
      this.setState({
        orderPriceValue: value
      });
    }
  }

  render() { 
      const { todaysOrder, reloadTable, columns, activeTab} = { ...this.state }      
    return (
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb" className="page-nav-info mb-1"> 
            <span className="page-title">Orders</span>
            <ol className="breadcrumb pl-0 pt-0">
              <li className="breadcrumb-item"><Link to="/order_management?tab=today" onClick={() => {this.onTabChange("today")}}> <i className="material-icons">home</i> Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Orders</li>
            </ol>
          </nav>

          <div>
              <ul
                className="nav nav-tabs tabs-bordered mb-4"
                role="tablist"
              >
                <li role="presentation">
                  <Link
                    to="/order_management?tab=today"
                    aria-controls="tab1"
                    className={activeTab && (activeTab === "" || activeTab.includes('today')) ?  "active show" : ""}
                    role="tab"
                    data-toggle="tab"
                    onClick={() => {this.onTabChange("today")}} 
                  >
                    Today's Orders
                      </Link>
                </li>
                <span className="tab-separator"></span>
                <li role="presentation">
                  <Link
                    to="/order_management?tab=previous"
                    aria-controls="tab2"
                    role="tab"
                    data-toggle="tab"
                    className={activeTab && activeTab.includes('previous') ?  "active show" : ""}
                    onClick={() => {this.onTabChange("previous")}}    
                  >
                    Previous Orders
                      </Link>
                </li>
              </ul>
              <div className="tab-content dashboard_table">
                <div role="tabpanel" 
                className={activeTab && (activeTab === "" || activeTab.includes('today')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                id="tab1">
                  {(!todaysOrder || todaysOrder.data === null) && <Spinner />}
                  <div className="table-responsive">
                    {todaysOrder && todaysOrder.data !== null && <DataTablesComp data={todaysOrder} columns={columns} reloadTable={reloadTable} pageLength="100" sortColumn="2" sortOrder="desc" id="todayOrderTable" />}
                  </div>

                </div>
                <div role="tabpanel" 
                className={activeTab && (activeTab.includes('previous')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                id="tab2">
                  <div className="table-responsive">
                    <DataTablesComp columns={columns} reloadTable={false} pageLength="100" sortColumn="2" sortOrder="desc" id="previousOrderTable" />
                  </div>
                </div>
              </div>
            </div>

        </div>

      </div>
    );
  }
}

export default OrderManagement;