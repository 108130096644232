import React from 'react';
import { withRouter } from "react-router-dom";
import ReactDragListView from 'react-drag-listview';
import 'react-calendar/dist/Calendar.css';
// import Swal from 'sweetalert2';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';
import FoodItemGroup from '../../FoodItemsGroup';
import FoodItemVariant from './FoodItemVariant';


class MenuMappingDetails extends React.Component {
    constructor(props){
        super(props);
        const data = [];
        for (let i = 1, len = 21; i < len; i += 1) {
        data.push({
            title: `rows${i}`
        });
        }
        
        this.state = {
            data,
            orderSourceList: [],
            basicDetails: {
                orderSource: { displayName: 'Order Source', value: "slice", error: "" },
                // file: { displayName: 'File', value: "", error: "" },
            },
            openModal: false,
            finalCategoryProduct: [],
            productsData: [],
            selectedFoodItems: [],
            strSelectedFoodItems: "",
            strProductsData: "",
            selectCategoryName: '',
            isSubmitUpdate: false,
            openCategoryName: '',
            svangProductData: [],
            variantOpenModal: false,
            fullProductsData: [],
            singleProduct: {},
        }

        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.getDatathirdPartyData = this.getDatathirdPartyData.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onFoodItemSave = this.onFoodItemSave.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.copyProduct = this.copyProduct.bind(this);
        this.openVariantModal = this.openVariantModal.bind(this);
        this.closeVariantModal = this.closeVariantModal.bind(this);
        this.onFoodVarianSave = this.onFoodVarianSave.bind(this);
        // this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.getOrderSource();
        this.getDatathirdPartyData();
        this.getProductsList()
    }
    async getOrderSource() {
        let self = this;
        let url = `getCollectionData`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, {collectionName: 'settings'})
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.getOrderSource();
                    return;
                }
                if(result.data.status){
                    if (result.data.data && result.data.data.length) {
                        for (let i = 0; i < result.data.data.length; i += 1) {
                            if (result.data.data[i].id === 'delivery_partners') {
                                self.setState({
                                    orderSourceList: result.data.data[i].data
                                });
                                break;
                            }
                        }   
                    }
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    self.setState({
                        orderSourceList: []
                    });
                }
            })
            .catch(error => {
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({
                    orderSourceList: []
                });
            })
        }
        catch (error) {
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({
                orderSourceList: []
            });
        } 
    }
    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    let products = result.data.response;
                    this.setState({
                        fullProductsData: products
                    });
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: []
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    manageProductData[ii].product.push({ id: products[i].id, name: products[i].name });
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData, strProductsData: JSON.stringify(manageProductData), selectedFoodItems: tempSelectedFoodItems, strSelectedFoodItems: JSON.stringify(tempSelectedFoodItems)});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: [] })
                    console.error("Oops! We encountered an error in fetching product data. Please try again.", error);
                })
        }
        catch (error) {
            this.setState({ productsData: [] })
            console.error("Problem in fetching previous product data: ", error);
        }
    }
    handleValidation(fieldName = "", value = "") {
        let formDetails = this.state.basicDetails;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
          formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "orderSource" && value === "") || (formSubmit === true && formDetails["orderSource"].value === "")) {
          fieldName = 'orderSource';
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
          isError = true;
        }
        this.setState({ basicDetails: formDetails });
        return isError;
    }
    onBasicDetailsChange(e) {
        let formDetails = this.state.basicDetails;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName].value = fieldValue;
        this.setState({ basicDetails: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
        formDetails[fieldName].error = "";
        this.setState({ basicDetails: formDetails });
        }
        this.getDatathirdPartyData();
    }
    async getDatathirdPartyData() {
        try {
            var self = this;
            self.setState({ submitProgress: true });
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            let url = `menu/get/thirdparty`;
            let requestData = {
                vendorId: vendorId,
                orderSource: self.state.basicDetails.orderSource.value
            };
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getDatathirdPartyData();
                    return;
                }
                let fullProductData = [];
                if (result.data.status) {
                    fullProductData = result.data.data;
                    let tempData = self.state.basicDetails;
                    Object.keys(tempData).forEach(function (key) {
                        tempData[key].error = "";
                    });
                    self.setState({ basicDetails: tempData });
                    let categoryArray = [];
                    let categorySpecificProduct = {};
                    let categorySpecificSvangProduct = {};
                    if (fullProductData && fullProductData.length) {
                        for (let i = 0; i < fullProductData.length; i+= 1) {
                            if (categoryArray.indexOf(fullProductData[i].category) === -1) {
                                categoryArray.push(fullProductData[i].category);
                                categorySpecificProduct[fullProductData[i].category] = [];
                                categorySpecificSvangProduct[fullProductData[i].category] = [];
                                categorySpecificProduct[fullProductData[i].category].push(fullProductData[i]);
                                if (fullProductData[i] && fullProductData[i].svangProductName) {
                                    categorySpecificSvangProduct[fullProductData[i].category].push({
                                        name: fullProductData[i].svangProductName,
                                        id: fullProductData[i].svangProductId,
                                        categoryName: fullProductData[i].svangCategoryName,
                                        variant: fullProductData[i].variant || []
                                    });
                                }
                            } else {
                                categorySpecificProduct[fullProductData[i].category].push(fullProductData[i]);
                                if (fullProductData[i] && fullProductData[i].svangProductName) {
                                    categorySpecificSvangProduct[fullProductData[i].category].push({
                                        name: fullProductData[i].svangProductName,
                                        id: fullProductData[i].svangProductId,
                                        categoryName: fullProductData[i].svangCategoryName,
                                        variant: fullProductData[i].variant || []
                                    });
                                }
                            }
                        }
                    }
                    
                    let finalCategoryProduct = [];
                    for (const [key, value] of Object.entries(categorySpecificProduct)) {
                        finalCategoryProduct.push({
                            category: key,
                            product: value,
                            isOpen: false,
                            svangProduct: categorySpecificSvangProduct[key]
                        });
                    }
                    self.setState({
                        finalCategoryProduct: finalCategoryProduct
                    })
                } else {
                    self.setState({
                        finalCategoryProduct: []
                    });
                    // toast.error(result.data.statusText, {
                    //     autoClose: 2000,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    // });
                }
                self.setState({ submitProgress: false });
            })
            .catch(error => {
                self.setState({ submitProgress: false, finalCategoryProduct: [] });
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            })
        } catch (error) {
            self.setState({ submitProgress: false, finalCategoryProduct: [] });
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } 
    }
    openExpand(index) {
        const tmpProd = this.state.finalCategoryProduct;
        // tmpProd[index].isOpen = !tmpProd[index].isOpen;
        for (let i = 0; i < tmpProd.length; i += 1 ) {
            if (i === index) {
                tmpProd[i].isOpen = !tmpProd[i].isOpen;
            } else {
                tmpProd[i].isOpen = false;
            }
        }
        this.setState({
            finalCategoryProduct: tmpProd,
            openCategoryName: tmpProd[index].category,
            svangProductData: tmpProd[index].svangProduct
        });
    }
    
    onOpenModal(e, selectCategoryName) {
        e.preventDefault();
        this.setState({ opneModal: true, selectCategoryName:  selectCategoryName});
    }

    onCloseModal() {
        this.setState({ opneModal: false, selectCategoryName:  "", productsData: JSON.parse(this.state.strProductsData), selectedFoodItems: JSON.parse(this.state.strSelectedFoodItems)});
    }
    onFoodItemSave(selectedFoodItems) {
        let tmpProductData = [];
        let self = this;
        selectedFoodItems.forEach(foodItem => {
            if (foodItem.product.length > 0) {
                foodItem.product.forEach(product => {
                    tmpProductData.push({
                        name: product.name,
                        id: product.id,
                        categoryName: foodItem.categoryName
                    });
                });
            }
        });
        let finalCategoryProduct = this.state.finalCategoryProduct;
        const selectCategoryName = this.state.selectCategoryName;
        let tmpsvangProductData = self.state.svangProductData;
        for (let i = 0; i < finalCategoryProduct.length; i += 1) {
            if (finalCategoryProduct[i].category === selectCategoryName) {
                if (!(finalCategoryProduct[i].svangProduct && finalCategoryProduct[i].svangProduct.length)) {
                    finalCategoryProduct[i].svangProduct = tmpProductData;
                    tmpsvangProductData = finalCategoryProduct[i].svangProduct;
                    
                } else {
                    for (let ii = 0; ii < tmpProductData.length; ii += 1) {
                        finalCategoryProduct[i].svangProduct.push(tmpProductData[ii]);
                        tmpsvangProductData = finalCategoryProduct[i].svangProduct;
                    }
                }
            }
        }
        this.setState({
            opneModal: false,
            selectCategoryName: '',
            svangProductData: tmpsvangProductData,
            productsData: JSON.parse(this.state.strProductsData),
            finalCategoryProduct: finalCategoryProduct,
            selectedFoodItems: JSON.parse(this.state.strSelectedFoodItems)
        });
    }
    removeProduct(mainIndex, index) {
        let self = this;
        const tmpsvangProductData = self.state.svangProductData;
        tmpsvangProductData.splice(index, 1);
        self.setState({
            svangProductData: tmpsvangProductData
        });
    }
    copyProduct(mainIndex, index, data) {
        let self = this;
        const finalCategoryProduct = self.state.finalCategoryProduct;
        finalCategoryProduct[mainIndex].svangProduct.splice(index, 0, data);
        // self.setState({
        //     finalCategoryProduct: finalCategoryProduct
        // });
        // const tmpsvangProductData = self.state.svangProductData;
        // tmpsvangProductData.splice(index, 0, data);
        self.setState({
            svangProductData: finalCategoryProduct[mainIndex].svangProduct,
            finalCategoryProduct: finalCategoryProduct
        });
    }
    openVariantModal(e, mainIndex, index, data) {
        e.preventDefault();
        const fullProductsData = this.state.fullProductsData;
        let singleProduct = {};
        if (fullProductsData && fullProductsData.length) {
            for (let i = 0; i < fullProductsData.length; i += 1) {
                if (fullProductsData[i].id === data.id) {
                    singleProduct = fullProductsData[i];
                    singleProduct.mainIndex = mainIndex;
                    singleProduct.variant = data.variant || [];
                    singleProduct.index = index;
                    break;
                }
            }
        }
        this.setState({ variantOpenModal: true, singleProduct: singleProduct});
    };
    closeVariantModal(data) {
        this.setState({ variantOpenModal: false, singleProduct: {}});
    }
    onFoodVarianSave(data, index) {
        let self = this;
        const tmpsvangProductData = JSON.stringify(self.state.svangProductData);
        const tmpsvangProductData1 = JSON.parse(tmpsvangProductData);
        if (!(tmpsvangProductData1[index].variant && tmpsvangProductData1[index].variant.length)) {
            tmpsvangProductData1[index].variant = data.variant;
        } else {
            tmpsvangProductData1[index].variant = data.variant;
        }
        self.setState({
            svangProductData: tmpsvangProductData1,
            variantOpenModal: true,
            singleProduct: {}
        })
    }
    async onSaveProduct(e, productData) {
        let self = this;
        self.setState({isSubmitUpdate: true});
        let url = `menu/update/thirdparty`;
        try {
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            productData.svangProduct = self.state.svangProductData;
            const requestData = {
                orderSource: self.state.basicDetails.orderSource.value,
                vendorId: vendorId,
                productData: productData
            };
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.onSaveProduct(e, productData);
                    return;
                }
                if (result.data.status) {
                    toast.success(`${self.state.openCategoryName} category successfully mapping.`, {
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    toast.error(result.data.statusText, {
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                self.setState({isSubmitUpdate: false});
            })
            .catch(error => {
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({isSubmitUpdate: false});
            })
        }
        catch (error) {
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({isSubmitUpdate: false});
        }
    }
    render() {
        const {
            orderSourceList,
            basicDetails,
            finalCategoryProduct,
            opneModal,
            productsData,
            selectedFoodItems,
            isSubmitUpdate,
            svangProductData,
            variantOpenModal,
            singleProduct
        } = { ...this.state }
        var self = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
              const data1 = [...svangProductData];
              const item = data1.splice(fromIndex, 1)[0];
              data1.splice(toIndex, 0, item);
              self.setState({ svangProductData: data1 });
            },
            nodeSelector: 'p',
            handleSelector: 'a'
          };
        return (
            <div className="row">
                <div className="col-md-12">
                    <div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="orderSource" className="required">Order Source</label>
                                <div className="input-group">
                                    <select
                                        className="form-control"
                                        id="orderSource"
                                        onChange={this.onBasicDetailsChange}
                                        value={basicDetails.orderSource.value}
                                        >
                                        <option value="">Select</option>
                                        {orderSourceList.map((sourceName, index) => (
                                            <option key={index} value={sourceName.key}>{sourceName.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="accordion">
                                    {finalCategoryProduct && finalCategoryProduct.length === 0 &&
                                        <h5 className="text-center">No Items Found.</h5>
                                    }
                                    {finalCategoryProduct && finalCategoryProduct.length > 0 && finalCategoryProduct.map((productData, cIndex) => (
                                        productData && productData.product && productData.product.length > 0 &&
                                            <div className="card" key={`card-${cIndex}`}>
                                                <div className="card-header" id={"scroll-category-"+cIndex}>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(cIndex)}>
                                                        <strong>{productData.category} {productData.isOpen}</strong>
                                                        <span className="float-right">{productData.svangProduct.length}/{productData.product.length}</span>
                                                    </a>
                                                </div>
                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="row m-0">
                                                        <div className="col-md-12 border-bottom-menumapping">
                                                            <button className="btn btn-primary has-icon float-right btn-sm mt-2 mb-2" onClick={(e) => this.onOpenModal(e, productData.category)}><i className="fa fa-plus fa-xs"></i> Add Products</button>
                                                            {!isSubmitUpdate && 
                                                                <button className="btn btn-primary has-icon float-right btn-sm mt-2 mb-2 mr-3" onClick={(e) => this.onSaveProduct(e, productData)}><i className="fa fa-plus fa-xs"></i> Save</button>
                                                            }
                                                            {isSubmitUpdate &&
                                                                <button className="btn btn-primary has-icon float-right btn-sm mt-2 mb-2 mr-3"><i className="fas fa-spinner fa-pulse fa-xs"></i> Save</button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card-body p-0">
                                                                {productData && productData.product && productData.product.map((item, pIndex) => (
                                                                    <span className="card-body-grid" key={`${cIndex}-${pIndex}`}>
                                                                        <span>{item.name}</span>
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card-body p-0">
                                                                <ReactDragListView {...dragProps}>
                                                                {svangProductData.map((item, sIndex) => (
                                                                    <p className="card-body-grid mb-0" key={`${sIndex}`}>
                                                                        <a href="/#" className="mr-2"><i className="fas fa-grip-vertical"></i></a>
                                                                        <span>{item.name}</span>
                                                                        <span className="float-right pointer" onClick={() => this.removeProduct(cIndex, sIndex)}><i className="fas fa-trash text-danger"></i></span>
                                                                        <span className="float-right mr-2 pointer" onClick={() => this.copyProduct(cIndex, sIndex, item)}><i className="fa fa-copy text-muted"></i></span>
                                                                        <a href="/#" className="float-right pointer mr-2" onClick={(e) => this.openVariantModal(e, cIndex, sIndex, item)}>Add Variant</a>
                                                                        <span className="d-block ml-3">
                                                                            {item.variant && item.variant.length > 0 && item.variant.map((vRow, vIndex) => (
                                                                                <span className="variant" key={vIndex}>{vRow.name}{vRow.type ? `(${vRow.type})`: ""}
                                                                                { item && item.variant && (item.variant.length-1) !== vIndex && 
                                                                                    <span>, </span>
                                                                                }
                                                                                </span>
                                                                            ))}
                                                                        </span>
                                                                    </p>
                                                                ))}
                                                                </ReactDragListView>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        {productsData && productsData.length > 0 && opneModal &&
                            <FoodItemGroup productsData={productsData}
                                opneModal={opneModal}
                                selectedFoodItems={selectedFoodItems}
                                title="Product List"
                                close={this.onCloseModal}
                                save={this.onFoodItemSave}>
                            </FoodItemGroup>
                        }
                        {Object.keys(singleProduct).length > 0 && variantOpenModal &&
                            <FoodItemVariant productsData={productsData}
                                opneModal={variantOpenModal}
                                singleProduct={singleProduct}
                                title={singleProduct.name}
                                index={singleProduct.index}
                                close={this.closeVariantModal}
                                save={this.onFoodVarianSave}>
                            </FoodItemVariant>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MenuMappingDetails);