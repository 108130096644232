import React from 'react';
// import { Link } from "react-router-dom";
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';

import 'react-responsive-modal/styles.css';

import FoodItemGroup from '../../FoodItemsGroup';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';

class EditCoupon extends React.Component {
    constructor(props) {
        super(props);
        const initialCouponData = {
            couponName: "",
            couponCode: "",
            couponValidFor: [],
            shortDescription: "",
            longDescription: "",
            fromDate: "",
            validuptoDate: "",
            discountType: "percentageOff",
            numRedemptions: "",
            takeOff: "",
            orderAbove: "",
            upto: "",
            autoGenerateCode: false,
            state: "inactive",
            restrictDays: [],
            selectedFoodItems: []
        }
        const initialCouponErrorData = {
            couponName: "",
            couponCode: "",
            shortDescription: "",
            fromDate: "",
            validuptoDate: "",
            numRedemptions: "",
            takeOff: "",
            orderAbove: "",
            upto: ""
        }
        const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const couponValidFor = ["Delivery", "Pickup"];
        this.state = {
            reloadCouponsTable: false,
            couponData: JSON.parse(JSON.stringify(initialCouponData)),
            couponErrorData: JSON.parse(JSON.stringify(initialCouponErrorData)),
            submitProgress: false,
            openModal: false,
            initialCouponData,
            initialCouponErrorData,
            weekdays: weekdays,
            couponValidFor: couponValidFor,
            opneModal: false,
            productsData: [],
            tempSelectedFoodItems: [],
            selectedFoodItemsToShow: []
        }
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmitToUpdate = this.onFormSubmitToUpdate.bind(this);
        this.showConfirmationBox = this.showConfirmationBox.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onFoodItemSave = this.onFoodItemSave.bind(this);
        this.clickCoupoun = this.clickCoupoun.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
    }

    componentDidMount() {
        this.getProductsList();
        this.getSingleCouponAllDetails();
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickCoupoun() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/marketing_management?tab=promotions`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/marketing_management?tab=promotions`);
    }

    isInt(value) {
        var x = parseFloat(value);
        return !isNaN(value) && (x | 0) === x;
    }

    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.couponData;
        let formErrors = this.state.couponErrorData;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "couponName" && value === "") || (formSubmit === true && formDetails["couponName"] === "")) {
            fieldName = 'couponName';
            formErrors[fieldName] = `The 'Coupon name' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "couponName" && value !== "") || (formSubmit === true && formDetails["couponName"] !== "")) {
            if (!(/[a-zA-Z]/.test(formDetails["couponName"]))) {
                fieldName = 'couponName';
                formErrors[fieldName] = `Coupon name is not in valid format.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "shortDescription" && value === "")
            || (formSubmit === true && formDetails["shortDescription"] === "")) {
            fieldName = 'shortDescription';
            formErrors[fieldName] = `The 'Short description' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "shortDescription" && value !== "")
            || (formSubmit === true && formDetails["shortDescription"] !== "")) {
            if (!(/[a-zA-Z]/.test(formDetails["shortDescription"]))) {
                fieldName = 'shortDescription';
                formErrors[fieldName] = `The 'Short description' is not in a valid format.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "numRedemptions" && value === "")
            || (formSubmit === true && formDetails["numRedemptions"] === "")) {
            fieldName = 'numRedemptions';
            formErrors[fieldName] = `The 'Number of redemptions' is a required field and must be a valid number.`;
            isError = true;
        }
        else if ((formSubmit === false && fieldName === "numRedemptions" && !this.isInt(value)) || (formSubmit === true && !this.isInt(formDetails["numRedemptions"]))) {
            fieldName = 'numRedemptions';
            formDetails[fieldName] = "";
        }
        else if ((formSubmit === false && fieldName === "numRedemptions" && value < 0) || (formSubmit === true && formDetails["numRedemptions"] < 0)) {
            fieldName = 'numRedemptions';
            formDetails[fieldName] = "";
        }
        if ((formSubmit === false && fieldName === "validuptoDate" && (value === "" || value === null))
            || (formSubmit === true && (formDetails["validuptoDate"] === "" || formDetails["validuptoDate"] === null))) {
            fieldName = 'validuptoDate';
            formErrors[fieldName] = `The 'Valid up to date' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "validuptoDate" && value !== "" && value !== null)
            || (formSubmit === true && formDetails["validuptoDate"] !== "" && formDetails["validuptoDate"] !== null)) {
            if (formDetails["validuptoDate"] !== "" && formDetails["fromDate"] !== "") {
                if (formDetails["fromDate"] > formDetails["validuptoDate"]) {
                    fieldName = 'validuptoDate';
                    formErrors[fieldName] = `The 'To' date cannot be earlier than the 'From' date.`;
                    isError = true;
                } else {
                    formErrors['validuptoDate'] = "";
                    formErrors['fromDate'] = "";
                }
            }
        }
        if ((formSubmit === false && fieldName === "fromDate" && (value === "" || value === null))
            || (formSubmit === true && (formDetails["fromDate"] === "" || formDetails["fromDate"] === null))) {
            fieldName = 'fromDate';
            formErrors[fieldName] = `The 'From date' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "fromDate" && (value !== "" || value !== null))
            || (formSubmit === true && (formDetails["fromDate"] !== "" || formDetails["fromDate"] !== null))) {
            if (formDetails["validuptoDate"] !== "" && formDetails["fromDate"] !== "") {
                if (formDetails["fromDate"] > formDetails["validuptoDate"]) {
                    fieldName = 'fromDate';
                    formErrors[fieldName] = `The 'From' date cannot be later than the 'To' date.`;
                    isError = true;
                } else {
                    formErrors['validuptoDate'] = "";
                    formErrors['fromDate'] = "";
                }
            }
        }
        if (formDetails.discountType === "percentageOff") {
            if ((formSubmit === false && fieldName === "takeOff" && (value === "" || value === "N/A"))
                || (formSubmit === true && (formDetails["takeOff"] === "" || formDetails["takeOff"] === "N/A"))) {
                fieldName = 'takeOff';
                formErrors[fieldName] = `This is a required field and it must be a valid number.`;
                isError = true;

            }
            else if ((formSubmit === false && fieldName === "takeOff" && value !== "" && isNaN(value))
                || (formSubmit === true && formDetails["takeOff"] !== "" && isNaN(formDetails["takeOff"]))) {
                fieldName = 'takeOff';
                formDetails[fieldName] = "";
            }
            else if ((formSubmit === false && fieldName === "takeOff" && value !== "" && value > 100)
                || (formSubmit === true && formDetails["takeOff"] !== "" && formDetails["takeOff"] > 100)) {
                fieldName = 'takeOff';
                formErrors[fieldName] = `It is an invalid value.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "takeOff" && value !== "" && value <= 0)
                || (formSubmit === true && formDetails["takeOff"] !== "" && formDetails["takeOff"] <= 0)) {
                fieldName = 'takeOff';
                formErrors[fieldName] = `It is an invalid value.`;
                isError = true;
            }
        }
        if (formDetails.discountType === "amountOff") {
            if ((formSubmit === false && fieldName === "takeOff" && (value === "" || value === "N/A"))
                || (formSubmit === true && (formDetails["takeOff"] === "" || formDetails["takeOff"] === "N/A"))) {
                fieldName = 'takeOff';
                formErrors[fieldName] = `It is a required field and it must be a valid number.`;
                isError = true;
            }
            else if ((formSubmit === false && fieldName === "takeOff" && value !== "" && isNaN(value))
                || (formSubmit === true && formDetails["takeOff"] !== "" && isNaN(formDetails["takeOff"]))) {
                fieldName = 'takeOff';
                formDetails[fieldName] = "";
            }
            else if ((formSubmit === false && fieldName === "takeOff" && value <= 0)
                || (formSubmit === true && formDetails["takeOff"] <= 0)) {
                fieldName = 'takeOff';
                formDetails[fieldName] = "";
            }

            if (
                (formSubmit === false && (fieldName === "orderAbove" || fieldName === "takeOff") && (formDetails["orderAbove"] !== "" && formDetails["takeOff"] !== ""))
                || (formSubmit === true && (formDetails["orderAbove"] !== "" && formDetails["takeOff"] !== ""))
            ) {
                if (parseInt(formDetails["takeOff"]) > parseInt(formDetails["orderAbove"])) {
                    fieldName = 'takeOff';
                    formErrors[fieldName] = `The 'Take off amount' should be less than the 'order amount'.`;
                    isError = true;
                } else {
                    fieldName = 'takeOff';
                    formErrors[fieldName] = ``;
                }
            }
        }

        if ((formSubmit === false && fieldName === "orderAbove" && value <= 0) || (formSubmit === true && formDetails["orderAbove"] <= 0)) {
            fieldName = 'orderAbove';
            formDetails[fieldName] = "";
        } else if ((formSubmit === false && fieldName === "orderAbove" && isNaN(value)) || (formSubmit === true && isNaN(formDetails["orderAbove"]))) {
            fieldName = 'orderAbove';
            formDetails[fieldName] = "";
        }

        // if ((formSubmit === false && fieldName === "upto" && value <= 0) || (formSubmit === true && formDetails["upto"] <= 0)) {
        //     fieldName = 'upto';
        //     formDetails[fieldName] = "";
        // } else if ((formSubmit === false && fieldName === "upto" && isNaN(value)) || (formSubmit === true && isNaN(formDetails["upto"]))) {
        //     fieldName = 'upto';
        //     formDetails[fieldName] = "";
        // }
        this.setState({ couponErrorData: formErrors });
        this.setState({ couponData: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        let formDetails = this.state.couponData;
        let formErrors = this.state.couponErrorData;
        let fieldName = e.target.id;
        let fieldValue = "";
        if (e.target.type === "checkbox") {
            fieldValue = e.target.checked;
            if (e.target.checked && e.target.name === "autoGenerateCode") {
                formDetails['couponCode'] = "";
            }
        } else {
            fieldValue = e.target.value;
        }
        formDetails[fieldName] = fieldValue;
        this.setState({ couponData: formDetails, isOpenFooter: true });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ couponErrorData: formErrors });
        }
    }

    onDateChange(value, fieldName) {
        let formDetails = this.state.couponData;
        let formErrors = this.state.couponErrorData;
        formDetails[fieldName] = value;
        this.setState({ couponData: formDetails, isOpenFooter: true });
        let isError = this.checkValidation(fieldName, value);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ couponErrorData: formErrors });
        }
    }

    showConfirmationBox(e) {
        e.preventDefault();
        let self = this;
        if (self.state.couponData.state === "active") {
            Swal.fire({
                title: 'Are you sure?',
                text: "Once you set the coupon status as 'Active', you will not be be able to Edit or Delete this coupon unless you deactivate it.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                cancelButtonText: 'Cancel'
            }).then(async (result) => {
                if (result.value) {
                    this.onFormSubmitToUpdate()
                }
            });
        } else {
            this.onFormSubmitToUpdate()
        }
    }

    onOpenModal(e) {
        e.preventDefault();
        let allProducts = this.state.productsData;
        let tempCouponData = this.state.couponData;
        let tempSelectedFoodItems = this.state.tempSelectedFoodItems;
        if (tempCouponData.selectedFoodItems.length === 0) {
            tempCouponData.selectedFoodItems = tempSelectedFoodItems;
            this.setState({ couponData: tempCouponData });
        } else {
            allProducts.forEach(productData => {
                let index = tempCouponData.selectedFoodItems.findIndex(sItem => {
                    return sItem.categoryName === productData.categoryName
                })
                if (index === -1) {
                    tempCouponData.selectedFoodItems.push({
                        categoryName: productData.categoryName,
                        product: [],
                        allProductSelected: false
                    })
                } else {
                    let isAllProductSelectd = tempCouponData.selectedFoodItems[index].product.length === productData.product.length
                    tempCouponData.selectedFoodItems[index].allProductSelected = isAllProductSelectd;
                }
            });
        }
        this.setState({ opneModal: true });
    }

    onCloseModal() {
        this.setState({ opneModal: false });
    }

    onFoodItemSave(selectedFoodItems) {
        let dataToShow = [];
        this.setState({ opneModal: false });
        let tempCouponData = this.state.couponData;
        tempCouponData.selectedFoodItems = selectedFoodItems;
        selectedFoodItems.forEach(foodItem => {
            if (foodItem.product.length > 0) {
                let items = [];
                foodItem.product.forEach(product => {
                    items.push(product.name);
                })
                dataToShow.push({ categoryName: foodItem.categoryName, items: items.join(', ') })
            }
        })
        this.setState({ selectedFoodItemsToShow: dataToShow });
        this.setState({ couponData: tempCouponData });
    }

    async getSingleCouponAllDetails() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorTimezone = vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
        let couponId = self.props.match.params.couponId;
        let url = `coupon/${vendor.id}/${couponId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getSingleCouponAllDetails();
                        return;
                    }
                    if (result.data.status) {
                        let data = result.data.response ? result.data.response : {};
                        let fromDate = data?.fromDate && data.fromDate !== "" ? moment(data.fromDate).tz(vendorTimezone).toDate() : "";
                        let validuptoDate = data?.validuptoDate && data.validuptoDate ? moment(data.validuptoDate).tz(vendorTimezone).toDate() : "";
                        if (data.selectedFoodItems.length > 0) {
                            let dataToShow = [];
                            data.selectedFoodItems.forEach(foodItem => {
                                let items = [];
                                foodItem.product.forEach(product => {
                                    items.push(product.name);
                                })
                                dataToShow.push({ categoryName: foodItem.categoryName, items: items.join(', ') })
                            })
                            this.setState({ selectedFoodItemsToShow: dataToShow });
                        }
                        if (data.couponValidFor === undefined) {
                            data.couponValidFor = ["Delivery", "Pickup"];
                        }
                        this.setState({ couponData: { ...data, fromDate, validuptoDate } });
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    toast.error("Oops! We encountered a problem in deleting the coupon. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting coupon: ", error);
                })
        }
        catch (error) {
            toast.error("Oops! We encountered a problem in deleting the coupon. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in deleting coupon: ", error);
        }
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var selectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: []
                            });
                            selectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    manageProductData[ii].product.push({ id: products[i].id, name: products[i].name });
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ tempSelectedFoodItems: selectedFoodItems });
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching previous days orders: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching previous days orders: ", error);
        }
    }

    async onFormSubmitToUpdate() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            let vendorTimezone = vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "" ? vendor.vendorAllDetails.tz : "America/New_York";
            if (vendorId) {
                let url = `coupon?db=mongodb`;
                let requestData = {};
                Object.keys(self.state.couponData).forEach(function (key) {
                    if (key === "validuptoDate") {
                        requestData[key] = moment(self.state.couponData[key]).endOf('day').tz(vendorTimezone).endOf('day').format();
                    } else if (key === "fromDate" && self.state.couponData[key] !== "") {
                        if (self.state.couponData[key] !== "" && self.state.couponData[key] !== null) {
                            requestData[key] = moment(self.state.couponData[key]).startOf('day').tz(vendorTimezone).startOf('day').format();
                        } else {
                            requestData[key] = "";
                        }
                    } else if (key === "selectedFoodItems" && self.state.couponData[key] !== "") {
                        let tempSelectedFoodItems = [];
                        if (self.state.couponData['discountType'] === "amountOff") {
                            self.state.couponData[key].forEach(selectedItem => {
                                if (selectedItem.product.length > 0) {
                                    tempSelectedFoodItems.push({ categoryName: selectedItem.categoryName, product: selectedItem.product })
                                }
                            })
                        }
                        requestData[key] = tempSelectedFoodItems;
                    } else {
                        requestData[key] = self.state.couponData[key];
                    }
                });
                requestData['vendorId'] = vendorId;
                requestData['couponId'] = self.props.match.params.couponId;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.put(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                self.onFormSubmitToUpdate();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("Success: Coupon updated!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                                self.props.history.push(
                                    {
                                        pathname: "/marketing_management",
                                        search: '?tab=promotions'
                                    });
                            } else {
                                self.setState({ submitProgress: false });
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in updating coupon details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating coupon details: ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in the correct format and try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        let { couponData, couponErrorData, submitProgress, productsData, selectedFoodItemsToShow, isShowFooter, isOpenFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div>
                <ReactTooltip />
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Edit Coupon</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                {/* eslint-disable-next-line */}
                                <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                {/* eslint-disable-next-line */}
                                <li className="breadcrumb-item"><a className="common-link" onClick={this.clickCoupoun}>Marketing</a></li>
                                {/* <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li> */}
                                {/* <li className="breadcrumb-item"><Link to="/marketing_management?tab=promotions">Marketing</Link></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Edit Coupon</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="col-12">
                        <form method="post" className="form-coupon">
                            <div className="form-row">
                                <div className="col-12">
                                    <div className="ms-panel custom-panel">
                                        <div className="ms-panel-header">
                                            <div className="d-flex">
                                                <h5 className="mb-0">Setup</h5>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ms-panel-body">
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlFor="name" className="required">Name</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className={"form-control " + (couponErrorData.couponName !== "" ? 'invalid' : '')}
                                                            id="couponName"
                                                            placeholder="Coupon Name"
                                                            value={couponData.couponName ? couponData.couponName : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            maxLength={50}
                                                        />
                                                        {couponErrorData.couponName && couponErrorData.couponName !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.couponName}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="name" className="required">Code</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className={"form-control " + (couponErrorData.couponCode !== "" ? 'invalid' : '')}
                                                            id="couponCode"
                                                            placeholder="Coupon Code"
                                                            value={couponData.couponCode ? couponData.couponCode : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            readOnly={couponData.autoGenerateCode}
                                                            maxLength={15} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="name" className="required">Short Description</label>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            className={"form-control " + (couponErrorData.shortDescription !== "" ? 'invalid' : '')}
                                                            id="shortDescription"
                                                            placeholder="Short Description"
                                                            value={couponData.shortDescription ? couponData.shortDescription : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            maxLength={50}
                                                        />
                                                        {couponErrorData.shortDescription && couponErrorData.shortDescription !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.shortDescription}</div>}
                                                    </div>
                                                    <label htmlFor="name">Coupon Status</label>
                                                    <div className="input-group">
                                                        <ul className="ms-list d-flex">
                                                            <li className="ms-list-item pl-0 mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="radio"
                                                                        name="radioExample1"
                                                                        value="active"
                                                                        checked={couponData.state === "active"}
                                                                        title="Once you set coupon status as Active, you won't be able to Edit/Delete this coupon unless you deactivate it."
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            tempCouponData.state = event.currentTarget.value;
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> Active </span>
                                                            </li>
                                                            <li className="ms-list-item mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="radio"
                                                                        name="radioExample1"
                                                                        value="inactive"
                                                                        checked={couponData.state === "inactive"}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            tempCouponData.state = event.currentTarget.value;
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> In-active </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="description">Additional Description</label>
                                                    <div className="input-group">
                                                        <textarea
                                                            rows="4"
                                                            id="longDescription"
                                                            name="longDescription"
                                                            className="form-control"
                                                            placeholder="Message"
                                                            value={couponData.longDescription ? couponData.longDescription : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}
                                                            maxLength={400}
                                                        ></textarea>
                                                        {couponErrorData.longDescription && couponErrorData.longDescription !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.longDescription}</div>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="ms-panel custom-panel">
                                        <div className="ms-panel-header">
                                            <div className="d-flex">
                                                <h5 className="mb-0">Discount</h5>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ms-panel-body">
                                            <div className="form-row">
                                                <div className="col-lg-6 col-md-12">
                                                    <label htmlFor="name" className="required">Discount Type</label>
                                                    <div className="input-group">
                                                        <ul className="ms-list d-flex">
                                                            <li className="ms-list-item pl-0 mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="radio"
                                                                        name="radioExample"
                                                                        value="percentageOff"
                                                                        checked={couponData.discountType === "percentageOff"}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            let tempCouponErrorData = this.state.couponErrorData;
                                                                            tempCouponData.discountType = event.currentTarget.value
                                                                            tempCouponData.takeOff = "";
                                                                            tempCouponData.orderAbove = "";
                                                                            tempCouponData.upto = "";
                                                                            tempCouponErrorData.takeOff = "";
                                                                            tempCouponErrorData.orderAbove = "";
                                                                            tempCouponErrorData.upto = "";
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                            this.setState({ couponErrorData: tempCouponErrorData })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> Percentage Off </span>
                                                            </li>
                                                            <li className="ms-list-item  mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="radio"
                                                                        name="radioExample"
                                                                        value="amountOff"
                                                                        checked={couponData.discountType === "amountOff"}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            let tempCouponErrorData = this.state.couponErrorData;
                                                                            tempCouponData.discountType = event.currentTarget.value
                                                                            tempCouponData.takeOff = "";
                                                                            tempCouponData.orderAbove = "";
                                                                            tempCouponData.upto = "";
                                                                            tempCouponErrorData.takeOff = "";
                                                                            tempCouponErrorData.orderAbove = "";
                                                                            tempCouponErrorData.upto = "";
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                            this.setState({ couponErrorData: tempCouponErrorData })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> Amount Off </span>
                                                            </li>
                                                            <li className="ms-list-item  mb-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="radio"
                                                                        name="radioExample"
                                                                        value="freeDelivery"
                                                                        checked={couponData.discountType === "freeDelivery"}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            let tempCouponErrorData = this.state.couponErrorData;
                                                                            tempCouponData.discountType = event.currentTarget.value
                                                                            tempCouponData.takeOff = "";
                                                                            tempCouponData.orderAbove = "";
                                                                            tempCouponData.upto = "";
                                                                            tempCouponErrorData.takeOff = "";
                                                                            tempCouponErrorData.orderAbove = "";
                                                                            tempCouponErrorData.upto = "";
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                            this.setState({ couponErrorData: tempCouponErrorData })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span> Free delivery </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {couponData.discountType === "percentageOff" && <div className="col-lg-4 col-md-7 div-perncetage-off">
                                                    <label htmlFor="name" className="required">Discount Details</label>
                                                    <div className="input-group custom-input-group">
                                                        <p>Take off </p>
                                                        <input type="text"
                                                            min="1"
                                                            max="100"
                                                            className={"form-control " + (couponErrorData.takeOff !== "" ? 'invalid' : '')}
                                                            id="takeOff"
                                                            name="takeOff"
                                                            value={couponData.takeOff !== null ? couponData.takeOff : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}>
                                                        </input>
                                                        <p>% up to $</p>
                                                        <input type="text"
                                                            className={"form-control " + (couponErrorData.upto !== "" ? 'invalid' : '')}
                                                            name="upto"
                                                            id="upto"
                                                            value={couponData.upto !== null ? couponData.upto : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}></input>
                                                        <p> on an order of $</p>
                                                        <input type="text"
                                                            min="1"
                                                            className={"form-control " + (couponErrorData.orderAbove !== "" ? 'invalid' : '')}
                                                            name="orderAbove"
                                                            id="orderAbove"
                                                            value={couponData.orderAbove !== null ? couponData.orderAbove : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}></input>
                                                        <p>or more</p>
                                                        {couponErrorData.takeOff && couponErrorData.takeOff !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.takeOff}</div>}
                                                    </div>
                                                </div>}
                                                {couponData.discountType === "amountOff" && <div className="col-lg-4 col-md-7 div-amount-off">
                                                    <label htmlFor="name" className="required">Discount Details</label>
                                                    <div className="input-group custom-input-group">
                                                        <p>Take off $</p>
                                                        <input type="text"
                                                            min="1"
                                                            className={"form-control " + (couponErrorData.takeOff !== "" ? 'invalid' : '')}
                                                            name="takeOff"
                                                            id="takeOff"
                                                            value={couponData.takeOff !== null ? couponData.takeOff : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}>
                                                        </input>
                                                        <p>on an order of $</p>
                                                        <input type="text"
                                                            min="1"
                                                            className={"form-control " + (couponErrorData.orderAbove !== "" ? 'invalid' : '')}
                                                            name="orderAbove"
                                                            id="orderAbove"
                                                            value={couponData.orderAbove !== null ? couponData.orderAbove : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}></input>
                                                        <p>or more</p>
                                                        {couponErrorData.takeOff && couponErrorData.takeOff !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.takeOff}</div>}
                                                    </div>
                                                </div>}
                                                {couponData.discountType === "freeDelivery" && <div className="col-lg-3 col-md-7 div-free-delivery">
                                                    <label htmlFor="name">Discount Details</label>
                                                    <div className="input-group custom-input-group">
                                                        <p>Free delivery on an order of $</p>
                                                        <input type="text"
                                                            min="1"
                                                            className={"form-control " + (couponErrorData.orderAbove !== "" ? 'invalid' : '')}
                                                            name="orderAbove"
                                                            id="orderAbove"
                                                            value={couponData.orderAbove !== null ? couponData.orderAbove : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut}></input>
                                                        <p>or more</p>
                                                    </div>
                                                </div>}

                                                <div className="col-lg-2 col-md-5 div-specific-item">
                                                    <label htmlFor="name"></label>
                                                    {productsData && productsData.length > 0 && couponData.discountType === "amountOff" && <div className="input-group custom-input-group">
                                                        <button type="submit" className="btn btn-primary shadow-none mt-1" onClick={this.onOpenModal}>
                                                            Select specific item
                                                                </button>
                                                    </div>}
                                                </div>

                                                <div className="col-lg-6 col-md-6"></div>
                                                <div className="col-lg-6 col-md-6 selected-food-items mt-2 custom-table">
                                                    {couponData.discountType === "amountOff" && selectedFoodItemsToShow.length > 0 && <div className="ms-panel">
                                                        <div className="ms-panel-header">
                                                            <div className="d-flex justify-content-between category-food">
                                                                <div className="align-self-center align-left">
                                                                    <h6>category</h6>
                                                                </div>
                                                                <div className="align-self-center align-right">
                                                                    <h6>Selected Food Items</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-panel-body category-food-list" style={{ height: selectedFoodItemsToShow.length > 1 ? '150px' : '100px' }}>
                                                            {selectedFoodItemsToShow.map((foodItem, index) => (
                                                                <div className="select-food_list-item d-flex justify-content-between" key={`selected-item-${index}`}>
                                                                    <div className="food_c-name">
                                                                        <h6>{foodItem.categoryName}</h6>

                                                                    </div><div className="food_c-content">
                                                                        <p>{foodItem.items}</p>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="ms-panel custom-panel">
                                        <div className="ms-panel-header">
                                            <div className="d-flex">
                                                <h5 className="mb-0">Restrictions</h5>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="ms-panel-body">
                                            <div className="form-row">
                                                <div className="col-lg-6 col-md-6 mb-2">
                                                    <label className="required">From Date</label>
                                                    <div className="input-group">
                                                        <DatePicker dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                            className={"form-control " + (couponErrorData.fromDate !== "" ? 'invalid' : '')}
                                                            id="fromDate"
                                                            name="fromDate"
                                                            format="MM-dd-y"
                                                            value={couponData.fromDate}
                                                            onChange={(value) => { this.onDateChange(value, "fromDate") }}
                                                        />
                                                        {couponErrorData.fromDate && couponErrorData.fromDate !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.fromDate}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mb-2">
                                                    <label className="required">To Date</label>
                                                    <div className="input-group">
                                                        <DatePicker dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                            className={"form-control " + (couponErrorData.validuptoDate !== "" ? 'invalid' : '')}
                                                            id="validuptoDate"
                                                            name="validuptoDate"
                                                            format="MM-dd-y"
                                                            value={couponData.validuptoDate}
                                                            onChange={(value) => { this.onDateChange(value, "validuptoDate") }}
                                                        />
                                                        {couponErrorData.validuptoDate && couponErrorData.validuptoDate !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.validuptoDate}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mb-2">
                                                    <label htmlFor="name" className="required">Number of Redemptions</label>
                                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip="Enter 0 for infinite number of redemptions."></i>
                                                    <div className="input-group">
                                                        <input type="text"
                                                            min="0"
                                                            className={"form-control " + (couponErrorData.numRedemptions !== "" ? 'invalid' : '')}
                                                            id="numRedemptions"
                                                            name="numRedemptions"
                                                            placeholder="Enter 0 for infinite number of redemptions"
                                                            value={couponData.numRedemptions !== null ? couponData.numRedemptions : ""}
                                                            onChange={this.onDetailsChange}
                                                            onBlur={this.onFocusOut} />
                                                        {couponErrorData.numRedemptions && couponErrorData.numRedemptions !== "" &&
                                                            <div className="invalid-feedback">{couponErrorData.numRedemptions}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2 div-restrict-days">
                                                    <label htmlFor="name">Select day(s) for which coupons will be valid</label>
                                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip="Choose which day(s) of the week this discount will be valid."></i>
                                                    <div className="input-group">
                                                        {this.state.weekdays && this.state.weekdays.map((day, index) => (
                                                            <span className="ml-1 mr-3" key={`day-${index}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        value={day}
                                                                        checked={couponData.restrictDays.includes(day)}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            let index = tempCouponData.restrictDays.findIndex(((rDay, index) => {
                                                                                return rDay === event.currentTarget.value
                                                                            }))
                                                                            if (index === -1) {
                                                                                tempCouponData.restrictDays.push(event.currentTarget.value)
                                                                            } else {
                                                                                tempCouponData.restrictDays.splice(index, 1);
                                                                            }
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                        }} />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{day}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label htmlFor="name">Coupon Valid For</label>
                                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip="Choose which order type of this discount be valid."></i>
                                                    <div className="input-group">
                                                        {this.state.couponValidFor && this.state.couponValidFor.map((vRow, index) => (
                                                            <span className="ml-1 mr-3" key={`delivery-type-${index}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        value={vRow}
                                                                        checked={couponData.couponValidFor.includes(vRow)}
                                                                        onChange={(event) => {
                                                                            let tempCouponData = this.state.couponData;
                                                                            let index = tempCouponData.couponValidFor.findIndex(((rValue, index) => {
                                                                                return rValue === event.currentTarget.value
                                                                            }))
                                                                            if (index === -1) {
                                                                                tempCouponData.couponValidFor.push(event.currentTarget.value)
                                                                            } else {
                                                                                tempCouponData.couponValidFor.splice(index, 1);
                                                                            }
                                                                            this.setState({ couponData: tempCouponData, isOpenFooter: true })
                                                                        }} />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{vRow}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <button type="submit" className="btn btn-primary shadow-none float-right" onClick={(event) => this.showConfirmationBox(event)}>
                                Update {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </form>

                        {this.state.opneModal &&
                            <FoodItemGroup productsData={productsData}
                                opneModal={this.state.opneModal}
                                selectedFoodItems={couponData.selectedFoodItems}
                                close={this.onCloseModal}
                                save={this.onFoodItemSave}></FoodItemGroup>}

                    </div>
                </div>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={(event) => this.showConfirmationBox(event)}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default EditCoupon;