import React from 'react'

class NotAuthorized extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <h6>Selected feature is not available under your current subscription plan. Please upgrade your plan to avail other features.</h6>
        </div>
      </div>
    );
  }
}

export default NotAuthorized;