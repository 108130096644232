import React from 'react';
import queryString from 'query-string';
import { Link, withRouter} from "react-router-dom";
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import PromotionIndex from './promotions-index';
import CampaignIndex from './campaigns-index';

class MarketingManagement extends React.Component {
  constructor(props) {
    super(props);
    let activeTab = queryString.parse(this.props.location.search).tab;
    if(!activeTab || activeTab === ""){
      activeTab = "campaigns";
    }
    this.state = {
        couponsData: {
            data: null            
        },
        reloadCouponsTable: false,
        activeTab: activeTab
    }
    this.onEditCoupon = this.onEditCoupon.bind(this);
    this.onDeleteCoupon = this.onDeleteCoupon.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.getPromoCodes();
  }

  async getPromoCodes(){
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `coupon/${vendorId}?db=mongodb`;
    try {
      let token = getAuthTokenFromLocalStorage();
      if (!token) {
        let auth = await getAuth();
        if (auth && auth.success && auth.token) {
          localStorage.setItem('token', auth.token);
        }
      }
      axiosInstance.get(url)
        .then(result => {
          // If auth token expired
          if("success" in result.data && result.data.success === false){
            localStorage.removeItem('token');
            this.getPromoCodes();
            return;
          }
          let couponData = result.data.response;
          this.setState({ couponsData: { data: couponData}});
        })
        .catch(error => {
          this.setState({ couponsData: { data: []} })
          console.error("Problem in fetching coupons data: ",error);
        })
    }
    catch (error) {
      this.setState({ couponsData: { data: []} })
      console.error("Problem in fetching coupons data: ",error);
    }
  }

  
  onEditCoupon(data, rowIndex){
    let allCouponData = JSON.parse(JSON.stringify(this.state.couponsData));
    allCouponData.data.splice(rowIndex, 1);
    allCouponData.data.unshift(data);
    this.setState({couponsData: allCouponData});
  }

  onDeleteCoupon(index)
  {
    let allCouponData = JSON.parse(JSON.stringify(this.state.couponsData));
    allCouponData.data.splice(index, 1);
    this.setState({couponsData: allCouponData});
  }

  onTabChange(tabName){
    this.setState({activeTab: tabName});
  }

  render() {
      const { couponsData, reloadCouponsTable, activeTab } = { ...this.state }
    return (
      <div className="row">
        <div className="col-md-12">
        <nav aria-label="breadcrumb" className="page-nav-info mb-1"> 
        <span className="page-title">Marketing</span>
            <ol className="breadcrumb pl-0 pt-0">
              <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Marketing</li>
            </ol>
          </nav>

          <div className="div-campaign-promotion">
              <ul
                className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4"
                role="tablist"
              >
                <li role="presentation">
                  <Link
                    to="/marketing_management?tab=campaigns"
                    aria-controls="tab1"
                    className={activeTab && (activeTab === "" || activeTab.includes('campaigns')) ?  "active show" : ""}
                    role="tab"
                    data-toggle="tab"   
                    onClick={() => {this.onTabChange("campaigns")}}                 
                  >
                   Campaigns
                      </Link>
                </li>
                <span className="tab-separator"></span>
                <li role="presentation">
                  <Link
                     to="/marketing_management?tab=promotions"
                    aria-controls="tab2"
                    className={activeTab && activeTab.includes('promotions') ?  "active show" : ""}
                    role="tab"
                    data-toggle="tab"  
                    onClick={() => {this.onTabChange("promotions")}}                     
                  >
                    Promotions
                      </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div role="tabpanel" 
                  className={activeTab && (activeTab === "" || activeTab.includes('campaigns')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                  id="tab1">
                  <CampaignIndex
                    couponsData={couponsData} 
                  />

                </div>
                <div role="tabpanel" 
                className={activeTab && (activeTab.includes('promotions')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                id="tab2">
                    <PromotionIndex 
                    couponsData={couponsData} 
                    reloadCouponsTable={reloadCouponsTable} 
                    DeleteCoupon={this.onDeleteCoupon}
                    EditCoupon={this.onEditCoupon}/>
                </div>
              </div>
            </div>
         

        </div>

      </div>
    );
  }
}

export default withRouter(MarketingManagement);