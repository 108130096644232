import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
const { v1: uuidv1 } = require('uuid');
class AddParentOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addParentOptionsData: {
                title: '',
                description: '',
                options: [{
                    id: uuidv1().toUpperCase(),
                    isSelected: true,
                    item: "",
                    price: 0,
                    description: ""
                }],
            },
            addParentOptionsErrorData: {
                title: '',
                options: [{
                    item: "",
                    price: ""
                }]
            },
            helpText: {
                title: 'Give a descriptive title.',
                description: 'Optionally link to where it should belong.',
                options: 'You can add as many items as with the price. Make one item default which is selected automatically and the customer will be able to select any other option. Example: Default pizza size may be small but a customer can select either medium, large or x-large.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {

    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addParentOptionsData;
        let formErrors = this.state.addParentOptionsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
            fieldName = 'options';
            if (index !== undefined) {       
                if (formDetails['options'][index][newField] === "") {
                    formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                    // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                    formDetails['options'][index][newField] = "";
                    isError = true;
                }
            }
        }

        if (fieldName === 'options' && index === undefined) {
            for (let i = 0; i < formDetails.options.length; i += 1) {
                if (formDetails.options[i].item === "") {
                    fieldName = 'options';
                    formErrors.options[i].item = `The 'Item' is a required field.`;
                    isError = true;
                }
                if (formDetails.options[i].price === "") {
                    fieldName = 'options';
                    formErrors.options[i].price = `The 'price' is a required field.`;
                    isError = true;
                } else if (Number(formDetails.options[i].price) < 0) {
                    fieldName = 'options';
                    formDetails.options[i].price ="";
                    isError = true;
                }
            }
        }
        
        this.setState({ addParentOptionsErrorData: formErrors });
        this.setState({ addParentOptionsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addParentOptionsData;
        let formErrors = this.state.addParentOptionsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addParentOptionsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addParentOptionsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addParentOptionsData;
        let formDetailsError = this.state.addParentOptionsErrorData;
        formDetails[formField].push({
            id: uuidv1().toUpperCase(),
            isSelected: false,
            item: "",
            price: 0,
            description: ""
        });
        formDetailsError[formField].push({
            item: "",
            price: ""
        });
        this.setState({ addParentOptionsData: formDetails, addParentOptionsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addParentOptionsData;
        let formDetailsError = this.state.addParentOptionsErrorData;
        if (formDetails[formField][index].isSelected) {
            formDetails[formField][index+1].isSelected = true;
        }
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ addParentOptionsData: formDetails, addParentOptionsErrorData: formDetailsError })
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const addParentOptionsData = this.state.addParentOptionsData;
            const addObj = {
                modifiresObject: {
                    title: addParentOptionsData.title,
                    description: addParentOptionsData?.description ? addParentOptionsData.description : "",
                },
                title: addParentOptionsData.title,
                description: addParentOptionsData?.description ? addParentOptionsData.description : "",
                categoryName: "linkTemplateParentOptions",
                vendorId: vendorId
            };
            for (let i = 0; i < addParentOptionsData.options.length; i += 1) {
                addParentOptionsData.options[i].price = Number(Number(addParentOptionsData.options[i].price).toFixed(2));
            }
            
            addObj.modifiresObject.options = addParentOptionsData.options;
            // eslint-disable-next-line
            // addObj.modifiresObject.options.sort((a, b) => a.isSelected < b.isSelected && 1 || -1);
            if (vendorId) {
                let url = `modifier/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=linktemplates`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            addParentOptionsData,
            addParentOptionsErrorData,
            submitProgress,
            helpText
        } = { ...this.state };
        return (
            <div className="parent-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add Parent Options</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=linktemplates">Parent Options</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Parent Options</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="title">Title</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                            <div className="input-group">
                                <input type="text"
                                    className={"form-control " + (addParentOptionsErrorData.title !== "" ? 'invalid' : '')}
                                    id="title"
                                    name="title"
                                    maxLength="40"
                                    placeholder="Title"
                                    value={addParentOptionsData.title}
                                    onChange={this.onBasicDetailsChange}
                                    />
                                {addParentOptionsErrorData.title !== "" &&
                                    <div className="invalid-feedback">
                                        {addParentOptionsErrorData.title}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="description">Description</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    maxLength="120"
                                    placeholder="Description"
                                    value={addParentOptionsData.description}
                                    onChange={this.onBasicDetailsChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6 col-lg-6">
                            <label>Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="addition-option-radio">
                                        <label className="ms-checkbox-wrap"></label>
                                        <div className="input-group ml-3 mb-0">
                                            <label htmlFor="parentOptionsItem">Item</label>
                                        </div>
                                        <div className="input-group ml-3 mb-0">
                                            <label htmlFor="parentOptionsDesc">Description</label>
                                        </div>
                                        <div className="input-group ml-3 mb-0">
                                            <label htmlFor="parentOptionsPrice">Price</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {addParentOptionsData.options && addParentOptionsData.options.length > 0 &&
                                addParentOptionsData.options.map((optionsRow, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-md-12">
                                            <div className="addition-option-radio mb-15">
                                                <label className="ms-checkbox-wrap mb-8">
                                                    <input
                                                        type="radio"
                                                        name="optionsRadioExample"
                                                        value={optionsRow.isSelected}
                                                        checked={optionsRow.isSelected === true}
                                                        // onClick={(e) => {
                                                        //     if (e.currentTarget.value === 'true') {
                                                        //         let addParentOptionsData = this.state.addParentOptionsData;
                                                        //         addParentOptionsData.options[index].isSelected = false;
                                                        //         this.setState({ addParentOptionsData: addParentOptionsData })
                                                        //     }
                                                        // }}
                                                        onChange={(event) => {
                                                            let addParentOptionsData = this.state.addParentOptionsData;
                                                            for (let i = 0; i < addParentOptionsData.options.length; i += 1) {
                                                                if (index === i) {
                                                                    addParentOptionsData.options[i].isSelected = true;
                                                                } else {
                                                                    addParentOptionsData.options[i].isSelected = false;
                                                                }
                                                            }
                                                            this.setState({ addParentOptionsData: addParentOptionsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <div className="input-group ml-3 mb-0">
                                                    {/* <label htmlFor="parentOptionsItem">Item</label> */}
                                                    <input type="text"
                                                        className={"form-control " + (addParentOptionsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                        id="parentOptionsItem"
                                                        name="parentOptionsItem"
                                                        maxLength="40"
                                                        placeholder="Item"
                                                        value={optionsRow.item}
                                                        // onChange={this.onBasicDetailsChange}
                                                        onChange={(e) => {
                                                            let tempData = addParentOptionsData;
                                                            let tempErrorData = addParentOptionsErrorData;
                                                            tempData.options[index].item = e.target.value;
                                                            this.setState({ addParentOptionsData: tempData })
                                                            let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                            if (!isError) {
                                                            tempErrorData.options[index].item = "";
                                                            this.setState({ addParentOptionsErrorData: tempErrorData });
                                                            }
                                                        }}
                                                    />
                                                    {addParentOptionsErrorData.options[index].item !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addParentOptionsErrorData.options[index].item}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="input-group ml-3 mb-0">
                                                    {/* <label htmlFor="parentOptionsDesc">Description</label> */}
                                                    <input type="text"
                                                        className="form-control"
                                                        id="parentOptionsDesc"
                                                        name="parentOptionsDesc"
                                                        maxLength="120"
                                                        placeholder="Description"
                                                        value={optionsRow.description}
                                                        // onChange={this.onBasicDetailsChange}
                                                        onChange={(e) => {
                                                            let tempData = addParentOptionsData;
                                                            tempData.options[index].description = e.target.value;
                                                            this.setState({ addParentOptionsData: tempData })
                                                        }}
                                                    />
                                                </div>
                                                <div className="input-group ml-3 mb-0">
                                                    {/* <label htmlFor="parentOptionsPrice">Price</label> */}
                                                    <input type="number"
                                                        className={"form-control " + (addParentOptionsErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                        id="parentOptionsPrice"
                                                        name="parentOptionsPrice"
                                                        placeholder="Price"
                                                        value={optionsRow.price}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                        // onChange={this.onBasicDetailsChange}
                                                        onChange={(e) => {
                                                            let tempData = addParentOptionsData;
                                                            let tempErrorData = addParentOptionsErrorData;
                                                            tempData.options[index].price = e.target.value;
                                                            this.setState({ addParentOptionsData: tempData })
                                                            let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                            if (!isError) {
                                                            tempErrorData.options[index].price = "";
                                                            this.setState({ addParentOptionsErrorData: tempErrorData });
                                                            }
                                                        }}
                                                    />
                                                    { addParentOptionsData.options.length === 1 && index === 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { addParentOptionsData.options.length === index+1 && index > 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { index < addParentOptionsData.options.length-1 &&
                                                        <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    }
                                                    {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    } */}
                                                    {addParentOptionsErrorData.options[index].price !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addParentOptionsErrorData.options[index].price}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                            Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddParentOptions;