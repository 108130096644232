import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { isAuthorizedToUse } from '../utils';
import { isLogin } from '.';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (

            isLogin() ? isAuthorizedToUse(rest.path) ? <Component {...props} vendorChange={rest.vendorChange}/> : <Redirect to="/not_authorized" /> : <Redirect to="/login" />
        )} />
    );
};

export default withRouter(PrivateRoute);