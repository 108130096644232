import React from 'react';
import { Link } from "react-router-dom";
import Spinner from '../../spinner';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class PreviewPrintStation extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        this.state = {
            vendorAllDetails,
            printVendorData: [],
            tmpSelectProductData: [],
            spinner: true
        }
    }

    componentDidMount() {
        this.getProductsList();
    }
    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    let products = result.data.response;
                    var allPrintStation = [];
                    var finalPrintStation = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < products.length; i += 1) {
                            if (products[i].printStationName && products[i].printStationName.length) {
                                for (let ii = 0; ii < products[i].printStationName.length; ii += 1) {
                                    if (allPrintStation.indexOf(products[i].printStationName[ii]) === -1) {
                                        allPrintStation.push(products[i].printStationName[ii]);
                                        finalPrintStation.push({
                                            printStationName: products[i].printStationName[ii],
                                            data: []
                                        });
                                    }
                                }
                            }
                        }

                        for (let i = 0; i < finalPrintStation.length; i += 1) {
                            for (let ii = 0; ii < vendor.vendorAllDetails.product_categories.length; ii += 1) {
                                finalPrintStation[i].data.push({
                                    categoryName: vendor.vendorAllDetails.product_categories[ii],
                                    product: []
                                });
                            }
                        }
                        for (let iii = 0; iii < finalPrintStation.length; iii += 1) {
                            for (let i = 0; i < products.length; i += 1) {
                                for (let ii = 0; ii < finalPrintStation[iii].data.length; ii += 1) {
                                    if (finalPrintStation[iii].data[ii].categoryName === products[i].product_category) {
                                        if (products[i].printStationName && products[i].printStationName.length) {
                                            if (products[i].printStationName.indexOf(finalPrintStation[iii].printStationName) !== -1) {
                                                finalPrintStation[iii].data[ii].product.push({
                                                    id: products[i].id,
                                                    name: products[i].name
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        this.setState({ tmpSelectProductData: finalPrintStation });
                        this.setState({ spinner: false });                        
                    }
                })
                .catch(error => {
                    console.error("Problem in fetching previous days orders: ", error);
                })
        }
        catch (error) {
            console.error("Problem in fetching previous days orders: ", error);
        }
    }
    
    
    render() {
        const { vendorAllDetails, tmpSelectProductData, spinner} = { ...this.state }
        return (
            <div className="add-product-page">
                {vendorAllDetails &&
                    <div className="row">
                        <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Preview</span>
                                <ol className="breadcrumb pl-0 pt-0">
                                    <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/print_management">Print</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Preview</li>
                                </ol>
                            </nav>
                        </div>
                        {!spinner && tmpSelectProductData && tmpSelectProductData.length > 0 &&
                            tmpSelectProductData.map((foodItem, index) => (
                                <div className="col-xl-12 col-md-12" key={index}>
                                    <div className="ms-panel ms-panel-fh custom-panel">
                                        <div className="ms-panel-header">
                                            <div className="d-flex">
                                                    <h5 className="mb-0">{foodItem.printStationName}</h5>
                                                </div>
                                                <hr />
                                        </div>
                                        <div className="ms-panel-body">
                                            <div className="selected-food-items custom-table">
                                                <div className="ms-panel">
                                                    <div className="ms-panel-header">
                                                        <div className="d-flex justify-content-between category-food">
                                                            <div className="align-self-center align-left">
                                                                <h6>Category</h6>
                                                            </div>
                                                            <div className="align-self-center align-right">
                                                                <h6>Selected Food Items</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="ms-panel-body category-food-list">
                                                        {foodItem.data.map((fItem, fIndex) => (
                                                            <div key={`selected-item-${fIndex}`}>
                                                                {fItem.product && fItem.product.length > 0 &&
                                                                    <div className="select-food_list-item d-flex justify-content-between preview-food-item">
                                                                        <div className="food_c-name">
                                                                            <h6>{fItem.categoryName}</h6>
                                                                        </div>
                                                                        {
                                                                        <div className="food_c-content" >
                                                                        {fItem.product.map((PfoodItem, Pindex) => (
                                                                            <span className="badge badge-outline-light badge-food-item" key={Pindex}>{PfoodItem.name}</span>
                                                                        ))}
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {spinner && 
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body">
                                        <div className="selected-food-items">
                                            <div className="ms-panel">
                                                <div className="ms-panel-body category-food-list">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {!spinner && !(tmpSelectProductData && tmpSelectProductData.length) &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body">
                                        <div className="selected-food-items">
                                            <div className="ms-panel">
                                                <div className="ms-panel-body category-food-list">
                                                    <h5 className="text-center">Not found print station.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>}
            </div>
        );
    }
}

export default PreviewPrintStation;