import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
class AddOptionalItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addOptionalItemsData: {
                title: '',
                description: '',
                validationOptions: false,
                optionLimit: '',
                optionValidate: 'max',
                optionValidType: 'optional',
                options: [{
                    description: "",
                    price: 0,
                    item: ""
                }]
            },
            addOptionalItemsErrorData: {
                title: '',
                optionLimit: '',
                options: [{
                    description: "",
                    price: "",
                    item: ""
                }]
            },
            helpText: {
                title: 'Give a descriptive name. It will show in the model window.',
                description: 'Write the name of the category to which it should apply e.g. for Vegetarian Pizza',
                validationOptions: 'If yes, you will be able to define the validation options below. If No, no validation will be enforced.',
                options: 'You can add as many items.',
                optionLimit: 'Example: If you have 10 items, you want validation to apply to only 4 items.',
                optionValidate: 'A Max of 4 means that you could select a maximum of 4 items from a list of 10. A Min of 4 means that you have to specify a minimum of 4 options from a list of 10. A Fix of 4 means that you have to select a fix of 4 any items from the list of 10.',
                optionValidType: 'If you select Mandatory - you have to select the number given in Option Limit. If it is optional, you may or may not select those items.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {

    }
    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addOptionalItemsData;
        let formErrors = this.state.addOptionalItemsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }

        if (formDetails.validationOptions) {
            if ((formSubmit === false && fieldName === "optionLimit" && value === "") || (formSubmit === true && formDetails["optionLimit"] === "")) {
                fieldName = 'optionLimit';
                formErrors[fieldName] = `The 'Option Limit' is a required field.`;
                isError = true;
            } else if (Number(formDetails["optionLimit"]) < 1 ) {
                fieldName = 'optionLimit';
                formErrors[fieldName] = `Enter value greater than 1.`;
                isError = true;
            } else  {
                var limitRegex = new RegExp(/\..*[0-9]/);
                if (limitRegex.test(Number(formDetails["optionLimit"]))) {
                    fieldName = 'optionLimit';
                    formErrors[fieldName] = `Enter a valid number.`;
                    isError = true;
                }
            }
        }
        
        if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
            fieldName = 'options';
            if (index !== undefined) {       
                if (formDetails['options'][index][newField] === "") {
                    formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                    // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                    formDetails['options'][index][newField] = "";
                    isError = true;
                }
            }
        }

        if (fieldName === 'options' && index === undefined) {
            for (let i = 0; i < formDetails.options.length; i += 1) {
                if (formDetails.options[i].item === "") {
                    fieldName = 'options';
                    formErrors.options[i].item = `The 'Item' is a required field.`;
                    isError = true;
                }
                if (formDetails.options[i].price === "") {
                    fieldName = 'options';
                    formErrors.options[i].price = `The 'price' is a required field.`;
                    isError = true;
                } else if (Number(formDetails.options[i].price) < 0) {
                    fieldName = 'options';
                    formDetails.options[i].price ="";
                    isError = true;
                }
            }
        }
        
        this.setState({ addOptionalItemsErrorData: formErrors });
        this.setState({ addOptionalItemsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addOptionalItemsData;
        let formErrors = this.state.addOptionalItemsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addOptionalItemsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addOptionalItemsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addOptionalItemsData;
        let formDetailsError = this.state.addOptionalItemsErrorData;
        formDetails[formField].push({
            description: "",
            price: 0,
            item: ""
        });
        formDetailsError[formField].push({
            description: "",
            price: "",
            item: ""
        });
        this.setState({ addOptionalItemsData: formDetails, addOptionalItemsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addOptionalItemsData;
        let formDetailsError = this.state.addOptionalItemsErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ addOptionalItemsData: formDetails, addOptionalItemsErrorData: formDetailsError })
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const addOptionalItemsData = this.state.addOptionalItemsData;
            if (addOptionalItemsData.validationOptions && Number(addOptionalItemsData.optionLimit) > addOptionalItemsData.options.length) {
                toast.error(`Please add more ${Number(addOptionalItemsData.optionLimit) - addOptionalItemsData.options.length} options.`, {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
                return;
            }
            const addObj = {
                modifiresObject: {
                    title: addOptionalItemsData.title,
                    description: addOptionalItemsData?.description ? addOptionalItemsData.description : "",
                },
                validationOptions: addOptionalItemsData.validationOptions,
                title: addOptionalItemsData.title,
                description: addOptionalItemsData?.description ? addOptionalItemsData.description : "",
                categoryName: "optionalItems",
                vendorId: vendorId
            };
            for (let i = 0; i < addOptionalItemsData.options.length; i += 1) {
                addOptionalItemsData.options[i].price = Number(Number(addOptionalItemsData.options[i].price).toFixed(2));
            }
            addObj.modifiresObject.options = addOptionalItemsData.options;
            if (addOptionalItemsData.validationOptions) {
                addObj.modifiresObject.limit = {
                    value: Number(addOptionalItemsData.optionLimit),
                    validate: addOptionalItemsData.optionValidate
                }
                addObj.modifiresObject.validType = addOptionalItemsData.optionValidType;
            }
            if (vendorId) {
                let url = `modifier/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=multipleitems`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            addOptionalItemsData,
            addOptionalItemsErrorData,
            submitProgress,
            helpText
        } = { ...this.state };
        return (
            <div className="optional-items-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add Multiple Items</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=multipleitems">Multiple Items</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Add Multiple Items</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="title">Title</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                            <div className="input-group">
                                <input type="text"
                                    className={"form-control " + (addOptionalItemsErrorData.title !== "" ? 'invalid' : '')}
                                    id="title"
                                    name="title"
                                    maxLength="40"
                                    placeholder="Title"
                                    value={addOptionalItemsData.title}
                                    onChange={this.onBasicDetailsChange}
                                    />
                                {addOptionalItemsErrorData.title !== "" &&
                                    <div className="invalid-feedback">
                                        {addOptionalItemsErrorData.title}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="description">Description</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    maxLength="120"
                                    placeholder="Description"
                                    value={addOptionalItemsData.description}
                                    onChange={this.onBasicDetailsChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <label htmlFor="description">Select Validation Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.validationOptions}></i>
                            <div className="input-group mb-0">
                                <ul className="ms-list d-flex">
                                    <li className="ms-list-item pl-0">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio"
                                                name="radioExample1"
                                                value={true}
                                                checked={addOptionalItemsData.validationOptions === true}
                                                onChange={(event) => {
                                                    let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                    tempaddOptionalItemsData.validationOptions = event.currentTarget.value === "true" ? true : false;
                                                    this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Yes </span>
                                    </li>
                                    <li className="ms-list-item">
                                        <label className="ms-checkbox-wrap">
                                            <input
                                                type="radio"
                                                name="radioExample1"
                                                value={false}
                                                checked={addOptionalItemsData.validationOptions === false}
                                                onChange={(event) => {
                                                    let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                    tempaddOptionalItemsData.validationOptions = event.currentTarget.value === "false" ? false : true;
                                                    this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> No </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {addOptionalItemsData.validationOptions &&
                        <div className="row">
                            <ReactTooltip />
                            <div className="col-md-6 col-lg-6">
                                <label htmlFor="optionLimit">Option Limit</label>
                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.optionLimit}></i>
                                <div className="input-group">
                                    <input type="number"
                                        className={"form-control " + (addOptionalItemsErrorData.optionLimit !== "" ? 'invalid' : '')}
                                        id="optionLimit"
                                        name="optionLimit"
                                        placeholder="Option Limit"
                                        value={addOptionalItemsData.optionLimit}
                                        onWheel={ event => event.currentTarget.blur() }
                                        onChange={this.onBasicDetailsChange}
                                        />
                                    {addOptionalItemsErrorData.optionLimit !== "" &&
                                        <div className="invalid-feedback">
                                            {addOptionalItemsErrorData.optionLimit}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {addOptionalItemsData.validationOptions && 
                        <div className="row">
                            <ReactTooltip />
                            <div className="col-lg-6 col-md-6">
                                <label htmlFor="description">Option Validate</label>
                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.optionValidate}></i>
                                <div className="input-group mb-0">
                                    <ul className="ms-list d-flex">
                                        <li className="ms-list-item pl-0">
                                            <label className="ms-checkbox-wrap">
                                                <input type="radio"
                                                    name="radioExample2"
                                                    value="max"
                                                    checked={addOptionalItemsData.optionValidate === "max"}
                                                    onChange={(event) => {
                                                        let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                        tempaddOptionalItemsData.optionValidate = "max";
                                                        tempaddOptionalItemsData.optionValidType = 'optional';
                                                        this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                    }}
                                                />
                                                <i className="ms-checkbox-check"></i>
                                            </label>
                                            <span> Max </span>
                                        </li>
                                        <li className="ms-list-item">
                                            <label className="ms-checkbox-wrap">
                                                <input
                                                    type="radio"
                                                    name="radioExample2"
                                                    value="min"
                                                    checked={addOptionalItemsData.optionValidate === "min"}
                                                    onChange={(event) => {
                                                        let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                        tempaddOptionalItemsData.optionValidate = "min";
                                                        tempaddOptionalItemsData.optionValidType = 'mandatory';
                                                        this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                    }}
                                                />
                                                <i className="ms-checkbox-check"></i>
                                            </label>
                                            <span> Min </span>
                                        </li>
                                        <li className="ms-list-item">
                                            <label className="ms-checkbox-wrap">
                                                <input
                                                    type="radio"
                                                    name="radioExample2"
                                                    value="fix"
                                                    checked={addOptionalItemsData.optionValidate === "fix"}
                                                    onChange={(event) => {
                                                        let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                        tempaddOptionalItemsData.optionValidate = "fix";
                                                        tempaddOptionalItemsData.optionValidType = 'mandatory';
                                                        this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                    }}
                                                />
                                                <i className="ms-checkbox-check"></i>
                                            </label>
                                            <span> Fix </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <label htmlFor="description">Option Validate Type</label>
                                <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.optionValidType}></i>
                                <div className="input-group mb-0">
                                    <ul className="ms-list d-flex">
                                        {(addOptionalItemsData.optionValidate === 'min' || addOptionalItemsData.optionValidate === 'fix') &&
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample3"
                                                        value="mandatory"
                                                        checked={addOptionalItemsData.optionValidType === "mandatory"}
                                                        onChange={(event) => {
                                                            let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                            tempaddOptionalItemsData.optionValidType = 'mandatory';
                                                            this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Mandatory </span>
                                            </li>
                                        }
                                        {(addOptionalItemsData.optionValidate === 'max' || addOptionalItemsData.optionValidate === 'min') &&
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample3"
                                                        value="optional"
                                                        checked={addOptionalItemsData.optionValidType === "optional"}
                                                        onChange={(event) => {
                                                            let tempaddOptionalItemsData = this.state.addOptionalItemsData;
                                                            tempaddOptionalItemsData.optionValidType = 'optional';
                                                            this.setState({ addOptionalItemsData: tempaddOptionalItemsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Optional </span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <label>Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="optionaItem">Item</label>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="optionaDescription">Description</label>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="optionaPrice">Price</label>
                                </div>
                            </div>
                            {addOptionalItemsData.options && addOptionalItemsData.options.length > 0 &&
                                addOptionalItemsData.options.map((optionsRow, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-md-4">
                                            {/* <label htmlFor="optionaItem">Item</label> */}
                                            <div className="input-group">
                                                <input type="text"
                                                    className={"form-control " + (addOptionalItemsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                    id="optionaItem"
                                                    name="optionaItem"
                                                    maxLength="40"
                                                    placeholder="Item"
                                                    value={optionsRow.item}
                                                    // onChange={this.onBasicDetailsChange}
                                                    onChange={(e) => {
                                                        let tempData = addOptionalItemsData;
                                                        let tempErrorData = addOptionalItemsErrorData;
                                                        tempData.options[index].item = e.target.value;
                                                        this.setState({ addOptionalItemsData: tempData })
                                                        let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                        if (!isError) {
                                                        tempErrorData.options[index].item = "";
                                                          this.setState({ addOptionalItemsErrorData: tempErrorData });
                                                        }
                                                      }}
                                                    />
                                                    {addOptionalItemsErrorData.options[index].item !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addOptionalItemsErrorData.options[index].item}
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {/* <label htmlFor="optionaDescription">Description</label> */}
                                            <div className="input-group">
                                                <input type="text"
                                                    className="form-control"
                                                    id="optionaDescription"
                                                    name="optionaDescription"
                                                    maxLength="120"
                                                    placeholder="Description"
                                                    value={optionsRow.description}
                                                    // onChange={this.onBasicDetailsChange}
                                                    onChange={(e) => {
                                                        let tempData = addOptionalItemsData;
                                                        tempData.options[index].description = e.target.value;
                                                        this.setState({ addOptionalItemsData: tempData })
                                                      }}
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {/* <label htmlFor="optionaPrice">Price</label> */}
                                            <div className="input-group">
                                                <input type="number"
                                                    className={"form-control " + (addOptionalItemsErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                    id="optionaPrice"
                                                    name="optionaPrice"
                                                    placeholder="Price"
                                                    value={optionsRow.price}
                                                    onWheel={ event => event.currentTarget.blur() }
                                                    // onChange={this.onBasicDetailsChange}
                                                    onChange={(e) => {
                                                        let tempData = addOptionalItemsData;
                                                        let tempErrorData = addOptionalItemsErrorData;
                                                        tempData.options[index].price = e.target.value;
                                                        this.setState({ addOptionalItemsData: tempData })
                                                        let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                        if (!isError) {
                                                        tempErrorData.options[index].price = "";
                                                          this.setState({ addOptionalItemsErrorData: tempErrorData });
                                                        }
                                                      }}
                                                    />
                                                    { addOptionalItemsData.options.length === 1 && index === 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { addOptionalItemsData.options.length === index+1 && index > 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { index < addOptionalItemsData.options.length-1 &&
                                                        <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    }
                                                    {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    } */}
                                                    {addOptionalItemsErrorData.options[index].price !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addOptionalItemsErrorData.options[index].price}
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                            Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddOptionalItems;