import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

class FoodItemVariant extends React.Component {
    constructor(props) {
        super(props);
        let optionalItemsData = JSON.parse(JSON.stringify(this.props.singleProduct))
        const errorOptions = [];
        const tmpVariant = [];
        if (optionalItemsData && optionalItemsData.variant && optionalItemsData.variant.length) {
            for (let i = 0; i < optionalItemsData.variant.length; i += 1) {
                errorOptions.push({
                    title: "",
                    name: ""
                });
                if (optionalItemsData.variant[i].title && optionalItemsData.variant[i].name) {
                    tmpVariant.push(optionalItemsData.variant[i])
                }
            }
        } else {
            errorOptions.push({
                title: "",
                name: ""
            });
            tmpVariant.push({
                title: "",
                name: ""
            });
        }
        this.state = {
            opneModal: this.props.opneModal,
            singleProduct: JSON.parse(JSON.stringify(this.props.singleProduct)),
            title: this?.props?.title || 'Prodct Name',
            editmenuVariant: {
                options: tmpVariant
            },
            index: this.props.index,
            menuVariantError: {
                options: errorOptions
            },
        }
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editmenuVariant;
        let formDetailsError = this.state.menuVariantError;
        formDetails[formField].push({
            title: "",
            name: ""
        });
        formDetailsError[formField].push({
            title: "",
            name: ""
        });
        this.setState({ editmenuVariant: formDetails, menuVariantError: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editmenuVariant;
        let formDetailsError = this.state.menuVariantError;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ editmenuVariant: formDetails, menuVariantError: formDetailsError })
    }

    onSubmit(e){
        e.preventDefault();
        const tmpeditmenuVariant = this.state.editmenuVariant;
        const tmpsingleProduct = this.state.singleProduct;
        let formDetailsError = this.state.menuVariantError;
        const variant = [];
        let valid = true;
        if (tmpeditmenuVariant && tmpeditmenuVariant.options && tmpeditmenuVariant.options.length) {
            for (let i = 0; i < tmpeditmenuVariant.options.length; i += 1) {
                if (tmpeditmenuVariant.options[i].title && tmpeditmenuVariant.options[i].name) {
                    variant.push(tmpeditmenuVariant.options[i]);
                    formDetailsError['options'][i].title = "";
                    formDetailsError['options'][i].name = "";
                }
                if (tmpeditmenuVariant.options[i].title && !tmpeditmenuVariant.options[i].name) {
                    if (valid) {
                        valid = false;
                    }
                    formDetailsError['options'][i].name = "The 'Name' is a required field.";
                }
                if (!tmpeditmenuVariant.options[i].title && tmpeditmenuVariant.options[i].name) {
                    if (valid) {
                        valid = false;
                    }
                    formDetailsError['options'][i].title = "The 'Title' is a required field.";
                }
            }
        }

        if (valid) {
            tmpeditmenuVariant.options = variant;
            tmpsingleProduct.variant = variant;
            this.setState({
                editmenuVariant: tmpeditmenuVariant,
                singleProduct: tmpsingleProduct
            });
            this.props.save(tmpsingleProduct, this.state.index);
        } else {
            this.setState({
                menuVariantError: formDetailsError
            })
        }
        // return;
        // this.props.save(tmpsingleProduct);
    }

    onClose(){
        const tmpeditmenuVariant = this.state.editmenuVariant;
        const tmpsingleProduct = this.state.singleProduct;
        const variant = [];
        if (tmpeditmenuVariant && tmpeditmenuVariant.options && tmpeditmenuVariant.options.length) {
            for (let i = 0; i < tmpeditmenuVariant.options.length; i += 1) {
                if (tmpeditmenuVariant.options[i].title && tmpeditmenuVariant.options[i].name) {
                    variant.push(tmpeditmenuVariant.options[i]);
                }
            }
        }
        tmpeditmenuVariant.options = variant;
        tmpsingleProduct.variant = variant;
        this.setState({
            editmenuVariant: tmpeditmenuVariant,
            singleProduct: tmpsingleProduct
        });
        this.props.close(tmpsingleProduct);
    }

    
    render() {        
        const { opneModal } = {...this.props}
        // const { title, editmenuVariant, menuVariantError } = {...this.state}
        const { title, editmenuVariant, menuVariantError } = {...this.state}
        return (
            <Modal open={opneModal} onClose={this.onClose} className="modal-food-item" center>
                <div className="container fooditem-group">
                    <h5>{title}</h5>
                    <form className="needs-validation clearfix" noValidate>
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="optionaTitle">Title</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="optionaName">Name</label>
                                    </div>
                                </div>
                                {editmenuVariant.options && editmenuVariant.options.length > 0 &&
                                    editmenuVariant.options.map((optionsRow, index) => (
                                        <div className="row" key={index}>
                                            <div className="col-md-6">
                                                {/* <label htmlFor="optionaTitle">Item</label> */}
                                                <div className="input-group">
                                                    <input type="text"
                                                        className={"form-control " + (menuVariantError.options[index].title !== "" ? 'invalid' : '')}
                                                        // className="form-control"
                                                        id="optionaTitle"
                                                        name="optionaTitle"
                                                        maxLength="40"
                                                        placeholder="Title"
                                                        value={optionsRow.title}
                                                        // onChange={this.onBasicDetailsChange}
                                                        onChange={(e) => {
                                                            let tempData = editmenuVariant;
                                                            let tempErrorData = menuVariantError;
                                                            tempData.options[index].title = e.target.value;
                                                            if (menuVariantError.options.length > 0) {
                                                                if (e.target.value) {
                                                                    tempErrorData.options[index].title = ""
                                                                } else if (!e.target.value && editmenuVariant.options[index].name) {
                                                                    tempErrorData.options[index].title = "The 'Title' is a required field."
                                                                } else if (!e.target.value && !editmenuVariant.options[index].name) {
                                                                    tempErrorData.options[index].name = ""
                                                                }
                                                                this.setState({ menuVariantError: tempErrorData });
                                                            } else {
                                                                if (e.target.value && editmenuVariant.options[index].name) {
                                                                    tempErrorData.options[index].title = "";
                                                                    tempErrorData.options[index].name = "";
                                                                } else if (!e.target.value && editmenuVariant.options[index].name) {
                                                                    tempErrorData.options[index].title = "The 'Title' is a required field.";
                                                                }
                                                            }
                                                            this.setState({ editmenuVariant: tempData })
                                                            // let isError = this.handleValidation("options", e.target.value, index, 'title');
                                                            // if (!isError) {
                                                            // tempErrorData.options[index].title = "";
                                                            // this.setState({ menuVariantError: tempErrorData });
                                                            // }
                                                        }}
                                                        />
                                                        {menuVariantError.options[index].title !== "" &&
                                                            <div className="invalid-feedback">
                                                                {menuVariantError.options[index].title}
                                                            </div>
                                                        }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {/* <label htmlFor="optionaName">Description</label> */}
                                                <div className="input-group">
                                                    <input type="text"
                                                        className={"form-control " + (menuVariantError.options[index].name !== "" ? 'invalid' : '')}
                                                        id="optionaName"
                                                        name="optionaName"
                                                        maxLength="120"
                                                        placeholder="Name"
                                                        value={optionsRow.name}
                                                        onChange={(e) => {
                                                            let tempData = editmenuVariant;
                                                            let tempErrorData = menuVariantError;
                                                            tempData.options[index].name = e.target.value;
                                                            if (menuVariantError.options.length > 1) {
                                                                if (e.target.value) {
                                                                    tempErrorData.options[index].name = ""
                                                                } else if (!e.target.value && editmenuVariant.options[index].title) {
                                                                    tempErrorData.options[index].name = "The 'Name' is a required field."
                                                                } else if (!e.target.value && !editmenuVariant.options[index].title) {
                                                                    tempErrorData.options[index].title = ""
                                                                }
                                                                this.setState({ menuVariantError: tempErrorData })
                                                            } else {
                                                                if (e.target.value && editmenuVariant.options[index].title) {
                                                                    tempErrorData.options[index].title = "";
                                                                    tempErrorData.options[index].name = "";
                                                                } else if (!e.target.value && editmenuVariant.options[index].title) {
                                                                    tempErrorData.options[index].name = "The 'Name' is a required field.";
                                                                }
                                                            }
                                                            this.setState({ editmenuVariant: tempData })
                                                            // let isError = this.handleValidation("options", e.target.value, index, 'name');
                                                            // if (!isError) {
                                                            // tempErrorData.options[index].name = "";
                                                            // this.setState({ menuVariantError: tempErrorData });
                                                            // }
                                                        }}
                                                        />
                                                        { editmenuVariant.options.length === 1 && index === 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { editmenuVariant.options.length === index+1 && index > 0 &&
                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        }
                                                        { index < editmenuVariant.options.length-1 &&
                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                        }
                                                        {menuVariantError.options[index].name !== "" &&
                                                            <div className="invalid-feedback">
                                                                {menuVariantError.options[index].name}
                                                            </div>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </form>
                    <button className="btn btn-primary float-right" onClick={this.onSubmit}>
                        Add
                    </button>
                </div>
            </Modal>
        )
    }
}
export default FoodItemVariant;