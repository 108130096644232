import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner from '../../spinner';
import ExtraHotSpice from '../../../assets/img/spice/extraHot.png';
import HotSpice from '../../../assets/img/spice/hot.png';
import MediumSpice from '../../../assets/img/spice/medium.png';
import MildSpice from '../../../assets/img/spice/mild.png';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class EditAdditionOptions extends React.Component {
    constructor(props) {
        super(props);
        const spiceOptions = [{
            item: "Mild",
            isSelected: true
        }, {
            item: "Medium",
            isSelected: false
        }, {
            item: "Hot",
            isSelected: false
        }, {
            item: "Extra Hot",
            isSelected: false
        }];
        let additionOptionsId = this.props.match.params.additionOptionsId;
        let errorOptions = [];
        let additionOptionData = this.props.location.state && this.props.location.state.additionOptionData ? this.props.location.state.additionOptionData : null;
        if (additionOptionData && additionOptionData.modifiresObject && additionOptionData.modifiresObject.options && additionOptionData.modifiresObject.options.length) {
            for (let i = 0; i < additionOptionData.modifiresObject.options.length; i += 1) {
                errorOptions.push({
                    item: ""
                });
            }
        }

        if (!additionOptionData) {
            this.getSingleModifier(additionOptionsId);
        }
        this.state = {
            spiceOptions: spiceOptions,
            isLoadPage: true,
            additionOptionsId: additionOptionsId,
            productsData: [],
            editAdditionOptionsData: {
                title: additionOptionData && additionOptionData.title ? additionOptionData.title : "",
                description: additionOptionData && additionOptionData.description ? additionOptionData.description : "",
                isSpiceOption: additionOptionData && additionOptionData.isSpiceOption === true ? true : false,
                options: additionOptionData && additionOptionData.modifiresObject && additionOptionData.modifiresObject.options ? additionOptionData.modifiresObject.options : [],
            },
            editAdditionOptionsErrorData: {
                title: '',
                options: errorOptions
            },
            helpText: {
                title: 'Give a descriptive title of the additional options. There is no cost for these items but they may change the behavior of the menu item.',
                description: 'Give an optional description. Example: Spice Level for dishes.',
                isSpiceOption: 'If yes, you will see these spice icons on the menu option to choose from. If no, you can choose descriptive modifiers.',
                options: 'Specify a list of items and select the one which should default to. Example: Fries, Chips and make Chips default.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    
    componentDidMount() {
        this.getProductsList();
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    if (!(result.data && result.data.status)) {
                        this.setState({ productsData: [] });
                        this.setState({ selectedFoodItems: [], firstTimeFoodItems: JSON.stringify([]) });
                        this.setState({isLoadPage: false});
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    if (products[i].addition_options && products[i].addition_options.length) {
                                        for (let iii = 0; iii < products[i].addition_options.length; iii += 1) {
                                            if (products[i].addition_options[iii].id === this.state.additionOptionsId) {
                                                tempSelectedFoodItems[ii].product.push({
                                                    id: products[i].id,
                                                    name: products[i].name
                                                });
                                            }
                                        }
                                    }
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name
                                    });
                                }
                            }
                        }
                        for (let i = 0; i < manageProductData.length; i += 1) {
                            if (manageProductData[i].product && manageProductData[i].product.length) {
                                if (tempSelectedFoodItems[i].product && tempSelectedFoodItems[i].product.length === manageProductData[i].product.length) {
                                    tempSelectedFoodItems[i].allProductSelected = true;
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems, firstTimeFoodItems: JSON.stringify(tempSelectedFoodItems) });
                        this.setState({isLoadPage: false});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching vendor product: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching vendor product: ", error);
        }
    }

    async getSingleModifier(additionOptionsId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifier/getsingle/${vendorId}/${additionOptionsId}`;
        if (vendorId && additionOptionsId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifier(additionOptionsId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.modifiresObject && data.modifiresObject.options && data.modifiresObject.options.length) {
                                for (let i = 0; i < data.modifiresObject.options.length; i += 1) {
                                    errorOptions.push({
                                        item: ""
                                    });
                                }
                            }
                            let tempFormData = self.state.editAdditionOptionsData;
                            let tempFormErrorData = self.state.editAdditionOptionsErrorData;

                            tempFormData.title = data && data.title ? data.title : "";
                            tempFormData.description =  data && data.description ? data.description : "";
                            tempFormData.isSpiceOption =  data && data.isSpiceOption === true ? true : false;
                            tempFormData.options =  data && data.modifiresObject && data.modifiresObject.options ? data.modifiresObject.options : [];
                            self.setState({ editAdditionOptionsData: tempFormData });
                            
                            tempFormErrorData.title = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editAdditionOptionsErrorData: tempFormErrorData });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editAdditionOptionsData;
        let formErrors = this.state.editAdditionOptionsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }
        
        if (!formDetails.isSpiceOption) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                }
            }
        }
        
        this.setState({ editAdditionOptionsErrorData: formErrors });
        this.setState({ editAdditionOptionsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.editAdditionOptionsData;
        let formErrors = this.state.editAdditionOptionsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editAdditionOptionsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editAdditionOptionsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editAdditionOptionsData;
        let formDetailsError = this.state.editAdditionOptionsErrorData;
        formDetails[formField].push({
            isSelected: false,
            item: ""
        });
        formDetailsError[formField].push({
            item: ""
        });
        this.setState({ editAdditionOptionsData: formDetails, editAdditionOptionsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editAdditionOptionsData;
        let formDetailsError = this.state.editAdditionOptionsErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ editAdditionOptionsData: formDetails, editAdditionOptionsErrorData: formDetailsError })
    }

    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const editAdditionOptionsData = this.state.editAdditionOptionsData;

            const editObj = {
                id: self.state.additionOptionsId,
                selectedFoodItems: self.state.selectedFoodItems,
                modifiresObject: {
                    title: editAdditionOptionsData.title,
                    description: editAdditionOptionsData?.description ? editAdditionOptionsData.description : "",
                },
                isSpiceOption: editAdditionOptionsData.isSpiceOption,
                title: editAdditionOptionsData.title,
                description: editAdditionOptionsData?.description ? editAdditionOptionsData.description : "",
                categoryName: "additionOptions",
                vendorId: vendorId
            };
            
            editObj.modifiresObject.options = editAdditionOptionsData.options;
            if (editAdditionOptionsData.isSpiceOption) {
                editObj.modifiresObject.isSpiceOption = true;
                editObj.modifiresObject.options = self.state.spiceOptions;
            }
            if (!editAdditionOptionsData.isSpiceOption) {
                // eslint-disable-next-line
                editObj.modifiresObject.options.sort((a, b) => a.isSelected < b.isSelected && 1 || -1);
            }
            if (vendorId) {
                let url = `modifier/additionoptions/edit`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // this.getmodifiers();
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=oneitemselectionwithnoprice`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            editAdditionOptionsData,
            editAdditionOptionsErrorData,
            submitProgress,
            productsData,
            selectedFoodItems,
            isLoadPage,
            spiceOptions,
            helpText
        } = { ...this.state };
        return (
            <div className="addition-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Edit One Item Without Price</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=oneitemselectionwithnoprice">One Item Without Price</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit One Item Without Price</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {isLoadPage && 
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            {isLoadPage && <Spinner />}
                        </div>
                    </div>
                }
                {!isLoadPage && 
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6 col-lg-6">
                            <form className="needs-validation clearfix" noValidate>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="title">Title</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                                        <div className="input-group">
                                            <input type="text"
                                                className={"form-control " + (editAdditionOptionsErrorData.title !== "" ? 'invalid' : '')}
                                                id="title"
                                                name="title"
                                                maxLength="40"
                                                placeholder="Title"
                                                value={editAdditionOptionsData.title}
                                                onChange={this.onBasicDetailsChange}
                                                />
                                            {editAdditionOptionsErrorData.title !== "" &&
                                                <div className="invalid-feedback">
                                                    {editAdditionOptionsErrorData.title}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="description">Description</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                                        <div className="input-group">
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                maxLength="120"
                                                placeholder="Description"
                                                value={editAdditionOptionsData.description}
                                                onChange={this.onBasicDetailsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="optionaPrice">Spice Options</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.isSpiceOption}></i>
                                        <div className="input-group mb-0">
                                            <ul className="ms-list d-flex">
                                                <li className="ms-list-item pl-0">
                                                    <label className="ms-checkbox-wrap">
                                                        <input type="radio"
                                                            name="radioExample2"
                                                            value={true}
                                                            checked={editAdditionOptionsData.isSpiceOption === true}
                                                            onChange={(event) => {
                                                                let editAdditionOptionsData = this.state.editAdditionOptionsData;
                                                                editAdditionOptionsData.isSpiceOption = event.currentTarget.value === "true" ? true : false;
                                                                this.setState({ editAdditionOptionsData: editAdditionOptionsData })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span> Yes </span>
                                                </li>
                                                <li className="ms-list-item">
                                                    <label className="ms-checkbox-wrap">
                                                        <input
                                                            type="radio"
                                                            name="radioExample2"
                                                            value={false}
                                                            checked={editAdditionOptionsData.isSpiceOption === false}
                                                            onChange={(event) => {
                                                                let editAdditionOptionsData = this.state.editAdditionOptionsData;
                                                                editAdditionOptionsData.isSpiceOption = event.currentTarget.value === "true" ? true : false;
                                                                this.setState({ editAdditionOptionsData: editAdditionOptionsData })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span> No </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {editAdditionOptionsData.isSpiceOption &&
                                        <div className="col-md-12 col-lg-12">
                                            {spiceOptions && spiceOptions.length > 0 &&
                                                spiceOptions.map((spiceOptionsRow, index) => (
                                                    <div className="spice-list-wrapper" key={index}>
                                                        {spiceOptionsRow.item === 'Mild' &&
                                                            <div className="spice_list">
                                                                <img src={MildSpice} alt={spiceOptionsRow.item}></img>
                                                                <p>{spiceOptionsRow.item}</p>
                                                            </div>
                                                        }
                                                        {spiceOptionsRow.item === 'Medium' &&
                                                            <div className="spice_list">
                                                                <img src={MediumSpice} alt={spiceOptionsRow.item}></img>
                                                                <p>{spiceOptionsRow.item}</p>
                                                            </div>
                                                        }
                                                        {spiceOptionsRow.item === 'Hot' &&
                                                            <div className="spice_list">
                                                                <img src={HotSpice} alt={spiceOptionsRow.item}></img>
                                                                <p>{spiceOptionsRow.item}</p>
                                                            </div>
                                                        }
                                                        {spiceOptionsRow.item === 'Extra Hot' &&
                                                            <div className="spice_list">
                                                                <img src={ExtraHotSpice} alt={spiceOptionsRow.item}></img>
                                                                <p>{spiceOptionsRow.item}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }     
                                        </div>
                                    }
                                    {!editAdditionOptionsData.isSpiceOption && 
                                        <div className="col-md-12 col-lg-12">
                                            <ReactTooltip />
                                            <label>Options</label>
                                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="addition-option-radio">
                                                        <label className="ms-checkbox-wrap"></label>
                                                        <label htmlFor="optionaItem">Item</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {editAdditionOptionsData.options && editAdditionOptionsData.options.length > 0 &&
                                                editAdditionOptionsData.options.map((optionsRow, index) => (
                                                    <div className="row" key={index}>
                                                        <div className="col-md-6">
                                                            <div className="addition-option-radio mb-15">
                                                                <label className="ms-checkbox-wrap mb-8">
                                                                    <input
                                                                        type="radio"
                                                                        name="optionsRadioExample"
                                                                        value={optionsRow.isSelected}
                                                                        checked={optionsRow.isSelected === true}
                                                                        onClick={(e) => {
                                                                            if (e.currentTarget.value === 'true') {
                                                                                let editAdditionOptionsData = this.state.editAdditionOptionsData;
                                                                                editAdditionOptionsData.options[index].isSelected = false;
                                                                                this.setState({ editAdditionOptionsData: editAdditionOptionsData })
                                                                            }
                                                                        }}
                                                                        onChange={(event) => {
                                                                            let editAdditionOptionsData = this.state.editAdditionOptionsData;
                                                                            for (let i = 0; i < editAdditionOptionsData.options.length; i += 1) {
                                                                                if (index === i) {
                                                                                    editAdditionOptionsData.options[i].isSelected = true;
                                                                                } else {
                                                                                    editAdditionOptionsData.options[i].isSelected = false;
                                                                                }
                                                                            }
                                                                            this.setState({ editAdditionOptionsData: editAdditionOptionsData })
                                                                        }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <div className="input-group mb-0">
                                                                    {/* <label htmlFor="optionaItem">Item</label> */}
                                                                    <input type="text"
                                                                        className={"form-control " + (editAdditionOptionsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                                        id="optionaItem"
                                                                        name="optionaItem"
                                                                        maxLength="40"
                                                                        placeholder="Item"
                                                                        value={optionsRow.item}
                                                                        // onChange={this.onBasicDetailsChange}
                                                                        onChange={(e) => {
                                                                            let tempData = editAdditionOptionsData;
                                                                            let tempErrorData = editAdditionOptionsErrorData;
                                                                            tempData.options[index].item = e.target.value;
                                                                            this.setState({ editAdditionOptionsData: tempData })
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].item = "";
                                                                            this.setState({ editAdditionOptionsErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        { editAdditionOptionsData.options.length === 1 && index === 0 &&
                                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                        }
                                                                        { editAdditionOptionsData.options.length === index+1 && index > 0 &&
                                                                            <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                        }
                                                                        { index < editAdditionOptionsData.options.length-1 &&
                                                                            <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                        }
                                                                        {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                            : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                        } */}
                                                                        {editAdditionOptionsErrorData.options[index].item !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {editAdditionOptionsErrorData.options[index].item}
                                                                            </div>
                                                                        }
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>    
                                    }
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Select food items assign to this modifier</label>
                                    <div id="accordion">
                                        {productsData && productsData.length === 0 &&
                                            <h5 className="text-center">No Items Found.</h5>
                                        }
                                        {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                            productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                <div className="card-header">
                                                    <span className="ml-1">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                    </span>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                        <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                    </a>
                                                </div>

                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {productData && productData.product && productData.product.map((item) => (
                                                            <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                Update {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default EditAdditionOptions;