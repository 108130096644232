import React from 'react';
// import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import ReactDragListViewMainModifier from 'react-drag-listview';
import { toast } from 'react-toastify';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import no_image from '../../../assets/img/no_image.jpg';
import ModifierComponent from './modifierComponent';
import '../../../footer';
const { v1: uuidv1 } = require('uuid');

class AddProduct extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        let productCategories = [];
        if (vendorAllDetails && vendorAllDetails.product_categories && vendorAllDetails.product_categories.length > 0) {
            vendorAllDetails.product_categories.forEach((categoryName, index) => {
                productCategories.push(categoryName)
            })
        }
        let available = [];
        if (vendorAllDetails && vendorAllDetails.schedule) {
            // eslint-disable-next-line
            for (let [dayName, daySchedule] of Object.entries(vendorAllDetails.schedule)) {
                // eslint-disable-next-line
                for (const [slotName, slotDetails] of Object.entries(daySchedule)) {
                    let index = available.findIndex((value) => {
                        return (value.name).trim() === (slotName).trim()
                    })
                    if (index === -1) {
                        available.push({ name: (slotName).trim(), selected: false });
                    }
                }
            }
        }
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails,
            modifierError: false,
            frm_productDetails: {
                vendorId: vendorAllDetails.id.trim(),
                name: "",
                // price: "",
                product_category: "",
                description: "",
                photo: "",
                special_instructions: true,
                available,
                modifiers: []
            },
            frm_errors: {
                name: "",
                available: "",
                // price: "",
                product_category: "",
                description: "",
                photo: "",
            },
            submitProgress: false,
            productCategories,
            file: null,
            allProducts: this?.props?.location?.state?.productsData ? this.props.location.state.productsData : [],
            printStation: [],
            selectedFoodItems: [],
            productsData: [],
            opneModal: false,
            modifiersData: [],
            choiceModifier: "",
            addModifiersData: [],
            itemOptions: [],
            oldAddModifiersData: ""
        }
        let self = this;
        this.dragPropsMainModifier = {
            onDragEnd(fromIndex, toIndex) {
                let addModifiersData = self.state.addModifiersData;
                const options = [...addModifiersData];
                const item = options.splice(fromIndex, 1)[0];
                options.splice(toIndex, 0, item);
                for (let i = 0; i < options.length; i += 1) {
                    options[i].isSorting = i;
                }
                addModifiersData = options;
                self.setState({
                    addModifiersData: addModifiersData,
                    oldAddModifiersData: JSON.stringify(addModifiersData)
                });
            },
            nodeSelector: "section",
            handleSelector: 'a'
        };
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.clickMenu = this.clickMenu.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
        this.onFoodItemSave = this.onFoodItemSave.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onErrorMessageSave = this.onErrorMessageSave.bind(this);
    }

    componentDidMount() {
        if (this.state.allProducts.length === 0) {
            this.getAllProductsData();
        }
        this.getPrintStation();
        this.getmodifiers();
    }
    homeClick(e) {
        e.preventDefault();
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickMenu(e) {
        e.preventDefault();
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/menu_management?tab=menus`);
        }
    }
    leaveAnyway(e) {
        e.preventDefault();
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/menu_management?tab=menus`);
    }
    onChangeInput() {
        this.setState({
            isOpenFooter: true
        });
    }
    onFoodItemSave(data, error) {
        let self = this;
        self.setState({
            modifierError: error
        });
        let addModifiersData = self.state.addModifiersData;
        if (!error) {
            for (let i = 0; i < addModifiersData.length; i += 1) {
                if (addModifiersData[i].id === data.id) {
                    addModifiersData[i] = data;
                    addModifiersData[i].isClose = true;
                }
            }
            self.setState({
                // frm_productDetails: {
                //     modifiers: addModifiersData
                // },
                oldAddModifiersData: JSON.stringify(addModifiersData),
                addModifiersData,
            });
        }
    }
    onErrorMessageSave(error) {
        let self = this;
        self.setState({
            modifierError: error
        });
    }
    async getAllProductsData() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        self.getAllProductsData();
                        return;
                    }
                    let products = result.data.response;
                    self.setState({ allProducts: products })
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: []
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    manageProductData[ii].product.push({ id: products[i].id, name: products[i].name });
                                }
                            }
                        }
                        self.setState({ productsData: manageProductData, selectedFoodItems: tempSelectedFoodItems });
                    }
                })
                .catch(error => {
                    self.setState({ allProducts: [], productsData: { data: [] } });
                    console.error("Problem in fetching all products: ", error);
                })
        }
        catch (error) {
            self.setState({ allProducts: [], productsData: { data: [] } })
            console.error("Problem in fetching all products: ", error);
        }
    }
    async getmodifiers() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `modifiers/get/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.getmodifiers();
                    return;
                }
                if (!result.data.status) {
                  self.setState({ modifiersData: [] })
                  console.error("Problem in fetching modifiers data");
                  return;
                }
                let modifiersData = result.data.response;
                if (!(modifiersData && modifiersData.length)) {
                  self.setState({ modifiersData: [] });
                  return;
                }
                self.setState({ modifiersData: modifiersData, choiceModifier: modifiersData[0]._id });
            }).catch(error => {
                self.setState({ modifiersData: [] })
                console.error("Problem in fetching modifiers data: ", error);
            })
        } catch (error) {
            self.setState({ modifiersData: [] })
            console.error("Problem in fetching modifiers data: ", error);
        }
    }
    async getPrintStation() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `print/get-print-station/${vendorId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if("success" in result.data && result.data.success === false){
                    localStorage.removeItem('token');
                    this.getPrintStation();
                    return;
                }
                if (result.data.response.length) {
                    for (let i = 0; i < result.data.response.length; i += 1) {
                        result.data.response[i].selected = false;
                    }
                }
                let printStationData = result.data.response;
                this.setState({ printStation: printStationData})
            }).catch(error => {
                this.setState({ printStation: [] })
                console.error("Problem in fetching print station data: ",error);
            })
        } catch (error) {
            this.setState({ printStation: { data: []} })
            console.error("Problem in fetching print station data: ",error);
        }
    }
    addNewModifier() {
        // e.preventDefault();
        let self = this;
        let tmpaddModifiersData = JSON.stringify(self.state.addModifiersData);
        let modifiersData = self.state.modifiersData;
        let itemOptions = self.state.itemOptions;
        let choiceModifier = self.state.choiceModifier;
        if (modifiersData && modifiersData.length) {
            for (let i = 0; i < modifiersData.length; i += 1) {
                if (modifiersData[i]._id === choiceModifier) {
                    modifiersData[i].isClose = true;
                    modifiersData[i].id = uuidv1().toUpperCase();
                    if (modifiersData[i].isItemOption && !modifiersData[i].isPizzaTopping && modifiersData[i].options.length) {
                        for (let ii = 0; ii < modifiersData[i].options.length; ii += 1) {                        
                            modifiersData[i].options[ii].id = uuidv1().toUpperCase();
                            itemOptions.push(modifiersData[i].options[ii]);
                        }
                    } else {
                        for (let ii = 0; ii < modifiersData[i].options.length; ii += 1) {                        
                            modifiersData[i].options[ii].id = uuidv1().toUpperCase();
                        }
                    }
                    var finalOut = JSON.parse(tmpaddModifiersData);
                    finalOut.push(modifiersData[i])
                    if (modifiersData[i].isItemOption || modifiersData[i].isComesWith) {
                        modifiersData.splice(i, 1);
                    }
                }
            }
        }
        self.setState({
            addModifiersData: finalOut,
            oldAddModifiersData: JSON.stringify(finalOut),
            modifiersData,
            itemOptions,
            choiceModifier: modifiersData.length ? modifiersData[0]._id : "",
            isOpenFooter: true
        });
    }
    isProductNameInUse(productName) {
        let allProducts = this.state.allProducts;
        if (allProducts && allProducts.length > 0) {
            productName = productName.trim().toLowerCase();
            let index = allProducts.findIndex(product => {
                return product.name.trim().toLowerCase() === productName
            });
            return index;
        } else {
            return -1;
        }
    }

    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_productDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"] === "")) {
            fieldName = 'name';
            formErrors[fieldName] = `The 'Product name' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "name" && value !== "") || (formSubmit === true && formDetails["name"] !== "")) {
            // eslint-disable-next-line
            if (/[^a-zA-Z0-9()\ [\]\/&.+-]/.test(formDetails["name"])) {
                fieldName = 'name';
                formErrors[fieldName] = `The 'Product name' can only include letters, digits and the special characters such as Blank space ( ) [ ] + - & . /`;
                isError = true;
            } else if (!(/[a-zA-Z]/.test(formDetails["name"]))) {
                fieldName = 'name';
                formErrors[fieldName] = `The 'Product name' is not in a valid format.`;
                isError = true;
            } else if (this.isProductNameInUse(formDetails["name"]) !== -1) {
                fieldName = 'name';
                formErrors[fieldName] = `The 'product name' already exists.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "description" && value !== "") || (formSubmit === true && formDetails["description"] !== "")) {
            if (!(/[a-zA-Z]/.test(formDetails["description"]))) {
                fieldName = 'description';
                formErrors[fieldName] = `The 'Product description' is not in a valid format.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "product_category" && value === "") || (formSubmit === true && formDetails["product_category"] === "")) {
            fieldName = 'product_category';
            formErrors[fieldName] = `The 'Product category' is a required field.`;
            isError = true;
        }
        // if ((formSubmit === false && fieldName === "price" && value === "") || (formSubmit === true && formDetails["price"] === "")) {
        //     fieldName = 'price';
        //     formErrors[fieldName] = `The 'Price' is a required field and it must be a valid number.`;
        //     isError = true;
        // } else if ((formSubmit === false && fieldName === "price" && value <= 0) || (formSubmit === true && formDetails["price"] <= 0)) {
        //     fieldName = 'price';
        //     formDetails[fieldName] = "";
        //     isError = true;
        // } else if ((formSubmit === false && fieldName === "price" && value > 9999) || (formSubmit === true && formDetails["price"] > 9999)) {
        //     fieldName = 'price';
        //     formErrors[fieldName] = `The 'Price' can not be more than 9999`;
        //     isError = true;
        // }
        if ((formSubmit === false && fieldName === "photo") || (formSubmit === true)) {
            fieldName = 'photo';
            if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName] === "")) {
              formErrors[fieldName] = `The 'Photo' is a required field.`;
              isError = true;
              this.setState({file: null});
            }
            
            else if ((formSubmit === false && value && value.name && !value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))
              || (formSubmit === true && formDetails[fieldName].value && formDetails[fieldName].value.name && !formDetails[fieldName].value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))) {
                formErrors[fieldName] = `You selected an invalid file. The valid formats are jpeg, jpg and png.`;
              isError = true;
              this.setState({file: null});
            }
          }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_productDetails: formDetails });
        return isError;
    }

    onFocusOut(e) {
        e.preventDefault();
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        e.preventDefault();
        let formDetails = this.state.frm_productDetails;
        let formErrors = this.state.frm_errors;
        let fieldName = e.target.id;
        this.setState({
            isOpenFooter: true
        });
        if (e.target.type === "file") {
            let fieldValue = e.target.files[0];
            let isError = this.checkValidation(fieldName, fieldValue);
            if (isError) {
                formDetails[fieldName] = "";
                this.setState({ frm_productDetails: formDetails });
                return false;
            } else {
                formDetails[fieldName] = fieldValue;
                formErrors[fieldName] = "";
                this.setState({ file: URL.createObjectURL(fieldValue) })
                this.setState({ frm_productDetails: formDetails });
                this.setState({ frm_errors: formErrors });
            }
        } else {
            let fieldValue = e.target.value;
            formDetails[fieldName] = fieldValue;
            this.setState({ frm_productDetails: formDetails });
            let isError = this.checkValidation(fieldName, fieldValue);
            if (!isError) {
                formErrors[fieldName] = "";
                this.setState({ frm_errors: formErrors });
            }
        }
    }

    confirmAndDelete(e, id, mRow, index) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this product modifier.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                this.openDeleteModifier(e, id, mRow, index);
            }
        });
    }

    openDeleteModifier(e, id, mRow, index) {
        let self = this;
        let addModifiersData = self.state.addModifiersData;
        let modifiersData = self.state.modifiersData;
        for (let i = 0; i < addModifiersData.length; i += 1) {
            if (addModifiersData[i].id === id && (addModifiersData[i].isItemOption || addModifiersData[i].isComesWith)) {
                modifiersData.push(addModifiersData[i]);
            }
        }
        addModifiersData.splice(index, 1);
        this.setState({
            isOpenFooter: true,
            oldAddModifiersData: JSON.stringify(addModifiersData),
            addModifiersData,
            modifiersData,
            choiceModifier: modifiersData.length ? modifiersData[0]._id : ""
        });
    }

    openModifier(e, id, mRow) {
        let self = this;
        if (!self.state.modifierError) {
            let addModifiersData = self.state.addModifiersData;
            for (let i = 0; i < addModifiersData.length; i += 1) {
                if (addModifiersData[i].id === id) {
                    addModifiersData[i].isClose = false;
                } else {
                    addModifiersData[i].isClose = true;
                }
            }
            this.setState({
                addModifiersData,
                oldAddModifiersData: JSON.stringify(addModifiersData)
            })
        }
    }
    closeModifier(e, id, mRow) {
        let self = this;
        if (!self.state.modifierError) {
            let addModifiersData = self.state.addModifiersData;
            let oldAddModifiersData = JSON.parse(self.state.oldAddModifiersData);
            // let modifiersData = self.state.modifiersData;
            for (let i = 0; i < addModifiersData.length; i += 1) {
                if (addModifiersData[i].id === id) {
                    oldAddModifiersData[i].isClose = true;
                    addModifiersData[i] = oldAddModifiersData[i]
                } else {
                    oldAddModifiersData[i].isClose = true;
                    addModifiersData[i].isClose = true;
                }
            }
            this.setState({
                addModifiersData,
                oldAddModifiersData: JSON.stringify(addModifiersData)
            })
        }
    }
    async onFormSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            if (vendorId) {
                let url = `menu/product/add`;
                let requestData = new FormData();
                let printStationNameArray = [];
                if (self.state.printStation && self.state.printStation.length) {
                    for (let i = 0; i < self.state.printStation.length; i += 1) {
                        if (self.state.printStation[i].selected) {
                            printStationNameArray.push(self.state.printStation[i].printStationName)
                        }
                    }
                }
                let frm_productDetails = self.state.frm_productDetails;
                frm_productDetails.modifiers = JSON.stringify(self.state.addModifiersData);
                Object.keys(self.state.frm_productDetails).forEach(function (key) {
                    let availableData = [];
                    if (key === "available") {
                        self.state.frm_productDetails[key].forEach((data, key) => {
                            if (data.selected) {
                                availableData.push(data.name)
                            }
                        });
                        requestData.append(key, availableData);
                    } else {
                        if (typeof self.state.frm_productDetails[key] === "string") {
                            requestData.append(key, self.state.frm_productDetails[key].trim());
                        } else {
                            requestData.append(key, self.state.frm_productDetails[key]);
                        }
                    }
                });
                
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    requestData.append('printStationName', printStationNameArray);
                    axiosInstance.post(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onFormSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("Success: Product added!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                                this.props.history.push(`/menu_management`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ submitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Oops! We encountered a problem in adding the product details. Please try again.", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding product details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in the correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        let { vendorAllDetails,
            frm_productDetails,
            frm_errors,
            productCategories,
            submitProgress,
            file,
            printStation,
            isOpenFooter,
            isShowFooter,
            modifiersData,
            choiceModifier,
            addModifiersData,
            itemOptions
        } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="add-product-page">
                {vendorAllDetails &&
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                <span className="page-title">Add Product</span>
                                <ol className="breadcrumb pl-0 pt-0">
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.clickMenu}>Menu</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Product</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh custom-panel">
                                <div className="ms-panel-header">
                                    <div className="d-flex">
                                        <h5 className="mb-0">Add Product Form</h5>
                                    </div>
                                    <hr />
                                </div>

                                <div className="ms-panel-body">
                                    <form className="needs-validation clearfix" noValidate>
                                        <div className="form-row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="name" className="required">Product Name</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.name !== "" ? 'invalid' : '')}
                                                        placeholder="Product Name"
                                                        id="name"
                                                        name="name"
                                                        maxLength="100"
                                                        value={frm_productDetails.name}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.name && frm_errors.name !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="product_category" className="required">Select Category</label>
                                                <div className="input-group">
                                                    <select
                                                        className={"form-control " + (frm_errors.product_category !== "" ? 'invalid' : '')}
                                                        id="product_category"
                                                        value={frm_productDetails.product_category}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    >
                                                        <option value="">Select</option>
                                                        {productCategories && productCategories.length > 0 && productCategories.map((option, index) => (
                                                            <option key={`category-index-${index}`} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                    {frm_errors.product_category && frm_errors.product_category !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.product_category}</div>}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 mb-3">
                                                <label htmlFor="price" className="required">Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">$</div>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className={"form-control " + (frm_errors.price !== "" ? 'invalid' : '')}
                                                        id="price"
                                                        placeholder="Product Price"
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        min="0"
                                                        max="9999"
                                                        value={frm_productDetails.price}
                                                        required
                                                    />
                                                    {frm_errors.price && frm_errors.price !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.price}</div>}
                                                </div>
                                            </div> */}
                                            
                                            {printStation && printStation.length > 0 &&
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="validationCustom25">Printer List</label>
                                                    <div className="input-group">
                                                        {printStation.map((printerRow, index) => (
                                                            <span className="ml-1" key={`option-${index}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox" checked={printerRow.selected}
                                                                        onChange={(e) => {
                                                                            let tempData = printStation;
                                                                            tempData[index].selected = e.target.checked;
                                                                            this.setState({ printStation: tempData, isOpenFooter: true })
                                                                        }} />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{printerRow.printStationName}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom25">Available</label>
                                                <div className="input-group">
                                                    {frm_productDetails && frm_productDetails.available && frm_productDetails.available.map((option, index) => (
                                                        <span className="ml-1" key={`option-${index}`}>
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="checkbox" checked={option.selected}
                                                                    onChange={(e) => {
                                                                        let tempData = frm_productDetails;
                                                                        tempData.available[index].selected = e.target.checked;
                                                                        this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                                    }} />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span>{option.name}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="description">Description</label>
                                                <div className="input-group">
                                                    <textarea
                                                        rows="5"
                                                        id="description"
                                                        className={"form-control " + (frm_errors.description !== "" ? 'invalid' : '')}
                                                        placeholder="Description"
                                                        value={frm_productDetails.description}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                        maxLength={300}
                                                    ></textarea>
                                                    {frm_errors.description && frm_errors.description !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.description}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="photo" className="required">Product Image</label>
                                                <div className="input-group">
                                                    <input
                                                        type="file"
                                                        className={"custom-file-input " + (frm_errors.photo !== "" ? 'invalid' : '')}
                                                        id="photo"
                                                        accept="image/x-png,image/jpeg,image/jpg,image/svg+xml"
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                    />
                                                    <label
                                                        className="custom-file-label"
                                                        htmlFor="photo"
                                                    >Upload Image...</label>
                                                    {frm_productDetails.photo && frm_productDetails.photo !== "" && frm_productDetails.photo.name && <p>{frm_productDetails.photo.name}</p>}
                                                    {frm_errors.photo && frm_errors.photo !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.photo}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh custom-panel">
                                <div className="ms-panel-header">
                                    <div className="d-flex">
                                        <h5 className="mb-0">Product</h5>
                                    </div>
                                    <hr />
                                </div>
                                <div className="product-img-wrapper">
                                    <img src={file ? file : no_image} alt="" />
                                </div>

                                <div className="ms-panel-header new">
                                    <label htmlFor="name">Special Instruction</label>
                                    <div>
                                        <label className="ms-switch">
                                            <input type="checkbox" checked={frm_productDetails.special_instructions}
                                                onChange={(e) => {
                                                    let tempData = frm_productDetails;
                                                    tempData.special_instructions = e.target.checked;
                                                    this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                }} />
                                            <span className="ms-switch-slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {vendorAllDetails &&
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh custom-panel">
                                <div className="ms-panel-header">
                                    <div className="d-flex">
                                        <h5 className="mb-0">Modifier</h5>
                                    </div>
                                    <hr />
                                </div>
                                <div className="ms-panel-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <label htmlFor="choiceModifier">Choice Modifier</label>
                                            <div className="input-group">
                                                <select
                                                    className="form-control"
                                                    name="choiceModifier"
                                                    id="choiceModifier"
                                                    onChange={(event) => {
                                                        // let tempAddModifierData = this.state.addModifierData;
                                                        // tempAddModifierData.choiceModifier = event.target.value;
                                                        this.setState({ choiceModifier: event.target.value, isOpenFooter: true })
                                                    }}
                                                    value={choiceModifier}>
                                                    {modifiersData && modifiersData.length && modifiersData.map((optionsRow, index) => (
                                                        <option value={optionsRow._id} key={index}>{optionsRow.displayName} ({optionsRow.name})</option>
                                                    ))}
                                                </select>
                                                <button type="button" className="btn btn-primary ml-3 mt-0 pt-1 pb-1" onClick={() => this.addNewModifier()}>Add Modifier</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactDragListViewMainModifier {...this.dragPropsMainModifier}>
                                            {addModifiersData && addModifiersData.length > 0 && addModifiersData.map((mRow, mIndex) => (
                                                <section className="modifire-section" key={`${mIndex}`}>
                                                    <div className="option-drag-drop-main">
                                                        {/* eslint-disable-next-line */}
                                                        <a className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></a>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card-body p-0">
                                                                <div className={"card-body-modifier card-body-grid cate-border background-gray mb-3 "+ (!mRow.isClose ? 'product-first-section' : '')}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <p className="mb-0 modifier-display-name">{mRow.displayName} ({mRow.name})</p>
                                                                            {mRow.options.map((vRow, vIndex) => (
                                                                                <span key={vIndex}>{vRow.name}{vRow.type ? `(${vRow.type})`: ""}
                                                                                { mRow && mRow.options && (mRow.options.length-1) !== vIndex && 
                                                                                    <span>, </span>
                                                                                }
                                                                                </span>
                                                                            ))}
                                                                            {/* <span>{mRow}</span> */}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p className="mb-0">  
                                                                                {mRow.isRequired && 
                                                                                <span>Required</span>
                                                                                }
                                                                                {mRow.isComesWith && !mRow.isRequired &&
                                                                                <span>Comes With</span>
                                                                                }
                                                                                {mRow.isComesWith && mRow.isRequired &&
                                                                                <span>, Comes With</span>
                                                                                }
                                                                                {mRow.isItemOption && !mRow.isRequired && !mRow.isComesWith &&
                                                                                <span>Item Option</span>
                                                                                }
                                                                                {mRow.isItemOption && (mRow.isRequired || mRow.isComesWith) &&
                                                                                <span>, Item Option</span>
                                                                                }
                                                                            </p>
                                                                            {mRow.choiceLimit && 
                                                                                <p className="mb-0">Choice of {mRow.choiceLimit}</p>
                                                                            }
                                                                            {!mRow.choiceLimit &&
                                                                                <p className="mb-0">No Limit</p>
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            {mRow.isClose && 
                                                                                <span className="custom-link" onClick={(e) => this.openModifier(e, mRow.id, mRow)}>Open</span>
                                                                            }
                                                                            {!mRow.isClose &&
                                                                                <span className="custom-link" onClick={(e) => this.closeModifier(e, mRow.id, mRow)}>Close</span>
                                                                            }
                                                                            <span> / </span>
                                                                            <span className="custom-link color-red" onClick={(e) => this.confirmAndDelete(e, mRow.id, mRow, mIndex)}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {!mRow.isClose &&
                                                                    <section className={!mRow.isClose ? "product-modifier-section": ""}>
                                                                        <ModifierComponent
                                                                            model="add"
                                                                            modifierId={mRow._id}
                                                                            modifierData={mRow}
                                                                            itemOptions={itemOptions}
                                                                            save={this.onFoodItemSave}
                                                                            errorMessage={this.onErrorMessageSave}
                                                                            onChangeInput={this.onChangeInput}
                                                                        >
                                                                        </ModifierComponent>
                                                                    </section>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            ))}
                                        </ReactDragListViewMainModifier>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { vendorAllDetails &&
                    <div className="row">
                        <div className="col-md-12 pb-3 text-right">
                            <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                        </div>
                    </div>
                }
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default AddProduct;