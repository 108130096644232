import React from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-responsive-modal';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';

class SettingsDetails extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = this.props.vendorAllDetails;
        const DEFAULT_TIPS = {
            id: 'tips',
            default: 1,
            value: ["0", "10", "15", "20"]
        }
        const posSettings = {
            clover: vendorAllDetails?.posSettings?.clover ? vendorAllDetails.posSettings.clover : false,
            square: vendorAllDetails?.posSettings?.square ? vendorAllDetails.posSettings.square : false
        }
        let categoryArray = [];
        if (vendorAllDetails.product_categories && vendorAllDetails.product_categories.length) {
            for (let i = 0; i < vendorAllDetails.product_categories.length; i += 1) {
                if (vendorAllDetails.printSidesAsLineItemsCategorySimpleArray && vendorAllDetails.printSidesAsLineItemsCategorySimpleArray.length && vendorAllDetails.printSidesAsLineItemsCategorySimpleArray.indexOf(vendorAllDetails.product_categories[i]) !== -1) {
                    categoryArray.push({
                        name: vendorAllDetails.product_categories[i],
                        checked: true
                    });
                } else {
                    categoryArray.push({
                        name: vendorAllDetails.product_categories[i],
                        checked: false
                    });
                }
            }
        }
        this.state = {
            isOpenFooter: false,
            isShowFooter: this.props.isShowFooter,
            printStation: [],
            settingsData: {
                deliveryLeadTime: vendorAllDetails?.deliveryLeadTime ? vendorAllDetails.deliveryLeadTime : 0,
                inDineLeadTime: vendorAllDetails?.inDineLeadTime ? vendorAllDetails.inDineLeadTime : 0,
                pickupLeadTime: vendorAllDetails?.pickupLeadTime ? vendorAllDetails.pickupLeadTime : 0,
                deliveryPublic: vendorAllDetails?.deliveryPublic ? vendorAllDetails.deliveryPublic : false,
                deliveryRadius: vendorAllDetails?.deliveryRadius ? vendorAllDetails.deliveryRadius : 0,
                directPayment: vendorAllDetails?.directPayment ? vendorAllDetails.directPayment : false,
                pickupRadius: vendorAllDetails?.pickupRadius ? vendorAllDetails.pickupRadius : 0,
                deliveryTaxable: vendorAllDetails?.deliveryTaxable ? vendorAllDetails.deliveryTaxable : false,
                deliveryMethod: vendorAllDetails?.deliveryMethod ? vendorAllDetails.deliveryMethod : '',
                deliveryUniversity: vendorAllDetails?.deliveryUniversity ? vendorAllDetails.deliveryUniversity : false,
                delivery_charge: vendorAllDetails?.delivery_charge ? vendorAllDetails.delivery_charge : 0,
                serviceCharge: vendorAllDetails?.serviceCharge ? vendorAllDetails.serviceCharge : 0,
                maximumDeliveryCharge: vendorAllDetails?.maximumDeliveryCharge ? vendorAllDetails.maximumDeliveryCharge : 0,
                serviceChargeType: vendorAllDetails?.serviceChargeType ? vendorAllDetails.serviceChargeType : 'percentage',
                serviceChargeTaxable: vendorAllDetails?.serviceChargeTaxable ? vendorAllDetails.serviceChargeTaxable : false,
                enabled: vendorAllDetails?.enabled ? vendorAllDetails.enabled : false,
                tips: vendorAllDetails?.tips ? vendorAllDetails.tips : DEFAULT_TIPS,
                payLater: vendorAllDetails?.payLater ? vendorAllDetails.payLater : false,
                paymentDisableDineIn: vendorAllDetails?.paymentDisableDineIn ? vendorAllDetails.paymentDisableDineIn : false,
                cloudPrint: vendorAllDetails?.cloudPrint ? vendorAllDetails.cloudPrint : false,
                minimumOrderCharge: vendorAllDetails?.minimumOrderCharge ? vendorAllDetails.minimumOrderCharge : "",
                dealsForStudent: vendorAllDetails?.dealsForStudent ? vendorAllDetails.dealsForStudent : false,
                pos: vendorAllDetails?.pos ? vendorAllDetails.pos : false,
                autoConfirm: vendorAllDetails?.autoConfirm ? vendorAllDetails.autoConfirm : false,
                manageWebsite: vendorAllDetails?.manageWebsite ? vendorAllDetails.manageWebsite : false,
                autoConfirmDelayInSeconds: vendorAllDetails?.autoConfirmDelayInSeconds ? vendorAllDetails.autoConfirmDelayInSeconds : 60,
                deviceDetails: vendorAllDetails?.deviceDetails ? vendorAllDetails.deviceDetails : [],
                selectedDeviceId: vendorAllDetails?.selectedDeviceId ? vendorAllDetails.selectedDeviceId : '',
                driverCommission: vendorAllDetails?.driverCommission ? vendorAllDetails.driverCommission : 0,
                posSettings: posSettings,
                posSettingMethod: posSettings?.clover ? "clover" : posSettings?.square ? "square" : "",
                delayOrderConfirm: vendorAllDetails?.delayOrderConfirm ? vendorAllDetails.delayOrderConfirm : false,
                printSidesAsLineItems: vendorAllDetails?.printSidesAsLineItems ? vendorAllDetails.printSidesAsLineItems : false,
                printSidesAsLineItemsCategoryArray: categoryArray,
                printSidesAsLineItemsCategorySimpleArray: vendorAllDetails.printSidesAsLineItemsCategorySimpleArray && vendorAllDetails.printSidesAsLineItemsCategorySimpleArray.length ? vendorAllDetails.printSidesAsLineItemsCategorySimpleArray : [] 
            },
            helpText: {
                inDineLeadTime: 'What is the time required to prepare the food for dine-in customers? If you are ready, specify 0; otherwise, 20 min is usual.',
                pickUpLeadTime: 'What is the time required to prepare a pickup order? Usually, 20 min time is average.',
                deliveryLeadTime: 'What is the time required to prepare a delivery order? Usually, 20 min time is average.',
                deliveryRadius: 'Specify the radius of the area you want to serve if you are going to use your delivery. Usually, customers use from 5 to 15 miles.',
                pickupRadius: 'Specify the radius of the area you want your pickup customers to come and pick up the food. Usually, you should allow customers from within 20 miles radius.',
                tipPercentage: 'Specify the various tip percentages and a default value that shows to the customer.',
                cloudPrint: 'Turn this on if you ordered a cloud-enabled printer such as Star MC-30. If you turn this on, you need to specify the MAC address of the printer. You will also need to define an API endpoint https://clouddev.svang.app/api/v1/cloudprint in your printer admin screen so that the print comes directly to the printer. Contact support@svang.app for help.',
                minimumOrderCharge: 'You can specify a minimum order amount you want from online or app customers. Usually, it is $15. If a customer orders food only for $10, we will add a $5 small order charge to make it $15. We do not want to turn away the customer and encourage them to order a minimum amount.',
                pos: 'Turn this on if student order print otherwise student order not print.',
                autoConfirm: 'Turn this on if order auto confirm.',
                autoDeviceConfirm: 'Selected device only order confirm manage.',
                autoConfirmDelayInSeconds: 'Delay time to order conformation(second).',
                directPayment: 'If direct payment is yes then order payment receives in the restaurant account otherwise received svang account.',
                driverCommission: 'Per order driver commission amount.',
                printSidesAsLineItems: ''
            },
            settingsErrorData: {
                deliveryLeadTime: "",
                inDineLeadTime: "",
                pickupLeadTime: "",
                deliveryRadius: "",
                pickupRadius: "",
                delivery_charge: "",
                serviceCharge: "",
                maximumDeliveryCharge: "",
                tipIndex: "",
                tips: "",
                minimumOrderCharge: "",
                driverCommission: ""
            },
            submitProgress: false,
            printStationModelShow: false,
            oldValuePrint: vendorAllDetails?.cloudPrint ? vendorAllDetails.cloudPrint : false,
            tmpoldValuePrint: "",
            updatePrintData: [],
            tmpsettingsData: ""
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
    }
    componentDidMount() {
        this.setState({
            tmpsettingsData: JSON.stringify(this.state.settingsData),
            tmpoldValuePrint: JSON.stringify(this.state.oldValuePrint)
        })
        this.getPrintStation();
    }

    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false,
            settingsData: JSON.parse(this.state.tmpsettingsData),
            oldValuePrint: JSON.parse(this.state.tmpoldValuePrint)
        });
        self.props.saveData(false);
    }

    async getPrintStation() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `print/get-print-station/${vendorId}?db=mongodb`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if("success" in result.data && result.data.success === false){
                    localStorage.removeItem('token');
                    this.getPrintStation();
                    return;
                }
                let printStationData = result.data.response;
                this.setState({ printStation: printStationData})
            }).catch(error => {
                this.setState({ printStation: [] })
                console.error("Problem in fetching print station data: ",error);
            })
        } catch (error) {
            this.setState({ printStation: [] })
            console.error("Problem in fetching print station data: ",error);
        }
    }

    onCloseModal() {
        let formDetails = this.state.settingsData;
        formDetails.cloudPrint = this.state.oldValuePrint;
        this.setState({
            printStationModelShow: false,
            settingsData: formDetails
        });
    }
    handleValidation(fieldName = "", value = "") {
        let formDetails = this.state.settingsData;
        let formErrors = this.state.settingsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "deliveryLeadTime") || (formSubmit === true)) {
            fieldName = 'deliveryLeadTime';

            if (formDetails[fieldName] > 120) {
                formErrors[fieldName] = `The delivery lead time should be less than 120.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The delivery lead time should be more than 0.`;
                isError = true;
            }
        }
        
        if ((formSubmit === false && fieldName === "autoConfirmDelayInSeconds" && value === "") || (formSubmit === true && formDetails[fieldName] === "")) {
            fieldName = 'autoConfirmDelayInSeconds';
            formErrors[fieldName] = `The 'Auto Confirm Delay In Seconds' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "inDineLeadTime") || (formSubmit === true)) {
            fieldName = 'inDineLeadTime';

            if (formDetails[fieldName] > 120) {
                formErrors[fieldName] = `The dine-in lead time should be less than 120.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The dine-in lead time can be 0 or less than 120.`;
                isError = true;
            }

        }
        if ((formSubmit === false && fieldName === "pickupLeadTime") || (formSubmit === true)) {
            fieldName = 'pickupLeadTime';
            if (formDetails[fieldName] > 120) {
                formErrors[fieldName] = `The pick up lead time should be less than 120.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The pick up lead time should be more than 0.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "delivery_charge") || (formSubmit === true)) {
            fieldName = 'delivery_charge';
            if (formDetails[fieldName] > 999) {
                formErrors[fieldName] = `The delivery charge should be less than 999.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The delivery charge can be between 0 and 999.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "maximumDeliveryCharge") || (formSubmit === true)) {
            fieldName = 'maximumDeliveryCharge';
            if (formDetails[fieldName] > 999) {
                formErrors[fieldName] = `The maximum delivery charge should be less than 999.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The maximum delivery charge can be between 0 and 999.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "serviceCharge") || (formSubmit === true)) {
            fieldName = 'serviceCharge';
            if (formDetails[fieldName] > 999) {
                formErrors[fieldName] = `The service charge should be less than 999.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The service charge can be between 0 and 999.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "minimumOrderCharge") || (formSubmit === true)) {
            fieldName = 'minimumOrderCharge';
            if (formDetails[fieldName] > 999) {
                formErrors[fieldName] = `The minimum order charge should be less than 999.`;
                isError = true;
            }
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The minimum order charge can be between 0 and 999.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "deliveryRadius") || (formSubmit === true)) {
            fieldName = 'deliveryRadius';
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The delivery radius should be more than 0.`;
                isError = true;
            }

        }
        if ((formSubmit === false && fieldName === "pickupRadius") || (formSubmit === true)) {
            fieldName = 'pickupRadius';
            if (formDetails[fieldName] < 0) {
                formErrors[fieldName] = `The pickup radius should be more than 0.`;
                isError = true;
            }

        }
        if ((formSubmit === false && fieldName === "tips") || (formSubmit === true)) {
            fieldName = 'tips';
            formDetails[fieldName].value.forEach((value, index) => {
                if (value === "") {
                    formErrors[fieldName] = `All fields are required.`;
                    formErrors['tipIndex'] = index;
                    isError = true;
                    return false;
                }
                else if (value < 0) {
                    formErrors[fieldName] = `Tip percentage should be more than 0.`;
                    formErrors['tipIndex'] = index;
                    isError = true;
                    return false;
                }
            });
            if (this.checkForDuplicates(formDetails[fieldName].value)) {
                formErrors[fieldName] = `Tip percentage contains duplicate values.`;
                formErrors['tipIndex'] = "";
                isError = true;
            }
        }
        this.setState({ settingsErrorData: formErrors });
        return isError;
    }

    checkForDuplicates(array) {
        return new Set(array).size !== array.length
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.settingsData;
        let formErrors = this.state.settingsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        this.props.saveData(true);
        this.setState({
            isOpenFooter: true
        });
        let fieldValue = e.target.value;
        if (fieldName === "tips") {
            let index = e.target.dataset.index;
            formDetails[fieldName].value[index] = fieldValue;
        } else {
            formDetails[fieldName] = fieldValue;
        }
        this.setState({ settingsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ settingsErrorData: formErrors });
        }
    }

    async onFormSubmit() {
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            let url = `vendor/updatevendor/settingdetails`;
            let requestData = {};
            let numberFields = ["deliveryLeadTime", "inDineLeadTime", "pickupLeadTime", "deliveryRadius", "pickupRadius", "delivery_charge", "serviceCharge", "maximumDeliveryCharge", "driverCommission"]
            requestData["vendorId"] = vendorId;
            Object.keys(self.state.settingsData).forEach(function (key) {
                if (numberFields.includes(key) && self.state.settingsData[key] === "" ) {
                    requestData[key] = "0";
                } else {
                    requestData[key] = self.state.settingsData[key];
                }
            });
            requestData.serviceCharge = Number(requestData.serviceCharge);
            requestData.driverCommission = Number(requestData.driverCommission);
            requestData.maximumDeliveryCharge = Number(requestData.maximumDeliveryCharge);
            if (requestData.cloudPrint) {
                requestData.autoConfirm = true;
                requestData.autoConfirmDelayInSeconds = 60;
            }
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.post(url, requestData)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.onFormSubmit();
                            return;
                        }
                        if (result.data.status) {
                            let vendorCurrentData = getLoginUserDetails();
                            global.config.sidebarFooter = false;
                            global.config.context = false;
                            this.props.saveData(false);
                            this.setState({
                                isOpenFooter: false,
                                isShowFooter: false,
                                oldValuePrint: result.data.response.cloudPrint,
                                tmpoldValuePrint: JSON.stringify(result.data.response.cloudPrint),
                                tmpsettingsData: JSON.stringify(this.state.settingsData),
                            });
                            vendorCurrentData['vendorAllDetails'] = result.data.response;
                            localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                            toast.success("Success: Setting details updated!", {
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            })
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        self.setState({ submitProgress: false });
                    })
                    .catch(error => {
                        self.setState({ submitProgress: false });
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in updating setting details.: ", error);
                    })
            }
            catch (error) {
                self.setState({ submitProgress: false });
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating setting details. ", error);
            }
        } else {
            toast.error("Please provide all required data in valid format.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    async updatePrintStation(data) {
        if (this.state.settingsData.cloudPrint) {
            for (let i = 0; i < data.length; i += 1) {
                if (!data[i].deviceAddress) {
                    data[i].deviceAddressError = `The 'Device Address' is a required field. It is the MAC address of the printer.`;
                } else {
                    if (!(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(data[i].deviceAddress))) {
                        data[i].deviceAddressError = `The 'Device Address' is not in a valid format.`;
                    } else {
                        data[i].deviceAddressError = '';
                    }
                }
            }
            this.setState({
                updatePrintData: data
            });
        } else {
            for (let i = 0; i < data.length; i += 1) {
                if (!data[i].hostName) {
                    data[i].hostNameError = `The 'Host Name' is a required field. It must be in the form of the IP address. Use the sVang mobile app admin screen to discover the printers.`;
                } else {
                    if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(data[i].hostName))) {
                        data[i].hostNameError = `The 'Host Name' is not in the valid IP address format.`;
                    } else {
                        data[i].hostNameError = '';
                    }
                }
            
                if (!data[i].port) {
                    data[i].portError = `The 'Port' is a required field and normally it is 9100.`;
                } else {
                    if (!(/^[0-9]+$/.test(data[i].port))) {
                        data[i].portError = `The 'Port' field is in a number only format.`;
                    } else {
                        data[i].portError = '';
                    }
                }
                
                // if (!data[i].dotwidth) {
                //     data[i].dotwidthError = `The 'Dot Width' is a required field. It is the number of characters that a printer can print in a line.`;
                // } else {
                //     if (!(/^[0-9]+$/.test(data[i].dotwidth))) {
                //         data[i].dotwidthError = `The 'Dot Width' is a number only field.`;
                //     } else {
                //         data[i].dotwidthError = '';
                //     }
                // }
                this.setState({
                    updatePrintData: data
                });
            }
        }

        let valid = true;
        for (let i = 0; i < data.length; i += 1) {
            // if (data[i].deviceAddressError || data[i].hostNameError || data[i].portError || data[i].dotwidthError) {
            if (data[i].deviceAddressError || data[i].hostNameError || data[i].portError) {
                valid = false;
                break;
            } else {
                valid = true;
            }
        }
        if (valid) {
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            let url = `vendor/updatevendor/printstation`;
            let requestData = {
                cloudPrint: this.state.settingsData.cloudPrint,
                vendorId,
                data: this.state.updatePrintData
            }
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.post(url, requestData)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.updatePrintStation(this.state.updatePrintData);
                            return;
                        }
                        if (result.data.status) {
                            let vendorCurrentData = getLoginUserDetails();
                            this.setState({
                                oldValuePrint: result.data.response.cloudPrint,
                                printStationModelShow: false,
                            });
                            vendorCurrentData['vendorAllDetails'] = result.data.response;
                            localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                            toast.success("Success: Setting details print station updated!", {
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            })
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in updating print station.: ", error);
                    })
            } catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating print station. ", error);
            }
        }
        
    }
    onBasicDetailsPrintStationChange(e, printStationName, data, key) {
        if (this.state.settingsData.cloudPrint) {
            for (let i = 0; i < data.length; i += 1) {
                if (data[i].printStationName === printStationName) {
                    data[i].deviceAddress = e.target.value;
                    if (!data[i].deviceAddress) {
                        data[i].deviceAddressError = `The 'Device Address' is a required field. It is the MAC address of the printer.`;
                    } else {
                        if (!(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(data[i].deviceAddress))) {
                            data[i].deviceAddressError = `The 'Device Address' is not in a valid format.`;
                        } else {
                            data[i].deviceAddressError = '';
                        }
                    }
                    this.setState({
                        updatePrintData: data
                    });
                }
            }
        } else {
            for (let i = 0; i < data.length; i += 1) {
                if (data[i].printStationName === printStationName) {
                    if (key === 'hostName') {
                        data[i].hostName = e.target.value;
                        if (!data[i].hostName) {
                            data[i].hostNameError = `The 'Host Name' is a required field. It must be in the form of the IP address. Use the sVang mobile app admin screen to discover the printers.`;
                        } else {
                            if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(data[i].hostName))) {
                                data[i].hostNameError = `The 'Host Name' is not in the valid IP address format.`;
                            } else {
                                data[i].hostNameError = '';
                            }
                        }
                        this.setState({
                            updatePrintData: data
                        });
                    } else if (key === 'port') {
                        data[i].port = e.target.value;
                        if (!data[i].port) {
                            data[i].portError = `The 'Port' is a required field and normally it is 9100.`;
                        } else {
                            if (!(/^[0-9]+$/.test(data[i].port))) {
                                data[i].portError = `The 'Port' field is in a number only format.`;
                            } else {
                                data[i].portError = '';
                            }
                        }
                        this.setState({
                            updatePrintData: data
                        });
                    }
                    // else {
                    //     data[i].dotwidth = e.target.value;
                    //     if (!data[i].dotwidth) {
                    //         data[i].dotwidthError = `The 'Dot Width' is a required field. It is the number of characters that a printer can print in a line.`;
                    //     } else {
                    //         if (!(/^[0-9]+$/.test(data[i].dotwidth))) {
                    //             data[i].dotwidthError = `The 'Dot Width' is a number only field.`;
                    //         } else {
                    //             data[i].dotwidthError = '';
                    //         }
                    //     }
                    //     this.setState({
                    //         updatePrintData: data
                    //     });
                    // }
                }
            }
        }
    }
    render() {
        let { settingsData, settingsErrorData, printStation, oldValuePrint, helpText, isOpenFooter, isShowFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
          } else {
              global.config.context = false;
          }
          if (global.config.sidebarFooter) {
              isShowFooter = true;
          }
        return (
            <div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Go Online</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Ready to Go Online?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample1"
                                                        value={true}
                                                        checked={settingsData.enabled === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.enabled = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample1"
                                                        value={false}
                                                        checked={settingsData.enabled === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.enabled = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Delivery Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Is Delivery Available?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample2"
                                                        value={true}
                                                        checked={settingsData.deliveryPublic === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryPublic = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample2"
                                                        value={false}
                                                        checked={settingsData.deliveryPublic === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryPublic = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Is Delivery Available to University?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample3"
                                                        value={true}
                                                        checked={settingsData.deliveryUniversity === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryUniversity = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample3"
                                                        value={false}
                                                        checked={settingsData.deliveryUniversity === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryUniversity = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Is Delivery Taxable?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample4"
                                                        value={true}
                                                        checked={settingsData.deliveryTaxable === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryTaxable = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample4"
                                                        value={false}
                                                        checked={settingsData.deliveryTaxable === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryTaxable = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Delivery Method</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample11"
                                                        value="dyod"
                                                        checked={settingsData.deliveryMethod === 'dyod'}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryMethod = 'dyod';
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> DYOD </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample11"
                                                        value="postmates"
                                                        checked={settingsData.deliveryMethod === "postmates"}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryMethod = "postmates";
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Postmates </span>
                                            </li>
                                            {/* <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample11"
                                                        value="both"
                                                        checked={settingsData.deliveryMethod === "both"}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.deliveryMethod = "both";
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Both </span>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Delivery Lead Time (Minutes)</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.deliveryLeadTime}></i>
                                    <div className="input-group">
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.deliveryLeadTime !== "" ? 'invalid' : '')}
                                            id="deliveryLeadTime"
                                            placeholder="Delivery Lead Time"
                                            value={settingsData.deliveryLeadTime}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="120" />
                                        {settingsErrorData.deliveryLeadTime !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.deliveryLeadTime}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Delivery Charge </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.delivery_charge !== "" ? 'invalid' : '')}
                                            id="delivery_charge"
                                            placeholder="Delivery Charge"
                                            value={settingsData.delivery_charge}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="999" />
                                        {settingsErrorData.delivery_charge !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.delivery_charge}
                                        </div>}
                                    </div>
                                </div>
                                {settingsData.deliveryMethod === 'postmates' && 
                                    <div className="col-lg-3 col-md-6 mb-2">
                                        <label htmlFor="name">Maximum Delivery Charge </label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">$</div>
                                            </div>
                                            <input type="number"
                                                className={"form-control " + (settingsErrorData.maximumDeliveryCharge !== "" ? 'invalid' : '')}
                                                id="maximumDeliveryCharge"
                                                placeholder="Maximum Delivery Charge"
                                                value={settingsData.maximumDeliveryCharge}
                                                onChange={this.onBasicDetailsChange}
                                                min="0"
                                                max="999" />
                                            {settingsErrorData.maximumDeliveryCharge !== "" && <div className="invalid-feedback">
                                                {settingsErrorData.maximumDeliveryCharge}
                                            </div>}
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Is Service Charge Taxable?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="seriviceChargeTaxableRadio"
                                                        value={true}
                                                        checked={settingsData.serviceChargeTaxable === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.serviceChargeTaxable = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="seriviceChargeTaxableRadio"
                                                        value={false}
                                                        checked={settingsData.serviceChargeTaxable === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.serviceChargeTaxable = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Service Charge Type</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="seriviceChargeTypeRadio"
                                                        value="percentage"
                                                        checked={settingsData.serviceChargeType === 'percentage'}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.serviceChargeType = event.currentTarget.value === "percentage" ? "percentage" : "fix";
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Percentage </span>
                                            </li>
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="seriviceChargeTypeRadio"
                                                        value="fix"
                                                        checked={settingsData.serviceChargeType === 'fix'}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.serviceChargeType = event.currentTarget.value === "fix" ? "fix" : "percentage";
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Fix </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Service Charge </label>
                                    <div className="input-group">
                                        {/* <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div> */}
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.serviceCharge !== "" ? 'invalid' : '')}
                                            id="serviceCharge"
                                            placeholder="Service Charge"
                                            value={settingsData.serviceCharge}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="999" />
                                        {settingsErrorData.serviceCharge !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.serviceCharge}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Delivery Radius (Miles)</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.deliveryRadius}></i>
                                    <div className="input-group">
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.deliveryRadius !== "" ? 'invalid' : '')}
                                            id="deliveryRadius"
                                            placeholder="Delivery Radius"
                                            value={settingsData.deliveryRadius}
                                            onChange={this.onBasicDetailsChange}
                                            min="0" />
                                        {settingsErrorData.deliveryRadius !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.deliveryRadius}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Pick Up Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Pick Up Lead Time (Minutes)</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.pickUpLeadTime}></i>
                                    <div className="input-group">
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.pickupLeadTime !== "" ? 'invalid' : '')}
                                            id="pickupLeadTime"
                                            placeholder="Pick-up Lead Time"
                                            value={settingsData.pickupLeadTime}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="120" />
                                        {settingsErrorData.pickupLeadTime !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.pickupLeadTime}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Pick Up Radius (Miles)</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.pickupRadius}></i>
                                    <div className="input-group">
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.pickupRadius !== "" ? 'invalid' : '')}
                                            id="pickupRadius"
                                            placeholder="Pick Up Radius"
                                            value={settingsData.pickupRadius}
                                            onChange={this.onBasicDetailsChange}
                                            min="0" />
                                        {settingsErrorData.pickupRadius !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.pickupRadius}
                                        </div>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Dine In Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Dine In Lead Time (Minutes)</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.inDineLeadTime}></i>
                                    <div className="input-group">
                                        <input type="number"
                                            className="form-control"
                                            id="inDineLeadTime"
                                            placeholder="Dine In Lead Time"
                                            value={settingsData.inDineLeadTime}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="120" />
                                        {settingsErrorData.inDineLeadTime !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.inDineLeadTime}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Pay Later (For Dine In only)</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample6"
                                                        value={true}
                                                        checked={settingsData.payLater === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.payLater = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample6"
                                                        value={false}
                                                        checked={settingsData.payLater === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.payLater = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Payment Disable (For Dine In only)</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample10"
                                                        value={true}
                                                        checked={settingsData.paymentDisableDineIn === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.paymentDisableDineIn = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample10"
                                                        value={false}
                                                        checked={settingsData.paymentDisableDineIn === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.paymentDisableDineIn = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Tip Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-12 col-md-12 mb-2 tip-percentage">
                                    <label htmlFor="name" className="required">Tip Percentage</label>
                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.tipPercentage}></i>
                                    <div className="input-group">
                                        {
                                            settingsData.tips && settingsData.tips.value && settingsData.tips.value.map((option, index) => (
                                                <React.Fragment key={`option-${index}`}>
                                                    <ul className="ms-list d-flex">
                                                        <li className="ms-list-item pl-0 pt-1">
                                                            <label className="ms-checkbox-wrap mr-1">
                                                                <input type="radio"
                                                                    name="radioExample5"
                                                                    value={index}
                                                                    checked={settingsData.tips.default === index}
                                                                    onChange={(event) => {
                                                                        let tempSettingsData = this.state.settingsData;
                                                                        tempSettingsData.tips.default = Number(event.currentTarget.value);
                                                                        this.props.saveData(true);
                                                                        this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                                    }}
                                                                />
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                            <span> Default </span>
                                                        </li>
                                                    </ul>
                                                    <input type="number"
                                                        className={"form-control mr-2 " + (settingsErrorData.tips !== "" && settingsErrorData.tipIndex === index ? 'invalid' : '')}
                                                        placeholder=""
                                                        value={option}
                                                        data-index={index}
                                                        min="0"
                                                        onChange={(event) => this.onBasicDetailsChange(event, 'tips')} />
                                                </React.Fragment>
                                            ))
                                        }
                                        {settingsErrorData.tips !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.tips}
                                        </div>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Print Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Cloud Print</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.cloudPrint}></i>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample7"
                                                        value={true}
                                                        checked={settingsData.cloudPrint === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.cloudPrint = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true });
                                                            // eslint-disable-next-line
                                                            if (printStation && printStation.length && oldValuePrint != tempSettingsData.cloudPrint) {
                                                                const tmpData = [];
                                                                for (let i = 0; i < printStation.length; i += 1) {
                                                                    tmpData.push({
                                                                        _id: printStation[i]._id,
                                                                        deviceAddress: "",
                                                                        deviceAddressError: "",
                                                                        printStationName: printStation[i].printStationName,
                                                                        printerVendor: printStation[i].printerVendor
                                                                    });
                                                                }
                                                                this.setState({ updatePrintData: tmpData });
                                                                this.setState({ printStationModelShow: true });
                                                            }
                                                            // let tempSettingsData = this.state.settingsData;
                                                            // tempSettingsData.cloudPrint = event.currentTarget.value === "true" ? true : false;
                                                            // this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample7"
                                                        value={false}
                                                        checked={settingsData.cloudPrint === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.cloudPrint = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                            // eslint-disable-next-line
                                                            if (printStation && printStation.length && oldValuePrint != tempSettingsData.cloudPrint) {
                                                                const tmpData = [];
                                                                for (let i = 0; i < printStation.length; i += 1) {
                                                                    tmpData.push({
                                                                        _id: printStation[i]._id,
                                                                        printStationName: printStation[i].printStationName,
                                                                        hostName: "",
                                                                        hostNameError: "",
                                                                        port: "9100",
                                                                        portError: "",
                                                                        dotwidth: printStation[i].printerVendor === 'impact' ? 396: 576,
                                                                        // dotwidthError: "",
                                                                        printerVendor: printStation[i].printerVendor
                                                                    });
                                                                }
                                                                this.setState({ updatePrintData: tmpData });
                                                                this.setState({ printStationModelShow: true });
                                                            }

                                                            // let tempSettingsData = this.state.settingsData;
                                                            // tempSettingsData.cloudPrint = event.currentTarget.value === "false" ? false : true;
                                                            // this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">POS Print</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.pos}></i>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample9"
                                                        value={true}
                                                        checked={settingsData.pos === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.pos = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample9"
                                                        value={false}
                                                        checked={settingsData.pos === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.pos = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Print Sides As Line Items</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.printSidesAsLineItems}></i>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="printSidesAsLineItemsRadio"
                                                        value={true}
                                                        checked={settingsData.printSidesAsLineItems === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.printSidesAsLineItems = event.currentTarget.value === "true" ? true : false;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="printSidesAsLineItemsRadio"
                                                        value={false}
                                                        checked={settingsData.printSidesAsLineItems === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.printSidesAsLineItems = event.currentTarget.value === "false" ? false : true;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {settingsData.printSidesAsLineItems &&
                                    <div className="col-md-3 mb-2">
                                        <label htmlFor="printSidesAsLineItemsCategory">Select Category</label>
                                        <div className="input-group row">
                                            {settingsData && settingsData.printSidesAsLineItemsCategoryArray && settingsData.printSidesAsLineItemsCategoryArray.map((category, index) => (
                                                <span className="ml-1 col-md-12" key={`option-${index}`}>
                                                    <label className="ms-checkbox-wrap">
                                                        <input type="checkbox" checked={category.checked}
                                                            onChange={(e) => {
                                                                let tempData = settingsData;
                                                                tempData.printSidesAsLineItemsCategoryArray[index].checked = e.target.checked;
                                                                if (e.target.checked) {
                                                                    tempData.printSidesAsLineItemsCategorySimpleArray.push(category.name)
                                                                } else {
                                                                    for (let i = 0; i < tempData.printSidesAsLineItemsCategorySimpleArray.length; i += 1) {
                                                                        if (tempData.printSidesAsLineItemsCategorySimpleArray[i] === category.name) {
                                                                            tempData.printSidesAsLineItemsCategorySimpleArray.splice(i, 1);
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({ settingsData: tempData })
                                                            }} />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span>{category.name}</span>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> 
                    </div>
                    <div className="ms-panel custom-panel">
                        <div className="ms-panel-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Other Settings</h5>
                            </div>
                            <hr />
                        </div>
                        <div className="ms-panel-body">
                            <div className="form-row">
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Direct Payment to Resturant</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.directPayment}></i>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample13"
                                                        value={true}
                                                        checked={settingsData.directPayment === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.directPayment = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample13"
                                                        value={false}
                                                        checked={settingsData.directPayment === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.directPayment = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Is Deals Available to Student?</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample8"
                                                        value={true}
                                                        checked={settingsData.dealsForStudent === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.dealsForStudent = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample8"
                                                        value={false}
                                                        checked={settingsData.dealsForStudent === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.dealsForStudent = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Minimum Order Charge </label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.minimumOrderCharge}></i>
                                    <div className="input-group mb-0">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.minimumOrderCharge !== "" ? 'invalid' : '')}
                                            id="minimumOrderCharge"
                                            placeholder="Minimum Order Charge"
                                            value={settingsData.minimumOrderCharge}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="999" />
                                        {settingsErrorData.minimumOrderCharge !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.minimumOrderCharge}
                                        </div>}
                                    </div>
                                </div>
                                {!settingsData.cloudPrint && 
                                    <div className="col-lg-3 col-md-6">
                                        <label htmlFor="name">Auto Confirm</label>
                                        <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.autoConfirm}></i>
                                        <div className="input-group mb-0">
                                            <ul className="ms-list d-flex">
                                                <li className="ms-list-item pl-0">
                                                    <label className="ms-checkbox-wrap">
                                                        <input type="radio"
                                                            name="radioExample12"
                                                            value={true}
                                                            checked={settingsData.autoConfirm === true}
                                                            onChange={(event) => {
                                                                let tempSettingsData = this.state.settingsData;
                                                                tempSettingsData.autoConfirm = event.currentTarget.value === "true" ? true : false;
                                                                this.props.saveData(true);
                                                                this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span> Yes </span>
                                                </li>
                                                <li className="ms-list-item">
                                                    <label className="ms-checkbox-wrap">
                                                        <input
                                                            type="radio"
                                                            name="radioExample12"
                                                            value={false}
                                                            checked={settingsData.autoConfirm === false}
                                                            onChange={(event) => {
                                                                let tempSettingsData = this.state.settingsData;
                                                                tempSettingsData.autoConfirm = event.currentTarget.value === "false" ? false : true;
                                                                this.props.saveData(true);
                                                                this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span> No </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {settingsData.autoConfirm && !settingsData.cloudPrint && 
                                    <div className="col-lg-3 col-md-6">
                                        <label htmlFor="deviceDetails">Select Device For Auto Confirm</label>
                                        <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.autoDeviceConfirm}></i>
                                        <div className="input-group">
                                            <select className="form-control" name="deviceDetails" id="university"
                                                onChange={(event) => {
                                                    let tempSettingsData = this.state.settingsData;
                                                    tempSettingsData.selectedDeviceId = event.target.value;
                                                    this.props.saveData(true);
                                                    this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                }}
                                                value={settingsData.selectedDeviceId}>
                                                <option value="">Select Device</option>
                                                {settingsData?.deviceDetails && settingsData?.deviceDetails.length && settingsData?.deviceDetails.map((device, index) => (
                                                    <option key={index} value={device.deviceId}>{device.deviceName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {settingsData.autoConfirm && !settingsData.cloudPrint && 
                                    <div className="col-lg-3 col-md-6 mb-2">
                                        <label htmlFor="name">Auto Confirm Delay In Seconds</label>
                                        <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.autoConfirmDelayInSeconds}></i>
                                        <div className="input-group">
                                            <input type="number"
                                                className="form-control"
                                                id="autoConfirmDelayInSeconds"
                                                placeholder="Auto Confirm Delay Time"
                                                value={settingsData.autoConfirmDelayInSeconds}
                                                onChange={this.onBasicDetailsChange}
                                                />
                                            {settingsErrorData.autoConfirmDelayInSeconds !== "" && <div className="invalid-feedback">
                                                {settingsErrorData.autoConfirmDelayInSeconds}
                                            </div>}
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label htmlFor="name">Per order driver commission</label>
                                    <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.driverCommission}></i>
                                    <div className="input-group mb-0">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="number"
                                            className={"form-control " + (settingsErrorData.driverCommission !== "" ? 'invalid' : '')}
                                            id="driverCommission"
                                            placeholder="Driver commission Amount"
                                            value={settingsData.driverCommission}
                                            onChange={this.onBasicDetailsChange}
                                            min="0"
                                            max="999" />
                                        {settingsErrorData.driverCommission !== "" && <div className="invalid-feedback">
                                            {settingsErrorData.driverCommission}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Manage Website</label>
                                    {/* <i className="fa fa-question-circle ml-2 text-secondary" data-tip={helpText.manageWebsite}></i> */}
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="manageWebsiteRadio"
                                                        value={true}
                                                        checked={settingsData.manageWebsite === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.manageWebsite = event.currentTarget.value === "true" ? true : false;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="manageWebsiteRadio"
                                                        value={false}
                                                        checked={settingsData.manageWebsite === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.manageWebsite = event.currentTarget.value === "false" ? false : true;
                                                            this.props.saveData(true);
                                                            this.setState({ settingsData: tempSettingsData, isOpenFooter: true })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">POS Setting</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="radioExample14"
                                                        value="clover"
                                                        checked={settingsData.posSettingMethod === 'clover'}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.posSettingMethod = 'clover';
                                                            tempSettingsData.posSettings.clover = true;
                                                            tempSettingsData.posSettings.square = false;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Clover </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="radioExample14"
                                                        value="square"
                                                        checked={settingsData.posSettingMethod === "square"}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.posSettingMethod = "square";
                                                            tempSettingsData.posSettings.clover = false;
                                                            tempSettingsData.posSettings.square = true;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Square </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label htmlFor="name">Delay Order Confirm</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="delayOrderConfirmRadio"
                                                        value={true}
                                                        checked={settingsData.delayOrderConfirm === true}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.delayOrderConfirm = event.currentTarget.value === "true" ? true : false;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="delayOrderConfirmRadio"
                                                        value={false}
                                                        checked={settingsData.delayOrderConfirm === false}
                                                        onChange={(event) => {
                                                            let tempSettingsData = this.state.settingsData;
                                                            tempSettingsData.delayOrderConfirm = event.currentTarget.value === "false" ? false : true;
                                                            this.setState({ settingsData: tempSettingsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-12 col-md-12 mb-4 text-right">
                        <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Save {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                    </div>
                    <Modal open={this.state.printStationModelShow} onClose={this.onCloseModal} styles={{modal: {width: '100%'}}} center>
                        <div className="container">
                            <h5>Print Station</h5>
                            <div className="col-xl-12 col-md-12 pl-0 pr-0">
                                <div className="ms-panel ms-panel-fh mb-0">
                                    <div className="ms-panel-body p-0">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                        {this.state.settingsData && this.state.settingsData.cloudPrint &&
                                                            <tr>
                                                                <th scope="col">Print Station Name</th>
                                                                <th scope="col">Device Address</th>
                                                            </tr>
                                                        }
                                                        {this.state.settingsData && !this.state.settingsData.cloudPrint &&
                                                            <tr>
                                                                <th scope="col">Print Station Name</th>
                                                                <th scope="col">Host Name</th>
                                                                <th scope="col">Port</th>
                                                                {/* <th scope="col">Dot width</th> */}
                                                            </tr>
                                                        }
                                                </thead>
                                                <tbody>
                                                    {this.state.settingsData && this.state.settingsData.cloudPrint && this.state.updatePrintData && this.state.updatePrintData.length &&
                                                        this.state.updatePrintData.map((uRow, uIndex) => (    
                                                            <tr key={uIndex}>
                                                                <td className="p-t-20" width="25%">{uRow.printStationName}</td>
                                                                <td width="75%">
                                                                    <form autoComplete="off">
                                                                    <input type="text"
                                                                        className={"form-control " + (uRow.deviceAddressError !== "" ? 'invalid' : '')}
                                                                        id={"deviceAddress"+uRow.printStationName}
                                                                        autoComplete="off"
                                                                        name={"deviceAddress"+uRow.printStationName}
                                                                        placeholder="Device Address"
                                                                        value={uRow.deviceAddress}
                                                                        onChange={(e) => this.onBasicDetailsPrintStationChange(e, uRow.printStationName, this.state.updatePrintData)} />
                                                                        {uRow.deviceAddressError && 
                                                                            <div className="invalid-feedback d-contents">{uRow.deviceAddressError}</div>
                                                                        }
                                                                        </form>
                                                                </td>
                                                                {/* <td>{uRow.deviceAddress}</td> */}
                                                            </tr>
                                                        ))
                                                    }
                                                    {this.state.settingsData && !this.state.settingsData.cloudPrint && this.state.updatePrintData && this.state.updatePrintData.length &&
                                                        this.state.updatePrintData.map((uRow, uIndex) => (    
                                                            <tr key={uIndex}>
                                                                <td className="p-t-20" width="25%">{uRow.printStationName}</td>
                                                                <td width="30%">
                                                                <form autoComplete="off">
                                                                    <input type="text"
                                                                        className={"form-control " + (uRow.hostNameError !== "" ? 'invalid' : '')}
                                                                        id={"hostName"+uRow.printStationName}
                                                                        autoComplete="off"
                                                                        name={"hostName"+uRow.printStationName}
                                                                        placeholder="Host Name"
                                                                        value={uRow.hostName}
                                                                        onChange={(e) => this.onBasicDetailsPrintStationChange(e, uRow.printStationName, this.state.updatePrintData, 'hostName')} />
                                                                        {uRow.hostNameError && 
                                                                            <div className="invalid-feedback d-contents">{uRow.hostNameError}</div>
                                                                        }
                                                                        </form>
                                                                </td>
                                                                <td width="20%">
                                                                <form autoComplete="off">
                                                                    <input type="text"
                                                                        className={"form-control " + (uRow.portError !== "" ? 'invalid' : '')}
                                                                        id={"port"+uRow.printStationName}
                                                                        autoComplete="off"
                                                                        name={"port"+uRow.printStationName}
                                                                        placeholder="Port"
                                                                        value={uRow.port}
                                                                        onChange={(e) => this.onBasicDetailsPrintStationChange(e, uRow.printStationName, this.state.updatePrintData, 'port')} />
                                                                        {uRow.portError && 
                                                                            <div className="invalid-feedback d-contents">{uRow.portError}</div>
                                                                        }
                                                                    </form>
                                                                    </td>
                                                                {/* <td width="25%">
                                                                <form autoComplete="off">
                                                                    <input type="text"
                                                                        className={"form-control " + (uRow.dotwidthError !== "" ? 'invalid' : '')}
                                                                        id={"dotwidth"+uRow.printStationName}
                                                                        autoComplete="off"
                                                                        name={"dotwidth"+uRow.printStationName}
                                                                        placeholder="Dot Width"
                                                                        value={uRow.dotwidth}
                                                                        onChange={(e) => this.onBasicDetailsPrintStationChange(e, uRow.printStationName, this.state.updatePrintData, 'dotwidth')} />
                                                                        {uRow.dotwidthError && 
                                                                            <div className="invalid-feedback d-contents">{uRow.dotwidthError}</div>
                                                                        }
                                                                    </form>
                                                                </td> */}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary float-right" onClick={() => this.updatePrintStation(this.state.updatePrintData)}>
                                    update
                            </button>
                        </div>
                    </Modal>                                                    

                </form>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default SettingsDetails;