import React from 'react';
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import { firestore, collectionName } from "../../../firebase";
import { auth } from "../../../firebase";
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';
import logo from '../../../assets/img/logo2.png';

const { v1: uuidv1 } = require('uuid');

class SetVendor extends React.Component {

    constructor(props) {
        super(props);
        const INITIAL_VENDOR_DATA = {
            name: "",
            title: "",
            website: "",
            address: "",
            latitude: "",
            longitude: "",
            state: "",
            city: "",
            zipcode: "",
            deliveryPublic: true,
            deliveryTaxable: true,
            pickupPublic: true,
            dineinPublic: true,
            pos: false,
            restaurantType: [],
            cuisineType: [],
            subscriptionPlan: 'basic'
        };
        const INITIAL_VENDOR_ERROR_DATA = {
            name: "",
            title: "",
            website: "",
            address: "",
        };
        const DEFAULT_TIPS = {
            id: 'tips',
            default: 1,
            value: ["0", "10", "15", "20"]
        }
        this.state = {
            vendorData: INITIAL_VENDOR_DATA,
            vendorErrorData: INITIAL_VENDOR_ERROR_DATA,
            serviceTypes: [],
            restaurantTypes: [],
            cuisineTypes: [],
            defaultTips: DEFAULT_TIPS,
            tips: null,
            commonError: "",
            submitProgress: false,
            googleLocationKey: process.env.REACT_APP_GOOGLELOCATIONAPI && process.env.REACT_APP_GOOGLELOCATIONAPI !== "" ? process.env.REACT_APP_GOOGLELOCATIONAPI : "AIzaSyAfo54HzapKPSPGoq3c0JCParHv9vkWdZE"
        }
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.extractAddressDetails = this.extractAddressDetails.bind(this);
        this.getAllFilters = this.getAllFilters.bind(this);
        this.verifyEmailOnAWS = this.verifyEmailOnAWS.bind(this);
    }

    componentDidMount() {
        this.getAllFilters();
    }

    async getAllFilters() {
        try {
            const allFilters = await firestore.collection(collectionName.VENDORFILTERS).get();
            allFilters.docs.forEach(snapshot => {
                let filter = snapshot.data();
                if (filter.name === "Service Type") {
                    let serviceTypes = [];
                    serviceTypes = filter.options.map(type => {
                        let returnValue = null;
                        if (type === "Delivery") {
                            returnValue = { name: type, dbField: 'deliveryPublic' }
                        } else if (type === "Pickup") {
                            returnValue = { name: type, dbField: 'pickupPublic' }
                        } else if (type === "Dine in") {
                            returnValue = { name: type, dbField: 'dineinPublic' }
                        }
                        return returnValue;
                    })
                    this.setState({ serviceTypes })
                }
                if (filter.name === "Restaurant Type") {
                    let formatedData = this.Chunkify(filter.options, filter.options.length / 2);
                    this.setState({ restaurantTypes: formatedData })
                }
                if (filter.name === "Cuisine Type") {
                    let formatedData = this.Chunkify(filter.options, filter.options.length / 2);
                    this.setState({ cuisineTypes: formatedData })
                }
                if (filter.name === "tips") {
                    this.setState({ tips: filter });
                }
            })
        }
        catch (error) {

        }
    }

    // Divide array into give number of sub-array
    Chunkify(a, n) {
        var len = a.length,
            out = [],
            i = 0,
            size;
        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, (i += size)));
            }
        } else {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, (i += size)));
            }
        }
        return out;
    };

    extractAddressDetails(data) {
        let address = data;
        let tempVendorData = this.state.vendorData;
        let tempErrorData = this.state.vendorErrorData;
        address = data.label;
        tempVendorData.address = address;
        tempErrorData['address'] = "";
        this.setState({ vendorErrorData: tempErrorData });
        geocodeByAddress(address)
            .then(results => {
                let addressAllDetails = results[0];
                getLatLng(addressAllDetails)
                    .then(({ lat, lng }) => {
                        tempVendorData.latitude = lat;
                        tempVendorData.longitude = lng;
                        this.setState({ vendorData: tempVendorData });
                    });
                if (
                    addressAllDetails.address_components &&
                    addressAllDetails.address_components.length > 0
                ) {
                    for (let i = 0; i < addressAllDetails.address_components.length; i += 1) {
                        var addressType = addressAllDetails.address_components[i].types[0];
                        var val = addressAllDetails.address_components[i]['long_name'];
                        if (addressType === "administrative_area_level_1") {
                            tempVendorData.state = val;
                        }
                        if (addressType === "locality") {
                            tempVendorData.city = val;
                        }
                        if (addressType === "postal_code") {
                            tempVendorData.zipcode = val;
                        }
                    }
                    this.setState({ vendorData: tempVendorData });
                }
            });
    }

    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.vendorData;
        let formErrors = this.state.vendorErrorData;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"] === "")) {
            fieldName = 'name';
            formErrors[fieldName] = `Restaurant name is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "name" && value !== "") || (formSubmit === true && formDetails["name"] !== "")) {
            if (!(/\d/.test(formDetails["name"]) || /[a-zA-Z]/.test(formDetails["name"]))) {
                fieldName = 'name';
                formErrors[fieldName] = `Please provide a valid and proper restaurant name.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `Restaurant short name is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "title" && value !== "") || (formSubmit === true && formDetails["title"] !== "")) {
            if (!(/\d/.test(formDetails["title"]) || /[a-zA-Z]/.test(formDetails["title"]))) {
                fieldName = 'title';
                formErrors[fieldName] = `Please choose a name other than the title.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "website") || (formSubmit === true)) {
            fieldName = 'website';
            if ((formSubmit === false && value !== "") || (formSubmit === true && formDetails[fieldName] !== "")) {
                // eslint-disable-next-line
                var urlRegex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
                if (!urlRegex.test(formDetails[fieldName])) {
                    formErrors[fieldName] = `Please enter a proper Restaurant website address. Example https://www.myrestaurant.com.`;
                    isError = true;
                }
            }
            else if (formSubmit === false) {
                formErrors[fieldName] = "";
            }
        }
        if ((formSubmit === false && fieldName === "address" && value === "") || (formSubmit === true && formDetails["address"] === "")) {
            fieldName = 'address';
            formErrors[fieldName] = `Address field is a required field.`;
            isError = true;
        }
        this.setState({ vendorErrorData: formErrors });
        this.setState({ vendorData: formDetails });
        return isError;
    }

    onDetailsChange(event) {
        let tempVendorData = this.state.vendorData;
        let tempErrorData = this.state.vendorErrorData;
        if (event.target.type === "checkbox") {
            if (event.target.checked) {
                tempVendorData[event.target.name].push(event.target.value);
            } else {
                var index = tempVendorData[event.target.name].indexOf(event.target.value);
                if (index !== -1) {
                    tempVendorData[event.target.name].splice(index, 1);
                }
            }
        } else {
            tempVendorData[event.target.name] = event.target.value;
            let isError = this.checkValidation(event.target.name, event.target.value);
            if (!isError) {
                tempErrorData[event.target.name] = "";
                this.setState({ vendorErrorData: tempErrorData });
            }
        }
        this.setState({ vendorData: tempVendorData });
    }

    onFocusOut(e) {
        let fieldName = e.target.name;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    async onFormSubmit(event) {
        event.preventDefault();
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            self.setState({ 'submitProgress': true });
            if (auth?.currentUser?.uid && auth.currentUser.uid !== "") {
                let vendorId = uuidv1().toUpperCase();
                let currentDate = new Date();
                let firestoreAuthUser = auth.currentUser;
                let appIdentifier = process.env.REACT_APP_APPIDENTIFIER && process.env.REACT_APP_APPIDENTIFIER !== "" ? process.env.REACT_APP_APPIDENTIFIER : "sVang";
                let newVendorData = {
                    id: vendorId,
                    enabled: false,
                    payLater: false,
                    cloudPrint: false,
                    autoConfirm: false,
                    autoConfirmDelayInSeconds: 60,
                    deliveryMethod: "",
                    paymentDisableDineIn: false,
                    dealsForStudent: false,
                    minimumOrderCharge: "",
                    serviceChargeType: "percentage",
                    manageWebsite: false,
                    delayOrderConfirm: false,
                    printSidesAsLineItems: false,
                    printSidesAsLineItemsCategoryArray: [],
                    printSidesAsLineItemsCategorySimpleArray: [],
                    posSettings: {
                        clover: false,
                        square: false
                    },
                    serviceChargeTaxable: false,
                    adminID: [firestoreAuthUser.uid],
                    product_categories: [],
                    createdAt: currentDate,
                    photo: "",
                    photos: [],
                    vendor_email: firestoreAuthUser.email,
                    autoChangeStatus: true,
                    driverCommission: 0,
                    tips: self.state.tips === null ? self.state.defaultTips : self.state.tips,
                    ...self.state.vendorData,
                };
                let userData = {
                    email: firestoreAuthUser.email,
                    firstName: "",
                    lastName: "",
                    id: firestoreAuthUser.uid,
                    userID: firestoreAuthUser.uid,
                    isOnline: false,
                    phone: "",
                    profilePictureURL: "",
                    pushToken: "",
                    pushTokenArray: [],
                    pushKitToken: "",
                    student: false,
                    univerSityID: "",
                    universityemail: "",
                    createdAt: currentDate,
                    created_at: currentDate,
                    lastOnlineTimestamp: currentDate,
                    appIdentifier: appIdentifier,
                    vendorID: vendorId
                };


                firestore.runTransaction((transaction) => {
                    transaction.set(firestore.collection(collectionName.VENDOR).doc(vendorId), newVendorData);
                    transaction.set(firestore.collection(collectionName.USERS).doc(firestoreAuthUser.uid), userData);
                    return Promise.resolve(true);
                }).then(async (result) => {
                    if (result) {
                        let token = getAuthTokenFromLocalStorage();
                        if (!token) {
                            let auth = await getAuth();
                            if (auth && auth.success && auth.token) {
                                localStorage.setItem('token', auth.token);
                            }
                        }
                        let vendorData = {
                            email: userData.email,
                            displayName: (userData.firstName ? userData.firstName : ""),
                            uid: userData.id,
                            id: userData.vendorID,
                            vendorAllDetails: userData
                        };
                        localStorage.setItem("vendor", JSON.stringify(vendorData));
                        self.setState({ 'submitProgress': false });
                        this.verifyEmailOnAWS(userData);
                        this.insertVendorMongoDBKafka(newVendorData);
                    } else {
                        self.setState({ 'submitProgress': false });
                        self.setState({ 'commonError': 'Oops! We encountered an expected error. Please try again, and if you continue to get the error, please send an email to support@svang.app' })
                    }
                }).catch((error) => {
                    console.error(error);
                    self.setState({ 'submitProgress': false });
                    self.setState({ 'commonError': 'Oops! We encountered an expected error. Please try again, and if you continue to get the error, please send an email to support@svang.app' })
                });

            } else {
                this.props.history.push("/login");
                auth.signOut();
            }
        } else {
            self.setState({ 'submitProgress': false });
            self.setState({ 'commonError': 'Oops! You did not enter the required field. Please enter them again and try again.' })
        }
    }

    insertVendorMongoDBKafka(vendorData) {
        let url = `insert/vendorMongoDBkafka`;
        axiosInstance.post(url, vendorData)
        .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                this.insertVendorMongoDBKafka(vendorData);
                return;
            }
        })
        .catch(error => {
            console.error("Exception in insert vendor mongoDB kafka ", error);
        });
    }

    verifyEmailOnAWS(userData) {
        let self = this;
        let url = `verifyEmail`;
        axiosInstance.post(url, { email: userData.email })
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.verifyEmailOnAWS(userData);
                    return;
                }
                if (result.data.status) {
                    self.props.history.push('/order_management');
                    let verificationStaus = result.data && result.data.verificationStaus && result.data.verificationStaus !== "" ? result.data.verificationStaus : ''
                    if (verificationStaus === "Success") {
                        toast.success(`Your account details were updated successfully.`, {
                            autoClose: 2000,
                            closeOnClick: true,
                        });
                    } else {
                        toast.success(`Your account details were updated successfully. Please check the verification email sent to your email ID '${userData.email}' and confirm it.`, {
                            autoClose: false,
                            closeOnClick: true,
                        });
                    }
                } else {
                    let error = result?.data?.statusText ? result.data.statusText : "";
                    console.error("Error: ", error);
                    self.props.history.push('/order_management');
                    toast.error(`Oops! We encountered an unexpected error. Please try again. If you continue to get the same error, please send us a mail to support@svang.app and mention a problem in sending verification email on your email ID '${userData.email}' and request a manual verification process.`, {
                        autoClose: false,
                        closeOnClick: true,
                    });
                }
            })
            .catch(error => {
                console.error("Exception in email ID verification process ", error);
                self.props.history.push('/order_management');
                toast.error(`Oops! We encountered an unexpected error. Please try again. If you continue to get the same error, please send us a mail to support@svang.app and mention a problem in sending verification email on your email ID '${userData.email}' and request a manual verification process.`, {
                    autoClose: false,
                    closeOnClick: true,
                });
            })
    }

    render() {
        const { vendorData, vendorErrorData, googleLocationKey, serviceTypes, restaurantTypes, cuisineTypes, commonError } = { ...this.state }
        return (
            <div className="ms-auth-container custom-auth-container">
                <div className="ms-auth-col custom-auth-col-4">
                    <div className="ms-auth-bg"></div>
                </div>
                <div className="ms-auth-col custom-auth-col-8">
                    <div className="ms-auth-form page-form" style={{ alignItems: 'unset' }}>
                        <form onSubmit={this.onFormSubmit} className="frm-set-vendor">
                            {commonError !== "" && <div className="alert alert-danger" role="alert">
                                {commonError}
                            </div>}
                            <img src={logo} alt="sVang" className="mb-3" />
                            <h4>Set Details</h4>
                            <p>Please enter your restaurant details</p>
                            <div className="mb-3">
                                <label htmlFor="name" className="required">Restaurant Name</label>
                                <div className="input-group">
                                    <input type="text"
                                        className={"form-control " + (vendorErrorData.name !== "" ? 'invalid' : '')}
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        value={vendorData.name}
                                        onChange={this.onDetailsChange}
                                        onBlur={this.onFocusOut}
                                        maxLength={75} />
                                    {vendorErrorData.name && vendorErrorData.name !== "" && <div className="invalid-feedback">
                                        {vendorErrorData.name}
                                    </div>}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="title" className="required">Restaurant Short Name</label>
                                <div className="input-group">
                                    <input type="text"
                                        className={"form-control " + (vendorErrorData.title !== "" ? 'invalid' : '')}
                                        id="title"
                                        name="title"
                                        placeholder="Short Name"
                                        value={vendorData.title}
                                        onChange={this.onDetailsChange}
                                        onBlur={this.onFocusOut}
                                        maxLength={50} />
                                    {vendorErrorData.title && vendorErrorData.title !== "" && <div className="invalid-feedback">
                                        {vendorErrorData.title}
                                    </div>}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="website">Restaurant Website</label>
                                <div className="input-group">
                                    <input type="text"
                                        className={"form-control " + (vendorErrorData.website !== "" ? 'invalid' : '')}
                                        id="website"
                                        name="website"
                                        placeholder="Website"
                                        value={vendorData.website}
                                        onChange={this.onDetailsChange}
                                        onBlur={this.onFocusOut} />
                                    {vendorErrorData.website && vendorErrorData.website !== "" && <div className="invalid-feedback">
                                        {vendorErrorData.website}
                                    </div>}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="required">Restaurant Address</label>
                                <div className="input-group">
                                    <GooglePlacesAutocomplete
                                        className="google-auto-complete"
                                        apiKey={googleLocationKey}
                                        selectProps={{
                                            placeholder: 'Type Restaurant Address',
                                            onChange: (data) => { this.extractAddressDetails(data) },
                                        }}
                                    />
                                    {vendorErrorData.address && vendorErrorData.address !== "" && <div className="invalid-feedback">
                                        {vendorErrorData.address}
                                    </div>}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address">Service Type</label>
                                <table className="w-100">
                                    <tbody>
                                        <tr>
                                            {serviceTypes && serviceTypes.length > 0 && serviceTypes.map((type, index) => (
                                                <td className="ms-list-item pl-0 mb-0" key={`service-${index}`}>
                                                    <label className="ms-checkbox-wrap">
                                                        <input type="checkbox"
                                                            id={type.dbField}
                                                            name={type.dbField}
                                                            value={type.value}
                                                            checked={vendorData[type.dbField]}
                                                            onChange={(e) => {
                                                                let tempData = vendorData;
                                                                vendorData[type.dbField] = e.target.checked;
                                                                this.setState({ vendorData: tempData })
                                                            }}
                                                        />
                                                        <i className="ms-checkbox-check"></i>
                                                    </label>
                                                    <span > {type.name} </span>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address">Restaurant Type</label>
                                <table className="w-100">
                                    <tbody>
                                        {restaurantTypes.length > 0 && restaurantTypes.map((types, index) => (
                                            <tr key={`restaurantTypes-${index}`}>
                                                {types.length > 0 && types.map((type, index) => (
                                                    <td key={`${type}-${index}`} style={{ width: '50%' }}>
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                value={type}
                                                                name="restaurantType"
                                                                onChange={this.onDetailsChange} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                        <span > {type} </span>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address">Cuisine Type</label>
                                <table className="w-100">
                                    <tbody>
                                        {cuisineTypes.length > 0 && cuisineTypes.map((types, index) => (
                                            <tr key={`cuisineTypes-${index}`}>
                                                {types.length > 0 && types.map((type, index) => (
                                                    <td key={`${type}-${index}`} style={{ width: '50%' }}>
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                value={type}
                                                                name="cuisineType"
                                                                onChange={this.onDetailsChange} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                        <span > {type} </span>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <button
                                className="btn btn-primary mt-5 d-block w-100"
                                type="submit"
                            > Continue {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetVendor;