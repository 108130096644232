import React from  'react'
const statusColor = {
    'Pending': '#ff8306',
    'Confirm': '#008080',
    'Out for Delivery': '#ff914d',
    'Delivered': '#7fba22',
    'Delivered (A)': '#ff8306',
    'At the door': '#0fc4d4',
    'Ready for Pickup': '#FFBF00',
    'Out from kitchen': '#CCCCFF',
    'Picked up': '#FF00FF',
    'Picked up (A)': '#ff8306',
    'Cancelled': '#000' ,
    'Completed': '#ff8306',
    'Completed (A)': '#ff8306',
}
const apiHost = process.env.REACT_APP_APIURL+"/";
const apiBaseURL = apiHost + "api/v1/";
const $ = require('jquery')
$.DataTable = require('datatables.net')

class DataTablesComp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tableRef: null
        }
    }

    componentDidMount(){
        if (this.props.id) {
            $(this.el).attr('id', this.props.id);
        }
        this.$el = $(this.el);
        let paging = true;
        if (this.props && this.props.paging && this.props.paging === 'true') {
            paging = true;
        }
        if (this.props && this.props.paging && this.props.paging === 'false') {
            paging = false;
        }

        let ordering = true;
        if (this.props && this.props.paging && this.props.ordering === 'true') {
            ordering = true;
        }
        if (this.props && this.props.ordering && this.props.ordering === 'false') {
            ordering = false;
        }
        let pageLength = 25;
        if (this.props && this.props.pageLength) {
            pageLength = Number(this.props.pageLength);
        }
        
        if (this.props.id === 'previousOrderTable') {
            var token = localStorage.getItem('token');
            let vendor = JSON.parse(localStorage.getItem('vendor'));
            let vendorId = vendor.id;
            this.setState({tableRef : this.$el.DataTable({
                    // ...this.props.data,
                    "processing": true,
                    "language": {
                        "processing": `
                            <div class="spinner spinner-8 spinner-margin" style="margin-top: 150px">
                                <div class="ms-circle1 ms-child"></div>
                                <div class="ms-circle2 ms-child"></div>
                                <div class="ms-circle3 ms-child"></div>
                                <div class="ms-circle4 ms-child"></div>
                                <div class="ms-circle5 ms-child"></div>
                                <div class="ms-circle6 ms-child"></div>
                                <div class="ms-circle7 ms-child"></div>
                                <div class="ms-circle8 ms-child"></div>
                                <div class="ms-circle9 ms-child"></div>
                                <div class="ms-circle10 ms-child"></div>
                                <div class="ms-circle11 ms-child"></div>
                                <div class="ms-circle12 ms-child"></div>
                            </div>`,
                    },
                    "serverSide": true,
                    "ajax": {
                        "url": `${apiBaseURL}order/get-previous-orders/${vendorId}?filter_by=previous`,
                        "type": "GET",
                        "headers": {
                            "Authorization": `Bearer ${token}`
                        },
                        "dataSrc": "response",
                    },
                    columnDefs: [{
                        targets: 0,
                        render: function (data, type, row, meta) {
                            return `<a href="/order_management/${row.FB_ID}">${row.orderID}</a>`
                        }
                    },
                    {
                        targets: 6,
                        render: function (data, type, row, meta) {
                            return `<div><span class="status-dot" style="background-color: ${statusColor[row.status]}"></span><span>${row.status}</span></div>`
                        }
                    }],
                    stateSave: true,
                    stateSaveCallback: function(settings,data) {
                        $('.spinner-margin').css('margin-top', '0px');
                        localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                    },
                    stateLoadCallback: function(settings) {
                        const data = JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) );
                        return data;
                    },
                    lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
                    pageLength: pageLength,
                    columns: this.props.columns, 
                    paging: paging,
                    ordering: false,
                    // order: [0, 'asc']
                })
            });
        } else {
            this.setState({tableRef : this.$el.DataTable({
                    ...this.props.data, 
                    stateSave: true,
                    stateSaveCallback: function(settings,data) {
                        localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                    },
                    stateLoadCallback: function(settings) {
                        const data = JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) );
                        return data;
                    },
                    lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
                    pageLength: pageLength,
                    columns: this.props.columns, 
                    paging: paging,
                    ordering: ordering,
                    order: [Number(this?.props?.sortColumn ? this.props.sortColumn : 0), 
                    this?.props?.sortOrder ? this.props.sortOrder : 'asc']
                })
            });
        }
        
    }

    componentDidUpdate(){
        if(this.props.reloadTable && this.props.reloadTable === true){
            this.reloadTableData(this.props.data)
        }
    }

    componentWillUnmount(){
        // this.$el.DataTable().destroy(true);
        this.setState({tableRef: null});
    }

    reloadTableData(data){
        this.state.tableRef.clear();
        this.state.tableRef.rows.add(data.data);
        this.state.tableRef.draw();
    }

    render() {
        return (
        <div>
                  <table
                    className='table table-bordered table-striped w-100'
                    id='dataTable'
                    ref={(el) => (this.el = el)}
                     />
             </div>
            );
        }
    
}
export default DataTablesComp;