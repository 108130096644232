import React from 'react'
import { ToastContainer } from 'react-toastify';

import logo from '../assets/img/logo.png'
import { auth } from "../firebase";
import Swal from 'sweetalert2';

class Header extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isOpen : window.innerWidth > 1024 ? true : false
    }
    this.logout = this.logout.bind(this);
  }

  logout(e) {
    e.preventDefault();
    var self = this;
    Swal.fire({
      text: 'Are you sure you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.value) {
        auth.signOut()
          .then(() => {
            localStorage.removeItem('vendor');
            // Remove Table
            localStorage.removeItem('DataTables_customerTable');
            localStorage.removeItem('DataTables_driverTable');
            localStorage.removeItem('DataTables_campaignsTable');
            localStorage.removeItem('DataTables_sentCampaignsTable');
            localStorage.removeItem('DataTables_scheduledCampaignsTable');
            localStorage.removeItem('DataTables_draftCampaignsTable');
            localStorage.removeItem('DataTables_promotionTable');
            localStorage.removeItem('DataTables_addtionOptionsTable');
            localStorage.removeItem('DataTables_additionOptionWithPriceTable');
            localStorage.removeItem('DataTables_allItemSelectionTable');
            localStorage.removeItem('DataTables_childOptionTable');
            localStorage.removeItem('DataTables_linkTemplateTable');
            localStorage.removeItem('DataTables_menuTable');
            localStorage.removeItem('DataTables_modifierTable');
            localStorage.removeItem('DataTables_optionalItemTable');
            localStorage.removeItem('DataTables_upsellTable');
            localStorage.removeItem('DataTables_upsell1Table');
            localStorage.removeItem('DataTables_todayOrderTable');
            localStorage.removeItem('DataTables_printerTable');
            localStorage.removeItem('DataTables_offDayTable');
            localStorage.removeItem('DataTables_previousOrderTable');
            localStorage.removeItem('DataTables_deliveryPartnerTable');
            
            self.props.vendorChange();
            self.props.history.push('/login')
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  }

  render() {
    return (
      <div className="header">
        <ToastContainer />
        <nav className="navbar ms-navbar">
          <div className="navbar-details">
            <div
              className="ms-aside-toggler ms-toggler pl-0"
              data-target="#ms-side-nav"
              data-toggle="slideLeft"
              onClick={this.props.toggleClick}
            >
              <span className="ms-toggler-bar bg-primary"></span>
              <span className="ms-toggler-bar bg-primary"></span>
              <span className="ms-toggler-bar bg-primary"></span>
            </div>
            {this.props.vendorAllDetails && 
            <div className="nav-vendor-name nav-vendor-name-lg">
              <span>Restaurant</span>
              <p>{this.props.vendorAllDetails.name}</p>
            </div>}
          </div>
          <div className="logo-sn logo-sm ms-d-block-sm">
            <a
              className="pl-0 ml-0 text-center navbar-brand mr-0"
              href="/order_management"
            >
              <img src={logo} alt="logo" />
            </a>
          </div>
          
          <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
            <li className="ms-nav-item ms-nav-user dropdown">
              <a href="/#" onClick={this.logout}> Logout</a>
            </li>
          </ul>
          <div
            className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
            data-toggle="slideDown"
            data-target="#ms-nav-options"
          >
            <a href="/#" onClick={this.logout}> Logout</a>
          </div>
        </nav>
        {this.props.vendorAllDetails && <div className="nav-vendor-name ms-d-block-sm"><p className="mb-0">{this.props.vendorAllDetails.name}</p></div>}
      </div>
    );
  }
}

export default Header;
