import React from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import ReactDragListView from 'react-drag-listview';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';
const { v1: uuidv1 } = require('uuid');

class EditModifier extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        let modifierId = this.props.match.params.modifierId;
        let errorOptions = [];
        let modifierData = this.props.location.state && this.props.location.state.modifierData ? this.props.location.state.modifierData : null;
        let optionArray = [];
        if (modifierData && modifierData.options && modifierData.options.length) {
            for (let i = 0; i < modifierData.options.length; i += 1) {
                optionArray.push(modifierData.options[i]);
                var temp = optionArray.sort((a, b) => a.isSorting - b.isSorting);
                optionArray=temp;
                errorOptions.push({
                    price: "",
                    name: "",
                    fullPrice: "",
                    halfPrice: ""
                });
            }
        }
        if (!modifierData) {
            this.getSingleModifiers(modifierId);
        }
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails,
            modifierId: modifierId,
            editModifierData: {
                name: modifierData && modifierData.name ? modifierData.name : "",
                displayName: modifierData && modifierData.displayName ? modifierData.displayName : "",
                choiceLimit: modifierData && modifierData.choiceLimit ? modifierData.choiceLimit : "",
                isRequired: modifierData && modifierData.isRequired === true ? true : false,
                isFixed: modifierData && modifierData.isFixed === true ? true : false,
                isComesWith: modifierData && modifierData.isComesWith === true ? true : false,
                isItemOption: modifierData && modifierData.isItemOption === true ? true : false,
                isPizzaTopping: modifierData && modifierData.isPizzaTopping === true ? true : false,
                ofFull: modifierData && modifierData.ofFull ? modifierData.ofFull : 0,
                options: optionArray && optionArray.length ? optionArray : [],
            },
            editModifierErrorData: {
                name: '',
                displayName: '',
                options: errorOptions
            },
            submitProgress: false,
        }
        let self = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let editModifierData = self.state.editModifierData;
                const options = [...editModifierData.options];
                const item = options.splice(fromIndex, 1)[0];
                options.splice(toIndex, 0, item);
                for (let i = 0; i < options.length; i += 1) {
                    options[i].isSorting = i;
                }
                editModifierData.options = options;
                self.setState({
                    editModifierData: editModifierData
                });
                self.setState({orderChange: true});
            },
            nodeSelector: "section",
            handleSelector: 'a'
        };
        this.clickMenu = this.clickMenu.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }

    componentDidMount() {
    }
    async getSingleModifiers(modifierId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifiers/getsingle/${vendorId}/${modifierId}`;
        if (vendorId && modifierId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifiers(modifierId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.options && data.options.length) {
                                for (let i = 0; i < data.options.length; i += 1) {
                                    errorOptions.push({
                                        price: "",
                                        name: "",
                                        fullPrice: "",
                                        halfPrice: "",
                                    });
                                }
                            }
                            let tempFormData = self.state.editModifierData;
                            let tempFormErrorData = self.state.editModifierErrorData;
                            tempFormData.name = data && data.name ? data.name : "";
                            tempFormData.displayName = data && data.displayName ? data.displayName : "";
                            tempFormData.choiceLimit = data && data.choiceLimit ? data.choiceLimit : "";
                            tempFormData.isRequired =  data && data.isRequired === true ? true : false;
                            tempFormData.isFixed =  data && data.isFixed === true ? true : false;
                            tempFormData.isComesWith =  data && data.isComesWith === true ? true : false;
                            tempFormData.isItemOption =  data && data.isItemOption === true ? true : false;
                            tempFormData.isPizzaTopping =  data && data.isPizzaTopping === true ? true : false;
                            tempFormData.ofFull =  data && data.ofFull ? data.ofFull : 0;
                            let optionArray = [];
                            if (data && data.options && data.options.length) {
                                for (let i = 0; i < data.options.length; i += 1) {
                                    optionArray.push(data.options[i]);
                                    var temp = optionArray.sort((a, b) => a.isSorting - b.isSorting);
                                    optionArray=temp;
                                }
                            }
                            tempFormData.options =  optionArray;
                            self.setState({ editModifierData: tempFormData });
                            tempFormErrorData.name = '';
                            tempFormErrorData.displayName = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editModifierErrorData: tempFormErrorData });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickMenu() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/menu_management?tab=modifiers`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/menu_management?tab=modifiers`);
    }
    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editModifierData;
        let formErrors = this.state.editModifierErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"].trim() === "")) {
            fieldName = 'name';
            formDetails["name"] = "";
            formErrors[fieldName] = `The 'Name' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "displayName" && value === "") || (formSubmit === true && formDetails["displayName"].trim() === "")) {
            fieldName = 'displayName';
            formDetails["displayName"] = "";
            formErrors[fieldName] = `The 'Display Name' is a required field.`;
            isError = true;
        }

        if (!formDetails.isPizzaTopping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                        // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        fieldName = 'options';
                        formDetails.options[i].name = "";
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].price === "") {
                        fieldName = 'options';
                        formErrors.options[i].price = `The 'Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].price) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].price ="";
                        isError = true;
                    }
                }
            }
        } else {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {
                    let dName = newField;
                    if (newField === 'fullPrice') {
                        dName = "Full Price";
                    } else if(newField === 'halfPrice') {
                        dName = "Half Price";
                    }
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${dName}' is a required field.`;
                        isError = true;
                    } else if (newField === 'fullPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    } else if (newField === 'halfPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        formDetails.options[i].name = "";
                        fieldName = 'options';
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].fullPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].fullPrice = `The 'Full Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].fullPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].fullPrice ="";
                        isError = true;
                    }
                    if (formDetails.options[i].halfPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].halfPrice = `The 'Half Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].halfPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].halfPrice ="";
                        isError = true;
                    }
                }
            }
        }

        
        this.setState({ editModifierErrorData: formErrors });
        this.setState({ editModifierData: formDetails });
        return isError;
    }
    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.editModifierData;
        let formErrors = this.state.editModifierErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editModifierData: formDetails, isOpenFooter: true });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editModifierErrorData: formErrors });
        }
    }
    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editModifierData;
        let formDetailsError = this.state.editModifierErrorData;
        formDetails[formField].push({
            name: "",
            price: 0,
            isSorting: formDetails[formField].length,
            fullPrice: 0,
            halfPrice: 0,
            id: uuidv1().toUpperCase()
        });
        formDetailsError[formField].push({
            name: "",
            price: "",
            fullPrice: "",
            halfPrice: ""
        });
        this.setState({ editModifierData: formDetails, editModifierErrorData: formDetailsError, isOpenFooter: true})
    }
    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editModifierData;
        let formDetailsError = this.state.editModifierErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        for (let i = 0; i < formDetails.options.length; i += 1) {
            formDetails.options[i].isSorting = i;
        }
        this.setState({ editModifierData: formDetails, editModifierErrorData: formDetailsError, isOpenFooter: true })
    }
    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            let modifierId = self.state.modifierId;
            let editObj = this.state.editModifierData;
            if ((editObj.isRequired || editObj.isFixed) && !editObj.choiceLimit) {
                editObj.choiceLimit = "1";
            }
            if (Number(editObj.choiceLimit) > editObj.options.length) {
                toast.error(`Please Add ${Number(editObj.choiceLimit) - editObj.options.length} More Option`, {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            editObj.vendorId = vendorId;
            editObj.modifierId = modifierId;
            editObj.name = editObj.name.trim();
            editObj.displayName = editObj.displayName.trim();
            for (let i = 0; i < editObj.options.length; i += 1) {
                editObj.options[i].name = editObj.options[i].name.trim();
                if (editObj.isPizzaTopping) {
                    editObj.options[i].fullPrice = Number(Number(editObj.options[i].fullPrice).toFixed(2));
                    editObj.options[i].halfPrice = Number(Number(editObj.options[i].halfPrice).toFixed(2));
                    editObj.options[i].price = 0;
                } else if (editObj.isComesWith) {
                    editObj.options[i].isChecked = true;
                    editObj.options[i].fullPrice = 0;
                    editObj.options[i].halfPrice = 0;
                    editObj.options[i].price = Number(Number(editObj.options[i].price).toFixed(2));
                } else if(editObj.isItemOption) {
                    if (i === 0) {
                        editObj.options[i].isChecked = true;
                    } else {
                        editObj.options[i].isChecked = false;
                    }
                    editObj.options[i].fullPrice = 0;
                    editObj.options[i].halfPrice = 0;
                    editObj.options[i].price = Number(Number(editObj.options[i].price).toFixed(2));
                } else {
                    editObj.options[i].isChecked = false;
                    editObj.options[i].fullPrice = 0;
                    editObj.options[i].halfPrice = 0;
                    editObj.options[i].price = Number(Number(editObj.options[i].price).toFixed(2));
                }
            }
            editObj.ofFull = Number(Number(editObj.ofFull).toFixed(2));
            if (vendorId) {
                let url = `modifiers/edit`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Updated the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                self.props.history.push(`/menu_management?tab=modifiers`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }
    render() {
        let { vendorAllDetails, isOpenFooter, isShowFooter,
            editModifierData,
            editModifierErrorData,
            submitProgress
        } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="add-product-page">
                {vendorAllDetails &&
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                    <span className="page-title">Edit Modifier</span>
                                    <ol className="breadcrumb pl-0 pt-0">
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.clickMenu}>Menu</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Modifier</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <form className="needs-validation clearfix" noValidate>
                            <ReactTooltip />
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (editModifierErrorData.name !== "" ? 'invalid' : '')}
                                            id="name"
                                            name="name"
                                            maxLength="40"
                                            placeholder="Name"
                                            value={editModifierData.name}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {editModifierErrorData.name !== "" &&
                                            <div className="invalid-feedback">
                                                {editModifierErrorData.name}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Display Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (editModifierErrorData.displayName !== "" ? 'invalid' : '')}
                                            id="displayName"
                                            name="displayName"
                                            maxLength="40"
                                            placeholder="Display Name"
                                            value={editModifierData.displayName}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {editModifierErrorData.displayName !== "" &&
                                            <div className="invalid-feedback">
                                                {editModifierErrorData.displayName}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isItemOption ? true : false}
                                        value={editModifierData.isItemOption}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.choiceLimit = "1";
                                                tempeditModifierData.isRequired = true;
                                                tempeditModifierData.isFixed = false;
                                                tempeditModifierData.isComesWith = false;
                                                tempeditModifierData.isPizzaTopping = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isItemOption = event.target.value === 'true' ? true : false;
                                            this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                        }}
                                        disabled={editModifierData.isComesWith || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Item Option
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="choiceLimit">Choice Limit</label>
                                    <div className="input-group">
                                        <select
                                            className="form-control"
                                            name="choiceLimit"
                                            id="choiceLimit"
                                            onChange={(event) => {
                                                let tempeditModifierData = this.state.editModifierData;
                                                tempeditModifierData.choiceLimit = event.target.value;
                                                this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                            }}
                                            value={editModifierData.choiceLimit}
                                            disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping}>
                                            <option value="">No Limit</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isRequired ? true : false}
                                        value={editModifierData.isRequired}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempeditModifierData = this.state.editModifierData;
                                            tempeditModifierData.isRequired = event.target.value === 'true' ? true : false;
                                            tempeditModifierData.isFixed = false;
                                            if (tempeditModifierData.isRequired && !tempeditModifierData.choiceLimit) {
                                                tempeditModifierData.choiceLimit = "1";
                                            }
                                            this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping || editModifierData.isFixed}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Required
                                    </label>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isFixed ? true : false}
                                        value={editModifierData.isFixed}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempeditModifierData = this.state.editModifierData;
                                            tempeditModifierData.isFixed = event.target.value === 'true' ? true : false;
                                            tempeditModifierData.isRequired = false;
                                            if (tempeditModifierData.isFixed && !tempeditModifierData.choiceLimit) {
                                                tempeditModifierData.choiceLimit = "1";
                                            }
                                            this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith || editModifierData.isPizzaTopping || editModifierData.isRequired}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Fixed
                                    </label>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isComesWith ? true : false}
                                        value={editModifierData.isComesWith}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.isItemOption = false;
                                                tempeditModifierData.isPizzaTopping = false;
                                                tempeditModifierData.choiceLimit = "";
                                                tempeditModifierData.isRequired = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isComesWith = event.target.value === 'true' ? true : false;
                                            tempeditModifierData.isFixed = false;
                                            this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Comes With
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 col-lg-4">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {editModifierData.isPizzaTopping ? true : false}
                                        value={editModifierData.isPizzaTopping}
                                        onChange={(event) => {
                                            let tempeditModifierData = this.state.editModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempeditModifierData.isComesWith = false;
                                                tempeditModifierData.isItemOption = false;
                                                tempeditModifierData.choiceLimit = "";
                                                tempeditModifierData.isRequired = false;
                                                tempeditModifierData.isFixed = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempeditModifierData.isPizzaTopping = event.target.value === 'true' ? true : false;
                                            this.setState({ editModifierData: tempeditModifierData, isOpenFooter: true })
                                        }}
                                        disabled={editModifierData.isItemOption || editModifierData.isComesWith}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Pizza Topping
                                    </label>
                                </div>
                                {editModifierData.isPizzaTopping && 
                                    <div className="col-lg-1 col-md-1">
                                        <label htmlFor="name">% of full</label>
                                        <div className="input-group">
                                        <input type="number"
                                            className="form-control"
                                            id="ofFull"
                                            name="ofFull"
                                            placeholder="% of full"
                                            value={editModifierData.ofFull}
                                            onWheel={ event => event.currentTarget.blur() }
                                            onChange={(e) => {
                                                let tempData = editModifierData;
                                                tempData.ofFull = e.target.value;
                                                if (Number(tempData.ofFull) < 0) {
                                                    tempData.ofFull = "";
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = 0;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = (Number(tempData.options[i].fullPrice)*Number(editModifierData.ofFull))/100;
                                                            }
                                                        }
                                                    }
                                                }
                                                this.setState({ editModifierData: tempData, isOpenFooter: true })
                                            }}
                                        />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <label>Options</label>
                                    {editModifierData.options && editModifierData.options.length > 0 &&
                                        <div className="row">
                                            <div className="col-md-1 option-drag-drop"></div>
                                            <div className="col-md-3">
                                                <label htmlFor="optionaName">Name</label>
                                            </div>
                                            {!editModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="optionaPrice">Price</label>
                                                </div>
                                            }
                                            {editModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="fullPrice">Full</label>
                                                </div>
                                            }
                                            {editModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="halfPrice">Half</label>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {editModifierData.options && editModifierData.options.length > 0 &&
                                        <ReactDragListView {...this.dragProps}>
                                            {editModifierData.options && editModifierData.options.length > 0 &&
                                                editModifierData.options.map((optionsRow, index) => (
                                                    <section key={index}>
                                                        <div className="row">
                                                            <div className="col-md-1 option-drag-drop">
                                                                {/* eslint-disable-next-line */}
                                                                <a className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></a>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                        className={"form-control " + (editModifierErrorData.options[index].name !== "" ? 'invalid' : '')}
                                                                        id="optionaName"
                                                                        name="optionaName"
                                                                        maxLength="40"
                                                                        placeholder="Name"
                                                                        value={optionsRow.name}
                                                                        onChange={(e) => {
                                                                            let tempData = editModifierData;
                                                                            let tempErrorData = editModifierErrorData;
                                                                            tempData.options[index].name = e.target.value;
                                                                            this.setState({ editModifierData: tempData, isOpenFooter: true })
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'name');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].name = "";
                                                                            this.setState({ editModifierErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        {editModifierErrorData.options[index].name !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {editModifierErrorData.options[index].name}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                            {!editModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (editModifierErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                                            id="optionaPrice"
                                                                            name="optionaPrice"
                                                                            placeholder="Price"
                                                                            value={optionsRow.price}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            onChange={(e) => {
                                                                                let tempData = editModifierData;
                                                                                let tempErrorData = editModifierErrorData;
                                                                                tempData.options[index].price = e.target.value;
                                                                                this.setState({ editModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].price = "";
                                                                                this.setState({ editModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {/* {editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                                <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                            } */}
                                                                            {editModifierErrorData.options[index].price !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {editModifierErrorData.options[index].price}
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {!editModifierData.isPizzaTopping && editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                    <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {editModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (editModifierErrorData.options[index].fullPrice !== "" ? 'invalid' : '')}
                                                                            id="fullPrice"
                                                                            name="fullPrice"
                                                                            placeholder="Full Price"
                                                                            value={optionsRow.fullPrice}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            onChange={(e) => {
                                                                                let tempData = editModifierData;
                                                                                let tempErrorData = editModifierErrorData;
                                                                                tempData.options[index].fullPrice = e.target.value;
                                                                                if (Number(tempData.options[index].fullPrice) > 0 && Number(editModifierData.ofFull) > 0) {
                                                                                    tempData.options[index].halfPrice = (Number(tempData.options[index].fullPrice)*Number(editModifierData.ofFull))/100;
                                                                                }
                                                                                this.setState({ editModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'fullPrice');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].fullPrice = "";
                                                                                this.setState({ editModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {editModifierErrorData.options[index].fullPrice !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {editModifierErrorData.options[index].fullPrice}
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {editModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (editModifierErrorData.options[index].halfPrice !== "" ? 'invalid' : '')}
                                                                            id="halfPrice"
                                                                            name="halfPrice"
                                                                            placeholder="Half Price"
                                                                            value={optionsRow.halfPrice}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            onChange={(e) => {
                                                                                let tempData = editModifierData;
                                                                                let tempErrorData = editModifierErrorData;
                                                                                tempData.options[index].halfPrice = e.target.value;
                                                                                this.setState({ editModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'halfPrice');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].halfPrice = "";
                                                                                this.setState({ editModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {/* {editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                                <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                            } */}
                                                                            {editModifierErrorData.options[index].halfPrice !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {editModifierErrorData.options[index].halfPrice}
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {editModifierData.isPizzaTopping && editModifierErrorData.options && editModifierErrorData.options.length > 1 &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                    <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </section>
                                                ))
                                            }
                                        </ReactDragListView>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    {/* eslint-disable-next-line */}
                                    <a className="common-link" onClick={(e) => this.onAddField(e, "options")}>Add New</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                    Save {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                }
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onEditSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default EditModifier;