import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
class AddAllItemsSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addAllItemsSelectionData: {
                title: '',
                inListAllItemsSelection: true,
                description: '',
                options: [{
                    description: "",
                    isSelected: true,
                    item: ""
                }]
            },
            addAllItemsSelectionErrorData: {
                title: '',
                options: [{
                    description: "",
                    item: ""
                }]
            },
            helpText: {
                title: 'Give a descriptive name. It will show in the model window.',
                description: 'Write the name of the category to which it should apply e.g. for Vegetarian Pizza',
                options: 'You can add as many items.',
                inListAllItemsSelection: ''
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {

    }
    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addAllItemsSelectionData;
        let formErrors = this.state.addAllItemsSelectionErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
            fieldName = 'options';
            if (index !== undefined) {       
                if (formDetails['options'][index][newField] === "") {
                    formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                }
            }
        }

        if (fieldName === 'options' && index === undefined) {
            for (let i = 0; i < formDetails.options.length; i += 1) {
                if (formDetails.options[i].item === "") {
                    fieldName = 'options';
                    formErrors.options[i].item = `The 'Item' is a required field.`;
                    isError = true;
                }
            }
        }
        
        this.setState({ addAllItemsSelectionErrorData: formErrors });
        this.setState({ addAllItemsSelectionData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addAllItemsSelectionData;
        let formErrors = this.state.addAllItemsSelectionErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addAllItemsSelectionData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addAllItemsSelectionErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addAllItemsSelectionData;
        let formDetailsError = this.state.addAllItemsSelectionErrorData;
        formDetails[formField].push({
            description: "",
            isSelected: true,
            item: ""
        });
        formDetailsError[formField].push({
            description: "",
            item: ""
        });
        this.setState({ addAllItemsSelectionData: formDetails, addAllItemsSelectionErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addAllItemsSelectionData;
        let formDetailsError = this.state.addAllItemsSelectionErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ addAllItemsSelectionData: formDetails, addAllItemsSelectionErrorData: formDetailsError })
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const addAllItemsSelectionData = this.state.addAllItemsSelectionData;
            const addObj = {
                modifiresObject: {
                    title: addAllItemsSelectionData.title,
                    description: addAllItemsSelectionData?.description ? addAllItemsSelectionData.description : "",
                    inListAllItemsSelection: addAllItemsSelectionData.inListAllItemsSelection
                },
                title: addAllItemsSelectionData.title,
                description: addAllItemsSelectionData?.description ? addAllItemsSelectionData.description : "",
                categoryName: "allItemsSelection",
                vendorId: vendorId
            };
            addObj.modifiresObject.options = addAllItemsSelectionData.options;
            if (vendorId) {
                let url = `modifier/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=allitemselection`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            addAllItemsSelectionData,
            addAllItemsSelectionErrorData,
            submitProgress,
            helpText
        } = { ...this.state };
        return (
            <div className="all-items-selection-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add All Items</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=allitemselection">All Items</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Add All Items</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="title">Title</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                            <div className="input-group">
                                <input type="text"
                                    className={"form-control " + (addAllItemsSelectionErrorData.title !== "" ? 'invalid' : '')}
                                    id="title"
                                    name="title"
                                    maxLength="40"
                                    placeholder="Title"
                                    value={addAllItemsSelectionData.title}
                                    onChange={this.onBasicDetailsChange}
                                    />
                                {addAllItemsSelectionErrorData.title !== "" &&
                                    <div className="invalid-feedback">
                                        {addAllItemsSelectionErrorData.title}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="description">All Items Selection</label>
                            {/* <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.inListAllItemsSelection}></i> */}
                            <div className="input-group mb-0">
                                <ul className="ms-list d-flex">
                                    <li className="ms-list-item pl-0">
                                        <label className="ms-checkbox-wrap">
                                            <input type="radio"
                                                name="AddRadioAllItemsSelection"
                                                value={true}
                                                checked={addAllItemsSelectionData.inListAllItemsSelection === true}
                                                onChange={(event) => {
                                                    let tempaddAllItemsSelectionData = this.state.addAllItemsSelectionData;
                                                    tempaddAllItemsSelectionData.inListAllItemsSelection = event.currentTarget.value === "true" ? true : false;
                                                    this.setState({ addAllItemsSelectionData: tempaddAllItemsSelectionData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> Yes </span>
                                    </li>
                                    <li className="ms-list-item">
                                        <label className="ms-checkbox-wrap">
                                            <input
                                                type="radio"
                                                name="AddRadioAllItemsSelection"
                                                value={false}
                                                checked={addAllItemsSelectionData.inListAllItemsSelection === false}
                                                onChange={(event) => {
                                                    let tempaddAllItemsSelectionData = this.state.addAllItemsSelectionData;
                                                    tempaddAllItemsSelectionData.inListAllItemsSelection = event.currentTarget.value === "false" ? false : true;
                                                    this.setState({ addAllItemsSelectionData: tempaddAllItemsSelectionData })
                                                }}
                                            />
                                            <i className="ms-checkbox-check"></i>
                                        </label>
                                        <span> No </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label htmlFor="description">Description</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    maxLength="120"
                                    placeholder="Description"
                                    value={addAllItemsSelectionData.description}
                                    onChange={this.onBasicDetailsChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <label>Options</label>
                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="optionaItem">Item</label>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="optionaDescription">Description</label>
                                </div>
                            </div>
                            {addAllItemsSelectionData.options && addAllItemsSelectionData.options.length > 0 &&
                                addAllItemsSelectionData.options.map((optionsRow, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-md-4">
                                            {/* <label htmlFor="optionaItem">Item</label> */}
                                            <div className="input-group">
                                                <input type="text"
                                                    className={"form-control " + (addAllItemsSelectionErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                    id="optionaItem"
                                                    name="optionaItem"
                                                    maxLength="40"
                                                    placeholder="Item"
                                                    value={optionsRow.item}
                                                    // onChange={this.onBasicDetailsChange}
                                                    onChange={(e) => {
                                                        let tempData = addAllItemsSelectionData;
                                                        let tempErrorData = addAllItemsSelectionErrorData;
                                                        tempData.options[index].item = e.target.value;
                                                        this.setState({ addAllItemsSelectionData: tempData })
                                                        let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                        if (!isError) {
                                                        tempErrorData.options[index].item = "";
                                                          this.setState({ addAllItemsSelectionErrorData: tempErrorData });
                                                        }
                                                      }}
                                                    />
                                                    {addAllItemsSelectionErrorData.options[index].item !== "" &&
                                                        <div className="invalid-feedback">
                                                            {addAllItemsSelectionErrorData.options[index].item}
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {/* <label htmlFor="optionaDescription">Description</label> */}
                                            <div className="input-group">
                                                <input type="text"
                                                    className="form-control"
                                                    id="optionaDescription"
                                                    name="optionaDescription"
                                                    maxLength="120"
                                                    placeholder="Description"
                                                    value={optionsRow.description}
                                                    // onChange={this.onBasicDetailsChange}
                                                    onChange={(e) => {
                                                        let tempData = addAllItemsSelectionData;
                                                        tempData.options[index].description = e.target.value;
                                                        this.setState({ addAllItemsSelectionData: tempData })
                                                      }}
                                                    />
                                                    { addAllItemsSelectionData.options.length === 1 && index === 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { addAllItemsSelectionData.options.length === index+1 && index > 0 &&
                                                        <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                    }
                                                    { index < addAllItemsSelectionData.options.length-1 &&
                                                        <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    }
                                                    {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                        : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                    } */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                            Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddAllItemsSelection;