import React from 'react';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    this.statusColor = {
      'Pending': '#ff8306',
      'Confirm': '#008080',
      'Out for Delivery': '#ff914d',
      'Delivered': '#7fba22',
      'Delivered (A)': '#ff8306',
      'At the door': '#0fc4d4',
      'Ready for Pickup': '#FFBF00',
      'Out from kitchen': '#CCCCFF',
      'Picked up': '#FF00FF',
      'Picked up (A)': '#ff8306',
      'Cancelled': '#000',
      'Completed': '#ff8306',
      'Completed (A)': '#ff8306',
    }
    this.state = {
      orderDetails: null,
      vendorTimezone: (vendor.vendorAllDetails && vendor.vendorAllDetails.tz && vendor.vendorAllDetails.tz !== "") ? vendor.vendorAllDetails.tz : "America/New_York",
      activeTab: this?.props?.location?.state?.activeTab ? this.props.location.state.activeTab : 'today'
    }
  }

  componentDidMount() {
    this.getOrderDetails();
  }

  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].title === nameKey) {
        return i;
      }
    }
  }

  async getOrderDetails() {
    const orderId = this.props.match.params.orderId;
    let vendor = getLoginUserDetails();
    let vendorId = vendor.id;
    let url = `order/get-order-details/${orderId}`;
    try {
      let token = getAuthTokenFromLocalStorage();
      if (!token) {
        let auth = await getAuth();
        if (auth && auth.success && auth.token) {
          localStorage.setItem('token', auth.token);
        }
      }
      axiosInstance.get(url)
        .then(result => {
          // If auth token expired
          if ("success" in result.data && result.data.success === false) {
            localStorage.removeItem('token');
            this.getOrderDetails();
            return;
          }
          let orderData = result.data && result.data.response && result.data.response.length > 0 && result.data.response[0];
          if (orderData && orderData.vendorID === vendorId) {
            let subTotal = 0;
            if (orderData && orderData.products && orderData.products.length > 0) {
              for (let i = 0; i < orderData.products.length; i += 1) {
                if (orderData.products[i].price && orderData.products[i].quantity) {
                  const tmpTotalAmount = orderData.products[i].totalAmount;
                  if (tmpTotalAmount) {
                    orderData.products[i].tmpTotalAmount = +(Number(tmpTotalAmount) * orderData.products[i].quantity).toFixed(2);
                  } else {
                    orderData.products[i].tmpTotalAmount = +(Number(orderData.products[i].price) * orderData.products[i].quantity).toFixed(2);
                  }
                  subTotal += orderData.products[i].tmpTotalAmount;
                  orderData.products[i].manageVariant = [];
                  if (orderData.products[i] && orderData.products[i].variant && orderData.products[i].variant.length > 0) {
                    orderData?.products[i]?.variant.forEach((doc, vIndex) => {
                      let index = null;
                      index = this.search(doc.title, orderData.products[i].manageVariant);
                      if (index !== undefined) {
                        orderData.products[i].manageVariant[index].data.push(doc);
                      } else {
                        orderData.products[i].manageVariant.push({ title: doc.title, data: [doc] });
                      }
                    });
                    if (orderData.products[i].manageVariant && orderData.products[i].manageVariant.length) {
                      let variantHtml = '';
                      for (let iii = 0; iii < orderData.products[i].manageVariant.length; iii += 1) {
                        variantHtml += `${orderData.products[i].manageVariant[iii].title} : `;
                        if (orderData.products[i].manageVariant[iii].data && orderData.products[i].manageVariant[iii].data.length) {
                          for (let iiii = 0; iiii < orderData.products[i].manageVariant[iii].data.length; iiii += 1) {
                            if (orderData.products[i].manageVariant[iii].data[iiii].type && orderData.products[i].manageVariant[iii].data[iiii].type !== 'Full') {
                              variantHtml += `${orderData.products[i].manageVariant[iii].data[iiii].name} - ${orderData.products[i].manageVariant[iii].data[iiii].type}`;
                            } else {
                              variantHtml += `${orderData.products[i].manageVariant[iii].data[iiii].name}`;
                            }
                            if (orderData.products[i].manageVariant[iii].data[iiii].price) {
                              variantHtml += `($${orderData.products[i].manageVariant[iii].data[iiii].price})`;
                              if ((orderData.products[i].manageVariant[iii].data.length - 1) !== iiii) {
                                variantHtml += ', ';
                              }
                            } else {
                              if (Number(orderData.products[i].manageVariant[iii].data[iiii].price) === 0) {
                                variantHtml += `($0)`;
                              }
                              if ((orderData.products[i].manageVariant[iii].data.length - 1) !== iiii) {
                                variantHtml += ', ';
                              }
                            }
                          }
                        }
                        if ((orderData.products[i].manageVariant.length - 1) !== iii) {
                          variantHtml += ', ';
                        }
                        variantHtml += ``;
                      }
                      orderData.products[i].itemModifier = variantHtml;
                    }
                  }
                }
              }
            }
            orderData.subTotal = subTotal;
            this.setState({ 'orderDetails': orderData });
          } else {
            this.props.history.push('/order_management')
          }
        })
        .catch(error => {
          console.error("Problem in fetching order's details: ", error);
        })
    }
    catch (error) {
      console.error("Problem in fetching order's details: ", error);
    }
  }

  render() {
    const { orderDetails, activeTab } = { ...this.state }
    return (
      <div className="row">
        {orderDetails !== null && <div className="col-md-12">
          <nav aria-label="breadcrumb" className="page-nav-info mb-1">
            <span className="page-title">{'orderNumber' in orderDetails ? orderDetails.orderNumber : 'Order Details'}</span>
            <ol className="breadcrumb pl-0 pt-0">
              <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
              <li className="breadcrumb-item"><Link to={`/order_management?tab=${activeTab}`}>Orders</Link></li>
              {orderDetails.orderNumber && <li className="breadcrumb-item active" aria-current="page">{orderDetails.orderNumber}</li>}
            </ol>
          </nav>

          <div className="ms-panel custom-panel">
            <div className="ms-panel-header">
              <div className="d-flex">
                <h5 className="mb-0">Order Details</h5>
              </div>
              <hr/>
            </div>
            <div className="ms-panel-body">
              <div className="row align-items-center">
                {orderDetails?.orderType &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Order Type</p>
                    <span className="mb-0">{orderDetails.orderType}</span>
                  </div>}
                {'orderDate' in orderDetails && orderDetails.orderDate.scheduleDate && orderDetails.orderDate.scheduleTime &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Delivery Time</p>
                    <span className="mb-0">{moment(orderDetails.orderDate.scheduleDate, "MM/DD/YYYY").format("MMM DD, YYYY") + " " +orderDetails.orderDate.scheduleTime.split(' - ')[0]}</span>
                  </div>}
                {orderDetails.deliveryType &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Delivery Type</p>
                    <span className="mb-0">{orderDetails.deliveryType}</span>
                  </div>}
                {orderDetails.status &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Order Status</p>
                    <span className="mb-0"><span className="status-dot mr-1" style={{ backgroundColor: this.statusColor[orderDetails.isCronUpdate ? orderDetails.status + ' (A)' : orderDetails.status] }}></span> {orderDetails.status}</span>
                  </div>}
                <div className="col-lg-3 col-md-4 mb-3">
                  <p className="font-weight-bold mb-2">Customer Name</p>
                  <span> {(orderDetails.author.firstName + ' ' || '') + (orderDetails.author.lastName || '')}</span>
                </div>
                {orderDetails.author && orderDetails.author.email &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Email Address</p>
                    <span className="mb-0"><a href={"mailto:" + orderDetails.author.email}>{orderDetails.author.email || ''}</a></span>
                  </div>}
                {orderDetails.author && orderDetails.author.phone &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Phone</p>
                    <span className="mb-0">{orderDetails.author.phone || ''}</span>
                  </div>}
                
                <div className="col-lg-3 col-md-4 mb-3">
                  <p className="font-weight-bold mb-2">Payment Status</p>
                  <span className="mb-0"> {orderDetails.paymentStatus}</span>
                </div>
                {orderDetails.paymentCardInfo && orderDetails.paymentCardInfo.cardNumber &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Card Number</p>
                    <span className="mb-0">{orderDetails.paymentCardInfo.cardNumber}</span>
                  </div>}                
                {orderDetails.address && orderDetails.address.line1 &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    <p className="font-weight-bold mb-2">Delivery Location</p>
                    <span className="mb-0">{orderDetails.address.line1}</span>
                    { orderDetails.address && orderDetails.address.line2 && 
                      <span className="mb-0">, {orderDetails.address.line2}</span>
                    }
                  </div>
                }
                {orderDetails?.receiptLink &&
                  <div className="col-lg-3 col-md-4 mb-3">
                    {/* eslint-disable-next-line */}
                    <a href={orderDetails.receiptLink} target="_blank">Open Receipt</a>
                  </div>}
              </div>
            </div>
          </div>

          {orderDetails && orderDetails.products && orderDetails.products.length > 0 &&
            <div className="ms-invoice-table mt-3">
              <table className="table table-bordered tbl-order-details">
                <thead>
                  <tr>
                    <th><h6>Qty</h6></th>
                    <th><h6>Name</h6></th>
                    <th className="text-right"><h6>Cost ($)</h6></th>
                    <th className="text-right"><h6>Total ($)</h6></th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.products.map((product, productIndex) => (<tr key={productIndex}>
                    <td><span className="font-weight-bold">{product.quantity}x</span></td>
                    <td><p className="font-weight-bold mb-1">{product.name}</p>
                      {product.itemModifier && <p className="mb-1">{product.itemModifier}</p>}
                      {product.add_special_instructions && <p className="mb-1">Special Instruction: {product.add_special_instructions}</p>}
                    </td>
                    <td className="text-right">$ {product?.totalAmount ? (parseFloat(product.totalAmount)).toFixed(2) : ""}</td>
                    <td className="text-right">$ {product?.tmpTotalAmount ? (parseFloat(product.tmpTotalAmount)).toFixed(2) : ""}</td>
                  </tr>))}
                </tbody>
                <tfoot>
                  {(orderDetails.subTotal !== "" && orderDetails.subTotal !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Sub-Total <span> : </span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.subTotal)).toFixed(2)}</td>
                  </tr>}
                  {('minimumOrderCharge' in orderDetails.resturantDetails && orderDetails.resturantDetails.minimumOrderCharge !== "" && orderDetails.resturantDetails.minimumOrderCharge !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Minimum Order Charge <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.minimumOrderCharge)).toFixed(2)}</td>
                  </tr>}
                  {('savings' in orderDetails.resturantDetails && orderDetails.resturantDetails.savings !== "" && orderDetails.resturantDetails.savings !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Savings <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.savings)).toFixed(2)}</td>
                  </tr>}
                  {(orderDetails.resturantDetails.deliveryCharge !== "" && orderDetails.resturantDetails.deliveryCharge !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Delivery Charge <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.deliveryCharge)).toFixed(2)}</td>
                  </tr>}
                  {(orderDetails.resturantDetails.serviceCharge !== "" && orderDetails.resturantDetails.serviceCharge !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Service Charge <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.serviceCharge)).toFixed(2)}</td>
                  </tr>}
                  {(orderDetails.resturantDetails.taxAmount !== "" && orderDetails.resturantDetails.taxAmount !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Tax <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.taxAmount)).toFixed(2)}</td>
                  </tr>}
                  {(orderDetails.resturantDetails.tipAmount !== "" && orderDetails.resturantDetails.tipAmount !== 0) && <tr>
                    <td colSpan="3" className="text-right"><strong>Tip <span>:</span> </strong></td>
                    <td className="text-right">$ {(parseFloat(orderDetails.resturantDetails.tipAmount)).toFixed(2)}</td>
                  </tr>}
                  <tr className="thead-light">
                    <th colSpan="3" className="text-right"><h6><strong>Total</strong></h6></th>
                    <th className="text-right"><h6><strong>$ {(parseFloat(orderDetails.resturantDetails.total)).toFixed(2)}</strong></h6></th>
                  </tr>
                </tfoot>
              </table>
            </div>}
        </div>}

        { orderDetails === null && <Spinner />}
      </div >
    );
  }
}

export default OrderDetails;