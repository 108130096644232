import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
class EditChildOptions extends React.Component {
    constructor(props) {
        super(props);
        let childOptionsId = this.props.match.params.childOptionsId;
        let errorOptions = [];
        let childOptionsData = this.props.location.state && this.props.location.state.childOptionsData ? this.props.location.state.childOptionsData : null;
        let parentOptionsData = this.props.location.state && this.props.location.state.parentOptionsData ? this.props.location.state.parentOptionsData : null;
        
        if (childOptionsData && childOptionsData.modifiresObject && childOptionsData.modifiresObject.options && childOptionsData.modifiresObject.options.length) {
            for (let i = 0; i < childOptionsData.modifiresObject.options.length; i += 1) {
                if (childOptionsData.topping) {
                    childOptionsData.modifiresObject.options[i].price = 0;
                    childOptionsData.modifiresObject.options[i].halfPrice = (childOptionsData.modifiresObject.options[i].leftPrice).toString();
                }
                if (!childOptionsData.topping) {
                    childOptionsData.modifiresObject.options[i].fullPrice = 0;
                    childOptionsData.modifiresObject.options[i].halfPrice = 0;
                    childOptionsData.modifiresObject.options[i].leftPrice = 0;
                    childOptionsData.modifiresObject.options[i].rightPrice = 0;
                }
                errorOptions.push({
                    item: "",
                    price: "",
                    fullPrice: "",
                    halfPrice: ""
                });
            }
        }
        if (!childOptionsData) {
            this.getSingleModifier(childOptionsId);
        }
        this.state = {
            isLoadPage: true,
            childOptionsId: childOptionsId,
            parentOptionsId: childOptionsData.templateId,
            parentOptionsData: parentOptionsData,
            productsData: [],
            editChildOptionsData: {
                title: childOptionsData && childOptionsData.title ? childOptionsData.title : "",
                templateId: childOptionsData.templateId,
                topping: childOptionsData && childOptionsData.topping ? true : false,
                linkId: childOptionsData && childOptionsData.linkId ? childOptionsData.linkId : "",
                description: childOptionsData && childOptionsData.description ? childOptionsData.description : "",
                options: childOptionsData && childOptionsData.modifiresObject && childOptionsData.modifiresObject.options ? childOptionsData.modifiresObject.options : [],
            },
            editChildOptionsErrorData: {
                title: '',
                linkId: '',
                options: errorOptions
            },
            helpText: {
                title: 'Give a descriptive title.',
                description: 'Optionally link to where it should belong.',
                options: 'You can add as many items as with the price. Make one item default which is selected automatically and the customer will be able to select any other option. Example: Default pizza size may be small but a customer can select either medium, large or x-large.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
    }
    componentDidMount() {
        this.getProductsList();
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    if (!(result.data && result.data.status)) {
                        this.setState({ productsData: [] });
                        this.setState({ selectedFoodItems: [], firstTimeFoodItems: JSON.stringify([]) });
                        this.setState({isLoadPage: false});
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    if (products[i].child_options && products[i].child_options.length) {
                                        for (let iii = 0; iii < products[i].child_options.length; iii += 1) {
                                            if (products[i].child_options[iii].id === this.state.childOptionsId) {
                                                tempSelectedFoodItems[ii].product.push({
                                                    id: products[i].id,
                                                    name: products[i].name
                                                });
                                            }
                                        }
                                    }
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name
                                    });
                                }
                            }
                        }
                        for (let i = 0; i < manageProductData.length; i += 1) {
                            if (manageProductData[i].product && manageProductData[i].product.length) {
                                if (tempSelectedFoodItems[i].product && tempSelectedFoodItems[i].product.length === manageProductData[i].product.length) {
                                    tempSelectedFoodItems[i].allProductSelected = true;
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems, firstTimeFoodItems: JSON.stringify(tempSelectedFoodItems) });
                        this.setState({isLoadPage: false});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching vendor product: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching vendor product: ", error);
        }
    }

    async getSingleModifier(childOptionsId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifier/getsingle/${vendorId}/${childOptionsId}`;
        if (vendorId && childOptionsId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifier(childOptionsId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.modifiresObject && data.modifiresObject.options && data.modifiresObject.options.length) {
                                for (let i = 0; i < data.modifiresObject.options.length; i += 1) {
                                    errorOptions.push({
                                        item: "",
                                        price: "",
                                        fullPrice: "",
                                        halfPrice: ""
                                    });
                                }
                            }
                            let tempFormData = self.state.editChildOptionsData;
                            let tempFormErrorData = self.state.editChildOptionsErrorData;

                            tempFormData.title = data && data.title ? data.title : "";
                            tempFormData.description =  data && data.description ? data.description : "";
                            tempFormData.options =  data && data.modifiresObject && data.modifiresObject.options ? data.modifiresObject.options : [];
                            tempFormData.templateId =  data && data.templateId ? data.templateId : "";
                            tempFormData.topping = data && data.topping ? true : false;
                            tempFormData.linkId = data && data.linkId ? data.linkId : "";
                            self.setState({ editChildOptionsData: tempFormData });
                            
                            tempFormErrorData.title = '';
                            tempFormErrorData.linkId = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editChildOptionsErrorData: tempFormErrorData });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editChildOptionsData;
        let formErrors = this.state.editChildOptionsErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "linkId" && value === "") || (formSubmit === true && formDetails["linkId"] === "")) {
            fieldName = 'linkId';
            formErrors[fieldName] = `The 'Parent Option' is a required field.`;
            isError = true;
        }

        if (!formDetails.topping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }

            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].price === "") {
                        fieldName = 'options';
                        formErrors.options[i].price = `The 'Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].price) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].price ="";
                        isError = true;
                    }
                }
            }
        }
        if (formDetails.topping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'fullPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    } else if (newField === 'halfPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }

            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].item === "") {
                        fieldName = 'options';
                        formErrors.options[i].item = `The 'Item' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].fullPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].fullPrice = `The 'Full Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].fullPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].fullPrice ="";
                        isError = true;
                    }
                    
                    if (formDetails.options[i].halfPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].halfPrice = `The 'Half Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].halfPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].halfPrice ="";
                        isError = true;
                    }
                }
            }
        }

        
        this.setState({ editChildOptionsErrorData: formErrors });
        this.setState({ editChildOptionsData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.editChildOptionsData;
        let formErrors = this.state.editChildOptionsErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editChildOptionsData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editChildOptionsErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editChildOptionsData;
        let formDetailsError = this.state.editChildOptionsErrorData;
        formDetails[formField].push({
            include: false,
            item: "",
            price: 0,
            fullPrice: 0,
            halfPrice: 0,
            leftPrice: 0,
            rightPrice: 0,
            description: ""
        });
        formDetailsError[formField].push({
            item: "",
            price: "",
            fullPrice: "",
            halfPrice: ""
        });
        this.setState({ editChildOptionsData: formDetails, editChildOptionsErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.editChildOptionsData;
        let formDetailsError = this.state.editChildOptionsErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ editChildOptionsData: formDetails, editChildOptionsErrorData: formDetailsError })
    }

    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const editChildOptionsData = this.state.editChildOptionsData;
            const editObj = {
                id: self.state.childOptionsId,
                selectedFoodItems: self.state.selectedFoodItems,
                modifiresObject: {
                    topping: editChildOptionsData.topping,
                    templateId: editChildOptionsData.templateId,
                    title: editChildOptionsData.title,
                    linkId: editChildOptionsData.linkId,
                    description: editChildOptionsData?.description ? editChildOptionsData.description : "",
                },
                topping: editChildOptionsData.topping,
                templateId: editChildOptionsData.templateId,
                linkId: editChildOptionsData.linkId,
                title: editChildOptionsData.title,
                description: editChildOptionsData?.description ? editChildOptionsData.description : "",
                categoryName: "linkTemplateChildOptions",
                vendorId: vendorId
            };
            for (let i = 0; i < editChildOptionsData.options.length; i += 1) {
                if (!editChildOptionsData.topping) {
                    editChildOptionsData.options[i].price = Number(Number(editChildOptionsData.options[i].price).toFixed(2));
                    delete editChildOptionsData.options[i].fullPrice;
                    delete editChildOptionsData.options[i].halfPrice;
                    delete editChildOptionsData.options[i].leftPrice;
                    delete editChildOptionsData.options[i].rightPrice;
                }
                if (editChildOptionsData.topping) {
                    editChildOptionsData.options[i].fullPrice = Number(Number(editChildOptionsData.options[i].fullPrice).toFixed(2));
                    editChildOptionsData.options[i].leftPrice = Number(Number(editChildOptionsData.options[i].halfPrice).toFixed(2));
                    editChildOptionsData.options[i].rightPrice = Number(Number(editChildOptionsData.options[i].halfPrice).toFixed(2));
                    // delete editChildOptionsData.options[i].halfPrice;
                    delete editChildOptionsData.options[i].price;
                }
            }
            editObj.modifiresObject.options = editChildOptionsData.options;
            if (vendorId) {
                let url = `modifier/linkTemplateChildOptions/edit`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // this.getmodifiers();
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                // self.props.history.push(`/menu_management?tab=linktemplates`);
                                self.props.history.push(`/menu_management/modifiers/child-options-details/${self.state.parentOptionsId}`, {
                                    parentOptionsData: self.state.parentOptionsData
                                });
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    moveChildOptions(e, modifireId, row) {
        e.preventDefault();
        let self = this;
        self.props.history.push(`/menu_management/modifiers/child-options-details/${modifireId}`, {
            parentOptionsData: row
        });
    }
    render() {
        const {
            editChildOptionsData,
            editChildOptionsErrorData,
            parentOptionsId,
            parentOptionsData,
            submitProgress,
            productsData,
            selectedFoodItems,
            helpText
        } = { ...this.state };
        return (
            <div className="parent-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Edit Child Options</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* eslint-disable-next-line */}
                                <li className="breadcrumb-item"><a className="primary-link" onClick={(e) => this.moveChildOptions(e, parentOptionsId, parentOptionsData)}>Child Options</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit Child Options</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="needs-validation clearfix" noValidate>
                    <ReactTooltip />
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="name">Pizza Topping</label>
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="toppingRadio"
                                                        value={true}
                                                        checked={editChildOptionsData.topping === true}
                                                        onChange={(event) => {
                                                            let tempeditChildOptionsData = this.state.editChildOptionsData;
                                                            tempeditChildOptionsData.topping = event.currentTarget.value === "true" ? true : false;
                                                            this.setState({ editChildOptionsData: tempeditChildOptionsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="toppingRadio"
                                                        value={false}
                                                        checked={editChildOptionsData.topping === false}
                                                        onChange={(event) => {
                                                            let tempeditChildOptionsData = this.state.editChildOptionsData;
                                                            tempeditChildOptionsData.topping = event.currentTarget.value === "false" ? false : true;
                                                            this.setState({ editChildOptionsData: tempeditChildOptionsData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="linkId">Parent Option</label>
                                    <div className="input-group">
                                        <select
                                            className={"form-control " + (editChildOptionsErrorData.linkId !== "" ? 'invalid' : '')}
                                            name="linkId"
                                            id="linkId"
                                            onChange={this.onBasicDetailsChange}
                                            value={editChildOptionsData.linkId}>
                                            <option value="">Select Parent Option</option>
                                            {parentOptionsData?.modifiresObject && parentOptionsData?.modifiresObject?.options && parentOptionsData?.modifiresObject?.options.length && parentOptionsData.modifiresObject.options.map((oRow, index) => (
                                                <option key={index} value={oRow.id}>{oRow.item}</option>
                                            ))}
                                        </select>
                                        {editChildOptionsErrorData.linkId && editChildOptionsErrorData.linkId !== "" &&
                                            <div className="invalid-feedback">{editChildOptionsErrorData.linkId}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="title">Title</label>
                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (editChildOptionsErrorData.title !== "" ? 'invalid' : '')}
                                            id="title"
                                            name="title"
                                            maxLength="40"
                                            placeholder="Title"
                                            value={editChildOptionsData.title}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {editChildOptionsErrorData.title !== "" &&
                                            <div className="invalid-feedback">
                                                {editChildOptionsErrorData.title}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <label htmlFor="description">Description</label>
                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                                    <div className="input-group">
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            maxLength="120"
                                            placeholder="Description"
                                            value={editChildOptionsData.description}
                                            onChange={this.onBasicDetailsChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ReactTooltip />
                                <div className="col-md-12 col-lg-12">
                                    <label>Options</label>
                                    <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="addition-option-radio">
                                                <label className="ms-checkbox-wrap"></label>
                                                <div className="input-group ml-3 mb-0">
                                                    <label htmlFor="parentOptionsItem">Item</label>
                                                </div>
                                                <div className="input-group ml-3 mb-0">
                                                    <label htmlFor="parentOptionsDesc">Description</label>
                                                </div>
                                                {!editChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="parentOptionsPrice">Price</label>
                                                    </div>
                                                }
                                                {editChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="parentOptionsFullPrice">Full Price</label>
                                                    </div>
                                                }
                                                
                                                {editChildOptionsData.topping &&
                                                    <div className="input-group ml-3 mb-0">
                                                        <label htmlFor="parentOptionsHalfPrice">Half Price</label>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {editChildOptionsData.options && editChildOptionsData.options.length > 0 &&
                                        editChildOptionsData.options.map((optionsRow, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-md-12">
                                                    <div className="addition-option-radio mb-15">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox" 
                                                            checked = {optionsRow.include ? true : false}
                                                            onChange={(e) => {
                                                                let editChildOptionsData = this.state.editChildOptionsData;
                                                                editChildOptionsData.options[index].include = e.target.checked;
                                                                this.setState({ editChildOptionsData: editChildOptionsData })
                                                            }}/>
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                        <div className="input-group ml-3 mb-0">
                                                            <input type="text"
                                                                className={"form-control " + (editChildOptionsErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                                id="parentOptionsItem"
                                                                name="parentOptionsItem"
                                                                maxLength="40"
                                                                placeholder="Item"
                                                                value={optionsRow.item}
                                                                onChange={(e) => {
                                                                    let tempData = editChildOptionsData;
                                                                    let tempErrorData = editChildOptionsErrorData;
                                                                    tempData.options[index].item = e.target.value;
                                                                    this.setState({ editChildOptionsData: tempData })
                                                                    let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                                    if (!isError) {
                                                                    tempErrorData.options[index].item = "";
                                                                    this.setState({ editChildOptionsErrorData: tempErrorData });
                                                                    }
                                                                }}
                                                            />
                                                            {editChildOptionsErrorData.options[index].item !== "" &&
                                                                <div className="invalid-feedback">
                                                                    {editChildOptionsErrorData.options[index].item}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="input-group ml-3 mb-0">
                                                            <input type="text"
                                                                className="form-control"
                                                                id="parentOptionsDesc"
                                                                name="parentOptionsDesc"
                                                                maxLength="120"
                                                                placeholder="Description"
                                                                value={optionsRow.description}
                                                                onChange={(e) => {
                                                                    let tempData = editChildOptionsData;
                                                                    tempData.options[index].description = e.target.value;
                                                                    this.setState({ editChildOptionsData: tempData })
                                                                }}
                                                            />
                                                        </div>
                                                        {!editChildOptionsData.topping &&
                                                            <div className="input-group ml-3 mb-0">
                                                                <input type="number"
                                                                    className={"form-control " + (editChildOptionsErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                                    id="parentOptionsPrice"
                                                                    name="parentOptionsPrice"
                                                                    placeholder="Price"
                                                                    value={optionsRow.price}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                    onChange={(e) => {
                                                                        let tempData = editChildOptionsData;
                                                                        let tempErrorData = editChildOptionsErrorData;
                                                                        tempData.options[index].price = e.target.value;
                                                                        this.setState({ editChildOptionsData: tempData })
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                        if (!isError) {
                                                                        tempErrorData.options[index].price = "";
                                                                        this.setState({ editChildOptionsErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                />
                                                                { editChildOptionsData.options.length === 1 && index === 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { editChildOptionsData.options.length === index+1 && index > 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { index < editChildOptionsData.options.length-1 &&
                                                                    <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                }
                                                                {editChildOptionsErrorData.options[index].price !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editChildOptionsErrorData.options[index].price}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {editChildOptionsData.topping &&
                                                            <div className="input-group ml-3 mb-0">
                                                                <input type="number"
                                                                    className={"form-control " + (editChildOptionsErrorData.options[index].fullPrice !== "" ? 'invalid' : '')}
                                                                    id="parentOptionsFullPrice"
                                                                    name="parentOptionsFullPrice"
                                                                    placeholder="Full Price"
                                                                    value={optionsRow.fullPrice}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                    onChange={(e) => {
                                                                        let tempData = editChildOptionsData;
                                                                        let tempErrorData = editChildOptionsErrorData;
                                                                        tempData.options[index].fullPrice = e.target.value;
                                                                        this.setState({ editChildOptionsData: tempData })
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'fullPrice');
                                                                        if (!isError) {
                                                                        tempErrorData.options[index].fullPrice = "";
                                                                        this.setState({ editChildOptionsErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                />
                                                                {editChildOptionsErrorData.options[index].fullPrice !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editChildOptionsErrorData.options[index].fullPrice}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {editChildOptionsData.topping &&
                                                            <div className="input-group ml-3 mb-0">
                                                                <input type="number"
                                                                    className={"form-control " + (editChildOptionsErrorData.options[index].halfPrice !== "" ? 'invalid' : '')}
                                                                    id="parentOptionsHalfPrice"
                                                                    name="parentOptionsHalfPrice"
                                                                    placeholder="Half Price"
                                                                    value={optionsRow.halfPrice}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                    onChange={(e) => {
                                                                        let tempData = editChildOptionsData;
                                                                        let tempErrorData = editChildOptionsErrorData;
                                                                        tempData.options[index].halfPrice = e.target.value;
                                                                        this.setState({ editChildOptionsData: tempData })
                                                                        let isError = this.handleValidation("options", e.target.value, index, 'halfPrice');
                                                                        if (!isError) {
                                                                        tempErrorData.options[index].halfPrice = "";
                                                                        this.setState({ editChildOptionsErrorData: tempErrorData });
                                                                        }
                                                                    }}
                                                                />
                                                                { editChildOptionsData.options.length === 1 && index === 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { editChildOptionsData.options.length === index+1 && index > 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { index < editChildOptionsData.options.length-1 &&
                                                                    <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                }
                                                                {editChildOptionsErrorData.options[index].halfPrice !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editChildOptionsErrorData.options[index].halfPrice}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Select food items assign to this modifier</label>
                                    <div id="accordion">
                                        {productsData && productsData.length === 0 &&
                                            <h5 className="text-center">No Items Found.</h5>
                                        }
                                        {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                            productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                <div className="card-header">
                                                    <span className="ml-1">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                    </span>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                        <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                    </a>
                                                </div>

                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {productData && productData.product && productData.product.map((item) => (
                                                            <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                Update {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default EditChildOptions;