import React from 'react';
// import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import {countryCode}  from'../../countryCode';
import '../../../footer';

class AddDriver extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails,
            driverData: [],
            countryCodeArray: countryCode(),
            frm_driverDetails: {
                vendorID: vendorAllDetails.id.trim(),
                firstName: "",
                lastName: "",
                email: "",
                countryCode: "+1",
                phoneArray: {
                    value: [""],
                    error: [""]
                },
                licenseNumber: "",
                address: ""
            },
            frm_errors: {
                firstName: "",
                lastName: "",
                email: "",
                countryCode: "",
                licenseNumber: "",
                address: ""
            },
            submitProgress: false,
        }
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.isEmailInUse = this.isEmailInUse.bind(this);
        this.isLicenseNumberInUse = this.isLicenseNumberInUse.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.clickDelivery = this.clickDelivery.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
    }

    componentDidMount() {
        this.getDriverList();
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickDelivery() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/delivery_management`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/delivery_management`);
    }
    async getDriverList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `delivery/getdriverlist/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getDriverList();
                    return;
                }
                let tmpDriverData = result.data.response;
                this.setState({ driverData: tmpDriverData });
            }).catch(error => {
                this.setState({ driverData: [] })
                console.error("Problem in fetching driver data: ", error);
            })
        } catch (error) {
            this.setState({ driverData: [] })
            console.error("Problem in fetching driver data: ", error);
        }
    }

    isEmailInUse(email) {
        let allDriver = this.state.driverData;
        let index = allDriver.findIndex(driverObj => {
            return driverObj.email.toLowerCase() === email.toLowerCase()
        });
        return index;
    }
    isLicenseNumberInUse(licenseNumber) {
        let allDriver = this.state.driverData;
        let index = allDriver.findIndex(driverObj => {
            return driverObj.licenseNumber.toLowerCase() === licenseNumber.toLowerCase()
        });
        return index;
    }
    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_driverDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }

        if ((formSubmit === false && fieldName === "firstName" && value === "") || (formSubmit === true && formDetails["firstName"] === "")) {
            fieldName = 'firstName';
            formErrors[fieldName] = `The 'First name' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "lastName" && value === "") || (formSubmit === true && formDetails["lastName"] === "")) {
            fieldName = 'lastName';
            formErrors[fieldName] = `The 'Last name' is a required field.`;
            isError = true;
        }
        if ((formSubmit === false && fieldName === "licenseNumber" && value === "") || (formSubmit === true && formDetails["licenseNumber"] === "")) {
            fieldName = 'licenseNumber';
            formErrors[fieldName] = `The 'License number' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "licenseNumber" && value !== "") || (formSubmit === true && formDetails["licenseNumber"] !== "")) {
            if (this.isLicenseNumberInUse(formDetails["licenseNumber"]) !== -1) {
                fieldName = 'licenseNumber';
                formErrors[fieldName] = `The 'License number' already exists.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "address" && value === "") || (formSubmit === true && formDetails["address"] === "")) {
            fieldName = 'address';
            formErrors[fieldName] = `The 'Address' is a required field.`;
            isError = true;
        }
        if ((formSubmit === false && fieldName === "countryCode" && value === "") || (formSubmit === true && formDetails["countryCode"] === "")) {
            fieldName = 'countryCode';
            formErrors[fieldName] = `The 'Country Code' is a required field.`;
            isError = true;
        }
        
        if ((formSubmit === false && fieldName === "email" && value === "") || (formSubmit === true && formDetails["email"] === "")) {
            fieldName = 'email';
            formErrors[fieldName] = `The 'Email' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "email" && value !== "") || (formSubmit === true && formDetails["email"] !== "")) {
            if (this.isEmailInUse(formDetails["email"]) !== -1) {
                fieldName = 'email';
                formErrors[fieldName] = `The 'Email' already exists.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "phoneArray") || (formSubmit === true && formDetails["phoneArray"].value[0] === "")) {
            fieldName = 'phoneArray';
            for (let i = 0; i < formDetails["phoneArray"].value.length; i += 1) {
                if (formDetails["phoneArray"].value[i] === "") {
                    formDetails["phoneArray"].error[i] = `The 'Phone number' can not be empty.`;
                    isError = true;
                } else {
                    var phonenoRegex = new RegExp(/^[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/);
                    if (!phonenoRegex.test(formDetails["phoneArray"].value[i])) {
                        formDetails["phoneArray"].error[i] = `The 'Phone number' is not in a valid format.`;
                        isError = true;
                    } else if (formDetails["phoneArray"].value[i].length < 7) {
                        formDetails["phoneArray"].error[i] = `The 'Phone number' enter minimum 7 charater.`;
                        isError = true;
                    } else {
                        formDetails["phoneArray"].error[i] = "";
                    }
                }
            }
        }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_driverDetails: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        let formDetails = this.state.frm_driverDetails;
        let formErrors = this.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ frm_driverDetails: formDetails, isOpenFooter: true });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ frm_errors: formErrors });
        }
    }
    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.frm_driverDetails;
        formDetails[formField].value.push("");
        // this.checkValidation("phoneArray", "");
        this.setState({ frm_driverDetails: formDetails })
      }
    
      onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.frm_driverDetails;
        formDetails[formField].value.splice(index, 1);
        this.checkValidation("phoneArray", "");
        this.setState({ frm_driverDetails: formDetails })
      }
    async onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            let addObj = {
                vendorID: self.state.frm_driverDetails.vendorID,
                isDisable: false,
                firstName: self.state.frm_driverDetails.firstName,
                lastName: self.state.frm_driverDetails.lastName,
                email: self.state.frm_driverDetails.email,
                address: self.state.frm_driverDetails.address,
                licenseNumber: self.state.frm_driverDetails.licenseNumber,
                phone: self.state.frm_driverDetails.phoneArray,
                countryCode: self.state.frm_driverDetails.countryCode
            };
            if (addObj.phone.value && addObj.phone.value.length) {
                if(addObj.phone.value[0] === "") {
                    let formDetails = this.state.frm_driverDetails;
                    formDetails["phoneArray"].error[0] = `The 'Phone number' can not be empty.`;
                    this.setState({
                        frm_driverDetails: formDetails
                    });
                    return;
                }
            }
            if (addObj.phone.error && addObj.phone.error.length) {
                let isValid = true;
                for (let i = 0; i < addObj.phone.error.length; i += 1) {
                    if (addObj.phone.error[i].length) {
                        isValid = false;
                        break;
                    }
                }
                if (!isValid) {
                    toast.error("Please provide all required data in correct format.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
            }
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            if (vendorId) {
                let url = `delivery/adddriver`;
                // let requestData = new FormData();
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onFormSubmit();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the driver successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                                this.props.history.push(`/delivery_management`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ submitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding driver details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding driver details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        let { vendorAllDetails, frm_driverDetails, frm_errors, submitProgress, countryCodeArray, isShowFooter, isOpenFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        
        return (
            <div className="add-product-page">
                <ReactTooltip />
                {vendorAllDetails &&
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                <span className="page-title">Add Driver</span>
                                <ol className="breadcrumb pl-0 pt-0">
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                    {/* eslint-disable-next-line */}
                                    <li className="breadcrumb-item"><a className="common-link" onClick={this.clickDelivery}>Delivery</a></li>
                                    {/* <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li> */}
                                    {/* <li className="breadcrumb-item"><Link to="/delivery_management">Delivery</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Add Driver</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-xl-12 col-md-12">
                            <form className="needs-validation clearfix" autoComplete="nops" noValidate>
                                <div className="ms-panel ms-panel-fh custom-panel">
                                    <div className="ms-panel-header">
                                        <div className="d-flex">
                                            <h5 className="mb-0">Basic Details</h5>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="ms-panel-body">
                                        <div className="form-row">
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="firstName" className="required">First Name</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.firstName !== "" ? 'invalid' : '')}
                                                        placeholder="First Name"
                                                        id="firstName"
                                                        autoComplete="nops"
                                                        name="firstName"
                                                        maxLength="15"
                                                        value={frm_driverDetails.firstName}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.firstName && frm_errors.firstName !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.firstName}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="lastName" className="required">Last Name</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.lastName !== "" ? 'invalid' : '')}
                                                        placeholder="Last Name"
                                                        id="lastName"
                                                        autoComplete="nops"
                                                        name="lastName"
                                                        value={frm_driverDetails.lastName}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.lastName && frm_errors.lastName !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.lastName}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="email" className="required">Email</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.email !== "" ? 'invalid' : '')}
                                                        placeholder="Email"
                                                        id="email"
                                                        autoComplete="nops"
                                                        name="email"
                                                        value={frm_driverDetails.email}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.email && frm_errors.email !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="licenseNumber" className="required">License Number</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.licenseNumber !== "" ? 'invalid' : '')}
                                                        placeholder="License Number"
                                                        id="licenseNumber"
                                                        autoComplete="nops"
                                                        name="licenseNumber"
                                                        value={frm_driverDetails.licenseNumber}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.licenseNumber && frm_errors.licenseNumber !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.licenseNumber}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="countryCode" className="required">Country Code</label>
                                                <div className="input-group">
                                                    <select className={"form-control " + (frm_errors.countryCode !== "" ? 'invalid' : '')} name="countryCode" id="countryCode"
                                                        onChange={this.onDetailsChange}
                                                        value={frm_driverDetails.countryCode}
                                                        >
                                                        <option value="">Select Country Code</option>
                                                        {countryCodeArray && countryCodeArray.length && countryCodeArray.map((country, index) => (
                                                            <option key={`${index}-${country.name}`} value={country.code}>{country.name}</option>
                                                        ))}
                                                    </select>
                                                    {frm_errors.countryCode && frm_errors.countryCode !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.countryCode}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 mb-1">
                                                <label htmlFor="tax" className="required">Phone</label>
                                                    {frm_driverDetails.phoneArray.value !== "" &&
                                                    frm_driverDetails.phoneArray.value.map((phone, index) => (
                                                        <div className="input-group" key={index}>
                                                        <input type="text"
                                                            className={"form-control " + (frm_driverDetails.phoneArray.error[index] !== "" ? 'invalid' : '')}
                                                            id="phoneArray"
                                                            placeholder="Phone"
                                                            autoComplete="nops"
                                                            value={phone}
                                                            maxLength="15"
                                                            onChange={(e) => {
                                                            let tempData = frm_driverDetails;
                                                            tempData.phoneArray.value[index] = e.target.value;
                                                            this.setState({ frm_driverDetails: tempData, isOpenFooter: true })
                                                            let isError = this.checkValidation("phoneArray", e.target.value);
                                                            if (!isError) {
                                                                tempData.phoneArray.error[index] = "";
                                                                this.setState({ basicDetails: tempData });
                                                            }
                                                            }} />
                                                        {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "phoneArray")}><i className="fas fa-plus"></i></button>
                                                            : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "phoneArray", index)}><i className="fas fa-times"></i></button>
                                                        } */}
                                                        {frm_driverDetails.phoneArray.error[index] && frm_driverDetails.phoneArray.error[index] !== "" && <div className="invalid-feedback">
                                                            {frm_driverDetails.phoneArray.error[index]}
                                                        </div>}
                                                        </div>
                                                    ))}
                                                </div>
                                            <div className="col-lg-3 col-md-4 mb-3">
                                                <label htmlFor="address" className="required">Home Address</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={"form-control " + (frm_errors.address !== "" ? 'invalid' : '')}
                                                        placeholder="Home Address"
                                                        id="address"
                                                        autoComplete="nops"
                                                        name="address"
                                                        value={frm_driverDetails.address}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.address && frm_errors.address !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.address}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="fooditem-group print-fooditem-group">
                                <div className="row">
                                    <div className="col-md-12 pb-3 text-right">
                                        <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default AddDriver;