import React from 'react';
import momentTZ from 'moment-timezone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';

import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';

class RestaurantBasicDetails extends React.Component {
  constructor(props) {
    super(props);
    let vendorAllDetails = this.props.vendorAllDetails;
    this.timeZonesList = momentTZ.tz.names();
    this.state = {
      isOpenFooter: false,
      isShowFooter: this.props.isShowFooter,
      basicDetails: {
        name: { displayName: 'Name', value: vendorAllDetails.name ? vendorAllDetails.name : "", error: "" },
        title: { displayName: 'Title', value: vendorAllDetails.title ? vendorAllDetails.title : "", error: "" },
        description: { displayName: 'Description', value: vendorAllDetails.description ? vendorAllDetails.description : "", error: "" },
        address: { displayName: 'Address', value: vendorAllDetails.address ? vendorAllDetails.address : "", error: "" },
        extraAddress: { displayName: 'Extra address', value: vendorAllDetails.extraAddress ? vendorAllDetails.extraAddress : "", error: "" },
        city: { displayName: 'City', value: vendorAllDetails.city ? vendorAllDetails.city : "", error: "" },
        state: { displayName: 'State', value: vendorAllDetails.state ? vendorAllDetails.state : "", error: "" },
        zipcode: { displayName: 'Zip-code', value: vendorAllDetails.zipcode ? vendorAllDetails.zipcode : "", error: "" },
        phone: { displayName: 'Phone number', value: vendorAllDetails.phone ? vendorAllDetails.phone : "", error: "" },
        website: { displayName: 'Website', value: vendorAllDetails.website ? vendorAllDetails.website : "", error: "" },
        tz: { displayName: 'Timezone', value: vendorAllDetails.tz ? vendorAllDetails.tz : "", error: "" },
        tax: { displayName: 'Tax', value: vendorAllDetails.tax ? vendorAllDetails.tax : "", error: "" },
        photo: { displayName: 'Photo', value: vendorAllDetails.photo ? vendorAllDetails.photo : "", error: "" },
        printerWebhook: { displayName: 'Webhook URL for printer', value: vendorAllDetails.printerWebhook ? vendorAllDetails.printerWebhook : "", error: "" },
        yourOrderImage: { displayName: 'Your order image', value: vendorAllDetails.yourOrderImage ? vendorAllDetails.yourOrderImage : "", error: "" },
        latitude: { displayName: 'Latitude', value: vendorAllDetails.latitude ? vendorAllDetails.latitude : "", error: "" },
        longitude: { displayName: 'Longitude', value: vendorAllDetails.longitude ? vendorAllDetails.longitude : "", error: "" },
        dateFormat: { displayName: 'Date foramt', value: this.props.dateFormat ? this.props.dateFormat : 'MM-DD-YYYY', error: "" },

        vendor_receipt_email: {
          displayName: 'Receipt email',
          value: vendorAllDetails.vendor_receipt_email && vendorAllDetails.vendor_receipt_email !== ""
            ? vendorAllDetails.vendor_receipt_email.split(',')
            : [""],
          error: [""]
        },

        toContactForm: {
          displayName: 'Contact form To Email ID',
          value: vendorAllDetails.toContactForm && vendorAllDetails.toContactForm !== ""
            ? vendorAllDetails.toContactForm.split(',')
            : vendorAllDetails.vendor_email && vendorAllDetails.vendor_email !== "" ? [vendorAllDetails.vendor_email] : [""],
          error: [""]
        },

        vendorFaxEmail: {
          displayName: 'Fax email',
          value: vendorAllDetails.vendorFaxEmail && vendorAllDetails.vendorFaxEmail !== ""
            ? vendorAllDetails.vendorFaxEmail.split(',')
            : [""],
          error: [""]
        },
      },
      tmpBasicDetails: "",
      vendorAddress: null,
      submitProgress: false,
      dateFormats: ["MM-DD-YYYY"]
    }
    this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
    this.extractAddressDetails = this.extractAddressDetails.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onAddField = this.onAddField.bind(this);
    this.onDeleteField = this.onDeleteField.bind(this);
    this.leaveAnyway = this.leaveAnyway.bind(this);
  }

  componentDidMount() {
    var self = this;
    self.setState({
      tmpBasicDetails: JSON.stringify(self.state.basicDetails)
    });
    setTimeout(() => {
      self.extractAddressDetails(this.state.basicDetails.address.value, 'label')
    }, 1000)
  }

  leaveAnyway() {
    let self = this;
    global.config.sidebarFooter = false;
    global.config.context = false;
    self.setState({
        isOpenFooter: false,
        isShowFooter: false,
        basicDetails: JSON.parse(self.state.tmpBasicDetails)
    });
    self.props.saveData(false);
  }

  handleValidation(fieldName = "", value = "") {
    let formDetails = this.state.basicDetails;
    let isError = false;
    let formSubmit = false;
    if (fieldName === "" && value === "") {
      formSubmit = true;
    }
    if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"].value === "")) {
      fieldName = 'name';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "title") || (formSubmit === true)) {
      fieldName = 'title';
      if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName].value === "")) {
        formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
        isError = true;
      }
      if ((formSubmit === false && value.split(' ').length > 2) || (formSubmit === true && formDetails[fieldName].value.split(' ').length > 2)) {
        formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' should be one or maximum two words short name.`;
        isError = true;
      }
    }
    if ((formSubmit === false && fieldName === "description" && value === "") || (formSubmit === true && formDetails["description"].value === "")) {
      fieldName = 'description';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "address" && value === "") || (formSubmit === true && formDetails["address"].value === "")) {
      fieldName = 'address';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "tz" && value === "") || (formSubmit === true && formDetails["tz"].value === "")) {
      fieldName = 'tz';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "city" && value === "") || (formSubmit === true && formDetails["city"].value === "")) {
      fieldName = 'city';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "state" && value === "") || (formSubmit === true && formDetails["state"].value === "")) {
      fieldName = 'state';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "zipcode" && value === "") || (formSubmit === true && formDetails["zipcode"].value === "")) {
      fieldName = 'zipcode';
      formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
      isError = true;
    }
    if ((formSubmit === false && fieldName === "phone") || (formSubmit === true)) {
      fieldName = 'phone';
      if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName].value === "")) {
        formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
        isError = true;
      } else {
        var phonenoRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/);
        if (!phonenoRegex.test(formDetails[fieldName].value)) {
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
          isError = true;
        } else {
          if (formDetails[fieldName].value.length < 5) {
            formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' value should be more than 5.`;
          }
        }
      }
    }
    if ((formSubmit === false && fieldName === "website") || (formSubmit === true)) {
      fieldName = 'website';
      if ((formSubmit === false && value !== "") || (formSubmit === true && formDetails[fieldName].value !== "")) {
        // eslint-disable-next-line
        var urlRegex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
        if (!urlRegex.test(formDetails[fieldName].value)) {
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
          isError = true;
        }
      }
      else if (formSubmit === false) {
        formDetails[fieldName].error = "";
      }
    }
    if ((formSubmit === false && fieldName === "tax") || (formSubmit === true)) {
      fieldName = 'tax';
      if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName].value === "")) {
        formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
        isError = true;
      } else {
        if (formDetails[fieldName].value > 100) {
          formDetails[fieldName].value = "";
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' value should be less than 100.`;
          isError = true;
        }
        if (formDetails[fieldName].value < 0) {
          formDetails[fieldName].value = "";
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' value should be more than 0.`;
          isError = true;
        }
      }
    }
    if ((formSubmit === false && fieldName === "photo") || (formSubmit === true)) {
      fieldName = 'photo';
      if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName].value === "")) {
        formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is a required field.`;
        isError = true;
      }
      else if ((formSubmit === false && value.name && !value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))
        || (formSubmit === true && formDetails[fieldName].value.name && !formDetails[fieldName].value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))) {
        formDetails[fieldName].error = `You selected an invalid file. The valid formats are jpeg, jpg and png.`;
        isError = true;
      }
    }
    if ((formSubmit === false && fieldName === "yourOrderImage") || (formSubmit === true)) {
      fieldName = 'yourOrderImage';

      if ((formSubmit === false && value.name && !value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) ||
        (formSubmit === true && formDetails[fieldName].value.name && !formDetails[fieldName].value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))) {
        formDetails[fieldName].error = `You selected an invalid file. The valid formats are jpeg, jpg and png.`;
        isError = true;
      }
    }
    if ((formSubmit === false && fieldName === "vendor_receipt_email") || (formSubmit === true)) {
      fieldName = 'vendor_receipt_email';
      if ((formSubmit === false && value.length > 0) || (formSubmit === true && formDetails[fieldName].value.length > 0)) {
        for (let i = 0; i < formDetails[fieldName].value.length; i++) {
          if (formDetails[fieldName].value[i] !== "") {
            let lastAtPos = formDetails[fieldName].value[i].lastIndexOf('@');
            let lastDotPos = formDetails[fieldName].value[i].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formDetails[fieldName].value[i].indexOf('@@') === -1 && lastDotPos > 2 && (formDetails[fieldName].value[i].length - lastDotPos) > 2)) {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            } else {
              formDetails[fieldName].error[i] = "";
            }
          } else {
            if (i > 0 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            } else if (i === 0 && formDetails[fieldName].value.length > 1 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' can not be empty.`;
              isError = true;
              break;
            }
          }
        }
      }
    }

    if ((formSubmit === false && fieldName === "toContactForm") || (formSubmit === true)) {
      fieldName = 'toContactForm';
      if ((formSubmit === false && value.length > 0) || (formSubmit === true && formDetails[fieldName].value.length > 0)) {
        for (let i = 0; i < formDetails[fieldName].value.length; i++) {
          if (formDetails[fieldName].value[i] !== "") {
            let lastAtPos = formDetails[fieldName].value[i].lastIndexOf('@');
            let lastDotPos = formDetails[fieldName].value[i].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formDetails[fieldName].value[i].indexOf('@@') === -1 && lastDotPos > 2 && (formDetails[fieldName].value[i].length - lastDotPos) > 2)) {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            }
            else {
              formDetails[fieldName].error[i] = "";
            }
          } else {
            if (i > 0 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            } else if (i === 0 && formDetails[fieldName].value.length > 1 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' can not be empty.`;
              isError = true;
              break;
            }
          }
        }
      }
    }

    if ((formSubmit === false && fieldName === "vendorFaxEmail") || (formSubmit === true)) {
      fieldName = 'vendorFaxEmail';
      if ((formSubmit === false && value.length > 0) || (formSubmit === true && formDetails[fieldName].value.length > 0)) {
        for (let i = 0; i < formDetails[fieldName].value.length; i++) {
          if (formDetails[fieldName].value[i] !== "") {
            let lastAtPos = formDetails[fieldName].value[i].lastIndexOf('@');
            let lastDotPos = formDetails[fieldName].value[i].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formDetails[fieldName].value[i].indexOf('@@') === -1 && lastDotPos > 2 && (formDetails[fieldName].value[i].length - lastDotPos) > 2)) {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            }
            else {
              formDetails[fieldName].error[i] = "";
            }
          } else {
            if (i > 0 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
              isError = true;
              break;
            } else if (i === 0 && formDetails[fieldName].value.length > 1 && formDetails[fieldName].value[i] === "") {
              formDetails[fieldName].error[i] = `The '${formDetails[fieldName].displayName}' can not be empty.`;
              isError = true;
              break;
            }
          }
        }
      }
    }

    if ((formSubmit === false && fieldName === "printerWebhook") || (formSubmit === true)) {
      fieldName = 'printerWebhook';
      if ((formSubmit === false && value !== "") || (formSubmit === true && formDetails[fieldName].value !== "")) {
        // eslint-disable-next-line
        var urlRegex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
        if (!urlRegex.test(formDetails[fieldName].value)) {
          formDetails[fieldName].error = `The '${formDetails[fieldName].displayName}' is not in a valid format.`;
          isError = true;
        }
      }
      else if (formSubmit === false) {
        formDetails[fieldName].error = "";
      }
    }

    this.setState({ basicDetails: formDetails });
    return isError;
  }

  extractAddressDetails(data, type = "") {
    let address = data;
    let formDetails = this.state.basicDetails;
    if (type !== "label") {
      address = data.label;
      formDetails['address'].value = address;
      // this.setState({ basicDetails: formDetails });
      this.setState({ vendorAddress: data });
    }
    geocodeByAddress(address)
      .then(results => {
        if (type === "label") {
          this.setState({ vendorAddress: { label: data, value: results[0] } })
        }
        let addressAllDetails = results[0];
        getLatLng(addressAllDetails)
          .then(({ lat, lng }) => {
            formDetails['latitude'].value = lat;
            formDetails['longitude'].value = lng;
            this.setState({ basicDetails: formDetails });
          })

        if (
          addressAllDetails.address_components &&
          addressAllDetails.address_components.length > 0
        ) {
          for (let i = 0; i < addressAllDetails.address_components.length; i += 1) {
            var addressType = addressAllDetails.address_components[i].types[0];
            var val = addressAllDetails.address_components[i]['long_name'];
            if (addressType === "administrative_area_level_1") {
              formDetails['state'].value = val;
            }
            if (addressType === "locality") {
              // document.getElementById("city").value = val;
              formDetails['city'].value = val;
            }
            if (addressType === "postal_code") {
              formDetails['zipcode'].value = val;
            }
          }
          this.setState({ basicDetails: formDetails });
        }
      });
  }

  onFocusOut(e) {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;
    this.handleValidation(fieldName, fieldValue);
  }

  onBasicDetailsChange(e) {
    let self = this;
    let formDetails = self.state.basicDetails;
    let fieldName = e.target.id;
    self.props.saveData(true);
    self.setState({
      isOpenFooter: true
    });
    if (e.target.type === "file") {
      let fieldValue = e.target.files[0];
      let isError = this.handleValidation(fieldName, fieldValue);
      if (isError) {
        formDetails[fieldName].value = "";
        this.setState({ basicDetails: formDetails });
        return false;
      } else {
        formDetails[fieldName].value = fieldValue;
        formDetails[fieldName].error = "";
        this.setState({ basicDetails: formDetails });
      }
    } else {
      let fieldValue = e.target.value;
      formDetails[fieldName].value = fieldValue;
      this.setState({ basicDetails: formDetails });
      let isError = this.handleValidation(fieldName, fieldValue);
      if (!isError) {
        formDetails[fieldName].error = "";
        this.setState({ basicDetails: formDetails });
      }
    }
  }

  onAddField(e, formField) {
    e.preventDefault();
    let formDetails = this.state.basicDetails;
    formDetails[formField].value.push("");
    this.props.saveData(true);
    this.setState({ basicDetails: formDetails, isOpenFooter: true })
  }

  onDeleteField(e, formField, index) {
    e.preventDefault();
    let formDetails = this.state.basicDetails;
    formDetails[formField].value.splice(index, 1);
    this.props.saveData(true);
    this.setState({ basicDetails: formDetails, isOpenFooter: true })
  }

  async onFormSubmit() {
    let self = this;
    let isError = self.handleValidation();
    if (!isError) {
      self.setState({ submitProgress: true });
      let vendor = JSON.parse(localStorage.getItem('vendor'));
      let vendorId = vendor.id;
      let url = `vendor/updatevendor/basicdetails`;
      let requestData = new FormData();
      requestData.append("vendorId", vendorId);
      Object.keys(self.state.basicDetails).forEach(function (key) {
        if (key === "toContactForm" || key === "vendor_receipt_email" || key === "vendorFaxEmail") {
          requestData.append(key, self.state.basicDetails[key].value.join(','));
        } else {
          requestData.append(key, self.state.basicDetails[key].value);
        }
      });

      try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
          let auth = await getAuth();
          if (auth && auth.success && auth.token) {
            localStorage.setItem('token', auth.token);
          }
        }
        axiosInstance.post(url, requestData)
          .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
              localStorage.removeItem('token');
              this.onFormSubmit();
              return;
            }
            if (result.data.status) {
              let vendorCurrentData = getLoginUserDetails();
              vendorCurrentData['vendorAllDetails'] = result.data.response;
              localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
              self.props.DateFormatChange(self.state.basicDetails.dateFormat.value);
              self.props.vendorChange();
              let tempData = self.state.basicDetails;
              Object.keys(tempData).forEach(function (key) {
                if (key === "toContactForm" || key === "vendor_receipt_email" || key === "vendorFaxEmail") {
                  tempData[key].error = [""];
                } else {
                  tempData[key].error = "";
                }
              });
              global.config.sidebarFooter = false;
              global.config.context = false;
              self.setState({
                  isOpenFooter: false,
                  isShowFooter: false,
              });
              self.props.saveData(false);
              self.setState({ basicDetails: tempData, tmpBasicDetails: JSON.stringify(tempData) });
              toast.success("Success: Restaurant details updated!", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              toast.error(result.data.statusText, {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
            self.setState({ submitProgress: false });
          })
          .catch(error => {
            self.setState({ submitProgress: false });
            toast.error("Oops! We encountered an error. Please try again.", {
              autoClose: 2000,
              closeOnClick: true,
              pauseOnHover: true,
            });
            console.error("Problem in updating restaurant details.: ", error);
          })
      }
      catch (error) {
        self.setState({ submitProgress: false });
        toast.error("Oops! We encountered an error. Please try again.", {
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        console.error("Problem in updating restaurant details. ", error);
      }
    } else {
      toast.error("Please provide all required data.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }

  render() {
    let { basicDetails, vendorAddress, dateFormats, isOpenFooter, isShowFooter } = { ...this.state }
    if (isOpenFooter) {
      global.config.context = true;
    } else {
        global.config.context = false;
    }
    if (global.config.sidebarFooter) {
        isShowFooter = true;
    }
    return (
      <div>
          {basicDetails &&
            <form className="needs-validation clearfix" noValidate>
              <div className="ms-panel custom-panel">
                <div className="ms-panel-header">
                  <div className="d-flex">
                    <h5 className="mb-0">Basic Details</h5>
                  </div>
                  <hr />
                </div>
                <div className="ms-panel-body">
                  <div className="form-row">
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="name" className="required">Name</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.name.error !== "" ? 'invalid' : '')}
                          id="name"
                          placeholder="Name"
                          value={basicDetails.name.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} />
                        {basicDetails.name.error && basicDetails.name.error !== "" && <div className="invalid-feedback">
                          {basicDetails.name.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="title" className="required">Short Name</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.title.error !== "" ? 'invalid' : '')}
                          id="title"
                          placeholder="Short Name"
                          maxLength="100"
                          value={basicDetails.title.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} />
                        {basicDetails.title.error && basicDetails.title.error !== "" && <div className="invalid-feedback">
                          {basicDetails.title.error}
                        </div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-lg-6 col-md-8 mb-1 d-block">
                      <label htmlFor="description" className="required">Description</label>
                      <div className="input-group">
                        <textarea rows="5" id="description"
                          className={"form-control " + (basicDetails.description.error !== "" ? 'invalid' : '')}
                          placeholder="Message"
                          maxLength="300"
                          value={basicDetails.description.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut}></textarea>
                        {basicDetails.description.error && basicDetails.description.error !== "" && <div className="invalid-feedback">
                          {basicDetails.description.error}
                        </div>}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="ms-panel custom-panel">
                <div className="ms-panel-header">
                  <div className="d-flex">
                    <h5 className="mb-0">Location</h5>
                  </div>
                  <hr />
                </div>
                <div className="ms-panel-body">
                  <div className="form-row">
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="address" className="required">Address</label>
                      <div className="input-group">
                        <GooglePlacesAutocomplete
                          className="google-auto-complete"
                          apiKey="AIzaSyAfo54HzapKPSPGoq3c0JCParHv9vkWdZE"
                          selectProps={{
                            value: vendorAddress,
                            placeholder: 'Type delivery address',
                            onChange: (data) => {
                              this.extractAddressDetails(data, "");
                              this.props.saveData(true);
                              this.setState({
                                isOpenFooter: true
                              })
                            },
                          }}
                        />
                        {basicDetails.address.error && basicDetails.address.error !== "" && <div className="invalid-feedback">
                          {basicDetails.address.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="extraAddress">Extra Address</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.extraAddress.error !== "" ? 'invalid' : '')}
                          id="extraAddress"
                          maxLength="50"
                          value={basicDetails.extraAddress.value}
                          onChange={this.onBasicDetailsChange} placeholder="Extra Address" />
                        {basicDetails.extraAddress.error && basicDetails.extraAddress.error !== "" && <div className="invalid-feedback">
                          {basicDetails.extraAddress.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="city" className="required">City</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.city.error !== "" ? 'invalid' : '')}
                          id="city"
                          placeholder="City"
                          value={basicDetails.city.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} readOnly />
                        {basicDetails.city.error && basicDetails.city.error !== "" && <div className="invalid-feedback">
                          {basicDetails.city.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="state" className="required">State</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.state.error !== "" ? 'invalid' : '')}
                          id="state"
                          placeholder="State"
                          value={basicDetails.state.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} readOnly />
                        {basicDetails.state.error && basicDetails.state.error !== "" && <div className="invalid-feedback">
                          {basicDetails.state.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="zipcode" className="required">Zipcode</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.zipcode.error !== "" ? 'invalid' : '')}
                          id="zipcode"
                          placeholder="Zipcode"
                          value={basicDetails.zipcode.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} readOnly />
                        {basicDetails.zipcode.error && basicDetails.zipcode.error !== "" && <div className="invalid-feedback">
                          {basicDetails.zipcode.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="address" className="required">Latitude</label>
                      <div className="input-group">
                        <input type="number"
                          className="form-control"
                          id="latitude"
                          placeholder="Latitude"
                          value={basicDetails.latitude.value} readOnly />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="address" className="required">Longitude</label>
                      <div className="input-group">
                        <input type="number"
                          className="form-control"
                          id="longitude"
                          placeholder="Longitude"
                          value={basicDetails.longitude.value} readOnly />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="ms-panel custom-panel">
                <div className="ms-panel-header">
                  <div className="d-flex">
                    <h5 className="mb-0">Contact Details</h5>
                  </div>
                  <hr />
                </div>
                <div className="ms-panel-body">
                  <div className="form-row">
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="phone" className="required">Phone Number</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.phone.error !== "" ? 'invalid' : '')}
                          id="phone"
                          placeholder="Phone Number"
                          maxLength="15"
                          value={basicDetails.phone.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} />
                        {basicDetails.phone.error && basicDetails.phone.error !== "" && <div className="invalid-feedback">
                          {basicDetails.phone.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="website">Website</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.website.error !== "" ? 'invalid' : '')}
                          id="website"
                          placeholder="Website"
                          value={basicDetails.website.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} />
                        {basicDetails.website.error && basicDetails.website.error !== "" && <div className="invalid-feedback">
                          {basicDetails.website.error}
                        </div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tax">Receipt Email ID</label>
                      {basicDetails.vendor_receipt_email.value !== "" &&
                        basicDetails.vendor_receipt_email.value.map((email, index) => (
                          <div className="input-group" key={index}>
                            <input type="text"
                              className="form-control"
                              id="vendor_receipt_email"
                              placeholder="Receipt Email ID"
                              value={email}
                              onChange={(e) => {
                                let tempData = basicDetails;
                                tempData.vendor_receipt_email.value[index] = e.target.value;
                                this.props.saveData(true);
                                this.setState({ basicDetails: tempData, isOpenFooter: true })
                                let isError = this.handleValidation("vendor_receipt_email", e.target.value);
                                if (!isError) {
                                  tempData.vendor_receipt_email.error[index] = "";
                                  this.setState({ basicDetails: tempData });
                                }
                              }} />
                            { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "vendor_receipt_email")}><i className="fas fa-plus"></i></button>
                              : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "vendor_receipt_email", index)}><i className="fas fa-times"></i></button>
                            }
                            {basicDetails.vendor_receipt_email.error[index] && basicDetails.vendor_receipt_email.error[index] !== "" && <div className="invalid-feedback">
                              {basicDetails.vendor_receipt_email.error[index]}
                            </div>}
                          </div>
                        ))}
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tax">Contact Form 'To' Email ID / Reports Email</label>
                      {basicDetails.toContactForm.value !== "" &&
                        basicDetails.toContactForm.value.map((email, index) => (
                          <div className="input-group" key={index}>
                            <input type="text"
                              className="form-control"
                              id="toContactForm"
                              placeholder="Contact Form To Email ID"
                              value={email}
                              onChange={(e) => {
                                let tempData = basicDetails;
                                tempData.toContactForm.value[index] = e.target.value;
                                this.props.saveData(true);
                                this.setState({ basicDetails: tempData, isOpenFooter: true })
                                let isError = this.handleValidation("toContactForm", e.target.value);
                                if (!isError) {
                                  tempData.toContactForm.error[index] = "";
                                  this.setState({ basicDetails: tempData });
                                }
                              }} />
                            { index === 0 ? <button type="button" className="btn-outline-primary has-icon btn-add-email" onClick={(e) => this.onAddField(e, "toContactForm")}><i className="fas fa-plus"></i></button>
                              : <button type="button" className="btn-outline-danger has-icon btn-add-email" onClick={(e) => this.onDeleteField(e, "toContactForm", index)}><i className="fas fa-times"></i></button>
                            }
                            {basicDetails.toContactForm.error[index] && basicDetails.toContactForm.error[index] !== "" && <div className="invalid-feedback">
                              {basicDetails.toContactForm.error[index]}
                            </div>}
                          </div>
                        ))}
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tax">Fax Email ID</label>
                      {basicDetails.vendorFaxEmail.value !== "" &&
                        basicDetails.vendorFaxEmail.value.map((email, index) => (
                          <div className="input-group" key={index}>
                            <input type="text"
                              className="form-control"
                              id="vendorFaxEmail"
                              placeholder="Fax Email ID"
                              value={email}
                              onChange={(e) => {
                                let tempData = basicDetails;
                                tempData.vendorFaxEmail.value[index] = e.target.value;
                                this.props.saveData(true);
                                this.setState({ basicDetails: tempData, isOpenFooter: true })
                                let isError = this.handleValidation("vendorFaxEmail", e.target.value);
                                if (!isError) {
                                  tempData.vendorFaxEmail.error[index] = "";
                                  this.setState({ basicDetails: tempData });
                                }
                              }} />
                            { index === 0 ? <button type="button" className="btn-outline-primary has-icon btn-add-email" onClick={(e) => this.onAddField(e, "vendorFaxEmail")}><i className="fas fa-plus"></i></button>
                              : <button type="button" className="btn-outline-danger has-icon btn-add-email" onClick={(e) => this.onDeleteField(e, "vendorFaxEmail", index)}><i className="fas fa-times"></i></button>
                            }
                            {basicDetails.vendorFaxEmail.error[index] && basicDetails.vendorFaxEmail.error[index] !== "" && <div className="invalid-feedback">
                              {basicDetails.vendorFaxEmail.error[index]}
                            </div>}

                          </div>
                        ))}
                    </div>

                  </div>
                </div>
              </div>
              <div className="ms-panel custom-panel">
                <div className="ms-panel-header">
                  <div className="d-flex">
                    <h5 className="mb-0">Other Details</h5>
                  </div>
                  <hr />
                </div>
                <div className="ms-panel-body">
                  <div className="form-row">
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tz" className="required">Timezone</label>
                      <div className="input-group">
                        <select
                          className={"form-control " + (basicDetails.tz.error !== "" ? 'invalid' : '')}
                          id="tz"
                          onChange={this.onBasicDetailsChange}
                          value={basicDetails.tz.value}>
                          <option value="">Select</option>
                          {this.timeZonesList.map((tzName, index) => (
                            <option key={index} value={tzName}>{tzName}</option>
                          ))}
                        </select>
                        {basicDetails.tz.error && basicDetails.tz.error !== "" && <div className="invalid-feedback">
                          {basicDetails.tz.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tax" className="required">Date Format</label>
                      <div className="input-group">
                        <select className="form-control" id="dateFormat"
                          onChange={this.onBasicDetailsChange}
                          value={basicDetails.dateFormat.value}>
                          {dateFormats.map((format, index) => (
                            <option key={index} value={format}>{format}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-1">
                      <label htmlFor="tax" className="required">Tax(%)</label>
                      <div className="input-group">
                        <input type="number"
                          className={"form-control " + (basicDetails.tax.error !== "" ? 'invalid' : '')}
                          id="tax"
                          placeholder="Tax"
                          value={basicDetails.tax.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut}
                          min={0}
                          max={100} />
                        {basicDetails.tax.error && basicDetails.tax.error !== "" && <div className="invalid-feedback">
                          {basicDetails.tax.error}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-panel custom-panel">
                <div className="ms-panel-header">
                  <div className="d-flex">
                    <h5 className="mb-0">Upload Photos</h5>
                  </div>
                  <hr />
                </div>
                <div className="ms-panel-body">
                  <div className="form-row">
                    <div className="col-md-12 restaurant-photos">
                      <label htmlFor="photo" className="required">Photos</label>
                      <div className="input-group">
                        <input type="file"
                          accept="image/x-png,image/jpeg,image/jpg,image/svg+xml"
                          className={"custom-file-input " + (basicDetails.photo.error !== "" ? 'invalid' : '')}
                          onChange={this.onBasicDetailsChange}
                          id="photo" />
                        <label className={"custom-file-label " + (basicDetails.photo.error !== "" ? 'invalid' : '')}
                          htmlFor="photo">Upload Photos...</label>
                        {basicDetails.photo.value && basicDetails.photo.value !== "" && basicDetails.photo.value.name && <p>{basicDetails.photo.value.name}</p>}
                        {basicDetails.photo.value && basicDetails.photo.value !== "" && !basicDetails.photo.value.name && <p>{basicDetails.photo.value}</p>}
                        {basicDetails.photo.error && basicDetails.photo.error !== "" && <div className="invalid-feedback">
                          {basicDetails.photo.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-md-12 mb-2">
                      <label htmlFor="website">Webhook URL for Printer</label>
                      <div className="input-group">
                        <input type="text"
                          className={"form-control " + (basicDetails.printerWebhook.error !== "" ? 'invalid' : '')}
                          id="printerWebhook"
                          placeholder="Webhook URL for Printer"
                          value={basicDetails.printerWebhook.value}
                          onChange={this.onBasicDetailsChange}
                          onBlur={this.onFocusOut} />
                        {basicDetails.printerWebhook.error && basicDetails.printerWebhook.error !== "" && <div className="invalid-feedback">
                          {basicDetails.printerWebhook.error}
                        </div>}
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label htmlFor="orderImage">Your Order Image</label>
                      <div className="input-group">
                        <input type="file"
                          accept="image/x-png,image/jpeg,image/jpg,image/svg+xml"
                          className={"custom-file-input " + (basicDetails.yourOrderImage.error !== "" ? 'invalid' : '')}
                          onChange={this.onBasicDetailsChange}
                          id="yourOrderImage" />
                        <label className={"custom-file-label " + (basicDetails.yourOrderImage.error !== "" ? 'invalid' : '')}
                          htmlFor="yourOrderImage">Upload Photos...</label>
                        {basicDetails.yourOrderImage.value && basicDetails.yourOrderImage.value !== "" && basicDetails.yourOrderImage.value.name && <p>{basicDetails.yourOrderImage.value.name}</p>}
                        {basicDetails.yourOrderImage.value && basicDetails.yourOrderImage.value !== "" && !basicDetails.yourOrderImage.value.name && <p>{basicDetails.yourOrderImage.value}</p>}
                        {basicDetails.yourOrderImage.error && basicDetails.yourOrderImage.error !== "" && <div className="invalid-feedback">
                          {basicDetails.yourOrderImage.error}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-4 text-right">
                  <button type="button" className="btn btn-primary btn-basic-save" onClick={this.onFormSubmit}>Save {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                </div>
              </div>

            </form>
          }
          { isShowFooter &&
            <div className="unsave-footer">
              <p>There are unsaved changes, please save your changes.</p>
              <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
              <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
            </div>
          }
      </div>
    );
  }
}

export default RestaurantBasicDetails;