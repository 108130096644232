import React from 'react';
// import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
// import FoodItemGroup from '../../FoodItemsGroup';
import '../../../footer';

class AddBulkProducts extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        let productCategories = [];
        if (vendorAllDetails && vendorAllDetails.product_categories && vendorAllDetails.product_categories.length > 0) {
            vendorAllDetails.product_categories.forEach((categoryName, index) => {
                productCategories.push(categoryName)
            })
        }
        let available = [];
        if (vendorAllDetails && vendorAllDetails.schedule) {
            // eslint-disable-next-line
            for (let [dayName, daySchedule] of Object.entries(vendorAllDetails.schedule)) {
                // eslint-disable-next-line
                for (const [slotName, slotDetails] of Object.entries(daySchedule)) {
                    let index = available.findIndex((value) => {
                        return (value.name).trim() === (slotName).trim()
                    })
                    if (index === -1) {
                        available.push({ name: (slotName).trim(), selected: true });
                    }
                }
            }
        }
        
        this.state = {
            vendorAllDetails,
            available: available,
            isOpenFooter: false,
            isShowFooter: false,
            frm_productDetails: {
                vendorId: vendorAllDetails.id.trim(),
                productCategory: "",
                productDataArray: [{
                    available: available,
                    description: "",
                    name: "",
                    photo: "",
                    photos: [],
                    price: "",
                    printStationName: [],
                    product_category: "",
                    special_instructions: true,
                    vendorID: vendorAllDetails.id.trim()
                }]
            },
            frm_errors: {
                productCategory: "",
                productDataArray: [{
                    name: '',
                    price: ''
                }]
            },
            submitProgress: false,
            productCategories,
            helpText: {
                categories: "Selected category all product price changes."
            },
        }
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.clickMenu = this.clickMenu.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
    }

    componentDidMount() {
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickMenu() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/menu_management?tab=menus`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/menu_management?tab=menus`);
    }
    onAddField(e) {
        e.preventDefault();
        let formDetails = this.state.frm_productDetails;
        let formDetailsError = this.state.frm_errors;
        formDetails.productDataArray.push({
            available: this.state.available,
            description: "",
            name: "",
            photo: "",
            photos: [],
            price: "",
            printStationName: [],
            product_category: "",
            special_instructions: true,
            vendorID: this.state.vendorAllDetails.id.trim()
        });
        formDetailsError.productDataArray.push({
            name: "",
            price: ""
        });
        this.setState({ frm_productDetails: formDetails, frm_errors: formDetailsError, isOpenFooter: true})
    }
    onDeleteField(e, index) {
        e.preventDefault();
        let formDetails = this.state.frm_productDetails;
        let formDetailsError = this.state.frm_errors;
        formDetails.productDataArray.splice(index, 1);
        formDetailsError.productDataArray.splice(index, 1);
        this.setState({ frm_productDetails: formDetails, frm_errors: formDetailsError, isOpenFooter: true })
    }
    checkValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.frm_productDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "productCategory" && value === "") || (formSubmit === true && formDetails["productCategory"] === "")) {
            fieldName = 'productCategory';
            formErrors[fieldName] = `The 'Product category' is a required field.`;
            isError = true;
        }
        

        if ((formSubmit === false && fieldName === 'productDataArray') || (formSubmit === true)) {
            fieldName = 'productDataArray';
            if (index !== undefined) {       
                if (formDetails['productDataArray'][index][newField] === "") {
                    formErrors['productDataArray'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                } else if (newField === 'price' && Number(formDetails['productDataArray'][index][newField]) < 0) {
                    // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                    formDetails['productDataArray'][index][newField] = "";
                    isError = true;
                }
            }
        }
        if (fieldName === 'productDataArray' && index === undefined) {
            for (let i = 0; i < formDetails.productDataArray.length; i += 1) {
                if (formDetails.productDataArray[i].name === "") {
                    fieldName = 'productDataArray';
                    formErrors.productDataArray[i].name = `The 'Name' is a required field.`;
                    isError = true;
                }
                if (formDetails.productDataArray[i].price === "") {
                    fieldName = 'productDataArray';
                    formErrors.productDataArray[i].price = `The 'Price' is a required field.`;
                    isError = true;
                } else if (Number(formDetails.productDataArray[i].price) < 0) {
                    fieldName = 'productDataArray';
                    formDetails.productDataArray[i].price ="";
                    isError = true;
                }
            }
        }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_productDetails: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e, name = "") {
        let formDetails = this.state.frm_productDetails;
        let formErrors = this.state.frm_errors;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ frm_productDetails: formDetails, isOpenFooter: true });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ frm_errors: formErrors });
        }
    }

    async onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            if (vendorId) {
                let url = `menu/product/bulkadd`;
                
                let finalDataArray = [];
                if (self.state.frm_productDetails.productDataArray && self.state.frm_productDetails.productDataArray.length) {
                    for (let i = 0; i < self.state.frm_productDetails.productDataArray.length; i += 1) {
                        self.state.frm_productDetails.productDataArray[i].product_category = self.state.frm_productDetails.productCategory;
                        finalDataArray.push(self.state.frm_productDetails.productDataArray[i]);
                    }
                }
                let requestData = {
                    productDataArray: finalDataArray,
                    vendorId: vendorId
                };
                
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onFormSubmit();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("Success: Product bulk added!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                                self.setState({
                                    frm_productDetails: {
                                        vendorId: vendorId.trim(),
                                        productCategory: "",
                                        productDataArray: [{
                                            available: self.state.available,
                                            description: "",
                                            name: "",
                                            photo: "",
                                            photos: [],
                                            price: "",
                                            printStationName: [],
                                            product_category: "",
                                            special_instructions: true,
                                            vendorID: vendorId.trim()
                                        }]
                                    },
                                    frm_errors: {
                                        productCategory: "",
                                        productDataArray: [{
                                            name: '',
                                            price: ''
                                        }]
                                    },
                                    submitProgress: false
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ submitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Oops! We encountered a problem in adding the bulk product details. Please try again.", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding bulk product details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in the correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        let { vendorAllDetails, frm_productDetails, frm_errors, productCategories, submitProgress, helpText, isOpenFooter, isShowFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div>
                <div className="bulk-add-product-section">
                    <ReactTooltip />
                    {vendorAllDetails &&
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                    <span className="page-title">Add Bulk Product</span>
                                    <ol className="breadcrumb pl-0 pt-0">
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.clickMenu}>Menu</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Bulk Product</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <form className="needs-validation clearfix" noValidate>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <label htmlFor="productCategory" className="required">Select Category</label>
                                            <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.categories}></i>
                                            <div className="input-group">
                                                <select
                                                    className={"form-control " + (frm_errors.productCategory !== "" ? 'invalid' : '')}
                                                    id="productCategory"
                                                    value={frm_productDetails.productCategory}
                                                    onChange={this.onDetailsChange}
                                                    // onBlur={this.onFocusOut}
                                                    required
                                                >
                                                    <option value="">Select Category</option>
                                                    {productCategories && productCategories.length > 0 && productCategories.map((option, index) => (
                                                        <option key={`category-index-${index}`} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                                {frm_errors.productCategory && frm_errors.productCategory !== "" &&
                                                    <div className="invalid-feedback">{frm_errors.productCategory}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {frm_productDetails && frm_productDetails.productDataArray && frm_productDetails.productDataArray.length > 0 &&
                                        frm_productDetails.productDataArray.map((productRow, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-md-3 col-lg-3">
                                                    <label htmlFor="name" className="required">Product Name</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={"form-control " + (frm_errors.productDataArray[index].name !== "" ? 'invalid' : '')}
                                                            placeholder="Product Name"
                                                            id="name"
                                                            name="name"
                                                            maxLength="100"
                                                            value={productRow.name}
                                                            // onChange={this.onDetailsChange}
                                                            onChange={(e) => {
                                                                let tempData = frm_productDetails;
                                                                let tempErrorData = frm_errors;
                                                                tempData.productDataArray[index].name = e.target.value;
                                                                this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                                let isError = this.checkValidation("productDataArray", e.target.value, index, 'name');
                                                                if (!isError) {
                                                                tempErrorData.productDataArray[index].name = "";
                                                                this.setState({ frm_errors: tempErrorData });
                                                                }
                                                            }}
                                                            // onBlur={this.onFocusOut}
                                                            required
                                                        />
                                                        {frm_errors.productDataArray[index].name !== "" &&
                                                            <div className="invalid-feedback">
                                                                {frm_errors.productDataArray[index].name}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-3">
                                                    <label htmlFor="name" className="required">Price</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            className={"form-control " + (frm_errors.productDataArray[index].price !== "" ? 'invalid' : '')}
                                                            placeholder="Price"
                                                            id="price"
                                                            name="price"
                                                            min="0"
                                                            max="9999"
                                                            value={productRow.price}
                                                            // onChange={this.onDetailsChange}
                                                            onChange={(e) => {
                                                                let tempData = frm_productDetails;
                                                                let tempErrorData = frm_errors;
                                                                tempData.productDataArray[index].price = e.target.value;
                                                                this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                                let isError = this.checkValidation("productDataArray", e.target.value, index, 'price');
                                                                if (!isError) {
                                                                tempErrorData.productDataArray[index].price = "";
                                                                this.setState({ frm_errors: tempErrorData });
                                                                }
                                                            }}
                                                            // onBlur={this.onFocusOut}
                                                            required
                                                        />
                                                        {frm_errors.productDataArray[index].price !== "" &&
                                                            <div className="invalid-feedback">
                                                                {frm_errors.productDataArray[index].price}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-lg-2">
                                                    <label htmlFor="name">Special Instruction</label>
                                                    <div>
                                                        <label className="ms-switch">
                                                            <input type="checkbox" checked={productRow.special_instructions}
                                                                onChange={(e) => {
                                                                    let tempData = frm_productDetails;
                                                                    tempData.productDataArray[index].special_instructions = e.target.checked;
                                                                    this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                                }} />
                                                            <span className="ms-switch-slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3 col-lg-3">
                                                    <label htmlFor={`validationCustom25-${index}`}>Available</label>
                                                    <div className="input-group">
                                                        {productRow && productRow.available && productRow.available.map((option, aIndex) => (
                                                            <span className="ml-1" key={`option-${index}-${aIndex}`}>
                                                                
                                                                <label className="ms-checkbox-wrap" htmlFor={`optioncheck-${index}-${aIndex}`}>
                                                                    <input type="checkbox" onChange={(e) => {
                                                                        option.selected = e.target.checked;
                                                                    }}/>
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{option.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div> */}
                                                <div className="col-md-3 col-lg-3">
                                                    <label htmlFor="description">Description</label>
                                                    <div className="input-group">
                                                        <textarea
                                                            rows="3"
                                                            id="description"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            value={productRow.description}
                                                            onChange={(e) => {
                                                                let tempData = frm_productDetails;
                                                                tempData.productDataArray[index].description = e.target.value;
                                                                this.setState({ frm_productDetails: tempData, isOpenFooter: true })
                                                            }}
                                                            maxLength={300}
                                                        ></textarea>
                                                        { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e)}><i className="fas fa-plus"></i></button>
                                                        : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, index)}><i className="fas fa-times"></i></button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="row">    
                                        <div className="col-md-12 pb-3 text-right">
                                            <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                        </div>
                                    </div>
                                </form>    
                            </div>
                        </div>
                    }
                </div>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default AddBulkProducts;