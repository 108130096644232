import React from 'react';
import ReactDOM from "react-dom";
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import moment from 'moment';
import DataTablesComp from '../../DataTables';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';

class RestaurantOffdayDetails extends React.Component {
    constructor(props) {
        super(props);
        const columns = [
            { title: `Date`, data: 'date' },
            { title: 'Reason', data: 'reason' },
            { title: 'Action', data: 'reason', orderable: false },
          ];
        this.state = {
            offdays: {
                data: null
            },
            columns: columns,
            reloadTable: false,
            reason: "",
            date: new Date(),
            datePickerformat: "",
            dateFormat: "",
            submitProgress: false,
            reasonError: "",
            isOpenFooter: false,
            isShowFooter: this.props.isShowFooter
        }
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.setOffdayTableData = this.setOffdayTableData.bind(this);
        this.addNewOffday = this.addNewOffday.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
    }

    componentDidMount() {
        let vendorDetails = getLoginUserDetails();
        this.setOffdayTableData(vendorDetails.vendorAllDetails);
    }

    componentDidUpdate() {
        if (this.props.dateFormat !== this.state.dateFormat) {
            let dateFormat = this.props.dateFormat ? this.props.dateFormat : "MM-DD-YYYY";
            let datePickerformat = dateFormat === "MM-DD-YYYY" ? "MM-dd-y" : "dd-MM-y";
            this.setState({ dateFormat, datePickerformat });
        }
    }

    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false,
            reason: "",
            date: new Date(),
            datePickerformat: "",
            dateFormat: "",
            reasonError: ""
        });
        self.props.saveData(false);
    }

    setOffdayTableData(vendorAllDetails) {
        this.setState({
            offdays: {
                data: vendorAllDetails.offdays,
                columnDefs: [{
                    targets: [2],
                    createdCell: (td, cellData, rowData, index) => {
                        ReactDOM.render(
                            <a href="/#" onClick={(event) => this.confirmAndDelete(event, index)}>Delete</a>, td)
                    }
                }]
            }
        })
    }

    confirmAndDelete(event, index) {
        var self = this;
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this off day!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let vendor = JSON.parse(localStorage.getItem('vendor'));
                let vendorId = vendor.id;
                let url = `vendor/updatevendor/deleteoffday`;

                // Prepare post data 
                let requestData = { "vendorId": vendorId, "offDayId": index + "" };

                // Submit request to update data
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                Swal.fire(
                                    'Error!',
                                    'Oops! We encountered an error. Please try again.',
                                    'error'
                                )
                                return;
                            }
                            if (result.data.status) {
                                let vendorCurrentData = getLoginUserDetails();
                                vendorCurrentData['vendorAllDetails'] = result.data.response;
                                localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                                self.setOffdayTableData(result.data.response);
                                self.setState({ reloadTable: true });
                                Swal.fire(
                                    'Deleted!',
                                    'Off day has been deleted.',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Error!',
                                    'Oops! We encountered an error. Please try again.',
                                    'error'
                                )
                            }
                        })
                        .catch(error => {
                            Swal.fire(
                                'Error!',
                                'Oops! We encountered an error. Please try again.',
                                'error'
                            )
                            console.error("Problem in updating schedule details.: ", error);
                        })
                }
                catch (error) {
                    Swal.fire(
                        'Error!',
                        'Oops! We encountered an error. Please try again.',
                        'error'
                    )
                    console.error("Problem in updating schedule details. ", error);
                }
            }
        })
    }

    onInputChange(field, value) {
        this.props.saveData(true);
        this.setState({
            isOpenFooter: true
        });
        if (field === "date") {
            this.setState({ "date": value });
        } else if (field === "reason") {
            this.setState({ "reason": value });
            if (value === "") {
                this.setState({ "reasonError": "Reason field is required" });
            } else {
                this.setState({ "reasonError": "" });
            }
        }
    }

    async addNewOffday() {
        let self = this;
        self.setState({ submitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/addoffday`;

        if (self.state.reason === "") {
            self.setState({ "reasonError": "Reason field is required" });
            self.setState({ submitProgress: false });
            toast.error("Please provide all required data.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        // Prepare post data 
        let requestData = {
            "vendorId": vendorId,
            "date": new moment(self.state.date).format(self.state.dateFormat),
            "fmt": self.state.dateFormat,
            "reason": self.state.reason
        };

        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        self.addNewOffday();
                        return;
                    }
                    if (result.data.status) {
                        let vendorCurrentData = getLoginUserDetails();
                        vendorCurrentData['vendorAllDetails'] = result.data.response;
                        localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                        self.setOffdayTableData(result.data.response);
                        global.config.sidebarFooter = false;
                        global.config.context = false;
                        self.setState({
                            isOpenFooter: false,
                            isShowFooter: false,
                            reason: "",
                            date: new Date(),
                            datePickerformat: "",
                            dateFormat: "",
                            reasonError: ""
                        });
                        self.props.saveData(false);
                        self.setState({ reloadTable: true });
                        toast.success("Success: Off day added!", {
                            autoClose: 2500,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 2500,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    self.setState({ submitProgress: false });
                    self.setState({ reason: "" });
                    self.setState({ date: new Date() });
                })
                .catch(error => {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating schedule details.: ", error);
                })
        }
        catch (error) {
            self.setState({ submitProgress: false });
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating schedule details. ", error);
        }
    }

    render() {
        let { offdays, columns, reloadTable, reason, date, datePickerformat, reasonError, isOpenFooter, isShowFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="section-offdays">
                <form className="needs-validation form-add-offdays clearfix p-3 mt-2" noValidate>
                    <div className="form-row">
                        <div className="col-md-3">
                            <label htmlFor="name" className="required">Date</label>
                            <div className="input-group">
                                <DatePicker className="form-control" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                    id="date"
                                    minDate={new Date()}
                                    value={date}
                                    format={datePickerformat}
                                    onChange={(value) => { this.onInputChange('date', value) }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="reason" className="required">Reason</label>
                            <div className="input-group">
                                <input type="text" id="reason" value={reason}
                                    onChange={(event) => {
                                        this.onInputChange('reason', event.target.value);
                                    }}
                                    className="form-control"
                                    placeholder="Write Reason" />
                                {reasonError && reasonError !== "" && <div className="invalid-feedback">
                                    {reasonError}
                                </div>}
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button type="button" className="btn btn-primary mt-4" onClick={this.addNewOffday}>Add {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                        </div>
                    </div>
                </form>
                <div className="table-responsive mt-4">
                    {offdays && offdays.data !== null && 
                    <DataTablesComp data={offdays} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="desc" id="offDayTable" />}
                </div>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.addNewOffday}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default RestaurantOffdayDetails;