import React from 'react'
import { Link } from "react-router-dom";
import DataTablesComp from '../../DataTables';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import Spinner from '../../spinner';

class CustomerManagement extends React.Component {
    constructor(props) {
        super(props);
        const columns = [
            { title: 'First Name', data: 'firstName' },
            { title: 'Last Name', data: 'lastName' },
            { title: 'Email', data: 'email' },
            { title: 'Order Source', data: 'orderSource' },
            { title: 'Status', data: 'status' },
        ];
        this.state = {
            customer: {
                data: null
            },
            columns: columns,
            reloadTable: false
        }
    }

    componentDidMount() {
        this.getCustolerList();
    }

    /**
     * This function prepare customer data
     * @param {Array} data The customer details
     * @returns The manage array data
     */
    prepareCustomerData(data) {
        let printData = [];
        try {
            printData = data.map(pData => {
                return {
                    ...pData, orderSource: pData.orderSource ? pData.orderSource : "-"
                }
            });
            return printData;
        } catch (error) {
            console.error(error);
            return printData;
        }
    }

    /**
     * This function called from page first time initialisation
     ** Called API [api/v1/customer/getcustomerlist/{vendorId}](https://apidev.svang.app/apidocs/#/Customer%20Management/post_api_v1_customer_getcustomerlist__vendorId_)
     ** If API give response then called function [prepareCustomerData](#preparecustomerdata)
     Response in all customers data
     */
    async getCustolerList() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `customer/getcustomerlist/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getCustolerList();
                    return;
                }

                let customerData = result.data.response;
                this.setState({
                    customer: {
                        data: self.prepareCustomerData(customerData),
                        columnDefs: []
                    }
                });
                this.setState({ reloadTable: true });
            }).catch(error => {
                this.setState({ customer: { data: [] } })
                console.error("Problem in fetching customer list data: ", error);
            })
        } catch (error) {
            this.setState({ customer: { data: [] } })
            console.error("Problem in fetching customer list data: ", error);
        }
    }

    render() {
        const { customer, reloadTable, columns } = { ...this.state }
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Customers</span>
                        <ol className="breadcrumb pl-0 pt-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Customers</li>
                        </ol>
                    </nav>
                    <div className="tab-content">
                        <div role="tabpanel"
                            className="tab-pane active fade in show" id="tab1">
                            {(!customer || customer.data === null) && <Spinner />}
                            <div className="table-responsive">
                                {customer && customer.data !== null &&
                                    <DataTablesComp data={customer} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="asc" id="customerTable" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerManagement;