import React from 'react'
import { Switch, withRouter } from "react-router-dom";

import { setLoginUserDetails, logoutUser, isLogin, getLoginUserDetails, setSubscriptionPlanDetails } from './utils';
import { auth } from './firebase';

import Sidebar from "./components/sidebar"
import Header from "./components/header"
import Login from "./components/pages/login"
import OrderManagement from './components/pages/order-management/index'
import OrderDetails from './components/pages/order-management/details'
import MenuManagement from './components/pages/menu-management/index'
import AddProduct from './components/pages/menu-management/add-product'
import AddBulkProducts from './components/pages/menu-management/add-bulk-products'
import EditProduct from './components/pages/menu-management/edit-product'
import AddModifier from './components/pages/menu-management/add-modifier'
import EditModifier from './components/pages/menu-management/edit-modifier'
import EditUpsell from './components/pages/menu-management/edit-upsell'
import AddUpsell from './components/pages/menu-management/add-upsell'
import AddOptionalItems from './components/pages/menu-management/add-optional-items'
import EditOptionalItems from './components/pages/menu-management/edit-optional-items'
import AddAdditionOptions from './components/pages/menu-management/add-addition-options'
import EditAdditionOptions from './components/pages/menu-management/edit-addition-options'
import AddAdditionOptionsWithPrice from './components/pages/menu-management/add-addition-options-with-price'
import EditAdditionOptionsWithPrice from './components/pages/menu-management/edit-addition-options-with-price'
import AddAllItemsSelection from './components/pages/menu-management/add-all-items-selection'
import EditAllItemsSelection from './components/pages/menu-management/edit-all-items-selection'
import AddParentOptions from './components/pages/menu-management/add-parent-options'
import EditParentOptions from './components/pages/menu-management/edit-parent-options'
import ChildOptionsDetails from './components/pages/menu-management/child-options-details'
import AddChildOptions from './components/pages/menu-management/add-child-options'
import EditChildOptions from './components/pages/menu-management/edit-child-options'
import PrintManagement from './components/pages/print-management/index'
import AddPrintStation from './components/pages/print-management/add-print-station'
import EditPrintStation from './components/pages/print-management/edit-print-station'
import PreviewPrintStation from './components/pages/print-management/preview-print-station'
import StoreManagement from './components/pages/store-management/index'
import Root from './components/pages/root'
import MarketingManagement from './components/pages/marketing-management/index'
import CampaignSetup from './components/pages/marketing-management/campaigns-setup'
import CampaignUpdate from './components/pages/marketing-management/campaign-update'
import EmailPreview from './components/pages/marketing-management/preview'
import Report from './components/pages/marketing-management/report'
import SetNewPassword from './components/pages/account-setup/set-new-password'
import SetVendor from './components/pages/account-setup/set-vendor'
import AddCoupon from './components/pages/marketing-management/coupon-add'
import EditCoupon from './components/pages/marketing-management/coupon-edit'
import CustomerManagement from './components/pages/customer-management/index'
import DeliveryManagement from './components/pages/delivery-management/index'
import AddDriver from './components/pages/delivery-management/add-driver'
import EditDriver from './components/pages/delivery-management/edit-driver'
import WebManagement from './components/pages/web-management/index'


import NotAuthorized from './components/pages/not-authorized/index'

import PrivateRoute from "./utils/private-route";
import PublicRoute from "./utils/public-route";

import './assets/css/custom.css'
import './assets/css/design-rev1.css'

class App extends React.Component {
  constructor(props) {
    super(props);
    setSubscriptionPlanDetails();
    auth.onAuthStateChanged(function(user) {
      if (user) {
          setLoginUserDetails(user);
      } else {
          logoutUser();
      }
  });
  
  this.state = {
    isSidebarOpen: window.innerWidth > 1024 ? true : false,
    vendorAllDetails: null
  }
  this.onToggleClick = this.onToggleClick.bind(this);
  this.onVendorChange = this.onVendorChange.bind(this);
  }

  componentDidMount(){
    this.onVendorChange()
  }

  onToggleClick(){
    let currentToggleState = this.state.isSidebarOpen;
    this.setState({isSidebarOpen: !currentToggleState});
  }

  onVendorChange(){
    let vendor = isLogin() ? getLoginUserDetails() : null;
    let vendorAllDetails = vendor && vendor.vendorAllDetails ? vendor.vendorAllDetails : null;
    this.setState({vendorAllDetails});
  }

  render() {
    let bodyContentPadding = window.innerWidth <= 1024 ? '0px' : this.state.isSidebarOpen ? '180px' : '60px';
    return (
        <div>
        {!isLogin() && <div className="ms-body ms-primary-theme ms-logged-out">
          <main className="body-content">
            <div className="ms-content-wrapper ms-auth">
            <PublicRoute restricted={true} component={Root} path="/" exact/>
            <PublicRoute restricted={true} component={Login} path="/login" vendorChange={this.onVendorChange}/>
            <PrivateRoute component={OrderManagement} path="/order_management" />
            <PublicRoute component={SetNewPassword} path="/set_new_password" exact/>
            <PublicRoute component={SetVendor} path="/set_vendor" exact/>
            </div>
          </main>
        </div>}

        {isLogin() && <div className="App ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
          <PublicRoute restricted={true} component={Login} path="/login"/>
          <Sidebar {...this.props} isSidebarOpen={this.state.isSidebarOpen} toggleClick={this.onToggleClick}></Sidebar>
          <main className="body-content" style={{paddingLeft: bodyContentPadding}}>
            <Header {...this.props} 
            vendorChange={this.onVendorChange}
            vendorAllDetails={this.state.vendorAllDetails} 
            isSidebarOpen={this.state.isSidebarOpen} 
            toggleClick={this.onToggleClick}></Header>
            <div className="ms-content-wrapper">
              <Switch>
                <PrivateRoute component={NotAuthorized} path="/not_authorized"/>
                <PrivateRoute component={SetNewPassword} path="/set_new_password" exact/>
                <PrivateRoute component={MenuManagement} path="/menu_management" exact/>
                <PrivateRoute component={AddProduct} path="/menu_management/product/add"/>
                <PrivateRoute component={AddBulkProducts} path="/menu_management/products/bulkadd"/>
                <PrivateRoute component={EditProduct} path="/menu_management/product/:productId"/>
                <PrivateRoute component={AddUpsell} path="/menu_management/modifiers/upsell/add"/>
                <PrivateRoute component={AddModifier} path="/menu_management/modifier/add"/>
                <PrivateRoute component={EditModifier} path="/menu_management/modifier/:modifierId"/>
                <PrivateRoute component={EditUpsell} path="/menu_management/modifiers/upsell/:upsellId"/>
                <PrivateRoute component={AddOptionalItems} path="/menu_management/modifiers/optional-items/add"/>
                <PrivateRoute component={EditOptionalItems} path="/menu_management/modifiers/optional-items/:optionalItemsId"/>
                <PrivateRoute component={AddAdditionOptions} path="/menu_management/modifiers/addition-options/add"/>
                <PrivateRoute component={EditAdditionOptions} path="/menu_management/modifiers/addition-options/:additionOptionsId"/>
                <PrivateRoute component={AddAdditionOptionsWithPrice} path="/menu_management/modifiers/addition-options-with-price/add"/>
                <PrivateRoute component={EditAdditionOptionsWithPrice} path="/menu_management/modifiers/addition-options-with-price/:additionOptionsWithPriceId"/>
                <PrivateRoute component={AddAllItemsSelection} path="/menu_management/modifiers/all-items-selection/add"/>
                <PrivateRoute component={EditAllItemsSelection} path="/menu_management/modifiers/all-items-selection/:allItemsSelectionId"/>
                <PrivateRoute component={AddParentOptions} path="/menu_management/modifiers/parent-options/add"/>
                <PrivateRoute component={EditParentOptions} path="/menu_management/modifiers/parent-options/:parentOptionsId"/>
                <PrivateRoute component={ChildOptionsDetails} path="/menu_management/modifiers/child-options-details/:parentOptionsId"/>
                <PrivateRoute component={AddChildOptions} path="/menu_management/modifiers/child-options/add"/>
                <PrivateRoute component={EditChildOptions} path="/menu_management/modifiers/child-options/:childOptionsId"/>
                <PrivateRoute component={PrintManagement} path="/print_management" exact/>
                <PrivateRoute component={AddPrintStation} path="/print_management/print_station/add"/>
                <PrivateRoute component={EditPrintStation} path="/print_management/print_station/:printStationId"/>
                <PrivateRoute component={PreviewPrintStation} path="/print_management/preview"/>
                <PrivateRoute component={StoreManagement} path="/store_management" vendorChange={this.onVendorChange} />
                <PrivateRoute component={OrderManagement} path="/order_management" exact/>
                <PrivateRoute component={OrderDetails} path="/order_management/:orderId" />
                <PrivateRoute component={MarketingManagement} path="/marketing_management" exact/>
                <PrivateRoute component={AddCoupon} path="/marketing_management/coupon/add"/>
                <PrivateRoute component={EditCoupon} path="/marketing_management/coupon/:couponId" exact/>
                <PrivateRoute component={CampaignSetup} path="/marketing_management/setup" exact/>
                <PrivateRoute component={EmailPreview} path="/marketing_management/preview" exact/>
                <PrivateRoute component={Report} path="/marketing_management/report/:campaignId" exact/>
                <PrivateRoute component={CampaignUpdate} path="/marketing_management/:campaignId" exact/>
                <PrivateRoute component={CustomerManagement} path="/customer_management" exact/>
                <PrivateRoute component={DeliveryManagement} path="/delivery_management" exact/>
                <PrivateRoute component={AddDriver} path="/delivery_management/driver/add"/>
                <PrivateRoute component={EditDriver} path="/delivery_management/driver/:driverId"/>
                <PrivateRoute component={WebManagement} path="/web_management"/>
              </Switch>
            </div>
          </main>
        </div>}
        </div>
    );
  }
}
export default withRouter(App);
