import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';

import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalEmailSent: 0,
            uniqueOpen: 0,
            uniqueClick: 0,
            totalUnsubscribe: 0,
            totalOpen: 0,
            totalClick: 0,
            openList: [],
            clickList: []
        }
    }

    componentDidMount() {
        let campaignId = this.props.match.params.campaignId;
        this.getCampaignAnalyticsData(campaignId);
    }

    async getCampaignAnalyticsData(campaignId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `campaign/analytics/${vendorId}/${campaignId}`;
        if (vendorId && campaignId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getCampaignAnalyticsData(campaignId);
                            return;
                        }
                        if (result.data.status) {
                            let response = result.data.response;
                            let campaignData = response.campaignData;
                            let analyticsData = response.analyticData;
                            if (campaignData && analyticsData.length > 0) {
                                self.setState({ totalEmailSent: 'totalSent' in campaignData ? campaignData.totalSent : 0 });
                                let uniqueOpen = 0;
                                let uniqueClick = 0;
                                let totalUnsubscribe = 0;
                                let totalOpen = 0;
                                let totalClick = 0;
                                let openList = [];
                                let clickList = [];
                                analyticsData.forEach(data => {
                                    totalOpen += data.emailOpenCount;
                                    totalClick += data.linkClickCount;
                                    if ('unsubscribeCount' in data) {
                                        totalUnsubscribe += data.unsubscribeCount;
                                    } else {
                                        totalUnsubscribe += 0;
                                    }
                                    if (data.emailOpenCount > 0) {
                                        openList.push(data);
                                        uniqueOpen += 1;
                                    }
                                    if (data.linkClickCount > 0) {
                                        clickList.push(data);
                                        uniqueClick += 1;
                                    }
                                })
                                this.setState({ uniqueOpen, uniqueClick, totalUnsubscribe, totalOpen, totalClick, openList, clickList });
                            }
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in adding product details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Oops! We encountered an error in updating the restaurant details. ", error);
            }
        } else {
            toast.error("Oops! We encountered an error in fetching the product data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Campaign Report</span>
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/marketing_management">Marketing</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Campaign Report</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Campaign Report</h6>
                        </div>
                        <div className="ms-panel-body ms-panel-fh">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Email Figures</h6>
                                </div>
                                <div className="ms-panel-body p-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="email-figures">
                                                <ul className="email-figures-list">
                                                    <li>
                                                        <span className="figure-value">{this.state.totalEmailSent}</span>
                                                        <span className="figure-header"><strong>Sent To</strong></span>
                                                    </li>
                                                    <li>
                                                        <span className="figure-value">{this.state.uniqueOpen}</span>
                                                        <span className="figure-header"><strong>Unique Opens</strong></span>
                                                    </li>
                                                    <li>
                                                        <span className="figure-value">{this.state.uniqueClick}</span>
                                                        <span className="figure-header"><strong>Unique Clicks</strong></span>
                                                    </li>
                                                    <li>
                                                        <span className="figure-value">{this.state.totalUnsubscribe}</span>
                                                        <span className="figure-header"><strong>Unsubscribed</strong></span>
                                                    </li>
                                                    <li>
                                                        <span className="figure-value">{this.state.totalOpen}</span>
                                                        <span className="figure-header"><strong>Total Opens</strong></span>
                                                    </li>
                                                    <li>
                                                        <span className="figure-value">{this.state.totalClick}</span>
                                                        <span className="figure-header"><strong>Total Clicks</strong></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row detail-analytics">
                                <div className="col-12 col-md-6">
                                    <div className="ms-panel ms-panel-fh email-analytics">
                                        <div className="ms-panel-header">
                                            <h6>Opens</h6>
                                        </div>
                                        <div className="ms-panel-body tbl-analytics">
                                            <div className="ms-panel-header">
                                                <div className="d-flex justify-content-between tbl-analytics-header">
                                                    <div>
                                                        <h6>#</h6>
                                                    </div>
                                                    <div>
                                                        <h6>Email</h6>
                                                    </div>
                                                    <div>
                                                        <h6>Total Opens</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-panel-body">
                                                {this.state.openList && this.state.openList.length > 0 ? this.state.openList.map((data, index) => (
                                                    <div className="d-flex justify-content-between tbl-analytics-body" key={`selected-item-${index}`}>
                                                        <div>
                                                            <p>{index + 1}</p>
                                                        </div>
                                                        <div>
                                                            <p>{data.email}</p>
                                                        </div>
                                                        <div>
                                                            <p>{data.emailOpenCount}</p>
                                                        </div>
                                                    </div>
                                                )) : <div className="tbl-analytics-body text-center">
                                                    <p>No data available in table</p>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="ms-panel ms-panel-fh email-analytics">
                                        <div className="ms-panel-header">
                                            <h6>Clicks</h6>
                                        </div>
                                        <div className="ms-panel-body tbl-analytics">
                                            <div className="ms-panel-header">
                                                <div className="d-flex justify-content-between tbl-analytics-header">
                                                    <div>
                                                        <h6>#</h6>
                                                    </div>
                                                    <div>
                                                        <h6>Email</h6>
                                                    </div>
                                                    <div>
                                                        <h6>Total Clicks</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-panel-body">
                                                {this.state.clickList && this.state.clickList.length > 0 ? this.state.clickList.map((data, index) => (
                                                    <div className="d-flex justify-content-between tbl-analytics-body" key={`selected-item-${index}`}>
                                                        <div>
                                                            <p>{index + 1}</p>
                                                        </div>
                                                        <div>
                                                            <p>{data.email}</p>
                                                        </div>
                                                        <div>
                                                            <p>{data.linkClickCount}</p>
                                                        </div>
                                                    </div>
                                                )) : <div className="tbl-analytics-body text-center">
                                                    <p>No data available in table</p>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Report);