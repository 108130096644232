import React from "react";

class Root extends React.Component {
    render() {
        return (
            <div>
                <p>Server is running</p>
            </div>
        )
    }
}

export default Root;