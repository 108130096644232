import React from 'react';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import DriverIndex from './driver-index';
import DeliveryPartnersIndex from './delivery-partner-index';
import MenuMappingDetails from './menu-mapping';

class DeliveryManagement extends React.Component {
    constructor(props) {
        super(props);
        let activeTab = queryString.parse(this.props.location.search).tab;
        if(!activeTab || activeTab === ""){
            activeTab = "driver";
        }
        this.state = {
            activeTab: activeTab
        }
        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidMount() {
    }
    onTabChange(tabName){
        this.setState({activeTab: tabName});
    }

    render() {
        const { activeTab } = { ...this.state }
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                        <span className="page-title">Delivery</span>
                        <ol className="breadcrumb pl-0 pt-0">
                            <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Delivery</li>
                        </ol>
                    </nav>
                    <ul
                        className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4"
                        role="tablist"
                    >
                        <li role="presentation">
                        <Link
                            to="/delivery_management?tab=driver"
                            aria-controls="tab1"
                            className={activeTab && (activeTab === "" || activeTab.includes('driver')) ?  "active show" : ""}
                            role="tab"
                            data-toggle="tab"   
                            onClick={() => {this.onTabChange("driver")}}                 
                        >
                        Driver Details
                            </Link>
                        </li>
                        <span className="tab-separator"></span>
                        <li role="presentation">
                        <Link
                            to="/delivery_management?tab=deliverypartner"
                            aria-controls="tab2"
                            className={activeTab && activeTab.includes('deliverypartner') ?  "active show" : ""}
                            role="tab"
                            data-toggle="tab"  
                            onClick={() => {this.onTabChange("deliverypartner")}}                     
                        >
                            Delivery Partners
                            </Link>
                        </li>
                        <span className="tab-separator"></span>
                        <li role="presentation">
                        <Link
                            to="/delivery_management?tab=menu-mapping"
                            aria-controls="tab3"
                            className={activeTab && activeTab.includes('menu-mapping') ?  "active show" : ""}
                            role="tab"
                            data-toggle="tab"  
                            onClick={() => {this.onTabChange("menu-mapping")}}                     
                        >
                            Menu Mapping
                            </Link>
                        </li>
                    </ul>
                    <div className="div-delivery-partner">
                        <div className="tab-content">
                            <div role="tabpanel" 
                                className={activeTab && (activeTab === "" || activeTab.includes('driver')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                                id="tab1">
                                <DriverIndex />
                            </div>
                            <div role="tabpanel" 
                                className={activeTab && (activeTab.includes('deliverypartner')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                                id="tab2">
                                    <DeliveryPartnersIndex />
                            </div>
                            <div role="tabpanel" 
                                className={activeTab && (activeTab.includes('menu-mapping')) ?  "tab-pane active fade in show" : "tab-pane fade"} 
                                id="tab3">
                                    <MenuMappingDetails />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeliveryManagement;