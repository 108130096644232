import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

class FoodItemGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opneModal: this.props.opneModal,
            selectedFoodItems: JSON.parse(JSON.stringify(this.props.selectedFoodItems)),
            title: this?.props?.title || 'Select specific item'
        }
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onCategoryClick(e, index, productData){
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if(e.target.checked){
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if(itemIndex === -1){
                    selectedProductItems[index].product.push({id: item.id, name: item.name});                    
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if(itemIndex !== -1){
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({selectedFoodItems: selectedProductItems});
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName){
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        
        if (e.target.checked) {            
            selectedProductItems[index].product.push({id: productItemId, name: productItemName})
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if(selectedProductItems[index].product.length === categoryItems.length){
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({selectedFoodItems: selectedProductItems});
    }

    onSubmit(e){
        e.preventDefault();
        this.props.save(this.state.selectedFoodItems);
    }

    onClose(){
        this.props.close();
    }

    render() {        
        const { productsData, opneModal } = {...this.props}
        const { selectedFoodItems, title } = {...this.state}
        return (
            <Modal open={opneModal} onClose={this.onClose} className="modal-food-item" center>
                            <div className="container fooditem-group">
                                <h5>{title}</h5>
                                <form method="post">
                                    <div className="mb-3">
                                        <div id="accordion" className="food-items">
                                            {productsData && productsData.length > 0 && productsData.map((productData, index) => (
                                                productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                    <div className="card-header">
                                                        <span className="ml-1">
                                                            <label className="ms-checkbox-wrap">
                                                                <input type="checkbox" 
                                                                checked = {selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => {this.onCategoryClick(e, index, productData)}}/>
                                                                <i className="ms-checkbox-check"></i>
                                                            </label>
                                                        </span>
                                                        <a className="collapsed card-link" data-toggle="collapse" href={`#index-${index}`}>
                                                            {productData.categoryName} {`(${selectedFoodItems[index].product.length})`}
                                                        </a>
                                                    </div>
                                                    <div id={`index-${index}`} className="collapse" data-parent="#accordion">
                                                        <div className="card-body" style={{ }}>
                                                            {productData && productData.product && productData.product.map((item) => (
                                                                <span className="ml-1 mr-3" key={`${item.id}`} style={{}}>
                                                                    <label className="ms-checkbox-wrap">
                                                                        <input type="checkbox" 
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => {this.onCategoryItemClick(e, productData, index, item.id, item.name)}}
                                                                        />
                                                                        <i className="ms-checkbox-check"></i>
                                                                    </label>
                                                                    <span>{item.name}</span>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary float-right" onClick={this.onSubmit}>
                                        Save
                                    </button>
                                </form>
                            </div>
                        </Modal>
        )
    }
}
export default FoodItemGroup;