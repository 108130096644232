import React from 'react';
import { toast } from 'react-toastify';

import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class RestaurantUniversityDetails extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = this.props.vendorAllDetails;
        this.state = {
            universityList: [],
            selectedUniversity: vendorAllDetails.univerSityID && vendorAllDetails.univerSityID.length > 0 ? vendorAllDetails.univerSityID[0] : "",
            tmpSelectedUniversity: vendorAllDetails.univerSityID && vendorAllDetails.univerSityID.length > 0 ? vendorAllDetails.univerSityID[0] : "",
            submitProgress: false,
            isOpenFooter: false,
            isShowFooter: this.props.isShowFooter
        }
        this.onUniversitySelect = this.onUniversitySelect.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
    }

    componentDidMount() {
        this.getUniversityList();
    }

    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false,
            selectedUniversity: self.state.tmpSelectedUniversity,
            submitProgress: false,
        });
        self.props.saveData(false);
    }

    async getUniversityList() {
        let url = `university`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getUniversityList();
                        return;
                    }
                    if (result.data.status) {
                        this.setState({ universityList: result.data.response })
                    }
                })
                .catch(error => {
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in getting university details.: ", error);
                })
        }
        catch (error) {
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating restaurant details. ", error);
        }
    }

    onUniversitySelect(e) {
        let fieldValue = e.target.value;
        this.props.saveData(true);
        this.setState({
            isOpenFooter: true
        });
        this.setState({ selectedUniversity: fieldValue });
    }

    async onFormSubmit() {
        let self = this;
        self.setState({ submitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/universitydetails`;

        // Prepare post data 
        let requestData = { "vendorId": vendorId, "univerSityID": self.state.selectedUniversity };

        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.onFormSubmit();
                        return;
                    }
                    if (result.data.status) {
                        let vendorCurrentData = getLoginUserDetails();
                        vendorCurrentData['vendorAllDetails'] = result.data.response;
                        localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                        global.config.sidebarFooter = false;
                        global.config.context = false;
                        self.setState({
                            tmpSelectedUniversity: self.state.selectedUniversity,
                            isOpenFooter: false,
                            isShowFooter: false,
                        });
                        self.props.saveData(false);
                        toast.success("Success: University affiliation details updated!", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        toast.error(result.data.statusText, {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    self.setState({ submitProgress: false });
                })
                .catch(error => {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating university affiliation details : ", error);
                })
        }
        catch (error) {
            self.setState({ submitProgress: false });
            toast.error("Oops! We encountered an error. Please try again.", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating university affiliation details. ", error);
        }
    }

    render() {
        let { selectedUniversity, universityList, isOpenFooter, isShowFooter } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div>
                <form className="needs-validation clearfix" noValidate>
                    <div className="form-row">
                        <div className="col-md-3 mb-2">
                            <label htmlFor="tz">Select University</label>
                            <div className="input-group">
                                <select className="form-control" id="university"
                                    onChange={this.onUniversitySelect}
                                    value={selectedUniversity}>
                                    <option value="">None</option>
                                    {universityList.map((university, index) => (
                                        <option key={index} value={university.id}>{university.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 mt-2">
                            <button type="button" className="btn btn-primary" onClick={this.onFormSubmit}>Save {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                        </div>
                    </div>
                </form>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default RestaurantUniversityDetails;