import axios from 'axios';
import { auth, firestore, collectionName } from "../firebase";

const apiHost = process.env.REACT_APP_APIURL+"/";
const apiBaseURL = apiHost + "api/v1/";
export const axiosInstance = axios.create({ baseURL: apiBaseURL })
const routeMenuItemMapping = {
    'order_management': 'orderManagement',
    'menu_management': 'menuManagement',
    'print_management': 'printManagement',
    'restaurant_management': 'restaurantManagement',
    'campaign_management': 'campaignAndPromotion',
    'store_management': 'storeManagement',
    'marketing_management': 'marketingManagement',
    'customer_management': 'customerManagement',
    'delivery_management': 'deliveryManagement',
    'web_management': 'webManagement'
}

export const getAuthTokenFromLocalStorage = () => {
    return localStorage.getItem('token');
}

axiosInstance.interceptors.request.use((req) => {
    var token = localStorage.getItem('token');
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    req.headers = headers;
    return req;
}, error => {
    return Promise.reject(error);
}
);

const getAuthPrimaryOrSecondary = async () => {
    try {
        let url = `get-auth-primary-secondary`;
        let result = await axios.get(apiBaseURL + url);
        if (result.status) {
            return result.data;
        } else {
            return {};
        }
    } catch (error) {
        console.error("Error");
        console.error(error);
    }
};

export const getAuth = async () => {
    let data = await getAuthPrimaryOrSecondary();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    let url = `89578159-d0f4-4a4c-a267-3595c01aa459`;
    let result = await axios.post(apiHost + url, data.data, { headers });
    return result.data;
}

export const isLogin = () => {
    try {
        if (localStorage.getItem('vendor')) {
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const setLoginUserDetails = (user) => {
    try {
        firestore.collection(collectionName.USERS).doc(user.uid).onSnapshot(async (snapshot) => {
            if (snapshot.exists) {
                var userData = snapshot.data();
                if (userData.vendorID && userData.vendorID !== "") {
                    let vendor = await firestore.collection(collectionName.VENDOR).doc(userData.vendorID).get();
                    if (vendor.exists) {
                        let vendorData = {
                            email: userData.email,
                            displayName: (userData.firstName ? userData.firstName : ""),
                            uid: userData.id,
                            id: userData.vendorID,
                            vendorAllDetails: vendor.data()
                        };
                        localStorage.setItem("vendor", JSON.stringify(vendorData));
                    }
    
                }
            }
        });
    } catch (error) {
        console.log(error);
    }
}

export const getLoginUserDetails = () => {
    return JSON.parse(localStorage.getItem('vendor'));
}

export const logoutUser = () => {
    try {
        auth.signOut()
            .then(() => {
                localStorage.removeItem('vendor');
            })
            .catch(error => {
                console.error(error);
            });
    } catch (error) {
        console.error(error);
    }
}

export const setSubscriptionPlanDetails = async () => {
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        let url = `subscription/plans`;
        axiosInstance.get(url)
        .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                setSubscriptionPlanDetails();
                return;
            }
            if (result.data.status) {
                let responseData = result.data.response;
                let subscriptionPlans = responseData.plans;
                let subscriptionPlanDetails = responseData.planDetails;
                localStorage.setItem('subscriptionPlans', JSON.stringify(subscriptionPlans));
                localStorage.setItem('subscriptionPlansDetails', JSON.stringify(subscriptionPlanDetails));
            } else {
                console.error("Problem in getting subscription plan details: ");
                localStorage.setItem('subscriptionPlans', []);
                localStorage.setItem('subscriptionPlansDetails', []);
            }
        })
        .catch(error => {
            console.error("Problem in getting subscription plan details: ", error);
        })
    } catch (error) {
        console.error("Problem in getting subscription plan details: ", error);
    }
}

export const getPlanDetailsByID = (id) => {
    try {
        let plans = JSON.parse(localStorage.getItem('subscriptionPlansDetails'));
        let planDetails = plans.filter(plan => {
            return plan['id'] === id;
        })
        if(planDetails.length > 0){
            planDetails = planDetails[0];
        } else {
            planDetails = null;
        }
        return planDetails;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const isAuthorizedToUse = (path) => {
    try {
        if (!path.includes("not_authorized")) {
            let currentUser = getLoginUserDetails();
            currentUser = currentUser.vendorAllDetails;
            let currentSubscriptionPlan = 'subscriptionPlan' in currentUser ? currentUser.subscriptionPlan : "";
            if (currentSubscriptionPlan && currentSubscriptionPlan !== "") {
                let planDetails = getPlanDetailsByID(currentSubscriptionPlan);
                if (planDetails) {
                    let allowedMenuItemsList = 'menuItems' in planDetails ? planDetails['menuItems'] : [];
                    let activeMenuItem = '';
                    for (const [key, value] of Object.entries(routeMenuItemMapping)) {
                        if (path.includes(key)) {
                            activeMenuItem = value;
                            break;
                        }
                    }
                    if (activeMenuItem !== "" && allowedMenuItemsList.indexOf(activeMenuItem) !== -1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

// Convert given date into ISO string format
export const dateToISOString = (date) => {
    try {
        if(typeof date !== "string"){
            return new Date(date.seconds * 1000).toISOString();
        } else {
            return date;
        }
    } catch (error) {
        console.error(error);
        return date;
    }
}

export const orderStatus = {
    pending: 'Pending',
    confirm: 'Confirm',
    outfordelivery: 'Out for Delivery',
    delivered: 'Delivered',
    atthedoor: 'At the door',
    readytopick: 'Ready for Pickup',
    outfromkitchen: 'Out from kitchen',
}

export const allDeliveryType = {
    deliveryatdoor: 'Delivery to door',
    pickatresturant: 'Pick at Restaurant',
    publicdelivery: 'Delivery',
    inDine: 'In Dine',
}