import React from 'react'
import { Link, withRouter } from "react-router-dom";
import logo from '../assets/img/logo.png';
import smallLogo from '../assets/img/apple-touch-icon.png';
import storeManagementIcon from '../assets/img/icons/restaurant-management.png';
import orderManagementIcon from '../assets/img/icons/order-management.png';
import menuManagementIcon from '../assets/img/icons/menu-management.png';
import MarketingManagement from '../assets/img/icons/campaign_management.png';
import PrintManagementIcon from '../assets/img/icons/print-management.png';
import CustomerManagementIcon from '../assets/img/icons/customer_management.png';
import DeliveryManagementIcon from '../assets/img/icons/delivery-management.png';
import WebManagementIcon from '../assets/img/icons/web-management.png';


import storeManagementIconActive from '../assets/img/icons/restaurant-management-orange.png';
import orderManagementIconActive from '../assets/img/icons/order-management-orange.png';
import menuManagementIconActive from '../assets/img/icons/menu-management-orange.png';
import MarketingManagementActive from '../assets/img/icons/campaign_management_orange.png';
import PrintManagementIconActive from '../assets/img/icons/print-management-orange.png';
import CustomerManagementIconActive from '../assets/img/icons/customer_management-orange.png';
import DeliveryManagementIconActive from '../assets/img/icons/delivery-management-orange.png';
import WebManagementIconActive from '../assets/img/icons/web-management-orange.png';
import './../footer';
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.location.pathname
    };
    // this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onMenuItemClick(data){
    let self = this;
    if (!global.config.context) {
        self.props.history.push(data);
    } else {
        global.config.sidebarFooter = true;
    }

    // if(window.innerWidth <= 1024){
    //   this.props.toggleClick();
    // }
  }

  componentDidUpdate(){
    if(this.props.location.pathname !== this.state.currentPage){
      this.setState({ currentPage: this.props.location.pathname })
    }
  }

  render() {
    const { currentPage } = { ...this.state }
    return (
      <div className="sidebar">
      <div className="ms-aside-overlay ms-overlay-left ms-toggler" 
      onClick={this.props.toggleClick}      
      style={{display: this.props.isSidebarOpen &&  window.innerWidth <= 1024 ? 'block' : 'none'}}></div>
        <aside
          id="ms-side-nav" 
          style={{transform: this.props.isSidebarOpen ? 'translateX(0%)' : window.innerWidth <= 1024  ? 'translateX(-100%)' : 'translateX(0%)', 
          width: this.props.isSidebarOpen ? '180px' : '60px'}}
          className="side-nav fixed ms-aside-scrollable ms-aside-left transition-1s"
        >
          <div className="logo-sn ms-d-block-lg">
            {/* <Link to="/order_management" className="pl-0 ml-0 text-center large-logo" */}
            {/* eslint-disable-next-line */}
            <Link onClick={() => this.onMenuItemClick('/order_management')} className="pl-0 ml-0 text-center large-logo"
            style={{display: this.props.isSidebarOpen &&  window.innerWidth >= 1024 ? 'block' : 'none'}}>
              <img src={logo} alt="sVang" />
            </Link>
            {/* <Link to="/order_management" className="pl-0 ml-0 text-center small-logo"  */}
            {/* eslint-disable-next-line */}
            <Link onClick={() => this.onMenuItemClick('/order_management')} className="pl-0 ml-0 text-center small-logo" 
            style={{display: !this.props.isSidebarOpen &&  window.innerWidth >= 1024 ? 'block' : 'none'}}>
              <img src={smallLogo} alt="sVang"/>
            </Link>
          </div>
          <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/order_management?tab=today')}
              className={currentPage.includes('/order_management') ? 'active' : ''}>                 
                  <img alt="Order Management" src={currentPage.includes('/order_management') ? orderManagementIconActive : orderManagementIcon} className="mr-3"/>
                  <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Orders</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/menu_management')}
              className={currentPage.includes('/menu_management') ? 'active' : ''}> 
                <img alt="Menu Management" src={currentPage.includes('/menu_management') ? menuManagementIconActive : menuManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Menu</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/print_management?tab=print_stations')}
              className={currentPage.includes('/print_management') ? 'active' : ''}> 
              <img alt="Print Management" src={currentPage.includes('/print_management') ? PrintManagementIconActive : PrintManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Print </span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/store_management')}
              className={currentPage.includes('/store_management') ? 'active' : ''}>   
                <img alt="Store Management" src={currentPage.includes('/store_management') ? storeManagementIconActive : storeManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Store </span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/marketing_management?tab=campaigns')}
                className={currentPage.includes('/marketing_management') ? 'active' : ''}>
                <img alt="Marketing Management" src={currentPage.includes('/marketing_management') ? MarketingManagementActive : MarketingManagement} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Marketing</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/customer_management')}
                className={currentPage.includes('/customer_management') ? 'active' : ''}>
                <img alt="Customers Management" src={currentPage.includes('/customer_management') ? CustomerManagementIconActive : CustomerManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Customers</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/delivery_management?tab=driver')}
                className={currentPage.includes('/delivery_management') ? 'active' : ''}>
                <img alt="Delivery Management" src={currentPage.includes('/delivery_management') ? DeliveryManagementIconActive : DeliveryManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Delivery</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* eslint-disable-next-line */}
              <Link onClick={() => this.onMenuItemClick('/web_management')}
                className={currentPage.includes('/web_management') ? 'active' : ''}>
                <img alt="Web Management" src={currentPage.includes('/web_management') ? WebManagementIconActive : WebManagementIcon} className="mr-3"/>
                <span style={{display: this.props.isSidebarOpen ? '' : 'none'}} className="transition-1s">Web</span>
              </Link>
            </li>
          </ul>
        </aside>
      </div>
    );
  }
}

export default withRouter(Sidebar);
