import React from 'react';
import { withRouter } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';
import ReactDragListView from 'react-drag-listview';
import ReactDragListView1 from 'react-drag-listview';

class CategoryDetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenFooter: false,
            isShowFooter: this.props.isShowFooter,
            cateName: "",
            cateNameError: "",
            submitProgress: false,
            editCateName: "",
            oldEditCateName: "",
            isOpenEdit: false,
            editCateNameError: "",
            editSubmitProgress: false,
            data: [],
            catData: [],
            orderChange: false,
            productOrderChange: false,
            sortingProgress: false,
            sortingProductProgress: false,
            productsData: [],
            strProductsData: "",
            fullProductsData: [],
            manageCateProductData: {},
            singleProductCate: [],
            tmpProductsData: {},
            tmpCatData:""
        }
        const self = this;
        // this.dragProps = {
        //     onDragEnd(fromIndex, toIndex) {
        //         const data = [...self.state.data];
        //         const item = data.splice(fromIndex, 1)[0];
        //         data.splice(toIndex, 0, item);
        //         self.setState({
        //             data
        //         });
        //         self.setState({orderChange: true});
        //     },
        //     nodeSelector: "tr",
        //     handleSelector: 'a'
        // };
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const catData = [...self.state.catData];
                const item = catData.splice(fromIndex, 1)[0];
                catData.splice(toIndex, 0, item);
                let data = []
                for (let i = 0; i < catData.length; i += 1) {
                    data.push(catData[i].cateName);
                }
                self.setState({
                    catData,
                    data,
                });
                self.props.saveData(true);
                self.setState({orderChange: true, isOpenFooter: true});
            },
            nodeSelector: "section",
            handleSelector: 'a'
        };
        this.dragProps1 = {
            onDragEnd(fromIndex, toIndex) {
                const singleProductCate = [...self.state.singleProductCate];
                const item = singleProductCate.splice(fromIndex, 1)[0];
                singleProductCate.splice(toIndex, 0, item);
                for (let i = 0; i < singleProductCate.length; i += 1) {
                    singleProductCate[i].sortingOrderBy = i;
                }
                self.setState({
                    singleProductCate
                });
                self.setState({productOrderChange: true});
            },
            nodeSelector: "section",
            handleSelector: 'span'
        };
        this.onActionChange = this.onActionChange.bind(this);
        this.addNewCategory = this.addNewCategory.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onEditInputChange = this.onEditInputChange.bind(this);
        this.editSubmitCategory = this.editSubmitCategory.bind(this);
        this.closeEditCategory = this.closeEditCategory.bind(this);
        this.isCategoryInUse = this.isCategoryInUse.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.saveNewSortOrder = this.saveNewSortOrder.bind(this);
        this.saveNewSortProductOrder = this.saveNewSortProductOrder.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
    }

    componentDidMount() {
        let vendorDetails = getLoginUserDetails();
        this.getProductsList()
        if (vendorDetails?.vendorAllDetails?.product_categories?.length) {
            this.setState({data: vendorDetails.vendorAllDetails.product_categories});
            let tmpData = [];
            if (vendorDetails.vendorAllDetails.product_categories && vendorDetails.vendorAllDetails.product_categories.length) {
                for (let i = 0; i < vendorDetails.vendorAllDetails.product_categories.length; i +=1) {
                    tmpData.push({
                        cateName: vendorDetails.vendorAllDetails.product_categories[i],
                        isOpen: false
                    })
                }
                this.setState({
                    catData: tmpData,
                    tmpCatData: JSON.stringify(tmpData)
                });
            }
        } else {
            this.setState({data: []});
        }
    }

    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false,
            orderChange: false,
            catData: JSON.parse(self.state.tmpCatData)
        });
        self.props.saveData(false);
      }

    prepareOrderDatatable(orders) {
        let ordersData = [];
        if (orders && orders.length) {
          ordersData = orders.map((order, index) => {
            return {
              ...order,
              rowIndex: index,
              price: order.price ? '$' + order.price : '',
              customizable: order.customizable === true ? "Yes" : "No"
            }
          });
        }
        return ordersData
    }
    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    let products = result.data.response;
                    this.setState({ tmpProductsData: { data: this.prepareOrderDatatable(products)}})
                    this.setState({
                        fullProductsData: products
                    });
                    var manageProductData = [];
                    var manageCateProductData = {};
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: []
                            });
                            manageCateProductData[vendor.vendorAllDetails.product_categories[i]] = [];
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    manageProductData[ii].product.push({ id: products[i].id, name: products[i].name });
                                    manageCateProductData[manageProductData[ii].categoryName].push(products[i])
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData, strProductsData: JSON.stringify(manageProductData), manageCateProductData: manageCateProductData});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: [], tmpProductsData: {} })
                    console.error("Oops! We encountered an error in fetching product data. Please try again.", error);
                })
        }
        catch (error) {
            this.setState({ productsData: [] })
            console.error("Problem in fetching previous product data: ", error);
        }
    }
    openExpand(index) {
        const tmpCatData = this.state.catData;
        const manageCateProductData = this.state.manageCateProductData;
        // let productOrderChange = this.state.productOrderChange;
        let tmpArray = [];
        for (let i = 0; i < tmpCatData.length; i += 1 ) {
            if (i === index) {
                tmpCatData[i].isOpen = !tmpCatData[i].isOpen;
                if (tmpCatData[i].isOpen) {
                    tmpArray = manageCateProductData[tmpCatData[i].cateName];
                } else {
                    tmpArray = [];
                }
            } else {
                tmpCatData[i].isOpen = false;
            }
        }
        const ttt = tmpArray.sort((a, b) => {
            return a.sortingOrderBy - b.sortingOrderBy;
        })
        this.setState({
            catData: tmpCatData,
            singleProductCate: ttt,
            productOrderChange: false
        });
    }

    onActionChange(event, cateName) {
        let action = event.target.value;
        if(action === "Edit"){
            this.openEditCategoryPage(event, cateName);
        }
        if(action === "Delete"){
            this.openDelete(event, cateName);
        }
        
        event.target.value = "";
    }

    isCategoryInUse(name){
        let allCategoryName = this.state.data;
        let index = allCategoryName.findIndex(categoryName => {
            return categoryName.toLowerCase() === name.toLowerCase()
        });
        return index;
    }

    onInputChange(field, value){
        if(field === "cateName"){
            this.setState({cateName : value});            
            if (value === "") {
                this.setState({cateNameError: "Category name field is required" });
            } else {
                this.setState({ cateNameError: "" });
            }
        }
    }

    async addNewCategory(){
        let self = this;
        self.setState({ submitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/addcategory`;

        if((self.state.cateName).trim() === ""){
            self.setState({ cateNameError: "The 'Category name'is a required field" });
            self.setState({ submitProgress: false, cateName: "" });
            toast.error("Please provide all required data.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        if (this.isCategoryInUse((self.state.cateName).trim()) !== -1) {
            this.setState({cateNameError: "The 'category name' already exists." });
            self.setState({ submitProgress: false });
            toast.error("The 'category name' already exists." , {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        // Prepare post data 
        let requestData = {
            "vendorId": vendorId,
            "cateName": self.state.cateName
        };
        
        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.addNewCategory();
                    return;
                }
                if(result.data.status){
                    let vendorCurrentData = getLoginUserDetails();
                    vendorCurrentData['vendorAllDetails'] = result.data.response;
                    localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                    // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                    this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                    let catData = self.state.catData;
                    catData.push({
                        cateName: self.state.cateName,
                        isOpen: false
                    });
                    
                    const manageCateProductData = this.state.manageCateProductData;
                    manageCateProductData[self.state.cateName] = [];
                    self.setState({
                        catData,
                        manageCateProductData: manageCateProductData
                    });
                    toast.success("Success: Category added!", {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    })
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                self.setState({ submitProgress: false });
                self.setState({ cateName: "" });
            })
            .catch(error => {
                self.setState({ submitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating category.: ", error);
            })
        }
        catch (error) {
            self.setState({ submitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating category. ", error);
        } 
    }

    openEditCategoryPage(event, cateName) {
        this.setState({
            oldEditCateName: cateName,
            editCateName: cateName,
            isOpenEdit: true
        });
        setTimeout(() => {
            this.nameInput.focus();
        }, 500);
    }

    onEditInputChange(field, value) {
        if(field === "editCateName"){
            this.setState({editCateName : value});            
            if (value === "") {
                this.setState({editCateNameError:  "The 'Category name'is a required field"});
            } else {
                this.setState({ editCateNameError: "" });
            }
        }
    }

    async editSubmitCategory() {
        let self = this;
        self.setState({ editSubmitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/editcategory`;

        if((self.state.editCateName).trim() === ""){
            self.setState({ editCateNameError:  "The 'Category name'is a required field" });
            self.setState({ editSubmitProgress: false });
            toast.error("Please provide all required data.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        if (this.isCategoryInUse((self.state.editCateName).trim()) !== -1) {
            this.setState({editCateNameError: "The 'category name' already exists." });
            self.setState({ editSubmitProgress: false });
            toast.error("The 'category name' already exists.", {
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        // Prepare post data 
        let requestData = {
            "vendorId": vendorId,
            "editCateName": self.state.editCateName,
            "oldEditCateName": self.state.oldEditCateName
        };

        // Submit request to update data
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.editSubmitCategory();
                    return;
                }
                if(result.data.status){
                    let vendorCurrentData = getLoginUserDetails();
                    vendorCurrentData['vendorAllDetails'] = result.data.response;
                    localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                    // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                    this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                    const catData = self.state.catData;
                    let tmpCateArray = {};
                    const manageCateProductData = this.state.manageCateProductData;
                    for (let i = 0; i < catData.length; i += 1) {
                        if (catData[i].cateName === self.state.oldEditCateName) {
                            catData[i].cateName = self.state.editCateName;
                            tmpCateArray[catData[i].cateName] = manageCateProductData[self.state.oldEditCateName];
                        } else {
                            tmpCateArray[catData[i].cateName] = manageCateProductData[catData[i].cateName];
                        }
                    }
                    self.setState({
                        catData,
                        manageCateProductData: tmpCateArray
                    });
                    toast.success("Success: Category added!", {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    })
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                self.setState({ editSubmitProgress: false });
                self.setState({ editCateName: "" });
                self.setState({ oldEditCateName: "" });
                this.closeEditCategory();
            })
            .catch(error => {
                self.setState({ editSubmitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in updating category.: ", error);
            })
        }
        catch (error) {
            self.setState({ editSubmitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating category. ", error);
        } 
    }

    closeEditCategory() {
        this.setState({
            editCateName: "",
            isOpenEdit: false,
            cateName: "",
            oldEditCateName: ""
        });
    }

    async openDelete(event, cateName) {
        let self = this;
        self.setState({ editSubmitProgress: true });
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `vendor/updatevendor/checkcategoryassignproduct`;
        let requestData = {
            "vendorId": vendorId,
            "cateName": cateName,
        };

        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
            .then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    self.openDelete();
                    return;
                }
                if(result.data.status){
                    self.setState({ editSubmitProgress: false });
                    if (result.data.empty) {
                        this.confirmAndDelete(event, cateName, vendorId);
                    } else {
                        toast.error("You can't delete this category name. One of the food items is using it.", {
                            autoClose: 4000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }) 
                    }
                } else {                    
                    toast.error(result.data.statusText, {
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    self.setState({ editSubmitProgress: false });
                }
            })
            .catch(error => {
                self.setState({ editSubmitProgress: false });
                toast.error("Something went wrong. Please try again after sometime.", {
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in checking category assign in product.: ", error);
            })
        }
        catch (error) {
            self.setState({ editSubmitProgress: false });
            toast.error("Something went wrong. Please try again after sometime.", {
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in checking category assign in product. ", error);
        } 
    }

    async confirmAndDelete(e, categoryName, vendorId) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this category.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let requestData = {
                    "vendorId": vendorId,
                    "cateName": categoryName,
                };
                let url = `vendor/updatevendor/removecategory`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, requestData)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the category. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                let vendorCurrentData = getLoginUserDetails();
                                vendorCurrentData['vendorAllDetails'] = result.data.response;
                                localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                                // this.getCategoryList(vendorCurrentData.vendorAllDetails.product_categories);
                                this.setState({data: vendorCurrentData.vendorAllDetails.product_categories});
                                let catData = this.state.catData;
                                let manageCateProductData = this.state.manageCateProductData;
                                for (let i = 0; i < catData.length; i += 1) {
                                    if (catData[i].cateName === categoryName) {
                                        delete manageCateProductData[categoryName];
                                        catData.splice(i, 1);
                                    }
                                }
                                this.setState({
                                    catData,
                                    manageCateProductData: manageCateProductData
                                });
                                toast.success("Success: category deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the category. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting category: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the category. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting category: ", error);
                }
            }
        });
    }

    async saveNewSortOrder() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let self = this;
        self.setState({sortingProgress: true});
        let requestData = {
            "vendorId": vendorId,
            "categoryArray": self.state.data,
        };
        let url = `vendor/updatevendor/editcategoryOrder`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        self.setState({sortingProgress: false});
                        toast.error("Problem in updating sort order:", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        return;
                    }
                    if (result.data.status) {
                        let vendorCurrentData = getLoginUserDetails();
                        let vendorAllDetails = vendorCurrentData['vendorAllDetails'];
                        vendorAllDetails.product_categories = self.state.data;
                        vendorCurrentData['vendorAllDetails'] = vendorAllDetails;
                        localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                        global.config.sidebarFooter = false;
                        global.config.context = false;
                        self.setState({
                            isOpenFooter: false,
                            isShowFooter: false,
                            // catData: JSON.parse(self.state.tmpCatData)
                        });
                        self.props.saveData(false);
                        self.setState({orderChange: false});
                        self.setState({sortingProgress: false});
                        toast.success("Success: Sorting order saved succesfully!", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        self.setState({sortingProgress: false});
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    self.setState({sortingProgress: false});
                    toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating sort order: ", error);
                })
        }
        catch (error) {
            self.setState({sortingProgress: false});
            toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating sort order: ", error);
        }
    }

    async saveNewSortProductOrder() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        this.setState({sortingProductProgress: true});
        let reqDataProduct = [];
        for (let i = 0; i < self.state.singleProductCate.length; i += 1) {
            reqDataProduct.push({
                name: self.state.singleProductCate[i].name,
                id: self.state.singleProductCate[i].id,
                sortingOrderBy: self.state.singleProductCate[i].sortingOrderBy
            })
        }
        let requestData = {
            vendorId: vendorId,
            productSortingData: reqDataProduct
        };
        let url = `vendor/updatevendor/editproductorder`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.post(url, requestData)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        self.setState({sortingProductProgress: false});
                        toast.error("Problem in updating sort order:", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        return;
                    }
                    if (result.data.status) {
                        let vendorCurrentData = getLoginUserDetails();
                        let vendorAllDetails = vendorCurrentData['vendorAllDetails'];
                        vendorAllDetails.product_categories = self.state.data;
                        vendorCurrentData['vendorAllDetails'] = vendorAllDetails;
                        localStorage.setItem("vendor", JSON.stringify(vendorCurrentData));
                        self.setState({productOrderChange: false});
                        self.setState({sortingProductProgress: false});
                        toast.success("Success: Sorting order saved succesfully!", {
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                    } else {
                        self.setState({sortingProductProgress: false});
                        toast.error(result.data.statusText, {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch(error => {
                    self.setState({sortingProductProgress: false});
                    toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in updating sort order: ", error);
                })
        }
        catch (error) {
            self.setState({sortingProductProgress: false});
            toast.error("Oops! We encountered a problem in updating sort order. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in updating sort order: ", error);
        }
    }

    openProductDetails(event, pRow) {
        event.preventDefault();
        let self = this;
        let productData = pRow;
        this.props.history.push(`/menu_management/product/${pRow.id}`, {productData, productsData: self.state.tmpProductsData.data});
    }

    render() {
        let {
            cateName,
            isOpenEdit,
            editCateName,
            cateNameError,
            singleProductCate,
            catData,
            manageCateProductData,
            editCateNameError,
            orderChange,
            productOrderChange,
            sortingProgress,
            sortingProductProgress,
            submitProgress,
            editSubmitProgress,
            isOpenFooter,
            isShowFooter
        } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="section-offdays">
                <form className="needs-validation form-add-offdays clearfix p-3 mt-2" noValidate>
                    {!isOpenEdit &&
                        <div className="form-row">
                            <div className="col-lg-3 col-md-6">
                                <label htmlFor="cateName" className="required">Category Name</label>
                                <div className="input-group">
                                    <input type="text" id="cateName" value={cateName}
                                        onChange={(event) => {
                                            this.onInputChange('cateName', event.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Category Name" />
                                    {cateNameError && cateNameError !== "" && <div className="invalid-feedback">
                                        {cateNameError}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.addNewCategory}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                            </div>
                        </div>
                    }
                    {isOpenEdit &&
                        <div className="form-row">
                            <div className="col-lg-3 col-md-6">
                                <label htmlFor="editCateName" className="required">Category Name</label>
                                <div className="input-group">
                                    <input type="text" ref={(input) => { this.nameInput = input; }} id="editCateName" value={editCateName}
                                        onChange={(event) => {
                                            this.onEditInputChange('editCateName', event.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Category Name" />
                                    {editCateNameError && editCateNameError !== "" && <div className="invalid-feedback">
                                        {editCateNameError}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.editSubmitCategory}>Edit {editSubmitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                <button type="button" className="btn btn-primary mt-4 ml-3" onClick={this.closeEditCategory}>Cancel</button>
                            </div>
                        </div>
                    }
                </form>


                <div className="form-row">
                    <div className="col-md-12">
                        {/* <ReactDragListView {...this.dragProps}> */}
                            <div className="category-list-table mt-5">
                                {orderChange && <div className="text-right">
                                    <button type="button" className="btn btn-primary mt-0" onClick={this.saveNewSortOrder}>Save New Order {sortingProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                </div>}
                                <h6 className="text-info">Note: Drag and drop category name to change its display order on Webapp and sVang App.</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ReactDragListView {...this.dragProps}>
                                            <div className="card-body p-0">
                                                {catData && catData.length > 0 && catData.map((category, sIndex) => (
                                                    <section className="card-body-grid cate-border mb-3" key={`${sIndex}`}>
                                                        <div className={"row " + (category.isOpen ? "pb-3" : "")} >
                                                            <div className="col-md-1">
                                                                {/* eslint-disable-next-line */}
                                                                <a className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></a>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <span>{category.cateName}</span>
                                                            </div>
                                                            <div className="col-md-2 text-center">
                                                                <span>{manageCateProductData && manageCateProductData[category.cateName] && manageCateProductData[category.cateName].length}</span>
                                                            </div>
                                                            <div className="col-md-3 text-center">
                                                                <span className="custom-link" onClick={(event) => this.openEditCategoryPage(event, category.cateName)}>Edit</span>
                                                                <span> / </span>
                                                                <span className="custom-link color-red" onClick={(event) => this.openDelete(event, category.cateName)}>Delete</span>
                                                            </div>
                                                            {manageCateProductData && manageCateProductData[category.cateName] && manageCateProductData[category.cateName].length > 0 &&
                                                                <div className={"col-md-1 cate-collapse-icon font-25 " + (category.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(sIndex)}>
                                                                </div>
                                                            }
                                                        </div>
                                                            {category.isOpen && productOrderChange &&
                                                                <div className="text-right">
                                                                    <button type="button" className="btn btn-primary mt-0 mb-2" onClick={this.saveNewSortProductOrder}>Save New Product Order {sortingProductProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                                                </div>
                                                            }
                                                            <ReactDragListView1 {...this.dragProps1}>
                                                            {category.isOpen && singleProductCate && singleProductCate.length > 0 && singleProductCate.map((pName, pIndex) => ( 
                                                                <section className="cate-product-section" key={pIndex}>
                                                                    <div className="cate-sub-product">
                                                                        <div className="row">
                                                                            <div className="col-md-1">
                                                                                <span className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></span>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                {/* {pName.name} */}
                                                                                <p className="custom-link mb-0" onClick={(e) => this.openProductDetails(e, pName)}>{pName.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            ))}
                                                            {category.isOpen && singleProductCate && singleProductCate.length === 0 &&
                                                                <div className="text-center">No Product.</div>
                                                            }
                                                        </ReactDragListView1>
                                                    </section>
                                                ))}
                                            </div>
                                        </ReactDragListView>
                                    </div>
                                </div>
                                {/* <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th scope="col">Category Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data && this.state.data.length > 0 ? this.state.data.map((categoryName, index) => (
                                            <tr key={index}>
                                                <td><a href="/#" className="mr-2"><i className="fas fa-grip-vertical"></i></a></td>
                                                <td>{categoryName}</td>
                                                <td>
                                                    <div>
                                                        <select className="form-control" onChange={(event) => this.onActionChange(event, categoryName)}>
                                                            <option value="">Select</option>
                                                            <option value="Edit">Edit</option>
                                                            <option value="Delete">Delete</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan="2" className="text-center"><label>No data available in table</label></td></tr>}
                                    </tbody>
                                </table> */}
                            </div>
                        {/* </ReactDragListView> */}
                    </div>
                </div>
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.saveNewSortOrder}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(CategoryDetails);