import React from 'react';
import momentTZ from 'moment-timezone';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class UploadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.timeZonesList = momentTZ.tz.names();
    this.state = {
      uploadDetails: {
        uploadFile: { displayName: "", value: "" ,error: "" },
      },
      mediaFileData: [],
      submitProgress: false,
      isOpenFooter: false,
      isShowFooter: this.props.isShowFooter
    }
    this.onUploadDetailsChange = this.onUploadDetailsChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.confirmAndDelete = this.confirmAndDelete.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.leaveAnyway = this.leaveAnyway.bind(this);
  }

  componentDidMount() {
      this.getMediaFile()
  }

  leaveAnyway() {
    let self = this;
    global.config.sidebarFooter = false;
    global.config.context = false;
    self.setState({
        isOpenFooter: false,
        isShowFooter: false,
        uploadDetails: {
          uploadFile: { displayName: "", value: "" ,error: "" },
        },
        submitProgress: false,
    });
    self.props.saveData(false);
  }
  async getMediaFile() {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `vendor/getmediafiles/${vendorId}`;
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        axiosInstance.get(url).then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                this.getMediaFile();
                return;
            }
            if (!result.data.status) {
                this.setState({ mediaFileData: [] });
                console.error("Problem in fetching media file data");
                return;
            }
            let mediaFileData = result.data.data;
            self.setState({
                mediaFileData: mediaFileData
            });
        }).catch(error => {
            this.setState({ mediaFileData: [] });
            console.error("Problem in fetching media file data: ", error);
        })
    } catch (error) {
        this.setState({ mediaFileData: [] })
        console.error("Problem in fetching media file data: ", error);
    }
  };
  copyUrl(row) {
    navigator.clipboard.writeText(row.fileUrl);
    toast.success("Copy link successfull!", {
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
  })
  }
  async removeMedia(row) {
    let self = this;
    // let vendor = JSON.parse(localStorage.getItem('vendor'));
    // let vendorId = vendor.id;
    let url = `vendor/removemediafiles`;
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        axiosInstance.post(url, { id: row.id })
        .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                toast.error("Oops! We encountered a problem in deleting media file. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            if (result.data.status) {
                // self.removeMedia(row);
                const tmpData = self.state.mediaFileData;
                for (let i = 0; i < tmpData.length; i += 1) {
                  if (tmpData[i].id === row.id) {
                    tmpData.splice(i, 1);
                  }
                }
                self.setState({
                  mediaFileData: tmpData,
                })
                toast.success("Success: media file deleted!", {
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            } else {
                toast.error(result.data.statusText, {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        })
        .catch(error => {
            toast.error("Oops! We encountered a problem in deleting media file. Please try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error("Problem in deleting media file: ", error);
        })
    } catch (error) {
        console.error("Problem in deleting media file: ", error);
    }
  };

  confirmAndDelete(event, row) {
    event.preventDefault();
    var self = this;
    event.preventDefault();
    Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure remove media!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.value) {
          self.removeMedia(row);
        }
    });
  }

  handleValidation(fieldName = "", value = "") {
    let formDetails = this.state.uploadDetails;
    let isError = false;
    let formSubmit = false;
    if (fieldName === "" && value === "") {
      formSubmit = true;
    }
    
    if ((formSubmit === false && fieldName === "uploadFile") || (formSubmit === true)) {
      fieldName = 'uploadFile';
      if ((formSubmit === false && value === "") || (formSubmit === true && formDetails[fieldName].value === "")) {
        formDetails[fieldName].error = `This is a required field.`;
        isError = true;
      }
      else if ((formSubmit === false && value.name && !value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))
        || (formSubmit === true && formDetails[fieldName].value.name && !formDetails[fieldName].value.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/))) {
        formDetails[fieldName].error = `You selected an invalid file. The valid formats are jpeg, jpg and png.`;
        isError = true;
      }
    }

    this.setState({ uploadDetails: formDetails });
    return isError;
  }

  onUploadDetailsChange(e) {
    let formDetails = this.state.uploadDetails;
    let fieldName = e.target.id;
    let fieldValue = e.target.files[0];
    this.props.saveData(true);
    this.setState({
      isOpenFooter: true
    });
    let isError = this.handleValidation(fieldName, fieldValue);
    if (isError) {
      formDetails[fieldName].value = "";
      this.setState({ uploadDetails: formDetails });
      return false;
    } else {
      formDetails[fieldName].value = fieldValue;
      formDetails[fieldName].error = "";
      this.setState({ uploadDetails: formDetails });
    }
  }

  async onFormSubmit() {
    let self = this;
    let isError = self.handleValidation();
    if (!isError) {
      self.setState({ submitProgress: true });
      let vendor = JSON.parse(localStorage.getItem('vendor'));
      let vendorId = vendor.id;
      let url = `vendor/updatevendor/uploadfile`;
      let requestData = new FormData();
      requestData.append("vendorId", vendorId);
      Object.keys(self.state.uploadDetails).forEach(function (key) {
        requestData.append(key, self.state.uploadDetails[key].value);
      });

      try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
          let auth = await getAuth();
          if (auth && auth.success && auth.token) {
            localStorage.setItem('token', auth.token);
          }
        }
        axiosInstance.post(url, requestData)
          .then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
              localStorage.removeItem('token');
              this.onFormSubmit();
              return;
            }
            if (result.data.status) {
              const tmpData = self.state.mediaFileData;
              tmpData.unshift(result.data.response);
              global.config.sidebarFooter = false;
              global.config.context = false;
              self.setState({
                  isOpenFooter: false,
                  isShowFooter: false,
              });
              self.props.saveData(false);
              self.setState({ mediaFileData: tmpData, uploadDetails: { uploadFile: { displayName: "", value: "" ,error: "" }} });
              toast.success("Success: Uploaded file!", {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              toast.error(result.data.statusText, {
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
            self.setState({ submitProgress: false });
          })
          .catch(error => {
            self.setState({ submitProgress: false });
            toast.error("Oops! We encountered an error. Please try again.", {
              autoClose: 2000,
              closeOnClick: true,
              pauseOnHover: true,
            });
            console.error("Problem in updating restaurant details.: ", error);
          })
      }
      catch (error) {
        self.setState({ submitProgress: false });
        toast.error("Oops! We encountered an error. Please try again.", {
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        console.error("Problem in updating restaurant details. ", error);
      }
    } else {
      toast.error("Please provide required data.", {
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }

  render() {
    let { uploadDetails, mediaFileData, isOpenFooter, isShowFooter } = { ...this.state }
    if (isOpenFooter) {
      global.config.context = true;
    } else {
        global.config.context = false;
    }
    if (global.config.sidebarFooter) {
        isShowFooter = true;
    }
    return (
      <div>
        <form className="needs-validation clearfix" noValidate>
          <div className="form-row media-upload-section">
              <div className="col-md-12">
                <div className="display-contents mb-0">
                    <input type="file"
                      accept="image/x-png,image/jpeg,image/jpg,image/svg+xml"
                      className={"upload-file-input " + (uploadDetails.uploadFile.error !== "" ? 'invalid' : '')}
                      onChange={this.onUploadDetailsChange}
                      id="uploadFile" />
                      <label className={"custom-file-label " + (uploadDetails.uploadFile.error !== "" ? 'invalid' : '')}
                      htmlFor="uploadFile">Upload Photos...</label>
                      {uploadDetails.uploadFile.value && uploadDetails.uploadFile.value !== "" && uploadDetails.uploadFile.value.name && <p>{uploadDetails.uploadFile.value.name}</p>}
                      {uploadDetails.uploadFile.value && uploadDetails.uploadFile.value !== "" && !uploadDetails.uploadFile.value.name && <p>{uploadDetails.uploadFile.value}</p>}
                      {uploadDetails.uploadFile.error && uploadDetails.uploadFile.error !== "" && 
                      <div className="invalid-feedback">
                        {uploadDetails.uploadFile.error}
                      </div>}
                </div>
                <div className="media-upload-btn mb-0">
                  <button type="button" className="btn btn-primary btn-basic-save mt-0" onClick={this.onFormSubmit}>Save {this.state.submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                </div>
              </div>
          </div>
        </form>
        <div>
          {mediaFileData && mediaFileData.length > 0 &&
              <div className="form-row">
                  {mediaFileData.map((row, index) => (
                      <div className="media-upload-img" key={index}>
                          <img src={row.fileUrl} alt={row.name+index}></img>
                          <div className="media-upload-icon-box">
                            <i className="fa fa-copy text-muted pointer" onClick={() =>  this.copyUrl(row)}></i>
                            <i className="fas fa-trash text-danger pointer" onClick={(event) => { this.confirmAndDelete(event, row) }}></i>
                          </div>
                      </div>
                  ))}
              </div>
          }
        </div>
        { isShowFooter &&
            <div className="unsave-footer">
              <p>There are unsaved changes, please save your changes.</p>
              <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
              <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
            </div>
        }
      </div>
    );
  }
}

export default UploadDetails;