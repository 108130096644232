import React from 'react';
import { toast } from 'react-toastify';
import ReactDragListView from 'react-drag-listview';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import '../../../footer';
const { v1: uuidv1 } = require('uuid');

class AddModifier extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails,
            addModifierData: {
                name: '',
                displayName: '',
                choiceLimit: '',
                isRequired: false,
                isFixed: false,
                isComesWith: false,
                isItemOption: false,
                isPizzaTopping: false,
                ofFull: 0,
                options: [{
                    price: 0,
                    isSorting: 0,
                    fullPrice: 0,
                    halfPrice: 0,
                    name: "",
                    id: uuidv1().toUpperCase()
                }]
            },
            addModifierErrorData: {
                name: '',
                displayName: '',
                options: [{
                    price: "",
                    fullPrice: "",
                    halfPrice: "",
                    name: ""
                }]
            },
            submitProgress: false
        }
        let self = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let addModifierData = self.state.addModifierData;
                const options = [...addModifierData.options];
                const item = options.splice(fromIndex, 1)[0];
                options.splice(toIndex, 0, item);
                for (let i = 0; i < options.length; i += 1) {
                    options[i].isSorting = i;
                }
                addModifierData.options = options;
                self.setState({
                    addModifierData: addModifierData
                });
                self.setState({orderChange: true});
            },
            nodeSelector: "section",
            handleSelector: 'a'
        };
        this.clickMenu = this.clickMenu.bind(this);
        this.leaveAnyway = this.leaveAnyway.bind(this);
        this.homeClick = this.homeClick.bind(this);
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
          return;
        };
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    clickMenu() {
        let self = this;
        if (self.state.isOpenFooter) {
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/menu_management?tab=modifiers`);
        }
    }
    leaveAnyway() {
        let self = this;
        global.config.sidebarFooter = false;
        global.config.context = false;
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/menu_management?tab=modifiers`);
    }
    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.addModifierData;
        let formErrors = this.state.addModifierErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"].trim() === "")) {
            fieldName = 'name';
            formDetails["name"] = "";
            formErrors[fieldName] = `The 'Name' is a required field.`;
            isError = true;
        }

        if ((formSubmit === false && fieldName === "displayName" && value === "") || (formSubmit === true && formDetails["displayName"].trim() === "")) {
            fieldName = 'displayName';
            formDetails["displayName"] = "";
            formErrors[fieldName] = `The 'Display Name' is a required field.`;
            isError = true;
        }

        if (!formDetails.isPizzaTopping) {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                        isError = true;
                    } else if (newField === 'price' && Number(formDetails['options'][index][newField]) < 0) {
                        // formErrors['options'][index][newField] = `Enter value greater than 0.`;
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        fieldName = 'options';
                        formDetails.options[i].name = "";
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].price === "") {
                        fieldName = 'options';
                        formErrors.options[i].price = `The 'Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].price) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].price ="";
                        isError = true;
                    }
                }
            }
        } else {
            if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
                fieldName = 'options';
                if (index !== undefined) {       
                    if (formDetails['options'][index][newField] === "") {
                        let dName = newField;
                        if (newField === 'fullPrice') {
                            dName = "Full Price";
                        } else if(newField === 'halfPrice') {
                            dName = "Half Price";
                        }
                        formErrors['options'][index][newField] = `The '${dName}' is a required field.`;
                        isError = true;
                    } else if (newField === 'fullPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    } else if (newField === 'halfPrice' && Number(formDetails['options'][index][newField]) < 0) {
                        formDetails['options'][index][newField] = "";
                        isError = true;
                    }
                }
            }
    
            if (fieldName === 'options' && index === undefined) {
                for (let i = 0; i < formDetails.options.length; i += 1) {
                    if (formDetails.options[i].name.trim() === "") {
                        formDetails.options[i].name = "";
                        fieldName = 'options';
                        formErrors.options[i].name = `The 'Name' is a required field.`;
                        isError = true;
                    }
                    if (formDetails.options[i].fullPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].fullPrice = `The 'Full Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].fullPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].fullPrice ="";
                        isError = true;
                    }

                    if (formDetails.options[i].halfPrice === "") {
                        fieldName = 'options';
                        formErrors.options[i].halfPrice = `The 'Half Price' is a required field.`;
                        isError = true;
                    } else if (Number(formDetails.options[i].halfPrice) < 0) {
                        fieldName = 'options';
                        formDetails.options[i].halfPrice ="";
                        isError = true;
                    }
                }
            }
        }
        
        
        this.setState({ addModifierErrorData: formErrors });
        this.setState({ addModifierData: formDetails });
        return isError;
    }
    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addModifierData;
        let formErrors = this.state.addModifierErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addModifierData: formDetails, isOpenFooter: true });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addModifierErrorData: formErrors });
        }
    }
    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.addModifierData;
        let formDetailsError = this.state.addModifierErrorData;
        formDetails[formField].push({
            name: "",
            price: 0,
            isSorting: formDetails[formField].length,
            fullPrice: 0,
            halfPrice: 0,
            id: uuidv1().toUpperCase()
        });
        formDetailsError[formField].push({
            name: "",
            price: "",
            fullPrice: "",
            halfPrice: ""
        });
        this.setState({ addModifierData: formDetails, addModifierErrorData: formDetailsError, isOpenFooter: true})
    }
    onDeleteField(e, formField, index) {
        e.preventDefault();
        let formDetails = this.state.addModifierData;
        let formDetailsError = this.state.addModifierErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        for (let i = 0; i < formDetails.options.length; i += 1) {
            formDetails.options[i].isSorting = i;
        }
        this.setState({ addModifierData: formDetails, addModifierErrorData: formDetailsError, isOpenFooter: true })
    }
    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            let addObj = this.state.addModifierData;

            if ((addObj.isRequired || addObj.isFixed) && !addObj.choiceLimit) {
                addObj.choiceLimit = "1";
            }

            if (Number(addObj.choiceLimit) > addObj.options.length) {
                toast.error(`Please Add ${Number(addObj.choiceLimit) - addObj.options.length} More Option`, {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }

            addObj.vendorId = vendorId;
            addObj.name = addObj.name.trim();
            addObj.displayName = addObj.displayName.trim();
            for (let i = 0; i < addObj.options.length; i += 1) {
                addObj.options[i].name = addObj.options[i].name.trim();
                if (addObj.isPizzaTopping) {
                    addObj.options[i].fullPrice = Number(Number(addObj.options[i].fullPrice).toFixed(2));
                    addObj.options[i].halfPrice = Number(Number(addObj.options[i].halfPrice).toFixed(2));
                    addObj.options[i].price = 0
                } else if (addObj.isComesWith) {
                    addObj.options[i].isChecked = true;
                    addObj.options[i].price = Number(Number(addObj.options[i].price).toFixed(2));
                    addObj.options[i].fullPrice = 0
                    addObj.options[i].halfPrice = 0
                } else if (addObj.isItemOption) {
                    if (i === 0) {
                        addObj.options[i].isChecked = true;
                    } else {
                        addObj.options[i].isChecked = false;
                    }
                    addObj.options[i].price = Number(Number(addObj.options[i].price).toFixed(2));
                    addObj.options[i].fullPrice = 0
                    addObj.options[i].halfPrice = 0
                } else {
                    addObj.options[i].isChecked = false;
                    addObj.options[i].price = Number(Number(addObj.options[i].price).toFixed(2));
                    addObj.options[i].fullPrice = 0
                    addObj.options[i].halfPrice = 0
                }
            }
            addObj.ofFull = Number(Number(addObj.ofFull).toFixed(2));
            if (vendorId) {
                let url = `modifiers/add`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                self.props.history.push(`/menu_management?tab=modifiers`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }
    render() {
        let { vendorAllDetails, isOpenFooter, isShowFooter,
            addModifierData,
            addModifierErrorData,
            submitProgress
        } = { ...this.state }
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="add-product-page">
                {vendorAllDetails &&
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                                    <span className="page-title">Add Modifier</span>
                                    <ol className="breadcrumb pl-0 pt-0">
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                                        {/* eslint-disable-next-line */}
                                        <li className="breadcrumb-item"><a className="common-link" onClick={this.clickMenu}>Menu</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Modifier</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <form className="needs-validation clearfix" noValidate>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (addModifierErrorData.name !== "" ? 'invalid' : '')}
                                            id="name"
                                            name="name"
                                            maxLength="40"
                                            placeholder="Name"
                                            value={addModifierData.name}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {addModifierErrorData.name !== "" &&
                                            <div className="invalid-feedback">
                                                {addModifierErrorData.name}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="name">Display Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            className={"form-control " + (addModifierErrorData.displayName !== "" ? 'invalid' : '')}
                                            id="displayName"
                                            name="displayName"
                                            maxLength="40"
                                            placeholder="Display Name"
                                            value={addModifierData.displayName}
                                            onChange={this.onBasicDetailsChange}
                                            />
                                        {addModifierErrorData.displayName !== "" &&
                                            <div className="invalid-feedback">
                                                {addModifierErrorData.displayName}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {addModifierData.isItemOption ? true : false}
                                        value={addModifierData.isItemOption}
                                        onChange={(event) => {
                                            let tempAddModifierData = this.state.addModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempAddModifierData.choiceLimit = "1";
                                                tempAddModifierData.isRequired = true;
                                                tempAddModifierData.isFixed = false;
                                                tempAddModifierData.isComesWith = false;
                                                tempAddModifierData.isPizzaTopping = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempAddModifierData.isItemOption = event.target.value === 'true' ? true : false;
                                            this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                        }}
                                        disabled={addModifierData.isComesWith || addModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Item Option
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-4">
                                    <label htmlFor="choiceLimit">Choice Limit</label>
                                    <div className="input-group">
                                        <select
                                            className="form-control"
                                            name="choiceLimit"
                                            id="choiceLimit"
                                            onChange={(event) => {
                                                let tempAddModifierData = this.state.addModifierData;
                                                tempAddModifierData.choiceLimit = event.target.value;
                                                this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                            }}
                                            value={addModifierData.choiceLimit}
                                            disabled={addModifierData.isItemOption || addModifierData.isComesWith || addModifierData.isPizzaTopping}>
                                            <option value="">No Limit</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {addModifierData.isRequired ? true : false}
                                        value={addModifierData.isRequired}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempAddModifierData = this.state.addModifierData;
                                            tempAddModifierData.isRequired = event.target.value === 'true' ? true : false;
                                            tempAddModifierData.isFixed = false;
                                            if (tempAddModifierData.isRequired && !tempAddModifierData.choiceLimit) {
                                                tempAddModifierData.choiceLimit = "1";
                                            }
                                            this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                        }}
                                        disabled={addModifierData.isItemOption || addModifierData.isComesWith || addModifierData.isPizzaTopping || addModifierData.isFixed}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Required
                                    </label>
                                </div>
                                <div className="col-md-2 col-lg-2 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {addModifierData.isFixed ? true : false}
                                        value={addModifierData.isFixed}
                                        onChange={(event) => {
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            let tempAddModifierData = this.state.addModifierData;
                                            tempAddModifierData.isFixed = event.target.value === 'true' ? true : false;
                                            tempAddModifierData.isRequired = false;
                                            if (tempAddModifierData.isFixed && !tempAddModifierData.choiceLimit) {
                                                tempAddModifierData.choiceLimit = "1";
                                            }
                                            this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                        }}
                                        disabled={addModifierData.isItemOption || addModifierData.isComesWith || addModifierData.isPizzaTopping || addModifierData.isRequired}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Fixed
                                    </label>
                                </div>
                                <div className="col-md-4 col-lg-4 m-t-35">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {addModifierData.isComesWith ? true : false}
                                        value={addModifierData.isComesWith}
                                        onChange={(event) => {
                                            let tempAddModifierData = this.state.addModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempAddModifierData.isItemOption = false;
                                                tempAddModifierData.isPizzaTopping = false;
                                                tempAddModifierData.choiceLimit = "";
                                                tempAddModifierData.isRequired = false;
                                                tempAddModifierData.isFixed = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempAddModifierData.isComesWith = event.target.value === 'true' ? true : false;
                                            this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                        }}
                                        disabled={addModifierData.isItemOption || addModifierData.isPizzaTopping}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Comes With
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 col-lg-4">
                                    <label className="ms-checkbox-wrap width-auto p-l-25 p-t-2 pointer">
                                        <input type="checkbox" 
                                        checked = {addModifierData.isPizzaTopping ? true : false}
                                        value={addModifierData.isPizzaTopping}
                                        onChange={(event) => {
                                            let tempAddModifierData = this.state.addModifierData;
                                            if (event.target.value === 'false') {
                                                event.target.value = 'true';
                                                tempAddModifierData.isComesWith = false;
                                                tempAddModifierData.isItemOption = false;
                                                tempAddModifierData.choiceLimit = "";
                                                tempAddModifierData.isRequired = false;
                                                tempAddModifierData.isFixed = false;
                                            } else {
                                                event.target.value = 'false';
                                            }
                                            tempAddModifierData.isPizzaTopping = event.target.value === 'true' ? true : false;
                                            this.setState({ addModifierData: tempAddModifierData, isOpenFooter: true })
                                        }}
                                        disabled={addModifierData.isItemOption || addModifierData.isComesWith}
                                        />
                                        <i className="ms-checkbox-check"></i>
                                        Pizza Topping
                                    </label>
                                </div>
                                {addModifierData.isPizzaTopping && 
                                    <div className="col-lg-1 col-md-1">
                                        <label htmlFor="name">% of full</label>
                                        <div className="input-group">
                                        <input type="number"
                                            className="form-control"
                                            id="ofFull"
                                            name="ofFull"
                                            placeholder="% of full"
                                            value={addModifierData.ofFull}
                                            onWheel={ event => event.currentTarget.blur() }
                                            onChange={(e) => {
                                                let tempData = addModifierData;
                                                tempData.ofFull = e.target.value;
                                                if (Number(tempData.ofFull) < 0) {
                                                    tempData.ofFull = "";
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = 0;
                                                            }
                                                            
                                                        }
                                                    }
                                                } else {
                                                    if (tempData.options && tempData.options.length) {
                                                        for (let i = 0; i < tempData.options.length; i += 1) {
                                                            if (Number(tempData.options[i].fullPrice) > 0) {
                                                                tempData.options[i].halfPrice = (Number(tempData.options[i].fullPrice)*Number(addModifierData.ofFull))/100;
                                                            }   
                                                        }
                                                    }
                                                }
                                                this.setState({ addModifierData: tempData, isOpenFooter: true })
                                            }}
                                        />
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <label>Options</label>
                                    {addModifierData.options && addModifierData.options.length > 0 &&
                                        <div className="row">
                                            <div className="col-md-1 option-drag-drop">
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="optionaName">Name</label>
                                            </div>
                                            {!addModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="optionaPrice">Price</label>
                                                </div>
                                            }
                                            {addModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="fullPrice">Full</label>
                                                </div>
                                            }
                                            {addModifierData.isPizzaTopping &&
                                                <div className="col-md-1">
                                                    <label htmlFor="halfPrice">Half</label>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {addModifierData.options && addModifierData.options.length > 0 &&
                                        <ReactDragListView {...this.dragProps}>
                                            {addModifierData.options && addModifierData.options.length > 0 &&
                                                addModifierData.options.map((optionsRow, index) => (
                                                    <section key={index}>
                                                        <div className="row">
                                                            <div className="col-md-1 option-drag-drop">
                                                                {/* eslint-disable-next-line */}
                                                                <a className="cursor-move mr-2"><i className="fas fa-grip-vertical"></i></a>
                                                            </div>
                                                            <div className="col-md-3">
                                                                {/* <label htmlFor="optionaName">Item</label> */}
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                        className={"form-control " + (addModifierErrorData.options[index].name !== "" ? 'invalid' : '')}
                                                                        id="optionaName"
                                                                        name="optionaName"
                                                                        maxLength="40"
                                                                        placeholder="Name"
                                                                        value={optionsRow.name}
                                                                        // onChange={this.onBasicDetailsChange}
                                                                        onChange={(e) => {
                                                                            let tempData = addModifierData;
                                                                            let tempErrorData = addModifierErrorData;
                                                                            tempData.options[index].name = e.target.value;
                                                                            this.setState({ addModifierData: tempData, isOpenFooter: true })
                                                                            let isError = this.handleValidation("options", e.target.value, index, 'name');
                                                                            if (!isError) {
                                                                            tempErrorData.options[index].name = "";
                                                                            this.setState({ addModifierErrorData: tempErrorData });
                                                                            }
                                                                        }}
                                                                        />
                                                                        {addModifierErrorData.options[index].name !== "" &&
                                                                            <div className="invalid-feedback">
                                                                                {addModifierErrorData.options[index].name}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                            {!addModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    {/* <label htmlFor="optionaPrice">Price</label> */}
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (addModifierErrorData.options[index].price !== "" ? 'invalid' : '')}
                                                                            id="optionaPrice"
                                                                            name="optionaPrice"
                                                                            placeholder="Price"
                                                                            value={optionsRow.price}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            // onChange={this.onBasicDetailsChange}
                                                                            onChange={(e) => {
                                                                                let tempData = addModifierData;
                                                                                let tempErrorData = addModifierErrorData;
                                                                                tempData.options[index].price = e.target.value;
                                                                                this.setState({ addModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'price');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].price = "";
                                                                                this.setState({ addModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {/* {addModifierErrorData.options && addModifierErrorData.options.length > 1 &&
                                                                                <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                            } */}
                                                                            {addModifierErrorData.options[index].price !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {addModifierErrorData.options[index].price}
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {!addModifierData.isPizzaTopping && addModifierErrorData.options && addModifierErrorData.options.length > 1 &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                        <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {addModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    {/* <label htmlFor="optionaPrice">Price</label> */}
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (addModifierErrorData.options[index].fullPrice !== "" ? 'invalid' : '')}
                                                                            id="fullPrice"
                                                                            name="fullPrice"
                                                                            placeholder="Full Price"
                                                                            value={optionsRow.fullPrice}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            // onChange={this.onBasicDetailsChange}
                                                                            onChange={(e) => {
                                                                                let tempData = addModifierData;
                                                                                let tempErrorData = addModifierErrorData;
                                                                                tempData.options[index].fullPrice = e.target.value;
                                                                                if (Number(tempData.options[index].fullPrice) > 0 && Number(addModifierData.ofFull) > 0) {
                                                                                    tempData.options[index].halfPrice = (Number(tempData.options[index].fullPrice)*Number(addModifierData.ofFull))/100;
                                                                                }
                                                                                this.setState({ addModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'fullPrice');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].fullPrice = "";
                                                                                this.setState({ addModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {addModifierErrorData.options[index].fullPrice !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {addModifierErrorData.options[index].fullPrice}
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {addModifierData.isPizzaTopping &&
                                                                <div className="col-md-1">
                                                                    {/* <label htmlFor="optionaPrice">Price</label> */}
                                                                    <div className="input-group">
                                                                        <input type="number"
                                                                            className={"form-control " + (addModifierErrorData.options[index].halfPrice !== "" ? 'invalid' : '')}
                                                                            id="halfPrice"
                                                                            name="halfPrice"
                                                                            placeholder="Half Price"
                                                                            value={optionsRow.halfPrice}
                                                                            onWheel={ event => event.currentTarget.blur() }
                                                                            // onChange={this.onBasicDetailsChange}
                                                                            onChange={(e) => {
                                                                                let tempData = addModifierData;
                                                                                let tempErrorData = addModifierErrorData;
                                                                                tempData.options[index].halfPrice = e.target.value;
                                                                                this.setState({ addModifierData: tempData, isOpenFooter: true })
                                                                                let isError = this.handleValidation("options", e.target.value, index, 'halfPrice');
                                                                                if (!isError) {
                                                                                tempErrorData.options[index].halfPrice = "";
                                                                                this.setState({ addModifierErrorData: tempErrorData });
                                                                                }
                                                                            }}
                                                                            />
                                                                            {/* {addModifierErrorData.options && addModifierErrorData.options.length > 1 &&
                                                                                <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                            } */}
                                                                            {addModifierErrorData.options[index].halfPrice !== "" &&
                                                                                <div className="invalid-feedback">
                                                                                    {addModifierErrorData.options[index].halfPrice}
                                                                                </div>
                                                                            }
                                                                            </div>
                                                                </div>
                                                            }
                                                            {addModifierData.isPizzaTopping && addModifierErrorData.options && addModifierErrorData.options.length > 1 &&
                                                                <div className="col-md-1">
                                                                    <div className="input-group">
                                                                        <button type="button" className="btn-outline-danger btn-add-email m-t-8i" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </section>
                                                ))
                                            }
                                        </ReactDragListView>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    {/* eslint-disable-next-line */}
                                    <a className="common-link" onClick={(e) => this.onAddField(e, "options")}>Add New</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                                    Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                }
                { isShowFooter &&
                    <div className="unsave-footer">
                        <p>There are unsaved changes, please save your changes.</p>
                        <button className="btn btn-primary mt-1 float-right" onClick={this.leaveAnyway}>Leave Anyway</button>
                        <button className="btn btn-primary mt-1 mr-2 float-right" onClick={this.onFormSubmit}>Save Changes</button>
                    </div>
                }
            </div>
        );
    }
}

export default AddModifier;