import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner from '../../spinner';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
class AddUpsell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadPage: true,
            addUpsellData: {
                categoryName: 'Upsell',
                name: '',
                description: ''
            },
            addUpsellErrorData: {
                name: ''
            },
            helpText: {
                name: 'Enter the name of the Upsell category. This name will show in the Model window. Use a descriptive name to show the clarity of the upsell item. You will select names of the Upsell items from right pane to assign to this.',
                description: 'Optional description of the name.'
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
    }
    
    componentDidMount() {
        this.getProductsList();
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }
    
    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    if (!(result.data && result.data.status)) {
                        this.setState({ productsData: [] });
                        this.setState({ selectedFoodItems: [], firstTimeFoodItems: JSON.stringify([]) });
                        this.setState({isLoadPage: false});
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name
                                    });
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems, firstTimeFoodItems: JSON.stringify(tempSelectedFoodItems) });
                        this.setState({isLoadPage: false});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching product: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching product: ", error);
        }
    }

    handleValidation(fieldName = "", value = "") {
        let formDetails = this.state.addUpsellData;
        let formErrors = this.state.addUpsellErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "name" && value === "") || (formSubmit === true && formDetails["name"] === "")) {
            fieldName = 'name';
            formErrors[fieldName] = `The 'Name' is a required field.`;
            isError = true;
        }
        this.setState({ addUpsellErrorData: formErrors });
        this.setState({ addUpsellData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.addUpsellData;
        let formErrors = this.state.addUpsellErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ addUpsellData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ addUpsellErrorData: formErrors });
        }
    }

    async onAddSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            const addObj = {
                categoryName: self.state.addUpsellData.categoryName,
                name: self.state.addUpsellData.name,
                description: self.state.addUpsellData.description,
                modifires: [],
                selectedFoodItems: self.state.selectedFoodItems
            };
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            addObj.vendorId = vendorId;
            if (vendorId) {
                let url = `modifier/add`;
                // let requestData = new FormData();
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                self.onAddSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    isOpenModifierModal: false,
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=upsell`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding modifier details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding modifier details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            productsData,
            selectedFoodItems,
            addUpsellData,
            addUpsellErrorData,
            submitProgress,
            isLoadPage,
            helpText
        } = { ...this.state };
        return (
            <div className="addition-options-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Add Upsell</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=upsell">Upsell</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Upsell</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {isLoadPage && 
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            {isLoadPage && <Spinner />}
                        </div>
                    </div>
                }
                {!isLoadPage &&
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6 col-lg-6">
                            <form className="needs-validation clearfix" noValidate>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <label htmlFor="name">Name</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.name}></i>
                                        <div className="input-group">
                                            <input type="text"
                                                className={"form-control " + (addUpsellErrorData.name !== "" ? 'invalid' : '')}
                                                id="name"
                                                name="name"
                                                placeholder="Name"
                                                maxLength="40"
                                                value={addUpsellData.name}
                                                onChange={this.onBasicDetailsChange}
                                                />
                                            {addUpsellErrorData.name !== "" &&
                                                <div className="invalid-feedback">
                                                    {addUpsellErrorData.name}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <label htmlFor="description">Description</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                                        <div className="input-group">
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                maxLength="120"
                                                placeholder="Description"
                                                value={addUpsellData.description}
                                                onChange={this.onBasicDetailsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Select upsell food items</label>
                                    <div id="accordion">
                                        {productsData && productsData.length === 0 &&
                                            <h5 className="text-center">No Items Found.</h5>
                                        }
                                        {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                            productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                <div className="card-header">
                                                    <span className="ml-1">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                    </span>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                        <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                    </a>
                                                </div>

                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {productData && productData.product && productData.product.map((item) => (
                                                            <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-primary float-right" onClick={this.onAddSubmit}>
                                Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default AddUpsell;