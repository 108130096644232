import React from 'react'
// import ReactDOM from "react-dom";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2';
// import DataTablesComp from '../../DataTables';
import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
// import Spinner from '../../spinner';

class ModifiersDetails extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { title: 'Product Name', data: 'name' },
      { title: 'Category', data: 'product_category' },
      { title: 'Customizable', data: 'customizable' },
      { title: 'Available', data: 'availableStatus' },
      { title: 'Price', data: 'price' },
      { title: 'Action', data: 'price', orderable: false },
    ];
    this.state = {
      prodData: this.props.productsData,
      modifiersData: [],
      productsData: {
        data: null
      },
      columns: columns,
      reloadTable: false,
      productCategoryList: [],
      productCategoryValue: '',
      fullProductData: [],
    }
    this.openNewModifierPage = this.openNewModifierPage.bind(this);
  }
  
  componentDidMount() {
    this.getmodifiers();
  }

  async getmodifiers() {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `modifiers/get/${vendorId}`;
    try {
        let token = getAuthTokenFromLocalStorage();
        if (!token) {
            let auth = await getAuth();
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
            }
        }
        axiosInstance.get(url).then(result => {
            // If auth token expired
            if ("success" in result.data && result.data.success === false) {
                localStorage.removeItem('token');
                self.getmodifiers('Upsell');
                return;
            }
            if (!result.data.status) {
              self.setState({ modifiersData: [] })
              console.error("Problem in fetching modifiers data");
              return;
            }
            let modifiersData = result.data.response;
            if (!(modifiersData && modifiersData.length)) {
              self.setState({ modifiersData: [] });
              return;
            }
            self.setState({ modifiersData: modifiersData, reloadTable: true });
        }).catch(error => {
            self.setState({ modifiersData: [] })
            console.error("Problem in fetching modifiers data: ", error);
        })
    } catch (error) {
        self.setState({ modifiersData: [] })
        console.error("Problem in fetching modifiers data: ", error);
    }
  }

  openNewModifierPage(event){
    event.preventDefault();
    let self = this;
    self.props.history.push(`/menu_management/modifier/add`);
  }
  openEditModifier(e, modifierId, row) {
    e.preventDefault();
    let self = this;
    self.props.history.push(`/menu_management/modifier/${modifierId}`, {
        modifierData: row
    });
  }
  

  openDeleteModifier(e, modifireId, row) {
    e.preventDefault();
    let message = 'You will not be able to recover this modifier.';
    Swal.fire({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it.',
        cancelButtonText: 'No, keep it'
    }).then(async (result) => {
        if (result.value) {
            let requestData = {
                id: modifireId,
                vendorId: row.vendorId,
            }
            let url = `modifiers/delete`;
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.delete(url, { data: requestData })
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            return;
                        }
                        if (result.data.status) {
                            this.getmodifiers();
                            toast.success("Modifier deleted Successfully", {
                                autoClose: 3000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            })
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in deleting modifier: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered a problem in deleting the modifier. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in deleting modifier: ", error);
            }
        }
    });
  }

  render() {
    const { modifiersData } = { ...this.state }
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div>
              <button onClick={(event) => { this.openNewModifierPage(event) }} className="btn btn-primary has-icon float-right btn-sm mt-0 mb-4 ml-4">
                {/* eslint-disable-next-line */}
                <i className="fa fa-plus fa-xs"></i> New Modifier
              </button>
            </div>
          </div>
        </div>
        <div className="row">
            {modifiersData && modifiersData.length > 0 && modifiersData.map((mRow, mIndex) => (
              <div className="col-md-12" key={`${mIndex}`}>
                <div className="card-body p-0">
                    <section className="card-body-modifier card-body-grid cate-border mb-3">
                      <div className="row">
                          <div className="col-md-5">
                              <p className="mb-0 modifier-display-name">{mRow.displayName} ({mRow.name})</p>
                              {mRow.options.map((vRow, vIndex) => (
                                  <span key={vIndex}>{vRow.name}{vRow.type ? `(${vRow.type})`: ""}
                                  { mRow && mRow.options && (mRow.options.length-1) !== vIndex && 
                                      <span>, </span>
                                  }
                                  </span>
                              ))}
                              {/* <span>{mRow}</span> */}
                          </div>
                          <div className="col-md-6">
                              <p className="mb-0">  
                                {mRow.isRequired && 
                                  <span>Required</span>
                                }
                                {mRow.isComesWith && !mRow.isRequired &&
                                  <span>Comes With</span>
                                }
                                {mRow.isComesWith && mRow.isRequired &&
                                  <span>, Comes With</span>
                                }
                                {mRow.isItemOption && !mRow.isRequired && !mRow.isComesWith &&
                                  <span>Item Option</span>
                                }
                                {mRow.isItemOption && (mRow.isRequired || mRow.isComesWith) &&
                                  <span>, Item Option</span>
                                }
                              </p>
                              {mRow.choiceLimit && 
                                <p className="mb-0">Choice of {mRow.choiceLimit}</p>
                              }
                              {!mRow.choiceLimit &&
                                <p className="mb-0">No Limit</p>
                              }
                          </div>
                          <div className="col-md-1">
                            <span className="custom-link" onClick={(e) => this.openEditModifier(e, mRow._id, mRow)}>Edit</span>
                            <span> / </span>
                            <span className="custom-link color-red" onClick={(e) => this.openDeleteModifier(e, mRow._id, mRow)}>Delete</span>
                          </div>
                      </div>
                  </section>
                </div>
              </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(ModifiersDetails);