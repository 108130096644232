import React from 'react';
import ReactDOM from "react-dom";
import { withRouter } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import DataTablesComp from '../../DataTables';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';
import { toast } from 'react-toastify';

class DeliveryPartnersIndex extends React.Component {
    constructor(props) {
        super(props);
        let vendorAllDetails = getLoginUserDetails().vendorAllDetails;
        const columns = [
            { title: 'Delivery Partners Name', data: 'name' },
            { title: 'To Email', data: 'emailTo' },
            { title: 'Include Pattern', data: 'includePattern' },
            { title: 'Exclude Pattern', data: 'excludePattern' },
            { title: 'Delivery', data: 'emailTo' },
            { title: 'Action', data: 'emailTo', orderable: false },
        ];
        this.state = {
            deliveryPartners: {
                data: null
            },
            deliveryPartnersSettingsData: [],
            deliveryPartnersData: [],
            columns: columns,
            reloadTable: false,
            isOpenEdit: false,
            reason: "",
            date: new Date(),
            datePickerformat: "",
            dateFormat: "",
            submitProgress: false,
            editSubmitProgress: false,
            reasonError: "",
            frm_deliveryPartnersDetails: {
                vendorID: vendorAllDetails.id.trim(),
                deliveryPartner: "",
                emailTo: "",
                name: "",
                excludePattern: "",
                includePattern: "",
                delivery: ""
            },
            frm_errors: {
                deliveryPartner: "",
                emailTo: ""
            },
            frm_EditdeliveryPartnersDetails: {
                vendorID: vendorAllDetails.id.trim(),
                deliveryPartner: "",
                olddeliveryPartner: "",
                emailTo: "",
                excludePattern: "",
                includePattern: "",
                id: "",
                name: "",
                delivery: ""
            },
            frm_Editerrors: {
                deliveryPartner: "",
                emailTo: ""
            },
        }
        this.getDeliveryPartnersList = this.getDeliveryPartnersList.bind(this);
        this.getDeliveryPartnersSettings = this.getDeliveryPartnersSettings.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.isDeliveryPartnerInUse = this.isDeliveryPartnerInUse.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.openEditDeliveryPartners = this.openEditDeliveryPartners.bind(this);
        this.onCancelEditSubmit = this.onCancelEditSubmit.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.editCheckValidation = this.editCheckValidation.bind(this);
        this.onEditDetailsChange = this.onEditDetailsChange.bind(this);
        this.onEditFocusOut = this.onEditFocusOut.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
    }

    componentDidMount() {
        this.getDeliveryPartnersList();
        this.getDeliveryPartnersSettings();
    }

    async getDeliveryPartnersSettings() {
        let self = this;
        let url = `delivery/getdeliverypartnerssettings`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getDeliveryPartnersSettings();
                    return;
                }

                let deliveryPartnersSettingsData = result.data.response;
                self.setState({
                    deliveryPartnersSettingsData: deliveryPartnersSettingsData
                });
            }).catch(error => {
                console.error("Problem in fetching delivery partners settings data: ", error);
            })
        } catch (error) {
            console.error("Problem in fetching delivery partners settings data: ", error);
        }
    }

    prepareDeliveryPartnersData(data) {
        let deliveryPartnersData = [];
        deliveryPartnersData = data.map(dData => {
            let delivery = '';
            if (!dData.excludePattern) {
                dData.excludePattern = "";
            }
            if (!dData.includePattern) {
                dData.includePattern = "";
            }
            if (dData?.delivery) {
                delivery = dData.delivery
            } else {
                delivery = dData.deliveryPartner
            }
            return {
                ...dData,
                delivery,
            }
        });
        return deliveryPartnersData;
    }

    async onDeliveryChange(event, deliveryPartner, rowData) {
        var self = this;
        let tempSettingsData = self.state.deliveryPartnersData;
        let tmpObject = {};
        for (let i = 0; i < tempSettingsData.length; i += 1) {
            if (tempSettingsData[i].id === rowData.id) {
                tempSettingsData[i].delivery = event.currentTarget.value;
                tmpObject = tempSettingsData[i];
            }
        }


        let editObj = {
            vendorID: tmpObject.vendorID,
            deliveryPartner: (tmpObject.deliveryPartner).toLowerCase(),
            olddeliveryPartner: (tmpObject.deliveryPartner).toLowerCase(),
            emailTo: tmpObject.emailTo,
            id: tmpObject.id,
            name: tmpObject.name,
            excludePattern: tmpObject.excludePattern,
            includePattern: tmpObject.includePattern,
            delivery: tmpObject.delivery
        };

        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        if (vendorId) {
            let url = `delivery/editdeliverypartner`;
            // let requestData = new FormData();
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.post(url, editObj)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            return;
                        }
                        if (result.data.status) {
                            self.setState({
                                deliveryPartners: {
                                    data: self.prepareDeliveryPartnersData(tempSettingsData),
                                    columnDefs: [
                                        {
                                            targets: [4],
                                            createdCell: (td, cellData, rowData) =>
                                                ReactDOM.render(
                                                    <div>
                                                        {vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.deliveryMethod
                                                            && vendor.vendorAllDetails.deliveryMethod === 'dyod' &&
                                                            <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                                <option value="dyod">DYOD</option>
                                                                <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                            </select>
                                                        }
                                                        {vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.deliveryMethod
                                                            && vendor.vendorAllDetails.deliveryMethod === 'postmates' &&
                                                            <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                                <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                            </select>
                                                        }
                                                        {vendor && vendor.vendorAllDetails && !vendor.vendorAllDetails.deliveryMethod &&
                                                            <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                                <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                            </select>
                                                        }
                                                    </div>
                                                    , td)
                                        },
                                        {
                                        targets: [5],
                                        createdCell: (td, cellData, rowData) =>
                                            ReactDOM.render(
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.deliveryPartner, rowData)}>
                                                        <option value="">Select</option>
                                                        <option value="Edit">Edit</option>
                                                        <option value="Delete">Delete</option>
                                                    </select>
                                                </div>
                                                , td)
                                    }
                                    ]
                                },
                                deliveryPartnersData: tempSettingsData,
                                reloadTable: true
                            });
                            // self.getDeliveryPartnersList();
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in editing delievry partner details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in editing delievry partner details. ", error);
            }

        } else {
            toast.error("Oops! We found problems in the data. Please check and try again.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    async getDeliveryPartnersList() {
        let self = this;
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `delivery/getdeliverypartnerslist/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url).then(result => {
                // If auth token expired
                if ("success" in result.data && result.data.success === false) {
                    localStorage.removeItem('token');
                    this.getDeliveryPartnersList();
                    return;
                }

                let deliveryPartnersData = result.data.response;
                this.setState({
                    deliveryPartners: {
                        data: self.prepareDeliveryPartnersData(deliveryPartnersData),
                        columnDefs: [
                            {
                                targets: [4],
                                createdCell: (td, cellData, rowData) =>
                                    ReactDOM.render(
                                        <div>
                                            {vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.deliveryMethod
                                                && vendor.vendorAllDetails.deliveryMethod === 'dyod' &&
                                                <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                    <option value="dyod">DYOD</option>
                                                    <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                </select>
                                            }
                                            {vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.deliveryMethod
                                                && vendor.vendorAllDetails.deliveryMethod === 'postmates' &&
                                                <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                    <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                </select>
                                            }
                                            {vendor && vendor.vendorAllDetails && !vendor.vendorAllDetails.deliveryMethod &&
                                                <select className="form-control delivery-select-box" value={rowData.delivery} onChange={(event) => {this.onDeliveryChange(event, rowData.deliveryPartner, rowData)}}>
                                                    <option value={rowData.deliveryPartner}>{rowData.name} Delivery</option>
                                                </select>
                                            }
                                        </div>
                                        , td)
                            },
                            {
                            targets: [5],
                            createdCell: (td, cellData, rowData) =>
                                ReactDOM.render(
                                    <div>
                                        <select className="form-control" onChange={(event) => this.onActionChange(event, rowData.deliveryPartner, rowData)}>
                                            <option value="">Select</option>
                                            <option value="Edit">Edit</option>
                                            {/* <option value="Preview">Preview</option> */}
                                            <option value="Delete">Delete</option>
                                        </select>
                                    </div>
                                    , td)
                        }
                        ]
                    },
                    deliveryPartnersData: deliveryPartnersData
                });
                this.setState({ reloadTable: true });
            }).catch(error => {
                this.setState({ deliveryPartners: { data: [] } })
                console.error("Problem in fetching delivery partners list data: ", error);
            })
        } catch (error) {
            this.setState({ deliveryPartners: { data: [] } })
            console.error("Problem in fetching delivery partners list data: ", error);
        }
    }

    onActionChange(event, name, row) {
        let action = event.target.value;
        if (action === "Edit") {
            this.openEditDeliveryPartners(event, name, row);
        }
        if (action === "Delete") {
            this.confirmAndDelete(event, row.id, row);
        }

        event.target.value = "";
    }

    // -----------------------------------------------------------------
    // Edit Section
    // -----------------------------------------------------------------
    openEditDeliveryPartners(event, name, row) {
        let delivery = '';
        if (row?.delivery) {
            delivery = row.delivery;
        } else {
            delivery = row.deliveryPartner
        }
        
        this.setState({
            frm_EditdeliveryPartnersDetails: {
                vendorID: row.vendorID,
                deliveryPartner: name,
                olddeliveryPartner: name,
                emailTo: row.emailTo,
                id: row.id,
                name: row.name,
                excludePattern: row.excludePattern,
                includePattern: row.includePattern,
                delivery: delivery
            },
            isOpenEdit: true
        });
        setTimeout(() => {
            this.nameInput.focus();
        }, 500);
    }

    onCancelEditSubmit() {
        this.setState({
            frm_EditdeliveryPartnersDetails: {
                vendorID: "",
                deliveryPartner: "",
                olddeliveryPartner: "",
                excludePattern: "",
                includePattern: "",
                emailTo: "",
                id: "",
                name: "",
                delivery: ""
            },
            isOpenEdit: false,
        });
    }

    isEditDeliveryPartnerInUse(name) {
        let alldeliveryPartnersData = this.state.deliveryPartnersData;
        const id = this.state.frm_EditdeliveryPartnersDetails.id;
        let index = alldeliveryPartnersData.findIndex(dpObj => {
            return dpObj.deliveryPartner.toLowerCase() === name.toLowerCase() && id !== dpObj.id
        });
        return index;
    }

    editCheckValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_EditdeliveryPartnersDetails;
        let formErrors = this.state.frm_Editerrors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }

        if ((formSubmit === false && fieldName === "deliveryPartner" && value === "") || (formSubmit === true && formDetails["deliveryPartner"] === "")) {
            fieldName = 'deliveryPartner';
            formErrors[fieldName] = `The 'Delivery Partner Name' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "deliveryPartner" && value !== "") || (formSubmit === true && formDetails["deliveryPartner"] !== "")) {
            if (this.isEditDeliveryPartnerInUse(formDetails["deliveryPartner"]) !== -1) {
                fieldName = 'deliveryPartner';
                formErrors[fieldName] = `The 'Delivery Partner Name' already exists.`;
                isError = true;
            }
        }
        
        if ((formSubmit === false && fieldName === "emailTo" && value === "") || (formSubmit === true && formDetails["emailTo"] === "")) {
            fieldName = 'emailTo';
            formErrors[fieldName] = `The 'To Email' is a required field.`;
            isError = true;
        }
        
        this.setState({ frm_Editerrors: formErrors });
        this.setState({ frm_EditdeliveryPartnersDetails: formDetails });
        return isError;
    }

    onEditFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.editCheckValidation(fieldName, fieldValue);
    }

    onEditDetailsChange(e) {
        let formDetails = this.state.frm_EditdeliveryPartnersDetails;
        let formErrors = this.state.frm_Editerrors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ frm_EditdeliveryPartnersDetails: formDetails });
        let isError = this.editCheckValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ frm_Editerrors: formErrors });
        }
    }

    async onEditFormSubmit() {
        let self = this;
        let isError = self.editCheckValidation();
        if (!isError) {
            let editObj = {
                vendorID: self.state.frm_EditdeliveryPartnersDetails.vendorID,
                deliveryPartner: (self.state.frm_EditdeliveryPartnersDetails.deliveryPartner).toLowerCase(),
                olddeliveryPartner: (self.state.frm_EditdeliveryPartnersDetails.olddeliveryPartner).toLowerCase(),
                emailTo: self.state.frm_EditdeliveryPartnersDetails.emailTo,
                id: self.state.frm_EditdeliveryPartnersDetails.id,
                excludePattern: self.state.frm_EditdeliveryPartnersDetails.excludePattern,
                includePattern: self.state.frm_EditdeliveryPartnersDetails.includePattern,
                name: self.state.frm_EditdeliveryPartnersDetails.name,
                delivery: self.state.frm_EditdeliveryPartnersDetails.delivery
            };

            self.setState({ editSubmitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            if (vendorId) {
                let url = `delivery/editdeliverypartner`;
                // let requestData = new FormData();
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditFormSubmit();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Edited the delievry partner successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.onCancelEditSubmit();
                                self.getDeliveryPartnersList();
                                // this.props.history.push(`/delivery_management`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ editSubmitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ editSubmitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in editing delievry partner details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ editSubmitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in editing delievry partner details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }



    // -----------------------------------------------------------------
    // Add Section
    // -----------------------------------------------------------------
    isDeliveryPartnerInUse(name) {
        let alldeliveryPartnersData = this.state.deliveryPartnersData;
        let index = alldeliveryPartnersData.findIndex(dpObj => {
            return dpObj.deliveryPartner.toLowerCase() === name.toLowerCase()
        });
        return index;
    }

    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_deliveryPartnersDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }

        if ((formSubmit === false && fieldName === "deliveryPartner" && value === "") || (formSubmit === true && formDetails["deliveryPartner"] === "")) {
            fieldName = 'deliveryPartner';
            formErrors[fieldName] = `The 'Delivery Partner Name' is a required field.`;
            isError = true;
        } else if ((formSubmit === false && fieldName === "deliveryPartner" && value !== "") || (formSubmit === true && formDetails["deliveryPartner"] !== "")) {
            if (this.isDeliveryPartnerInUse(formDetails["deliveryPartner"]) !== -1) {
                fieldName = 'deliveryPartner';
                formErrors[fieldName] = `The 'Delivery Partner Name' already exists.`;
                isError = true;
            }
        }
        
        if ((formSubmit === false && fieldName === "emailTo" && value === "") || (formSubmit === true && formDetails["emailTo"] === "")) {
            fieldName = 'emailTo';
            formErrors[fieldName] = `The 'To Email' is a required field.`;
            isError = true;
        }
        
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_deliveryPartnersDetails: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        let formDetails = this.state.frm_deliveryPartnersDetails;
        let formErrors = this.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ frm_deliveryPartnersDetails: formDetails });
        let isError = this.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ frm_errors: formErrors });
        }
    }

    async onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            const deliveryPartnersSettingsData = self.state.deliveryPartnersSettingsData;
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            let name = '';
            let addObj = {
                vendorID: vendorId,
                deliveryPartner: (self.state.frm_deliveryPartnersDetails.deliveryPartner).toLocaleLowerCase(),
                emailTo: self.state.frm_deliveryPartnersDetails.emailTo,
                name: name,
                excludePattern: self.state.frm_deliveryPartnersDetails.excludePattern,
                includePattern: self.state.frm_deliveryPartnersDetails.includePattern,
                delivery: ""
            };
            for (let i = 0; i < deliveryPartnersSettingsData.length; i += 1) {
                if (deliveryPartnersSettingsData[i].key === addObj.deliveryPartner) {
                    addObj.name = deliveryPartnersSettingsData[i].name;
                    addObj.delivery = addObj.deliveryPartner;
                    break;
                }
            }
            self.setState({ submitProgress: true });
            if (vendorId) {
                let url = `delivery/adddeliverypartner`;
                // let requestData = new FormData();
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, addObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onFormSubmit();
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Added the delivery partner successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    frm_deliveryPartnersDetails: {
                                        deliveryPartner: "",
                                        emailTo: ""
                                    }    
                                });
                                self.getDeliveryPartnersList();
                                // this.props.history.push(`/delivery_management`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            self.setState({ submitProgress: false });
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in adding delivery partner details: ", error);
                        })
                }
                catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in adding delivery partner details. ", error);
                }

            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }


    // -----------------------------------------------------------------
    // Delete Section
    // -----------------------------------------------------------------
    confirmAndDelete(e, id, row) {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this delivery partner.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it.',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                let self = this;
                let requestData = {
                    id: id,
                    vendorID: row.vendorID
                }
                let url = `delivery/removedeliverypartner`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.delete(url, { data: requestData })
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                toast.error("Oops! We encountered a problem in deleting the delivery partner. Please try again.", {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return;
                            }
                            if (result.data.status) {
                                self.getDeliveryPartnersList();
                                toast.success("Success: delivery partner deleted!", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        })
                        .catch(error => {
                            toast.error("Oops! We encountered a problem in deleting the delivery partner. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in deleting delivery partner: ", error);
                        })
                }
                catch (error) {
                    toast.error("Oops! We encountered a problem in deleting the driver. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in deleting driver: ", error);
                }
            }
        });
    }

    render() {
        const {
            deliveryPartners,
            columns,
            reloadTable,
            frm_deliveryPartnersDetails,
            frm_errors,
            submitProgress,
            isOpenEdit,
            frm_EditdeliveryPartnersDetails,
            editSubmitProgress,
            frm_Editerrors,
            deliveryPartnersSettingsData
        } = { ...this.state }
        return (
            <div className="section-offdays">
                {!isOpenEdit &&
                    <form className="needs-validation form-add-offdays clearfix p-3 mt-2" autoComplete="nops" noValidate>
                        <div className="form-row">
                            <div className="col-md-2">
                                <label htmlFor="name" className="required">Delivery Partner Name</label>
                                <div className="input-group">
                                    <select
                                        className={"form-control " + (frm_errors.deliveryPartner !== "" ? 'invalid' : '')}
                                        id="deliveryPartner"
                                        value={frm_deliveryPartnersDetails.deliveryPartner}
                                        onChange={this.onDetailsChange}
                                        onBlur={this.onFocusOut}
                                        required
                                    >
                                        <option value="">Select</option>
                                        {deliveryPartnersSettingsData && deliveryPartnersSettingsData.length > 0 && deliveryPartnersSettingsData.map((option, index) => (
                                            <option key={`category-index-${index}`} value={option.key}>{option.name}</option>
                                        ))}
                                    </select>
                                    {frm_errors.deliveryPartner && frm_errors.deliveryPartner !== "" &&
                                    <div className="invalid-feedback">{frm_errors.deliveryPartner}</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="reason" className="required">To Email</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className={"form-control " + (frm_errors.emailTo !== "" ? 'invalid' : '')}
                                    placeholder="To Email"
                                    id="emailTo"
                                    autoComplete="nops"
                                    name="emailTo"
                                    value={frm_deliveryPartnersDetails.emailTo}
                                    onChange={this.onDetailsChange}
                                    onBlur={this.onFocusOut}
                                    required
                                />
                                    {frm_errors.emailTo && frm_errors.emailTo !== "" &&
                                        <div className="invalid-feedback">{frm_errors.emailTo}</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="includePattern">Include Pattern</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ex. First Half Topping (First Half), Second Half Topping (Second Half)"
                                    id="includePattern"
                                    autoComplete="nops"
                                    name="includePattern"
                                    value={frm_deliveryPartnersDetails.includePattern}
                                    onChange={this.onDetailsChange}
                                    onBlur={this.onFocusOut}
                                />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="excludePattern">Exclude Pattern</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Exclude Pattern Ex. Wings Options, Extra Options"
                                    id="excludePattern"
                                    autoComplete="nops"
                                    name="excludePattern"
                                    value={frm_deliveryPartnersDetails.excludePattern}
                                    onChange={this.onDetailsChange}
                                    onBlur={this.onFocusOut}
                                />
                                </div>
                            </div>
                            <div className="col-md-1">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.onFormSubmit}>Add {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                            </div>
                        </div>
                    </form>
                }
                {isOpenEdit &&
                    <form className="needs-validation form-add-offdays clearfix p-3 mt-2" autoComplete="nops" noValidate>
                        <div className="form-row">
                            <div className="col-md-2">
                                <label htmlFor="name" className="required">Delivery Partner Name</label>
                                <div className="input-group">
                                    <select
                                        className={"form-control " + (frm_Editerrors.deliveryPartner !== "" ? 'invalid' : '')}
                                        id="deliveryPartner"
                                        ref={(input) => { this.nameInput = input; }}
                                        value={frm_EditdeliveryPartnersDetails.deliveryPartner}
                                        onChange={this.onEditDetailsChange}
                                        onBlur={this.onEditFocusOut}
                                        required
                                    >
                                        <option value="">Select</option>
                                        {deliveryPartnersSettingsData && deliveryPartnersSettingsData.length > 0 && deliveryPartnersSettingsData.map((option, index) => (
                                            <option key={`category-index-${index}`} value={option.key}>{option.name}</option>
                                        ))}
                                    </select>
                                    {frm_Editerrors.deliveryPartner && frm_Editerrors.deliveryPartner !== "" &&
                                    <div className="invalid-feedback">{frm_Editerrors.deliveryPartner}</div>}
                                </div>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="reason" className="required">To Email</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className={"form-control " + (frm_errors.emailTo !== "" ? 'invalid' : '')}
                                    placeholder="To Email"
                                    id="emailTo"
                                    autoComplete="nops"
                                    name="emailTo"
                                    value={frm_EditdeliveryPartnersDetails.emailTo}
                                    onChange={this.onEditDetailsChange}
                                    onBlur={this.onEditFocusOut}
                                    required
                                />
                                    {frm_Editerrors.emailTo && frm_Editerrors.emailTo !== "" &&
                                        <div className="invalid-feedback">{frm_Editerrors.emailTo}</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="includePattern">Include Pattern</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ex. First Half Topping (First Half), Second Half Topping (Second Half)"
                                    id="includePattern"
                                    autoComplete="nops"
                                    name="includePattern"
                                    value={frm_EditdeliveryPartnersDetails.includePattern}
                                    onChange={this.onEditDetailsChange}
                                    onBlur={this.onEditFocusOut}
                                />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="excludePattern">Exclude Pattern</label>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Exclude Pattern Ex. Wings Options, Extra Options"
                                    id="excludePattern"
                                    autoComplete="nops"
                                    name="excludePattern"
                                    value={frm_EditdeliveryPartnersDetails.excludePattern}
                                    onChange={this.onEditDetailsChange}
                                    onBlur={this.onEditFocusOut}
                                />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button type="button" className="btn btn-primary mt-4" onClick={this.onEditFormSubmit}>Edit {editSubmitProgress && <i className="fas fa-spinner fa-pulse"></i>}</button>
                                <button type="button" className="btn btn-primary mt-4 ml-3" onClick={this.onCancelEditSubmit}>Cancel</button>
                            </div>
                        </div>
                    </form>
                }
                <div className="table-responsive mt-4">
                    {deliveryPartners && deliveryPartners.data !== null && 
                    <DataTablesComp data={deliveryPartners} columns={columns} reloadTable={reloadTable} sortColumn="0" sortOrder="desc" id="deliveryPartnerTable"/>}
                </div>
            </div>
        );
    }
}

export default withRouter(DeliveryPartnersIndex);