import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner from '../../spinner';
import { getLoginUserDetails, axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class EditAllItemsSelection extends React.Component {
    constructor(props) {
        super(props);
        let allItemsSelectionId = this.props.match.params.allItemsSelectionId;
        let errorOptions = [];
        let allItemsSelectionData = this.props.location.state && this.props.location.state.allItemsSelectionData ? this.props.location.state.allItemsSelectionData : null;
        if (allItemsSelectionData && allItemsSelectionData.modifiresObject && allItemsSelectionData.modifiresObject.options && allItemsSelectionData.modifiresObject.options.length) {
            for (let i = 0; i < allItemsSelectionData.modifiresObject.options.length; i += 1) {
                errorOptions.push({
                    description: "",
                    item: ""
                });
            }
        }
        if (allItemsSelectionData.modifiresObject.inListAllItemsSelection === undefined) {
            allItemsSelectionData.modifiresObject.inListAllItemsSelection = true;
        }
        if (!allItemsSelectionData) {
            this.getSingleModifier(allItemsSelectionId);
        }
        this.state = {
            isLoadPage: true,
            allItemsSelectionId: allItemsSelectionId,
            productsData: [],
            editAllItemsSelectionData: {
                title: allItemsSelectionData && allItemsSelectionData.title ? allItemsSelectionData.title : "",
                description: allItemsSelectionData && allItemsSelectionData.description ? allItemsSelectionData.description : "",
                inListAllItemsSelection: allItemsSelectionData && allItemsSelectionData.modifiresObject && allItemsSelectionData.modifiresObject.inListAllItemsSelection ? allItemsSelectionData.modifiresObject.inListAllItemsSelection : false,
                options: allItemsSelectionData && allItemsSelectionData.modifiresObject && allItemsSelectionData.modifiresObject.options ? allItemsSelectionData.modifiresObject.options : [],
                categoryName: allItemsSelectionData && allItemsSelectionData.categoryName ? allItemsSelectionData.categoryName : "",
            },
            editAllItemsSelectionErrorData: {
                title: '',
                options: errorOptions
            },
            helpText: {
                title: 'Give a descriptive name. It will show in the model window.',
                description: 'Write the name of the category to which it should apply e.g. for Vegetarian Pizza',
                options: 'You can add as many items.',
                inListAllItemsSelection: ''
            }
        }
        this.onBasicDetailsChange = this.onBasicDetailsChange.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategoryItemClick = this.onCategoryItemClick.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
    }
    componentDidMount() {
        this.getProductsList();
    }

    async getProductsList() {
        let vendor = JSON.parse(localStorage.getItem('vendor'));
        let vendorId = vendor.id;
        let url = `menu/product/${vendorId}`;
        try {
            let token = getAuthTokenFromLocalStorage();
            if (!token) {
                let auth = await getAuth();
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                }
            }
            axiosInstance.get(url)
                .then(result => {
                    // If auth token expired
                    if ("success" in result.data && result.data.success === false) {
                        localStorage.removeItem('token');
                        this.getProductsList();
                        return;
                    }
                    if (!(result.data && result.data.status)) {
                        this.setState({ productsData: [] });
                        this.setState({ selectedFoodItems: [], firstTimeFoodItems: JSON.stringify([]) });
                        this.setState({isLoadPage: false});
                        return;
                    }
                    let products = result.data.response;
                    var manageProductData = [];
                    var tempSelectedFoodItems = [];
                    if (vendor && vendor.vendorAllDetails && vendor.vendorAllDetails.product_categories && vendor.vendorAllDetails.product_categories.length) {
                        for (let i = 0; i < vendor.vendorAllDetails.product_categories.length; i += 1) {
                            manageProductData.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                isOpen: false
                            });
                            tempSelectedFoodItems.push({
                                categoryName: vendor.vendorAllDetails.product_categories[i],
                                product: [],
                                allProductSelected: false
                            })
                        }
                        // eslint-disable-next-line
                        for (let i = 0; i < products.length; i += 1) {
                            for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                if (manageProductData[ii].categoryName === products[i].product_category) {
                                    if (products[i].all_items_selection && products[i].all_items_selection.length) {
                                        for (let iii = 0; iii < products[i].all_items_selection.length; iii += 1) {
                                            if (products[i].all_items_selection[iii].id === this.state.allItemsSelectionId) {
                                                tempSelectedFoodItems[ii].product.push({
                                                    id: products[i].id,
                                                    name: products[i].name
                                                });
                                            }
                                        }
                                    }
                                    manageProductData[ii].product.push({
                                        id: products[i].id,
                                        name: products[i].name
                                    });
                                }
                            }
                        }
                        for (let i = 0; i < manageProductData.length; i += 1) {
                            if (manageProductData[i].product && manageProductData[i].product.length) {
                                if (tempSelectedFoodItems[i].product && tempSelectedFoodItems[i].product.length === manageProductData[i].product.length) {
                                    tempSelectedFoodItems[i].allProductSelected = true;
                                }
                            }
                        }
                        this.setState({ productsData: manageProductData });
                        this.setState({ selectedFoodItems: tempSelectedFoodItems, firstTimeFoodItems: JSON.stringify(tempSelectedFoodItems) });
                        this.setState({isLoadPage: false});
                    }
                })
                .catch(error => {
                    this.setState({ productsData: { data: [] } })
                    console.error("Problem in fetching vendor product: ", error);
                })
        }
        catch (error) {
            this.setState({ productsData: { data: [] } })
            console.error("Problem in fetching vendor product: ", error);
        }
    }

    async getSingleModifier(allItemsSelectionId) {
        let self = this;
        let vendor = getLoginUserDetails();
        let vendorId = vendor.id;
        let url = `modifier/getsingle/${vendorId}/${allItemsSelectionId}`;
        if (vendorId && allItemsSelectionId) {
            try {
                let token = getAuthTokenFromLocalStorage();
                if (!token) {
                    let auth = await getAuth();
                    if (auth && auth.success && auth.token) {
                        localStorage.setItem('token', auth.token);
                    }
                }
                axiosInstance.get(url)
                    .then(result => {
                        // If auth token expired
                        if ("success" in result.data && result.data.success === false) {
                            localStorage.removeItem('token');
                            this.getSingleModifier(allItemsSelectionId);
                            return;
                        }
                        if (result.data.status) {
                            let data = result.data.response;
                            let errorOptions = [];
                            if (data && data.modifiresObject && data.modifiresObject.options && data.modifiresObject.options.length) {
                                for (let i = 0; i < data.modifiresObject.options.length; i += 1) {
                                    errorOptions.push({
                                        description: "",
                                        item: ""
                                    });
                                }
                            }
                            let tempFormData = self.state.editAllItemsSelectionData;
                            let tempFormErrorData = self.state.editAllItemsSelectionErrorData;
                            tempFormData.title = data && data.title ? data.title : "";
                            tempFormData.description =  data && data.description ? data.description : "";
                            tempFormData.options =  data && data.modifiresObject && data.modifiresObject.options ? data.modifiresObject.options : [];
                            tempFormData.categoryName =  data && data.categoryName ? data.categoryName : "";
                            if (data && data.modifiresObject && data.modifiresObject.inListAllItemsSelection === undefined) {
                                tempFormData.inListAllItemsSelection = true;
                            } else {
                                tempFormData.inListAllItemsSelection = data && data.modifiresObject && data.modifiresObject.inListAllItemsSelection ? data.modifiresObject.inListAllItemsSelection : false;
                            }
                            self.setState({ editAllItemsSelectionData: tempFormData });
                            
                            tempFormErrorData.title = '';
                            tempFormErrorData.options = errorOptions;
                            self.setState({ editAllItemsSelectionErrorData: tempFormErrorData });


                            // self.setState({ productData: data });
                        } else {
                            toast.error(result.data.statusText, {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops! We encountered an error. Please try again.", {
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        console.error("Problem in get single modifier details: ", error);
                    })
            }
            catch (error) {
                toast.error("Oops! We encountered an error. Please try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                console.error("Problem in get single modifier details. ", error);
            }
        } else {
            toast.error("Problem in get single modifier data!", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    onCategoryItemClick(e, productData, index, productItemId, productItemName) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;

        if (e.target.checked) {
            selectedProductItems[index].product.push({ id: productItemId, name: productItemName })
        } else {
            let itemIndex = selectedProductItems[index].product.findIndex(item => {
                return item.id === productItemId
            })
            if (itemIndex !== -1) {
                selectedProductItems[index].product.splice(itemIndex, 1);
            }
        }
        if (selectedProductItems[index].product.length === categoryItems.length) {
            selectedProductItems[index].allProductSelected = true;
        } else {
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    onCategoryClick(e, index, productData) {
        let selectedProductItems = this.state.selectedFoodItems;
        let categoryItems = productData.product;
        if (e.target.checked) {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex === -1) {
                    selectedProductItems[index].product.push({ id: item.id, name: item.name });
                }
            })
            selectedProductItems[index].allProductSelected = true;
        } else {
            categoryItems.forEach(item => {
                let itemIndex = selectedProductItems[index].product.findIndex(selectItem => {
                    return item.id === selectItem.id
                })
                if (itemIndex !== -1) {
                    selectedProductItems[index].product.splice(itemIndex, 1);
                }
            });
            selectedProductItems[index].allProductSelected = false;
        }
        this.setState({ selectedFoodItems: selectedProductItems });
    }

    openExpand(index) {
        const tmpProd = this.state.productsData;
        tmpProd[index].isOpen = !tmpProd[index].isOpen;
        this.setState({
            productsData: tmpProd
        });
    }

    handleValidation(fieldName = "", value = "", index, newField) {
        let formDetails = this.state.editAllItemsSelectionData;
        let formErrors = this.state.editAllItemsSelectionErrorData;
        let formSubmit = false;
        let isError = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        
        if ((formSubmit === false && fieldName === "title" && value === "") || (formSubmit === true && formDetails["title"] === "")) {
            fieldName = 'title';
            formErrors[fieldName] = `The 'Title' is a required field.`;
            isError = true;
        }
        
        if ((formSubmit === false && fieldName === 'options') || (formSubmit === true)) {
            fieldName = 'options';
            if (index !== undefined) {       
                if (formDetails['options'][index][newField] === "") {
                    formErrors['options'][index][newField] = `The '${newField}' is a required field.`;
                    isError = true;
                }
            }
        }

        if (fieldName === 'options' && index === undefined) {
            for (let i = 0; i < formDetails.options.length; i += 1) {
                if (formDetails.options[i].item === "") {
                    fieldName = 'options';
                    formErrors.options[i].item = `The 'Item' is a required field.`;
                    isError = true;
                }
            }
        }
        
        this.setState({ editAllItemsSelectionErrorData: formErrors });
        this.setState({ editAllItemsSelectionData: formDetails });
        return isError;
    }

    onBasicDetailsChange(e, name = "") {
        let formDetails = this.state.editAllItemsSelectionData;
        let formErrors = this.state.editAllItemsSelectionErrorData;
        let fieldName = name === "" ? e.target.id : name;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        this.setState({ editAllItemsSelectionData: formDetails });
        let isError = this.handleValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            this.setState({ editAllItemsSelectionErrorData: formErrors });
        }
    }

    onAddField(e, formField) {
        e.preventDefault();
        let formDetails = this.state.editAllItemsSelectionData;
        let formDetailsError = this.state.editAllItemsSelectionErrorData;
        formDetails[formField].push({
            description: "",
            isSelected: true,
            item: ""
        });
        formDetailsError[formField].push({
            description: "",
            item: ""
        });
        this.setState({ editAllItemsSelectionData: formDetails, editAllItemsSelectionErrorData: formDetailsError})
    }

    onDeleteField(e, formField, index) {
        let formDetails = this.state.editAllItemsSelectionData;
        let formDetailsError = this.state.editAllItemsSelectionErrorData;
        formDetails[formField].splice(index, 1);
        formDetailsError[formField].splice(index, 1);
        this.setState({ editAllItemsSelectionData: formDetails, editAllItemsSelectionErrorData: formDetailsError })
    }

    async onEditSubmit(e) {
        e.preventDefault();
        let self = this;
        let isError = self.handleValidation();
        if (!isError) {
            self.setState({ submitProgress: true });
            let vendor = getLoginUserDetails();
            let vendorId = vendor.id;
            const editAllItemsSelectionData = this.state.editAllItemsSelectionData;
            const editObj = {
                id: self.state.allItemsSelectionId,
                selectedFoodItems: self.state.selectedFoodItems,
                modifiresObject: {
                    title: editAllItemsSelectionData.title,
                    description: editAllItemsSelectionData?.description ? editAllItemsSelectionData.description : "",
                    inListAllItemsSelection: editAllItemsSelectionData.inListAllItemsSelection,
                },
                title: editAllItemsSelectionData.title,
                description: editAllItemsSelectionData?.description ? editAllItemsSelectionData.description : "",
                categoryName: "allItemsSelection",
                vendorId: vendorId
            };
            editObj.modifiresObject.options = editAllItemsSelectionData.options;
            if (vendorId) {
                let url = `modifier/allitemsselection/edit`;
                try {
                    let token = getAuthTokenFromLocalStorage();
                    if (!token) {
                        let auth = await getAuth();
                        if (auth && auth.success && auth.token) {
                            localStorage.setItem('token', auth.token);
                        }
                    }
                    axiosInstance.post(url, editObj)
                        .then(result => {
                            // If auth token expired
                            if ("success" in result.data && result.data.success === false) {
                                localStorage.removeItem('token');
                                this.onEditSubmit(e);
                                return;
                            }
                            if (result.data.status) {
                                toast.success("We Edited the modifier successfully.", {
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({
                                    submitProgress: false,
                                    reloadTable: true
                                });
                                // this.getmodifiers();
                                // self.props.history.push(`/menu_management?tab=modifiers`);
                                self.props.history.push(`/menu_management?tab=allitemselection`);
                            } else {
                                toast.error(result.data.statusText, {
                                    autoClose: 5000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                self.setState({ submitProgress: false });
                            }
                        })
                        .catch(error => {
                            self.setState({ submitProgress: false });
                            toast.error("Oops! We encountered an error. Please try again.", {
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            console.error("Problem in editing modifier details: ", error);
                        })
                } catch (error) {
                    self.setState({ submitProgress: false });
                    toast.error("Oops! We encountered an error. Please try again.", {
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    console.error("Problem in editing modifier details. ", error);
                }
            } else {
                toast.error("Oops! We found problems in the data. Please check and try again.", {
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                self.setState({ submitProgress: false });
            }
        } else {
            toast.error("Please provide all required data in correct format.", {
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            self.setState({ submitProgress: false });
        }
    }

    render() {
        const {
            editAllItemsSelectionData,
            editAllItemsSelectionErrorData,
            submitProgress,
            productsData,
            selectedFoodItems,
            isLoadPage,
            helpText
        } = { ...this.state };
        return (
            <div className="all-items-selection-add">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb" className="page-nav-info mb-1">
                            <span className="page-title">Edit All Items</span>
                            <ol className="breadcrumb pl-0 pt-0">
                                <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li>
                                {/* <li className="breadcrumb-item"><Link to="/menu_management?tab=modifiers">Modifiers</Link></li> */}
                                <li className="breadcrumb-item"><Link to="/menu_management?tab=allitemselection">All Items</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit All Items</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {isLoadPage && 
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            {isLoadPage && <Spinner />}
                        </div>
                    </div>
                }
                {!isLoadPage &&
                    <div className="row">
                        <ReactTooltip />
                        <div className="col-md-6 col-lg-6">
                            <form className="needs-validation clearfix" noValidate>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="title">Title</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.title}></i>
                                        <div className="input-group">
                                            <input type="text"
                                                className={"form-control " + (editAllItemsSelectionErrorData.title !== "" ? 'invalid' : '')}
                                                id="title"
                                                name="title"
                                                maxLength="40"
                                                placeholder="Title"
                                                value={editAllItemsSelectionData.title}
                                                onChange={this.onBasicDetailsChange}
                                                />
                                            {editAllItemsSelectionErrorData.title !== "" &&
                                                <div className="invalid-feedback">
                                                    {editAllItemsSelectionErrorData.title}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label htmlFor="description">Description</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.description}></i>
                                        <div className="input-group">
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                maxLength="120"
                                                placeholder="Description"
                                                value={editAllItemsSelectionData.description}
                                                onChange={this.onBasicDetailsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                    <label htmlFor="description">All Items Selection</label>
                                    {/* <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.inListAllItemsSelection}></i> */}
                                    <div className="input-group mb-0">
                                        <ul className="ms-list d-flex">
                                            <li className="ms-list-item pl-0">
                                                <label className="ms-checkbox-wrap">
                                                    <input type="radio"
                                                        name="EditRadioAllItemsSelection"
                                                        value={true}
                                                        checked={editAllItemsSelectionData.inListAllItemsSelection === true}
                                                        onChange={(event) => {
                                                            let tempeditAllItemsSelectionData = this.state.editAllItemsSelectionData;
                                                            tempeditAllItemsSelectionData.inListAllItemsSelection = event.currentTarget.value === "true" ? true : false;
                                                            this.setState({ editAllItemsSelectionData: tempeditAllItemsSelectionData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> Yes </span>
                                            </li>
                                            <li className="ms-list-item">
                                                <label className="ms-checkbox-wrap">
                                                    <input
                                                        type="radio"
                                                        name="EditRadioAllItemsSelection"
                                                        value={false}
                                                        checked={editAllItemsSelectionData.inListAllItemsSelection === false}
                                                        onChange={(event) => {
                                                            let tempeditAllItemsSelectionData = this.state.editAllItemsSelectionData;
                                                            tempeditAllItemsSelectionData.inListAllItemsSelection = event.currentTarget.value === "false" ? false : true;
                                                            this.setState({ editAllItemsSelectionData: tempeditAllItemsSelectionData })
                                                        }}
                                                    />
                                                    <i className="ms-checkbox-check"></i>
                                                </label>
                                                <span> No </span>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <label>Options</label>
                                        <i className="fa fa-question-circle ml-3 text-secondary" data-tip={helpText.options}></i>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="optionaItem">Item</label>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="optionaDescription">Description</label>
                                            </div>
                                        </div>
                                        {editAllItemsSelectionData.options && editAllItemsSelectionData.options.length > 0 &&
                                            editAllItemsSelectionData.options.map((optionsRow, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-md-4">
                                                        {/* <label htmlFor="optionaItem">Item</label> */}
                                                        <div className="input-group">
                                                            <input type="text"
                                                                className={"form-control " + (editAllItemsSelectionErrorData.options[index].item !== "" ? 'invalid' : '')}
                                                                id="optionaItem"
                                                                name="optionaItem"
                                                                maxLength="40"
                                                                placeholder="Item"
                                                                value={optionsRow.item}
                                                                // onChange={this.onBasicDetailsChange}
                                                                onChange={(e) => {
                                                                    let tempData = editAllItemsSelectionData;
                                                                    let tempErrorData = editAllItemsSelectionErrorData;
                                                                    tempData.options[index].item = e.target.value;
                                                                    this.setState({ editAllItemsSelectionData: tempData })
                                                                    let isError = this.handleValidation("options", e.target.value, index, 'item');
                                                                    if (!isError) {
                                                                    tempErrorData.options[index].item = "";
                                                                    this.setState({ editAllItemsSelectionErrorData: tempErrorData });
                                                                    }
                                                                }}
                                                                />
                                                                {editAllItemsSelectionErrorData.options[index].item !== "" &&
                                                                    <div className="invalid-feedback">
                                                                        {editAllItemsSelectionErrorData.options[index].item}
                                                                    </div>
                                                                }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {/* <label htmlFor="optionaDescription">Description</label> */}
                                                        <div className="input-group">
                                                            <input type="text"
                                                                className="form-control"
                                                                id="optionaDescription"
                                                                name="optionaDescription"
                                                                maxLength="120"
                                                                placeholder="Description"
                                                                value={optionsRow.description}
                                                                // onChange={this.onBasicDetailsChange}
                                                                onChange={(e) => {
                                                                    let tempData = editAllItemsSelectionData;
                                                                    tempData.options[index].description = e.target.value;
                                                                    this.setState({ editAllItemsSelectionData: tempData })
                                                                }}
                                                                />
                                                                { editAllItemsSelectionData.options.length === 1 && index === 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { editAllItemsSelectionData.options.length === index+1 && index > 0 &&
                                                                    <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                }
                                                                { index < editAllItemsSelectionData.options.length-1 &&
                                                                    <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                }
                                                                {/* { index === 0 ? <button type="button" className="btn-outline-primary btn-add-email" onClick={(e) => this.onAddField(e, "options")}><i className="fas fa-plus"></i></button>
                                                                    : <button type="button" className="btn-outline-danger btn-add-email" onClick={(e) => this.onDeleteField(e, "options", index)}><i className="fas fa-times"></i></button>
                                                                } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Select food items assign to this modifier</label>
                                    <div id="accordion">
                                        {productsData && productsData.length === 0 &&
                                            <h5 className="text-center">No Items Found.</h5>
                                        }
                                        {productsData && productsData.length > 0 && selectedFoodItems && selectedFoodItems.length && productsData.map((productData, index) => (
                                            productData && productData.product && productData.product.length > 0 && <div className="card" key={`card-${index}`}>
                                                <div className="card-header">
                                                    <span className="ml-1">
                                                        <label className="ms-checkbox-wrap">
                                                            <input type="checkbox"
                                                                checked={selectedFoodItems[index]?.allProductSelected ? true : false}
                                                                onChange={(e) => { this.onCategoryClick(e, index, productData) }} />
                                                            <i className="ms-checkbox-check"></i>
                                                        </label>
                                                    </span>
                                                    {/* eslint-disable-next-line */}
                                                    <a className={"card-link default-link " + (productData.isOpen ? "" : "collapsed")} data-toggle="collapse" onClick={() => this.openExpand(index)}>
                                                        <strong>{productData.categoryName} {productData.isOpen} {`(${selectedFoodItems[index].product.length})`}</strong>
                                                    </a>
                                                </div>

                                                <div className={"collapse " + (productData.isOpen ? "show" : "")}>
                                                    <div className="card-body" style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {productData && productData.product && productData.product.map((item) => (
                                                            <span className="ml-1 mr-3 mt-2 mb-2 card-body-checkbox" key={`${item.id}`}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input type="checkbox"
                                                                        checked={selectedFoodItems[index].product.findIndex(selectedItem => {
                                                                            return item.id === selectedItem.id
                                                                        }) !== -1 ? true : false}
                                                                        value={item.id}
                                                                        onChange={(e) => { this.onCategoryItemClick(e, productData, index, item.id, item.name) }}
                                                                    />
                                                                    <i className="ms-checkbox-check"></i>
                                                                </label>
                                                                <span>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-primary float-right" onClick={this.onEditSubmit}>
                                Update {submitProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default EditAllItemsSelection;