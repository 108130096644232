import React from 'react'
import { auth, firestore, collectionName } from "../../firebase";
import { Modal } from 'react-responsive-modal';
import logo from '../../assets/img/logo2.png';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: "",
                password: ""
            },
            loginProgress: false,
            ForgotPasswordProgress: false,
            errors: {},
            commonError: "",
            commonSuccess: "",
            recoveryEmail: "",
            recoveryEmailError: "",
            opneModal: false
        };
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please provide an email address.";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Please provide a valid email address";
            }
        }
        //Password
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please provide a password.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChange(field, e) {
        // Delete field from error object
        let errors = this.state.errors;
        delete errors[field];
        this.setState({ errors: errors });
        // Add field in fields object
        let fields = this.state.fields;
        if (e.target.type !== "checkbox") {
            fields[field] = e.target.value;
        } else {
            fields[field] = e.target.checked;
        }
        this.setState({ commonSuccess: "" });
        this.setState({ fields });
    }

    LoginFormSubmit(e) {
        e.preventDefault();
        this.setState({ loginProgress: true });
        if (this.handleValidation()) {
            try {
                let fields = this.state.fields;
                auth
                    .signInWithEmailAndPassword(fields['email'], fields['password'])
                    .then(async (user) => {
                        //If user logged in successfully
                        firestore.collection(collectionName.USERS).doc(user.user.uid).onSnapshot(async (snapshot) => {
                            if (snapshot.exists) {
                                let userData = snapshot.data();
                                if (userData.vendorID && userData.vendorID !== "") {
                                    let vendor = await firestore.collection(collectionName.VENDOR).doc(userData.vendorID).get();
                                    if (vendor.exists) {
                                        let vendorData = {
                                            email: userData.email,
                                            displayName: (userData.firstName ? userData.firstName : ""),
                                            uid: userData.id,
                                            id: userData.vendorID,
                                            vendorAllDetails: vendor.data()
                                        };
                                        localStorage.setItem("vendor", JSON.stringify(vendorData));
                                        this.setState({ loginProgress: false });
                                        this.props.vendorChange();
                                        this.props.history.push("/order_management");
                                    } else {
                                        this.setState({ loginProgress: false });
                                        this.setState({ 'commonError': 'Incorrect E-mail Address or Password' })
                                        auth.signOut();
                                        return;
                                    }
                                }
                                else {
                                    this.setState({ loginProgress: false });
                                    this.setState({ 'commonError': 'Incorrect E-mail Address or Password' })
                                    auth.signOut();
                                }
                            }
                            else {
                                this.props.history.push("/set_new_password");
                            }
                        })
                    })
                    .catch((error) => {
                        console.error("signInWithEmailAndPassword error", error)
                        this.setState({ loginProgress: false });
                        this.setState({ 'commonError': 'Incorrect E-mail Address or Password' })
                        auth.signOut();
                    });
            }
            catch (error) {
                console.error("Internal error: ", error);
                this.setState({ loginProgress: false });
                this.setState({ "commonError": "Oops! We encountered an error. Please try again." });
                auth.signOut();
            }
        } else {
            this.setState({ loginProgress: false });
        }
    }

    validateRecoveryEmail(email = "") {
        let recoveryEmail = email && email !== "" ? email : this.state.recoveryEmail;
        let isError = false;
        if (recoveryEmail === "") {
            this.setState({ recoveryEmailError: "Email ID is required" });
            isError = true;
        } else {
            let lastAtPos = recoveryEmail.lastIndexOf('@');
            let lastDotPos = recoveryEmail.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && recoveryEmail.indexOf('@@') === -1 && lastDotPos > 2 && (recoveryEmail.length - lastDotPos) > 2)) {
                this.setState({ recoveryEmailError: "Email ID is not in the correct format" });
                isError = true;
            } else {
                this.setState({ recoveryEmailError: "" });
                isError = false;
            }
        }
        return isError;
    }

    handleChangeForgotPassword(e) {
        this.setState({ [e.target.name]: e.target.value });
        this.validateRecoveryEmail(e.target.value);
    }

    onForgotPasswordSubmit(e) {
        e.preventDefault();
        let self = this;
        self.setState({ ForgotPasswordProgress: true });
        let isError = self.validateRecoveryEmail();
        if (!isError) {
            auth.sendPasswordResetEmail(self.state.recoveryEmail)
                .then(() => {
                    let loginFields = self.state.fields;
                    loginFields.email = self.state.recoveryEmail;
                    self.setState({ fields: loginFields });
                    self.setState({ commonSuccess: 'Reset password link sent on your Email.' });
                    self.setState({ ForgotPasswordProgress: false });
                    self.onCloseModal(e);
                })
                .catch(error => {
                    console.error("Forgot password error: ", error);
                    self.setState({ ForgotPasswordProgress: false });
                    self.setState({ commonError: "Oops! We encountered an error. Please try again." })
                    self.onCloseModal(e);
                });
        }
    }

    onOpenModal(e) {
        e.preventDefault();
        this.setState({ opneModal: true });
    }

    onCloseModal(e) {
        e.preventDefault();
        this.setState({ recoveryEmail: "", recoveryEmailError: "" });
        this.setState({ opneModal: false });
    }

    render() {
        return (
            <div className="ms-auth-container custom-auth-container">
                <div className="ms-auth-col custom-auth-col-4">
                    <div className="ms-auth-bg"></div>
                </div>
                <div className="ms-auth-col custom-auth-col-8">
                    <div className="ms-auth-form page-form">

                        <form onSubmit={this.LoginFormSubmit.bind(this)}>
                            {this.state.commonSuccess !== "" && <div className="alert alert-success" role="alert">
                                {this.state.commonSuccess}
                            </div>}
                            {this.state.commonError !== "" && <div className="alert alert-danger" role="alert">
                                {this.state.commonError}
                            </div>}
                            <img src={logo} alt="sVang" className="mb-3" />
                            <h4>Login to Account</h4>
                            <p>Please enter your email and password to continue</p>
                            <div className="mb-3">
                                <label htmlFor="validationCustom08">Email Address</label>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className={"form-control " + (this.state.errors["email"] ? 'invalid' : '')}
                                        id="validationCustom08"
                                        placeholder="Email Address"
                                        onChange={this.handleChange.bind(this, "email")}
                                        onBlur={this.handleChange.bind(this, "email")}
                                        value={this.state.fields["email"]}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.errors["email"]}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="validationCustom09">Password</label>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className={"form-control " + (this.state.errors["password"] ? 'invalid' : '')}
                                        id="validationCustom09"
                                        placeholder="Password"
                                        onChange={this.handleChange.bind(this, "password")}
                                        value={this.state.fields["password"]}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.errors["password"]}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="ms-checkbox-wrap">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        name="remember_me"
                                        onClick={this.handleChange.bind(this, "remember_me")}
                                        value={this.state.fields["remember_me"]} />
                                    <i className="ms-checkbox-check"></i>
                                </label>
                                <span> Remember Password </span>
                                <label className="d-block mt-3"
                                ><a
                                    href="/"
                                    className="btn-link"
                                    onClick={this.onOpenModal}
                                >Forgot Password?</a
                                    ></label
                                >
                            </div>
                            <button
                                className="btn btn-primary mt-4 d-block w-100"
                                type="submit"
                            >

                                Sign In  {this.state.loginProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </form>
                    </div>
                </div>

                <Modal open={this.state.opneModal} onClose={this.onCloseModal} styles={{ modal: { width: '20%' } }} center>
                    <div className="container">
                        <h5>Forgot Password?</h5>
                        <p>Enter your email to recover your password</p>
                        <form method="post" onSubmit={this.onForgotPasswordSubmit.bind(this)}>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className={"form-control " + (this.state.errors["email"] ? 'invalid' : '')}
                                        placeholder="Email Address"
                                        onChange={this.handleChangeForgotPassword.bind(this)}
                                        value={this.state.recoveryEmail}
                                        name="recoveryEmail"
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.recoveryEmailError}
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary float-right">
                                Reset Password {this.state.ForgotPasswordProgress && <i className="fas fa-spinner fa-pulse"></i>}
                            </button>
                        </form>

                    </div>
                </Modal>
            </div>
        );
    }
}

export default Login;