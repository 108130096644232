import React from 'react';
// import { Link } from "react-router-dom";

import RestaurantBasicDetails from './basic-details';
// import RestaurantFilterDetails from './filter-details';
import RestaurantCategoryDetails from './category-details';
import RestaurantOffdayDetails from './offday-details';
import RestaurantScheduleDetails from './schedule-details';
import RestaurantUniversityDetails from './university-affiliations';
import SettingsDetails from './setting-details';
import UploadDetails from './upload-details';
import '../../../footer';
import { getLoginUserDetails } from '../../../utils';

class StoreManagement extends React.Component {
    constructor(props) {
        super(props);
        let vendor = getLoginUserDetails();
        this.state = {
            isOpenFooter: false,
            isShowFooter: false,
            vendorAllDetails: vendor.vendorAllDetails,
            dateFormat: vendor.vendorAllDetails.dateFormat ? vendor.vendorAllDetails.dateFormat : 'MM-DD-YYYY',
            tabObj: {
                tab1: true,
                tab2: false,
                tab3: false,
                tab4: false,
                tab5: false,
                tab6: false,
                tab7: false
            }
        }
        this.onDateFormatChange = this.onDateFormatChange.bind(this);
        this.onVendorChange = this.onVendorChange.bind(this);
        this.homeClick = this.homeClick.bind(this);
        this.saveData = this.saveData.bind(this);
    }
    
    onDateFormatChange(format){
        this.setState({dateFormat: format});
    }

    onVendorChange(){
        this.props.vendorChange();
    }
    homeClick() {
        let self = this;
        if (self.state.isOpenFooter) {
            global.config.sidebarFooter = true;
            self.setState({
                isShowFooter: true
            });
        } else {
            self.setState({
                isOpenFooter: false,
                isShowFooter: false
            });
            self.props.history.push(`/order_management?tab=today`);
        }
    }
    onChangeTab(key) {
        let self = this;
        const isOpenFooter = self.state.isOpenFooter;
        if (isOpenFooter) {
            global.config.sidebarFooter = true;
            self.setState({
                isShowFooter: true
            });
            return;
        }
        let tabObj = this.state.tabObj;
        let ttabObj = {};
        for (const prop in tabObj) {
            if (prop === key) {
                ttabObj[prop] = true;
            } else {
                ttabObj[prop] = false;
            }
        }
        this.setState({
            tabObj:ttabObj
        })
    }

    saveData(status) {
        let self = this;
        self.setState({
            isOpenFooter: status,
            isShowFooter: status
        })
    }
    render() {
        let { vendorAllDetails, dateFormat, tabObj, isOpenFooter, isShowFooter } = {...this.state}
        if (isOpenFooter) {
            global.config.context = true;
        } else {
            global.config.context = false;
        }
        if (global.config.sidebarFooter) {
            isShowFooter = true;
        }
        return (
            <div className="row store-management-page">
                <div className="col-md-12">
                    <nav aria-label="breadcrumb" className="page-nav-info mb-1"> 
                    <span className="page-title">Store</span>
                        <ol className="breadcrumb pl-0 pt-0">
                            {/* eslint-disable-next-line */}
                            <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                            {/* <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li> */}
                            <li className="breadcrumb-item active" aria-current="page">Store</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-md-12 pl-3 pr-3">
                        <ul
                            className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4 mt-0"
                            role="tablist"
                        >
                            <li role="presentation">
                                {/* <a
                                    href="#tab1"
                                    aria-controls="tab1"
                                    className="active show"
                                    role="tab"
                                    data-toggle="tab"
                                >
                                    Store Details
                                </a> */}
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab1')}
                                    className={"pointer " + (tabObj.tab1 ? "active show": "")}
                                    // aria-controls="tab1"
                                >
                                    Store Details
                                </a>
                            </li>
                            <span className="tab-separator"></span>
                            {/* <li role="presentation">
                                <a
                                    href="#tab2"
                                    aria-controls="tab2"
                                    role="tab"
                                    data-toggle="tab"
                                >
                                    Category Details
                      </a>
                            </li> */}
                            <span className="tab-separator"></span>
                            <li role="presentation">
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab3')}
                                    className={"pointer " + (tabObj.tab3 ? "active show": "")}
                                    // href="#tab3"
                                    // aria-controls="tab3"
                                    // role="tab"
                                    // data-toggle="tab"
                                >
                                    Off-days Details
                      </a>
                            </li>
                            <span className="tab-separator"></span>
                            <li role="presentation">
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab4')}
                                    className={"pointer " + (tabObj.tab4 ? "active show": "")}
                                    // href="#tab4"
                                    // aria-controls="tab4"
                                    // role="tab"
                                    // data-toggle="tab"
                                >
                                    Schedule Details
                      </a>
                            </li>
                            <span className="tab-separator"></span>
                            <li role="presentation">
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab5')}
                                    className={"pointer " + (tabObj.tab5 ? "active show": "")}
                                    // href="#tab5"
                                    // aria-controls="tab5"
                                    // role="tab"
                                    // data-toggle="tab"
                                >
                                    University affiliations
                      </a>
                            </li>
                            <span className="tab-separator"></span>
                            <li role="presentation">
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab6')}
                                    className={"pointer " + (tabObj.tab6 ? "active show": "")}
                                    // href="#tab6"
                                    // aria-controls="tab6"
                                    // role="tab"
                                    // data-toggle="tab"
                                >
                                    Upload
                      </a>
                            </li>
                            <span className="tab-separator"></span>
                            <li role="presentation">
                                {/* eslint-disable-next-line */}
                                <a
                                    onClick={() => this.onChangeTab('tab7')}
                                    className={"pointer " + (tabObj.tab7 ? "active show": "")}
                                    // href="#tab7"
                                    // aria-controls="tab7"
                                    // role="tab"
                                    // data-toggle="tab"
                                >
                                    Settings
                      </a>
                            </li>
                            
                        </ul>
                        <div className="tab-content">
                            {tabObj.tab1 &&
                                <div role="tabpanel" className={tabObj.tab1 ? "tab-pane active show fade in" : ""} id="tab1">
                                    <RestaurantBasicDetails vendorAllDetails={vendorAllDetails} dateFormat={dateFormat} 
                                    DateFormatChange={(value) => this.onDateFormatChange(value)} vendorChange={this.onVendorChange}
                                    saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                            {/* <div role="tabpanel" className="tab-pane fade" id="tab2">
                                <RestaurantFilterDetails />
                            </div> */}
                            {tabObj.tab2 &&
                                <div role="tabpanel" className={tabObj.tab2 ? "tab-pane active show fade in" : ""} id="tab2">
                                    <RestaurantCategoryDetails vendorAllDetails={vendorAllDetails} />
                                </div>
                            }
                            {tabObj.tab3 &&
                                <div role="tabpanel" className={tabObj.tab3 ? "tab-pane active show fade in" : ""} id="tab3">
                                    <RestaurantOffdayDetails vendorAllDetails={vendorAllDetails} dateFormat={dateFormat} 
                                        saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                            {tabObj.tab4 && 
                                <div role="tabpanel" className={tabObj.tab4 ? "tab-pane active show fade in" : ""} id="tab4">
                                    <RestaurantScheduleDetails vendorAllDetails={vendorAllDetails} 
                                        saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                            {tabObj.tab5 && 
                                <div role="tabpanel" className={tabObj.tab5 ? "tab-pane active show fade in" : ""} id="tab5">
                                    <RestaurantUniversityDetails vendorAllDetails={vendorAllDetails}
                                        saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                            {tabObj.tab6 && 
                                <div role="tabpanel" className={tabObj.tab6 ? "tab-pane active show fade in" : ""} id="tab6">
                                    <UploadDetails vendorAllDetails={vendorAllDetails}
                                        saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                            {tabObj.tab7 && 
                                <div role="tabpanel" className={tabObj.tab7 ? "tab-pane active show fade in" : ""} id="tab7">
                                    <SettingsDetails vendorAllDetails={vendorAllDetails}
                                        saveData={this.saveData} isShowFooter={isShowFooter}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                

            </div>
        );
    }
}

export default StoreManagement;