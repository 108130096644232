import React from 'react'
// import { Link } from "react-router-dom";
import queryString from 'query-string';
// import Spinner from '../../spinner';
import { getLoginUserDetails } from '../../../utils';
import MenuDetails from './menu-details';
// import BulkChangePriceDetails from './bulk-change-price-details';
import CategoryDetails from './category-details';
import ModifiersDetails from './modifiers-details';
// import ModifierDetails from './modifier-details';
// import UpsellDetails from './upsell-details';
// import OptionalItemsDetails from './optional-items-details';
// import AdditionOptionsDetails from './addition-options-details';
// import AdditionOptionsWithPriceDetails from './addition-options-with-price-details';
// import AllItemSelectionDetails from './all-item-selection-details';
// import LinkTemplatesDetails from './link-template-details';

import { axiosInstance, getAuthTokenFromLocalStorage, getAuth } from '../../../utils';

class MenuManagement extends React.Component {
  constructor(props) {
    super(props);
    let activeTab = queryString.parse(this.props.location.search).tab;
    if(!activeTab || activeTab === ""){
      activeTab = "menus";
    }
    let vendor = getLoginUserDetails();
    this.state = {
      isOpenFooter: false,
      isShowFooter: false,
      isPageLoad: true,
      reRendor: false,
      tabObj: {
        tab1: true,
        tab2: false,
        tab3: false,
      },
      activeTab: activeTab,
      vendorAllDetails: vendor.vendorAllDetails,
      productsData: []
    }
    this.onTabChange = this.onTabChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.homeClick = this.homeClick.bind(this);
  }

  componentDidMount() {
    this.getProductsList();
  }
  async getProductsList() {
    let self = this;
    let vendor = JSON.parse(localStorage.getItem('vendor'));
    let vendorId = vendor.id;
    let url = `menu/product/${vendorId}`;
    try {
      let token = getAuthTokenFromLocalStorage();
      if (!token) {
        let auth = await getAuth();
        if (auth && auth.success && auth.token) {
          localStorage.setItem('token', auth.token);
        }
      }
      axiosInstance.get(url)
        .then(result => {
          // If auth token expired
          if("success" in result.data && result.data.success === false){
            localStorage.removeItem('token');
            self.getProductsList();
            return;
          }
          let products = result.data.response;
          self.setState({ productsData: products, isPageLoad: false });
        })
        .catch(error => {
          self.setState({ productsData: [], isPageLoad: false });
          console.error("Problem in fetching vendor product: ",error);
        })
    }
    catch (error) {
      self.setState({ productsData: [], isPageLoad: false });
      console.error("Problem in fetching vendor product: ",error);
    }
  }
  saveData(status) {
    let self = this;
    self.setState({
        isOpenFooter: status,
        isShowFooter: status
    })
  }
  homeClick() {
    let self = this;
    if (self.state.isOpenFooter) {
        global.config.sidebarFooter = true;
        self.setState({
            isShowFooter: true
        });
    } else {
        self.setState({
            isOpenFooter: false,
            isShowFooter: false
        });
        self.props.history.push(`/order_management?tab=today`);
    }
  }
  onTabChange(key){
    // this.props.onClick();
    let self = this;
        const isOpenFooter = self.state.isOpenFooter;
        if (isOpenFooter) {
            global.config.sidebarFooter = true;
            self.setState({
                isShowFooter: true
            });
            return;
        }
        let tabObj = this.state.tabObj;
        let ttabObj = {};
        for (const prop in tabObj) {
            if (prop === key) {
                ttabObj[prop] = true;
            } else {
                ttabObj[prop] = false;
            }
        }
        this.setState({
            tabObj:ttabObj
        })
    // this.setState({activeTab: tabName});
    // this.setState({
    //   reRendor: false
    // })
  }
  render() {
    // const { vendorAllDetails, activeTab, productsData, isPageLoad} = { ...this.state }
    // const { vendorAllDetails, activeTab, productsData, reRendor} = { ...this.state };
    let { vendorAllDetails, productsData, reRendor, tabObj, isOpenFooter, isShowFooter} = { ...this.state };
    if (isOpenFooter) {
        global.config.context = true;
    } else {
        global.config.context = false;
    }
    if (global.config.sidebarFooter) {
        isShowFooter = true;
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb" className="page-nav-info mb-1">
            <span className="page-title">Menu</span>
            <ol className="breadcrumb pl-0 pt-0">
                {/* eslint-disable-next-line */}
                <li className="breadcrumb-item"><a className="common-link" onClick={this.homeClick}> <i className="material-icons">home</i> Home</a></li>
                {/* <li className="breadcrumb-item"><Link to="/order_management?tab=today"> <i className="material-icons">home</i> Home</Link></li> */}
                <li className="breadcrumb-item active" aria-current="page">Menu</li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12 pl-3 pr-3">
          <ul className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4 mt-0" role="tablist">
            <li role="presentation">
              {/* eslint-disable-next-line */}
              <a
                // to="/menu_management?tab=menus"
                // aria-controls="tab1"
                className={"pointer " + (tabObj.tab1 ? "active show": "")}
                // role="tab"
                // data-toggle="tab"   
                onClick={() => {this.onTabChange("tab1")}}                 
              >
                Menu
              </a>
            </li>
            <span className="tab-separator"></span>
            <li role="presentation">
              {/* eslint-disable-next-line */}
              <a
                // to="/menu_management?tab=category"
                // aria-controls="tab3"
                className={"pointer " + (tabObj.tab2 ? "active show": "")}
                // role="tab"
                // data-toggle="tab"   
                onClick={() => {this.onTabChange("tab2")}}
              >
                Categories
              </a>
            </li>
            <span className="tab-separator"></span>
            <li role="presentation">
              {/* eslint-disable-next-line */}
              <a
                // to="/menu_management?tab=modifiers"
                // aria-controls="tab11"
                className={"pointer " + (tabObj.tab3 ? "active show": "")}
                // role="tab"
                // data-toggle="tab"   
                onClick={() => {this.onTabChange("tab3")}}
              >
                Modifiers
              </a>
            </li>
            {/* <span className="tab-separator"></span> */}
            {/* <li role="presentation">
              <Link
                to="/menu_management?tab=bulkchangeprice"
                aria-controls="tab2"
                className={activeTab && (activeTab === "" || activeTab.includes('bulkchangeprice')) ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("bulkchangeprice")}}
              >
                Bulk Change Price
              </Link>
            </li>
            <span className="tab-separator"></span> */}
            {/* <li role="presentation">
              <Link
                to="/menu_management?tab=modifiers"
                aria-controls="tab4"
                className={activeTab && (activeTab === "" || activeTab.includes('modifiers')) ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("modifiers")}}                 
              >
                Modifiers
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=upsell"
                aria-controls="tab5"
                className={activeTab && (activeTab === "" || activeTab.includes('upsell')) ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("upsell")}}
              >
                Upsell
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=multipleitems"
                aria-controls="tab6"
                className={activeTab && (activeTab === "" || activeTab.includes('multipleitems')) ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("multipleitems")}}
              >
                Multiple Items
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=allitemselection"
                aria-controls="tab7"
                className={activeTab && (activeTab === "" || activeTab === 'allitemselection') ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("allitemselection")}}
              >
                All Item
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=oneitemselectionwithnoprice"
                aria-controls="tab8"
                className={activeTab && (activeTab === "" || activeTab === 'oneitemselectionwithnoprice') ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("oneitemselectionwithnoprice")}}
              >
                One Item Without Price
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=oneitemselectionwithprice"
                aria-controls="tab9"
                className={activeTab && (activeTab === "" || activeTab === 'oneitemselectionwithprice') ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("oneitemselectionwithprice")}}
              >
                One Item With Price
              </Link>
            </li> */}
            {/* <span className="tab-separator"></span>
            <li role="presentation">
              <Link
                to="/menu_management?tab=linktemplates"
                aria-controls="tab10"
                className={activeTab && (activeTab === "" || activeTab === 'linktemplates') ?  "active show" : ""}
                role="tab"
                data-toggle="tab"   
                onClick={() => {this.onTabChange("linktemplates")}}
              >
                Linked Templates
              </Link>
            </li> */}
          </ul>
          <div className="tab-content">
            {tabObj.tab1 &&
              <div role="tabpanel" className={tabObj.tab1 ? "tab-pane active show fade in" : ""} id="tab1">
                    {reRendor &&  <MenuDetails productsData={productsData} />}
                    {!reRendor &&  <MenuDetails productsData={productsData} />}
              </div>
            }
            {tabObj.tab2 &&
              <div role="tabpanel" className={tabObj.tab2 ? "tab-pane active show fade in" : ""} id="tab2">
                  <CategoryDetails vendorAllDetails={vendorAllDetails} saveData={this.saveData} isShowFooter={isShowFooter} />
              </div>
            }
            {tabObj.tab3 &&
              <div role="tabpanel" className={tabObj.tab3 ? "tab-pane active show fade in" : ""} id="tab3">
                  <ModifiersDetails vendorAllDetails={vendorAllDetails} />
              </div>
            }
            
            {/* <div role="tabpanel"
              className={activeTab && (activeTab.includes('bulkchangeprice')) ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab3">
                <BulkChangePriceDetails vendorAllDetails={vendorAllDetails} reRendor={reRendor} customAlert={(type) => {
                  this.onTabChange(type);
                  this.getProductsList();
                  this.setState({
                    reRendor: true
                  })
                  }} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab.includes('modifiers')) ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab4">
                  <ModifierDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab.includes('upsell')) ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab5">
                  <UpsellDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab.includes('multipleitems')) ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab6">
                  <OptionalItemsDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab === 'allitemselection') ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab7">
                  <AllItemSelectionDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab === 'oneitemselectionwithnoprice') ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab8">
                  <AdditionOptionsDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab === 'oneitemselectionwithprice') ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab9">
                  <AdditionOptionsWithPriceDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
            {/* <div role="tabpanel"
              className={activeTab && (activeTab === 'linktemplates') ?  "tab-pane active fade in show" : "tab-pane fade"}
              id="tab10">
                  <LinkTemplatesDetails productsData={productsData} vendorAllDetails={vendorAllDetails} />
            </div> */}
          </div>
        </div>
        <div className="col-md-12">
        {/* {isPageLoad && 
            <div className="row">
                <div className="col-md-12 pl-0">
                    <Spinner />
                </div>
            </div>
        } */}
        </div>
      </div>
    );
  }
}

export default MenuManagement;